/**
 * Sklonovanie frazy 'záznam' podla poctu zaznamov
 *
 * @param {*} recordCount
 * @return {*} 
 */
export const getTotalText = (recordCount) => {
  if (recordCount === 1) {
    return `záznam`;
  } else if (recordCount >= 2 && recordCount <= 4) {
    return `záznamy`;
  } else {
    return `záznamov`;
  }
};

/**
 * Sklonovanie frazy 'načítaný záznam' podla poctu zaznamov
 *
 * @param {*} recordCount
 * @return {*} 
 */
export const getLoadedText = (recordCount) => {
  if (recordCount === 1) {
    return `načítaný záznam`;
  } else if (recordCount >= 2 && recordCount <= 4) {
    return `načítané záznamy`;
  } else {
    return `načítaných záznamov`;
  }
};