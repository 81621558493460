import { useState, useEffect } from 'react';
import classNames from "classnames";
import PropTypes from 'prop-types';
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import withStyles from '@mui/styles/withStyles';
import SaveIcon from '@mui/icons-material/Save'

import withRouter from '../../coraWebMComponents/utils/withRouter';
import ArrowBack from "../../coraWebMComponents/dataDisplay/icons/ArrowBack";
import IconButton from "../../coraWebMComponents/inputs/IconButton";
import ListItemMenu from "../../coraWebMComponents/navigation/ListItemMenu";
import getSharedStyles from "../../coraWebMComponents/sharedStyles";
import Button from "../../coraWebMComponents/inputs/Button";
import EditIcon from "../../coraWebMComponents/dataDisplay/icons/EditIcon";
import Dialog from "../../coraWebMComponents/feedBack/Dialog";

import routes from '../../routes';
import { findDeepMenu } from '../../App';
import { adminModeSetCurrEdited, adminModeSetEditedData, adminModeUpdateOrAdd } from "../../actions/adminModeActions";
import { PUBLIC_ZONE_SUBMENU, ISpMnu } from "../../constants/adminConsts";

const styles = (theme) => ({
  ...getSharedStyles(theme),
});

const PzMnuComponent = (props) => {

  const [openDialog, setOpenDialog] = useState(false);
  const [pathActual, setPathActual] = useState(null);

  useEffect(() => {
    setPathActual(props.location.pathname);
  }, [props.location.pathname]);

  if (props.location.pathname !== pathActual)
    return (<></>);

  const handleClickMenu = (route) => {
    props.navigate({ pathname: route });
  };

  const handleCurrEdited = (e, value) => {
    e.stopPropagation();
    props.dispatch(adminModeSetCurrEdited(value));
    props.dispatch(adminModeSetEditedData(null));
  };

  const handleEditData = (data) => {
    props.dispatch(adminModeSetEditedData(data));
  };

  const handleSaveSection = async (e) => {
    e.stopPropagation();
    const { dataEdited } = props.adminMode;
    props.dispatch(adminModeSetCurrEdited(null));
    await props.dispatch(adminModeUpdateOrAdd(ISpMnu, dataEdited));
    // props.initMenu();
    handleCloseDialog();
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const getEditButton = (drawer) => {
    const { classes } = props;
    return (
      <Button
        variant="contained"
        color="secondary"
        className={classNames(classes.w100, classes.textTransformNone, classes.adminButton)}
        onClick={(e) => handleCurrEdited(e, drawer)}
        startIcon={<EditIcon />}
      >
        Upraviť
      </Button>
    );
  };

  const getSaveButton = () => {
    const { classes } = props;
    return (
      <Button
        variant="contained"
        color="secondary"
        type="success"
        className={classNames(classes.w100, classes.textTransformNone)}
        onClick={handleOpenDialog}
        startIcon={<SaveIcon />}
      >
        Uložiť
      </Button>
    );
  };

  const actualMenu = findDeepMenu(props.menuTree, (x) => x.Route === props.location.pathname);
  let backRoute = routes.PZ;
  if (actualMenu && actualMenu.NadMenuID) {
    const foundMenu = findDeepMenu(props.menuTree, (x) => x.MenuID === actualMenu.NadMenuID);
    if (foundMenu && foundMenu.Route) {
      backRoute = foundMenu.Route;
    }
  }
  const subMenu = actualMenu?.children.filter((x) => x.Visible === 1);
  const { classes } = props;
  const { currEdited, isActive } = props.adminMode;

  return (<>
    <div className="container" style={{ marginTop: "70px" }}>
      <Link to={backRoute}>
        <IconButton
          className={classNames(classes.backButton, classes.navigationBackButton, classes.ml1, classes.mr6)}
          toolTip={""}
          toolTipPlace="right"
          size="large">
          <ArrowBack
            variant="fab"
            style={{ fontSize: 30 }}
          />
        </IconButton>
      </Link>
      <ListItemMenu
        color="secondary"
        menu={subMenu}
        // onClick={handleClickMenu}
        onClick={currEdited !== PUBLIC_ZONE_SUBMENU ? handleClickMenu : null}
        paperClasses={classNames(classes.ml3, classes.mr3, classes.mb3)}
        inEdit={currEdited === PUBLIC_ZONE_SUBMENU}
        onEdit={handleEditData}
      />
      {isActive &&
        currEdited !== PUBLIC_ZONE_SUBMENU &&
        getEditButton(PUBLIC_ZONE_SUBMENU)}
      {isActive && currEdited === PUBLIC_ZONE_SUBMENU && getSaveButton()}
    </div>
    <Dialog
      modal={false}
      open={openDialog}
      onClose={() => { }}
      contentText="Informácia: Potvrdením uložíte zmeny"
      title="Uloženie nastavení"
      actions={[
        {
          label: "Nie",
          color: "primary",
          onClick: handleCloseDialog,
          keyboardFocused: false,
        },
        {
          label: "Áno",
          color: "secondary",
          onClick: (e) => handleSaveSection(e),
          keyboardFocused: true,
        },
      ]}
    />
  </>);
};

PzMnuComponent.propTypes = {
  menuTree: PropTypes.object.isRequired,
  isAuth: PropTypes.bool
};

const mapStateToProps = (state) => {
  return {
    user: state.user.data,
    isAuth: state.user.data !== null,
    listPeople: state.listPeople.data,
    person: state.person.data,
    visiblePublicZone: state.visiblePublicZone.data.Data,
    egovHandlerUrl: state.egovHandlerUrl.data.Data,
    adminMode: state.adminMode,
  };
};

export default withRouter(connect(mapStateToProps)(withStyles(styles)(PzMnuComponent)));
