import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';

import withStyles from '@mui/styles/withStyles';
import InputAdornment from '@mui/material/InputAdornment';
import CloseIcon from '@mui/icons-material/Close';

import Paper from '../../coraWebMComponents/surfaces/Paper';
import Button from '../../coraWebMComponents/inputs/Button';
import Typography from '../../coraWebMComponents/dataDisplay/Typography';
import ArrowBack from '../../coraWebMComponents/dataDisplay/icons/ArrowBack';
import getSharedStyles from '../../coraWebMComponents/sharedStyles';
import TextField from '../../coraWebMComponents/inputs/TextField';
import Recaptcha from '../../coraWebMComponents/Recaptcha';
import RaisedButton from '../../coraWebMComponents/inputs/RaisedButton';
import ArrowForward from '../../coraWebMComponents/dataDisplay/icons/ArrowForward';
import CarIcon from '../../coraWebMComponents/dataDisplay/icons/CarIcon';
import ParkingCardIcon from '../../coraWebMComponents/dataDisplay/icons/ParkingCard';
import withRouter from '../../coraWebMComponents/utils/withRouter';
import { isValidEcv } from '../../coraWebMComponents/utils/validator';
import { convertDate } from '../../coraWebMComponents/utils/convert';
import IconButton from '../../coraWebMComponents/inputs/IconButton';
import withWidth from '../../coraWebMComponents/views/withWidth';

import routes from '../../routes';
import { getParkCardVerify } from '../../utils/publicZone/publicUtils';
import * as userActions from '../../actions/userActions';

const styles = theme => ({
  ...getSharedStyles(theme),
});

const PublicCardVerify = (props) => {
  const [ECV, setECV] = React.useState('');
  const [recaptcha, setRecaptcha] = React.useState('');
  const [errorTextECV, setErrorTextECV] = React.useState('');
  const [data, setData] = React.useState(null);

  const handleECV = (_name, value) => {
    value = value.toUpperCase();
    value = value.trim();

    const errorTextAddCarEcv = isValidEcv(value, props.locale);

    setErrorTextECV(errorTextAddCarEcv);
    setECV(value);
  };

  const handleRecaptcha = (token) => {
    setRecaptcha(token);
  };

  const handleBack = () => {
    props.navigate({ pathname: routes.PRELOGIN });
  };

  const onClose = () => {
    setECV('');
    setErrorTextECV('');
    setData(null);
  };

  const handleVerify = async () => {
    const errorTextAddCarEcv = isValidEcv(ECV, props.locale);
    setErrorTextECV(errorTextAddCarEcv);
    if (errorTextAddCarEcv) {
      return;
    }

    const res = await getParkCardVerify(ECV).then(x => x.Data);
    if (!res) {
      props.dispatch(userActions.showErrorMsg("Pre zadané EČV neexistuje platná karta"));
    }
    setData(res);
  };

  const { classes, isMobile } = props;
  return (
    <div className='container' style={{ marginTop: '70px' }}>
      <Paper className={classNames(classes.paperCardNoAppbar, !isMobile && classes.mt4)} elevation={isMobile ? 0 : 2} >
        <Button
          color="inherit"
          className={classNames(classes.navigationBackButton)}
          aria-label="Prihlásenie"
          onClick={handleBack}
        >
          <ArrowBack
            fontSize='default'
            variant='fab'
          />
        </Button>
        <Typography variant="subtitle1" className={classNames(classes.mb4, classes.textCenter)}>Overenie parkovacej karty</Typography>
        <div >
          <TextField
            label="EČV"
            fullwidth={true}
            name='ECV'
            value={ECV}
            errorText={errorTextECV}
            error={errorTextECV ? true : false}
            onChange={handleECV}
            inputProps={{
              startAdornment: (
                <InputAdornment position="start" style={{ marginLeft: '-2px' }}>
                  <CarIcon />
                </InputAdornment>
              ),
              endAdornment: (
                <InputAdornment position="end">
                  <IconButton disableTooltip={true} size='small' onClick={onClose} ><CloseIcon fontSize='small' /></IconButton>
                </InputAdornment>
              )
            }}
            variant="standard"
          />
          <div className={classNames(classes.mt4)}>
            <Recaptcha onChange={handleRecaptcha} />
          </div>

          <div className={classNames(classes.row, classes.justifyContentCenter)}>
            <RaisedButton
              disabled={isValidEcv(ECV, props.locale) || !recaptcha}
              onClick={handleVerify}
              className={classNames(classes.raisedButton)}
            >
              <div className={classNames(classes.row, classes.w100, classes.alignItemsCenter, classes.justifyContentBetween)}>
                <Typography
                  variant="button" style={{ color: '#fff' }}>
                  Overiť
                </Typography>
                <ArrowForward
                  fontSize='large'
                />
              </div>
            </RaisedButton>
          </div>
          {data &&
            <>
              <div className={classNames(props.classes.row, props.classes.alignItemsCenter, props.classes.justifyContentStart, props.classes.mb2, props.classes.mt4)}>
                <ParkingCardIcon style={{ width: "45", height: "30" }}></ParkingCardIcon>
                <div className={classNames(props.classes.ml2)}>
                  <Typography variant="button" style={{ fontWeight: 'bold' }}>{data.N_KARTA_TYP}</Typography>
                </div>
              </div>
              <div className={classNames(props.classes.row, props.classes.alignItemsCenter)} >
                <Typography variant="body1">EČV</Typography>
                <Typography variant="body2">{data.ECV}</Typography>
              </div>
              <div className={classNames(props.classes.row, props.classes.alignItemsCenter)} >
                <Typography variant="body1">Kategória</Typography>
                <Typography variant="body2">{data.N_KARTA_KAT}</Typography>
              </div>
              <div className={classNames(props.classes.row, props.classes.alignItemsCenter)} >
                <Typography variant="body1">Typ karty</Typography>
                <Typography variant="body2">{data.N_KARTA_TYP}</Typography>
              </div>
              <div className={classNames(props.classes.row, props.classes.alignItemsCenter)} >
                <Typography variant="body1">Dátum platnosti od</Typography>
                <Typography variant="body2">{convertDate(data.D_OD)}</Typography>
              </div>
              <div className={classNames(props.classes.row, props.classes.alignItemsCenter)} >
                <Typography variant="body1">Dátum platnosti do</Typography>
                <Typography variant="body2">{convertDate(data.D_DO)}</Typography>
              </div>
              <div className={classNames(props.classes.row, props.classes.alignItemsCenter)} >
                <Typography variant="body1">Číslo karty</Typography>
                <Typography variant="body2">{data.C_KARTA}</Typography>
              </div>
            </>
          }
        </div>
      </Paper >
    </div >
  );
};

export default withRouter(connect()(withStyles(styles)(withWidth()(PublicCardVerify))));