import PropTypes from 'prop-types';
import classNames from 'classnames';

import { Typography } from '@mui/material';
import Stack from '@mui/material/Stack';
import makeStyles from '@mui/styles/makeStyles';

import getSharedStyles from "../../coraWebMComponents/sharedStyles";
import IconButton from '../../coraWebMComponents/inputs/IconButton';
import ClearIcon from '../../coraWebMComponents/dataDisplay/icons/ClearIcon';

const useStyles = makeStyles((theme) => ({
  ...getSharedStyles(theme),
  titleContainer: {
    justifyContent: "flex-start",
  },
  dialogTitle: {
    boxSizing: "border-box",
    padding: theme.spacing(1),
  },
  closeButton: {
    color: theme.palette.grey[500],
  },
}));

/**
 * Header with close button
 *
 * @param {*} { title, onClose, variant, classes }
 * classes: 
 *  - titleContainer - na pretazenie Stacku pre titulok, napr. zarovnanie na stred: {justifyContent: "center"}
 *  - dialogTitle - na pretazenie Typography pre titulok
 *  - iconButton - na pretazenie IconButton pre close ikonu
 * @return {*} 
 */
const DialogHeader = ({ title, onClose, variant = "H6", classes }) => {
  const _classes = useStyles();

  return (
    <Stack justifyContent="space-between" alignItems={"baseline"} width="100%" spacing={1} direction={'row'}>
      <Stack flexGrow="1" className={classNames(_classes.titleContainer, classes?.titleContainer)} spacing={2} direction={'row'}>
        <Typography variant={variant} className={classNames(_classes.dialogTitle, classes?.dialogTitle)}>{title}</Typography>
      </Stack>
      <Stack flexGrow="0" justifyContent="flex-end" spacing={1} direction={'row'}>
        <IconButton
          aria-label="Close"
          toolTip='Zavieť okno'
          className={classNames(_classes.closeButton, classes?.iconButton)}
          onClick={onClose}
          size="large">
          <ClearIcon />
        </IconButton>
      </Stack>
    </Stack>
  );
};

DialogHeader.propTypes = {
  title: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  variant: PropTypes.string,
  classes: PropTypes.object,
};

export default DialogHeader;