import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import withStyles from '@mui/styles/withStyles';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import { Typography } from '@mui/material';

import ButtonFab from '../../coraWebMComponents/inputs/ButtonFab';
import { withLocalizationConsumer } from '../../coraWebMComponents/localization/withLocalization';
import storage from '../../coraWebMComponents/utils/withStorage';
import getSharedStyles from '../../coraWebMComponents/sharedStyles';

const styles = theme => ({
  ...getSharedStyles(theme),
  timeControlHolder: {
    borderRadius: '30px',
    border: '1px solid #e1e1e1'
  },
  btnOK: {
    backgroundColor: '#4caf50',
    borderRadius: '30px',
    height: '55px'
  },
});

class TimeDialogComponent extends React.Component {
  state = {
    fullWidth: true,
    maxWidth: 'sm',
  };

  render() {
    const { classes } = this.props;
    return (
      <Dialog
        fullWidth={this.state.fullWidth}
        maxWidth={this.state.maxWidth}
        open={this.props.data.showTimeDialog > 0}
        onClose={this.props.handleClose}
        aria-labelledby="max-width-dialog-title"
      >
        <DialogTitle>
          <DialogContentText style={{ textAlign: 'center' }}>
            <Typography variant="h6" inline="true">{this.props.data.dateTitle}</Typography>
          </DialogContentText>
          <DialogContentText style={{ textAlign: 'center' }}>
            <Typography variant="h4" inline="true" style={{ fontWeight: 'bold' }}>{this.props.data.dateValue}</Typography>
          </DialogContentText>
          <DialogContentText style={{ textAlign: 'center' }}>
            <Typography variant="caption">{this.props.data.captionOdDo}</Typography>
            <Typography variant="caption">{this.props.data.captionCena}</Typography>
          </DialogContentText>
        </DialogTitle>
        <DialogContent>
          <DialogContentText style={{ textAlign: 'center' }}>
            <Typography variant="h6" inline="true">{this.props.data.timeTitle}</Typography>
          </DialogContentText>
          <DialogContentText style={{ textAlign: 'center' }}>
            <Typography variant="h2" inline="true" style={{ fontWeight: 'bold' }}>{this.props.data.timeValue}</Typography>
          </DialogContentText>
          {!this.props.data.disableMinutes &&
            <div className={classNames(classes.timeControlHolder, classes.mb3)}>
              <div className={classNames(classes.row, classes.alignItemsCenter)} >
                <ButtonFab
                  // disabled={true ? false : true}
                  style={{ backgroundColor: '#ffffff' }}
                  onClick={_event => this.props.handleModifyTime(this.props.data.showTimeDialog === 2, 1)}
                  size='small'
                >
                  <Typography variant="h5" className={classNames(classes.textCenter)} >-</Typography>
                </ButtonFab>
                <Typography variant="body1" style={{ textAlign: 'center' }} >{this.props.data.btnMinutes}</Typography>
                <ButtonFab
                  // disabled={true ? false : true}
                  style={{ backgroundColor: '#ffffff' }}
                  onClick={_event => this.props.handleModifyTime(this.props.data.showTimeDialog === 2, 2)}
                  size='small'
                >
                  <Typography variant="h5" className={classNames(classes.textCenter)} >+</Typography>
                </ButtonFab>
              </div>
            </div>}
          <div className={classNames(classes.timeControlHolder, classes.mb3)}>
            <div className={classNames(classes.row, classes.alignItemsCenter)} >
              <ButtonFab
                // disabled={true ? false : true}
                style={{ backgroundColor: '#ffffff' }}
                onClick={_event => this.props.handleModifyTime(this.props.data.showTimeDialog === 2, 3)}
                size='small'
              >
                <Typography variant="h5" className={classNames(classes.textCenter)} >-</Typography>
              </ButtonFab>
              <Typography variant="body1" style={{ textAlign: 'center' }} >{this.props.data.btnHours}</Typography>
              <ButtonFab
                // disabled={true ? false : true}
                style={{ backgroundColor: '#ffffff' }}
                onClick={_event => this.props.handleModifyTime(this.props.data.showTimeDialog === 2, 4)}
                size="small"
              >
                <Typography variant="h5" className={classNames(classes.textCenter)} >+</Typography>
              </ButtonFab>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={this.props.handleClose}
            size="large"
            variant="contained"
            color="secondary"
            className={classNames(classes.btnOK, classes.w100)}
          >
            {"OK"}
          </Button>
        </DialogActions>
      </Dialog>
    );
  }
}

TimeDialogComponent.propTypes = {
  handleClose: PropTypes.func.isRequired,
  handleModifyTime: PropTypes.func.isRequired,
  data: PropTypes.object.isRequired,
  classes: PropTypes.object.isRequired
};

export default storage(withLocalizationConsumer(withStyles(styles)(TimeDialogComponent)));