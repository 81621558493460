import classNames from 'classnames';

import { Box, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';

import Paper from '../../coraWebMComponents/surfaces/Paper';
import IconButton from '../../coraWebMComponents/inputs/IconButton';
import ToolTip from '../../coraWebMComponents/inputs/ToolTip';
import Typography from '../../coraWebMComponents/dataDisplay/Typography';
import ArrowForward from '../../coraWebMComponents/dataDisplay/icons/ArrowForward';
import DogIcon from '../../coraWebMComponents/dataDisplay/icons/DogIcon';
import PersonIcon from '../../coraWebMComponents/dataDisplay/icons/PersonIcon';
import PeopleIcon from '../../coraWebMComponents/dataDisplay/icons/PeopleIcon';
import ParkingIcon from '../../coraWebMComponents/dataDisplay/icons/ParkingIcon';
import ParkingCard from '../../coraWebMComponents/dataDisplay/icons/ParkingCard';
import MapIcon from '../../coraWebMComponents/dataDisplay/icons/MapIcon';
import ParkingPermit from '../../coraWebMComponents/dataDisplay/icons/ParkingPermit';
import AddBlackIcon from '../../coraWebMComponents/dataDisplay/icons/AddBlackIcon';
import DescriptionIcon from '../../coraWebMComponents/dataDisplay/icons/DescriptionIcon';
import EuroIcon from '../../coraWebMComponents/dataDisplay/icons/EuroIcon';
import TicketIcon from '../../coraWebMComponents/dataDisplay/icons/TicketIcon';
import SetingsIcon from '../../coraWebMComponents/dataDisplay/icons/SetingsIcon';
import SubsidiesIcon from '../../coraWebMComponents/dataDisplay/icons/SubsidiesIcon';
import SecurityIcon from '../../coraWebMComponents/dataDisplay/icons/SecurityIcon';
import BudgetIcon from '../../coraWebMComponents/dataDisplay/icons/BudgetIcon';
import AdminSegmentationIcon from '../../coraWebMComponents/dataDisplay/icons/AdminSegmentationIcon';
import DebtorsIcon from '../../coraWebMComponents/dataDisplay/icons/DebtorsIcon';
import GraveIcon from '../../coraWebMComponents/dataDisplay/icons/GraveIcon';
import CitizensIcon from '../../coraWebMComponents/dataDisplay/icons/CitizensIcon';
import TowingIcon from '../../coraWebMComponents/dataDisplay/icons/TowingIcon';
import PublicationIcon from '../../coraWebMComponents/dataDisplay/icons/PublicationIcon';
import OrganizationIcon from '../../coraWebMComponents/dataDisplay/icons/OrganizationIcon';
import FirmIcon from '../../coraWebMComponents/dataDisplay/icons/FirmIcon';
import ShopIcon from '../../coraWebMComponents/dataDisplay/icons/ShopIcon';
import SelfGovIcon from '../../coraWebMComponents/dataDisplay/icons/SelfGovIcon';
import EducationIcon from '../../coraWebMComponents/dataDisplay/icons/EducationIcon';
import SocialServiceIcon from '../../coraWebMComponents/dataDisplay/icons/SocialServiceIcon';
import ConstructionOfficeIcon from '../../coraWebMComponents/dataDisplay/icons/ConstructionOfficeIcon';
import ElectionIcon from '../../coraWebMComponents/dataDisplay/icons/ElectionIcon';
import DefectsIcon from '../../coraWebMComponents/dataDisplay/icons/DefectsIcon';
import ImportantObjectsIcon from '../../coraWebMComponents/dataDisplay/icons/ImportantObjectsIcon';
import PetitionsIcon from '../../coraWebMComponents/dataDisplay/icons/PetitionsIcon';
import RegistryRecordsIcon from "../../coraWebMComponents/dataDisplay/icons/RegistryRecordsIcon";
import OpenDataIcon from "../../coraWebMComponents/dataDisplay/icons/OpenDataIcon";
import CatalogOpenDataIcon from "../../coraWebMComponents/dataDisplay/icons/CatalogOpenDataIcon";

// Podklad dlazdice
const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: '#ffffff',
  ...theme.typography.body2,
  textAlign: 'left',
  height: "100%"
}));

const GetIcon = ({ icon, style, color, stroke }) => {
  switch (icon) {
    case 'PersonIcon':
      return <PersonIcon style={{ ...style, color, stroke }} />;
    case 'ParkingIcon':
      return <ParkingIcon style={{ ...style, color, stroke }} />;
    case 'ParkingCard':
      return <ParkingCard style={{ ...style, color, stroke }} />;
    case 'ParkingPermit':
      return <ParkingPermit style={{ ...style, color, stroke}} />;
    case 'DogIcon':
      return <DogIcon style={{ ...style, color, stroke }} />;
    case 'AddBlackIcon':
      return <AddBlackIcon style={{ ...style, color, stroke }} />;
    case 'DescriptionIcon':
      return <DescriptionIcon style={{ ...style, color, stroke}} />;
    case 'EuroIcon':
      return <EuroIcon style={{ ...style, color, stroke }} />;
    case 'PeopleIcon':
      return <PeopleIcon style={{ ...style, color, stroke}} />;
    case 'TicketIcon':
      return <TicketIcon style={{ ...style, color, stroke}} />;
    case 'SetingsIcon':
      return <SetingsIcon style={{ ...style, color, stroke}} />;
    case 'MapIcon':
      return <MapIcon style={{ ...style, color, stroke}} />;
    case 'SubsidiesPublicIcon':
      return <SubsidiesIcon style={{ ...style, color, stroke}} />;
    case 'SecurityIcon':
      return <SecurityIcon style={{ ...style, color, stroke}} />;
    case 'BudgetIcon':
      return <BudgetIcon style={{ ...style, color, stroke}} />;
    case 'AdminSegmentationIcon':
      return <AdminSegmentationIcon style={{ ...style, color, stroke}} />;
    case 'DebtorsIcon':
      return <DebtorsIcon style={{ ...style, color, stroke}} />;
    case 'GraveIcon':
      return <GraveIcon style={{ ...style, color, stroke}} />;
    case 'CitizensIcon':
      return <CitizensIcon style={{ ...style, color, stroke}} />;
    case 'TowingIcon':
      return <TowingIcon style={{ ...style, color, stroke}} />;
    case 'PublicationIcon':
      return <PublicationIcon style={{ ...style, color, stroke}} />;
    case 'OrganizationIcon':
      return <OrganizationIcon style={{ ...style, color, stroke}} />;
    case 'FirmIcon':
      return <FirmIcon style={{ ...style, color, stroke}} />;
    case 'ShopIcon':
      return <ShopIcon style={{ ...style, color, stroke}} />;
    case 'SelfGovIcon':
      return <SelfGovIcon style={{ ...style, color, stroke}} />;
    case 'EducationIcon':
      return <EducationIcon style={{ ...style, color, stroke}} />;
    case 'SocialServiceIcon':
      return <SocialServiceIcon style={{ ...style, color, stroke}} />;
    case 'ConstructionOfficeIcon':
      return <ConstructionOfficeIcon style={{ ...style, color, stroke}} />;
    case 'ElectionIcon':
      return <ElectionIcon style={{ ...style, color, stroke}} />;
    case 'DefectsIcon':
      return <DefectsIcon style={{ ...style, color, stroke}} />;
    case 'ImportantObjectsIcon':
      return <ImportantObjectsIcon style={{ ...style, color, stroke}} />;
    case 'PetitionsIcon':
      return <PetitionsIcon style={{ ...style, color, stroke}} />;
    case 'RegistryRecordsIcon':
      return <RegistryRecordsIcon style={{ ...style, color, stroke}} />;
    case 'OpenDataIcon':
      return <OpenDataIcon style={{ ...style, color, stroke}} />;
    case 'CatalogOpenDataIcon':
      return <CatalogOpenDataIcon style={{ ...style, color, stroke}} />;
    default:
      return null;
  }
};

// Obsah dlazdica
const card = (classes, onClick, item, matchesThemeMD) => (
  <Card sx={{
    width: "100%", height: "100%",
    display: "flex",
    flexDirection: "column",
  }}
    variant="outlined" >
    <CardContent
      sx={{
        whiteSpace: "normal",
        flexGrow: 1,
        p: 2, //okraj okolo textu v karte
        // minHeight: matchesThemeMD ? "6em" : "3em",
      }}>
      <ToolTip label={item.Title} place="bottom">
        <Box sx={{
          height: "100%",
          // TODO: zapnut, ak chcem popisok na 1 riadok a orezany s '...' ak nevojde
          // whiteSpace: "nowrap",
          // overflow: "hidden",
          // textOverflow: "ellipsis"
        }}>
          <Stack flexDirection="row" justifyContent={"flex-start"} alignItems={"flex-start"}
            className={classNames(classes.w100)}
          >
            <GetIcon icon={item.Icon} style={{ width: '24px', height: '24px', marginRight: "8px" }} color="#757575" stroke="#757575" />
            <Typography title={item.Title} variant="body2" gutterBottom>
              {item.Title}
            </Typography>
          </Stack>
          <Typography variant="caption" className={classNames(classes.textCaption)}>
            {item.Tooltip}
          </Typography>
        </Box>
      </ToolTip>
    </CardContent>
    <CardActions
      sx={{
        height: matchesThemeMD ? "1em" : "0.8em",
        // minHeight: matchesThemeMD ? "1em" : "0.8em" , 
        pt: 0,
        pb: 2
      }}
      fullWidth
    >
      <Stack
        sx={{ flexDirection: "row" }}
        width={"100%"}
        justifyContent="space-between"
        alignItems="center"
      >
        <IconButton
          onClick={() => onClick(item.IZstZs)}
          variant="contained"
          color="inherit"
          toolTip=""
          toolTipPlace="right"
          size="large">
          <ArrowForward className={classNames(classes.navigationForwardButton)} />
        </IconButton>
      </Stack>
    </CardActions>
  </Card >
);

/**
 * Dlazdica s menu
 *
 * @param {*} { classes, item, onClick, matchesThemeMD }
 * @return {*} 
 */
const MenuTilesItem = ({ classes, item, onClick, matchesThemeMD }) => {
  return (
    <>
      <Item
        elevation={1}
      // className={classNames(classes.btnZstZs, (switchedOn ? classes.switchedOn : classes.switchedOff))}
      >
        <Box sx={{ height: "100%" }} >
          <Button
            component={"div"}
            fullWidth
            sx={{
              p: 0, textTransform: 'none',
              height: "100%",
            }}
            onClick={() => onClick(item.Route)}
          >
            {
              card(classes, onClick, item, matchesThemeMD)
            }
          </Button>
        </Box>
      </Item>
    </>
  );
};

export default MenuTilesItem;