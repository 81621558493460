import React from "react";
import { connect } from "react-redux";
import classNames from "classnames";
import ReactDOMServer from "react-dom/server";

import withStyles from '@mui/styles/withStyles';
import SaveIcon from '@mui/icons-material/Save';
import Switch from "@mui/material/Switch";

import Button from "../../coraWebMComponents/inputs/Button";
import Typography from "../../coraWebMComponents/dataDisplay/Typography";
import ArrowForward from "../../coraWebMComponents/dataDisplay/icons/ArrowForward";
import getSharedStyles from "../../coraWebMComponents/sharedStyles";
import IconButton from "../../coraWebMComponents/inputs/IconButton";
import ToolTip from "../../coraWebMComponents/inputs/ToolTip";
import ArrowBack from "../../coraWebMComponents/dataDisplay/icons/ArrowBack";
import PublicIcon from "../../coraWebMComponents/dataDisplay/icons/PublicIcon";
import PersonIcon from "../../coraWebMComponents/dataDisplay/icons/PersonIcon";
import TicketIcon from "../../coraWebMComponents/dataDisplay/icons/TicketIcon";
import MapIcon from "../../coraWebMComponents/dataDisplay/icons/MapIcon";
import ParkingCard from "../../coraWebMComponents/dataDisplay/icons/ParkingCard";
import ParkingIconOutlined from "../../coraWebMComponents/dataDisplay/icons/ParkingIconOutlined";
import { withLocalizationConsumer } from "../../coraWebMComponents/localization/withLocalization";
import CircularProgress from "../../coraWebMComponents/feedBack/progress/CircularProgress";
import EditIcon from "../../coraWebMComponents/dataDisplay/icons/EditIcon";
import Dialog from "../../coraWebMComponents/feedBack/Dialog";
import Editor from "../../coraWebMComponents/inputs/Editor";
import * as storage from "../../coraWebMComponents/utils/storage";
import withMeta from "../../coraWebMComponents/utils/withMeta";
import withRouter from "../../coraWebMComponents/utils/withRouter";

import routes from "../../routes";
import * as ciselnikyActions from "../../actions/ciselnikyActions";
import AdminModeExitButton from "../adminMode/exitButton";
import {
  adminModeSetActiveState,
  adminModeReset,
  adminModeSetCurrEdited,
  adminModeSetEditedData,
  adminModeUpdateOrAdd,
} from "../../actions/adminModeActions";
import {
  PRELOGIN_BTNS,
  PUBLIC_ZONE,
  MY_ZONE,
  PARKING,
  IFrmTxt,
  ISpMnu,
} from "../../constants/adminConsts";
import PulseInfoButton from "../info/PulseInfoButton";

const styles = (theme) => ({
  ...getSharedStyles(theme),
  preloadWrapper: {
    minHeight: "100vh",
    width: "100%",
    backgroundColor: "#343434",
  },
  firstStepWrapper: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    minHeight: "80vh",
  },
  whiteBtn: {
    height: "65px",
    backgroundColor: "#ffffff!important",
    color: "#000",
    padding: "8px 14px",
    width: "250px",
    variant: "contained",
    size: "large",
    // "&:hover": {
    //     backgroundColor: "#bdbdbd",
    // },
    "&:hover $whiteBtnArrow": {
      transform: "translatex(5px)",
    },
  },
  whiteBtnArrow: {
    transition: "transform 0.25s ease",
  },
  currentCity: {
    color: "#bdbdbd",
    fontWeight: 400,
    padding: "20px 0 50px 0",
    letterSpacing: "0.25rem",
    fontSize: "17px",
    textTransform: "capitalize",
    textAlign: "center",
    [theme.breakpoints.up("sm")]: {
      padding: "30px 0 70px 0",
      fontSize: "19px",
      letterSpacing: "0.32rem",
    },
    [theme.breakpoints.up("lg")]: {
      fontSize: "21px",
      letterSpacing: "0.4rem",
    },
  },
  myZoneWrapper: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
    maxWidth: "400px",
    margin: "0 auto",
    padding: "40px",
    [theme.breakpoints.up("md")]: {
      justifyContent: "center",
      padding: "0px",
      maxWidth: "600px",
    },
  },
  myZoneText: {
    fontWeight: 300,
    color: theme.palette.primary.main,
    letterSpacing: "2px",
    lineHeight: "23px",
    [theme.breakpoints.up("lg")]: {
      fontSize: "0.9rem",
      lineHeight: "1.58rem",
    },
  },
  myZoneBtnWrapper: {
    marginTop: "70px",
  },
  outlineBtn: {
    letterSpacing: "0.15rem",
    padding: "8px 14px",
    width: "250px",
  },
  appLogo: {
    width: "150px",
    height: "150px",
    [theme.breakpoints.up("sm")]: {
      width: "200px",
      height: "200px",
    },
    [theme.breakpoints.up("lg")]: {
      width: "250px",
      height: "250px",
    },
  },
  buttonDisabled: {
    backgroundColor: "#bdbdbd!important",
  },
  footerText: {
    color: "#bdbdbd",
    letterSpacing: "2px",
    lineHeight: "23px",
    // position: "fixed",
    width: "100%",
    bottom: 0,
    paddingBottom: "1em",
  },
});

class PreLogin extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeStep: 0,
      N_M: "",
      textMyZone: "",
      textParking: "",
      textPublicZone: "",
      textFooter: "",
      visiblePublicZone: false,
      visibleParkShort: false,
      visibleParkCardUnReg: false,
      visibleMapButton: false,
      visiblePublicCardVerifyButton: false,
      mapButtonDisabled: false,
      infoPrilohy: false,
      isLoading: true,
      openDialog: false,
      openSaveDialog: false,
      preLoginBtns: [],
    };
  }

  componentDidMount = async () => {
    if (this.props.isAuth) {
      // nacitaj public + privat menu
      await this.props.meta.init(false);
    } else {
      // nacitaj public menu
      await this.props.meta.init(true);
    }

    await this.props.dispatch(ciselnikyActions.getCityOfSetup());
    this.props.cityOfSetup &&
      this.setState({ N_M: this.props.cityOfSetup.HODN });

    await this.initTextData();

    // Mapa
    let bShwMB = this.props.meta?.menu?.find((x) => x.MenuID === 4004016)?.Visible === 1;

    // Overenie platnosti parkovacích kariet
    let bShwPCV = this.props.meta?.menu?.find((x) => x.MenuID === 4004094)?.Visible === 1;

    this.setState({
      visibleMapButton: bShwMB,
      visiblePublicCardVerifyButton: bShwPCV,
      preLoginBtns: [
        this.props.preLoginBtns?.find((btn) => btn.MenuID === 4004019), // PublicZone
        this.props.preLoginBtns?.find((btn) => btn.MenuID === 4004017), // MyZone
        this.props.preLoginBtns?.find((btn) => btn.MenuID === 4004018), // Parking
      ],
      mapButtonDisabled:
        this.props.meta.menu.find((x) => x.MenuID === 4004016)?.Visible === 2,
    });

    if (this.props.versionInfo.length === 0) {
      this.props.dispatch(ciselnikyActions.getVersionInfo());
    }
  };

  initTextData = async () => {
    const txtMyZone = await this.props.translateWithLocaleAsync(
      this.props.locale,
      "DM_MOJA_ZONA"
    );
    const txtPublicZone = await this.props.translateWithLocaleAsync(
      this.props.locale,
      "DM_VEREJNA_ZONA"
    );
    const txtParking = await this.props.translateWithLocaleAsync(
      this.props.locale,
      "DM_PZ_PARKING"
    );
    const txtFooter = await this.props.translateWithLocaleAsync(
      this.props.locale,
      "DM_WELCOME_PAGE"
    );

    // Zobrazenie verejnej zóny
    await this.props.dispatch(ciselnikyActions.getVisiblePublicZone());
    var bShwPZ = false;
    if (this.props.visiblePublicZone) {
      if (this.props.visiblePublicZone.HODN) {
        if (this.props.visiblePublicZone.HODN.trim() === "1") bShwPZ = true;
      }
    }

    // Zobrazenie parkovacích lístkov
    await this.props.dispatch(ciselnikyActions.getVisibleParkShort());
    var bShwPS = false;
    if (this.props.visibleParkShort) {
      if (this.props.visibleParkShort.HODN) {
        if (this.props.visibleParkShort.HODN.trim() === "1") bShwPS = true;
      }
    }

    // Zobrazenie parkovacích kariet bez registrácie
    await this.props.dispatch(ciselnikyActions.getVisibleParkCardUnReg());
    var bShwPCU = false;
    if (this.props.visibleParkCardUnReg) {
      if (this.props.visibleParkCardUnReg.HODN) {
        if (this.props.visibleParkCardUnReg.HODN.trim() === "1") bShwPCU = true;
      }
    }

    this.setState({
      textMyZone: txtMyZone,
      textParking: txtParking,
      textPublicZone: txtPublicZone,
      textFooter: txtFooter,
      visiblePublicZone: bShwPZ,
      visibleParkShort: bShwPS,
      visibleParkCardUnReg: bShwPCU,
      isLoading: false,
    });
  };

  handleEditData = (data) => {
    const { currEdited } = this.props.adminMode;
    switch (currEdited) {
      case PUBLIC_ZONE:
        this.props.dispatch(
          adminModeSetEditedData([
            {
              ...this.state.textPublicZone,
              TxtFrm: data,
              ID: 4004001,
              locale: this.props.locale,
              N_KLUC: "DM_VEREJNA_ZONA",
              IFrmTxt: 4,
            },
          ])
        );
        break;
      case MY_ZONE:
        this.props.dispatch(
          adminModeSetEditedData([
            {
              ...this.state.textMyZone,
              TxtFrm: data,
              ID: 4002001,
              locale: this.props.locale,
              N_KLUC: "DM_MOJA_ZONA",
              IFrmTxt: 1,
            },
          ])
        );
        break;
      case PARKING:
        this.props.dispatch(
          adminModeSetEditedData([
            {
              ...this.state.textParking,
              TxtFrm: data,
              ID: 4004001,
              locale: this.props.locale,
              N_KLUC: "DM_PZ_PARKING",
              IFrmTxt: 28,
            },
          ])
        );
        break;
      default:
        return;
    }
  };

  handleEditButtonData = (buttonData) => {
    buttonData.Visible = buttonData.Visible === 1 ? 0 : 1;
    const newPreloginBtns = this.state.preLoginBtns.map((btn) =>
      btn.MenuID === buttonData.MenuID ? buttonData : btn
    );
    this.setState({ preLoginBtns: newPreloginBtns });
    this.props.dispatch(adminModeSetEditedData(newPreloginBtns));
  };

  handleSaveSection = async () => {
    const { dataEdited, currEdited } = this.props.adminMode;
    if (currEdited === PRELOGIN_BTNS) {
      await this.props.dispatch(adminModeUpdateOrAdd(ISpMnu, dataEdited));
    } else {
      await this.props.dispatch(adminModeUpdateOrAdd(IFrmTxt, dataEdited));
      await this.initTextData();
    }
    this.props.dispatch(adminModeSetCurrEdited(null));
    this.handleCloseDialog();
  };

  handleCurrEdited = (value) => {
    this.props.dispatch(adminModeSetCurrEdited(value));
    this.props.dispatch(adminModeSetEditedData(null));
  };

  handleOpenSaveDialog = () => {
    this.setState({ openSaveDialog: true });
  };

  handleOpenLogoutAdminDialog = () => {
    this.setState({ openDialog: true });
  };

  handleCloseDialog = () => {
    this.setState({ openDialog: false, openSaveDialog: false });
  };

  setAdminModeActive = (isActive) => {
    this.props.dispatch(adminModeSetActiveState(isActive));
    storage.setItem("adminMode", isActive);
    this.handleCloseDialog();
    if (!isActive) {
      this.props.dispatch(adminModeReset());
      this.props.navigate("/");
    }
  };

  handleChange = (value) => {
    if (this.props.adminMode.currEdited !== PRELOGIN_BTNS) {
      this.setState({ activeStep: value });
    }
  };

  handleGoLogin = () => {
    if (!this.props.user.data) {
      this.props.navigate(routes.LOGIN);
    } else if (this.props.adminMode.isActive) {
      this.props.navigate(routes.LOGIN);
    } else {
      this.props.navigate(routes.HOME);
    }
  };

  handleGoPublicZone = () => {
    this.props.navigate(routes.PZ);
  };

  handleMap = () => {
    this.props.navigate(routes.MAP);
  };

  handleParkingShort = () => {
    this.props.navigate(routes.PZ_PARKING_SHORT);
  };

  handleParkingCardUnReg = () => {
    this.props.navigate(routes.PZ_PARKING_CARD_VERIFY);
  };

  handleValidityCheck = () => {
    this.props.navigate(routes.PUBLIC_CARD_VERIFY);
  };

  handleBack = () => {
    this.handleChange(0);
  };

  getVer = () => {
    let verzia = process.env.REACT_APP_VERSION;
    let verzia1 = verzia.substring(0, 4);
    let verzia2 = verzia.substring(4, verzia.lastIndexOf("."));
    return "Verzia: " + verzia1 + "." + verzia2;
  };

  getEditButton = (drawer) => {
    const { classes } = this.props;
    return (
      <Button
        variant="contained"
        color="secondary"
        style={{
          width: "200px",
          left: "50%",
          marginBottom: "1rem",
          marginTop: "1rem",
          transform: "translateX(-50%)",
        }}
        className={classNames(
          classes.w100,
          classes.textTransformNone,
          classes.adminButton
        )}
        onClick={() => this.handleCurrEdited(drawer)}
        startIcon={<EditIcon />}
      >
        Upraviť
      </Button>
    );
  };

  getSaveButton = () => {
    const { classes } = this.props;
    return (
      <Button
        variant="contained"
        color="secondary"
        style={{
          width: "200px",
          left: "50%",
          marginBottom: "1rem",
          marginTop: "1rem",
          transform: "translateX(-50%)",
        }}
        type="success"
        className={classNames(classes.w100, classes.textTransformNone)}
        onClick={this.handleOpenSaveDialog}
        startIcon={<SaveIcon />}
      >
        Uložiť
      </Button>
    );
  };

  ReactToHtml = (component) => {
    const HTML = ReactDOMServer.renderToStaticMarkup(component);
    const HTMLstring = HTML.toString();
    return HTMLstring;
  };

  render() {
    const { classes, adminMode } = this.props;
    const { isActive, currEdited } = adminMode;
    const {
      visiblePublicZone,
      visibleParkShort,
      visibleParkCardUnReg,
      preLoginBtns,
    } = this.state;
    const [publicZoneButton, myZoneButton, parkingButton] = preLoginBtns;

    const PZbutton =
      (visiblePublicZone &&
        publicZoneButton &&
        publicZoneButton.Visible === 1) ||
        (publicZoneButton && isActive)
        ? publicZoneButton
        : null;
    const MZbutton =
      (myZoneButton && myZoneButton.Visible === 1) || (myZoneButton && isActive)
        ? myZoneButton
        : null;
    const Pbutton =
      ((visibleParkShort || visibleParkCardUnReg) &&
        parkingButton &&
        parkingButton.Visible === 1) ||
        (parkingButton && isActive)
        ? parkingButton
        : null;

    return (
      (<div className={classNames(classes.preloadWrapper)}>
        <CircularProgress loading={this.state.isLoading} />
        <AdminModeExitButton />
        {this.state.activeStep === 0 && (
          <div className={classes.firstStepWrapper}>
            {/* {isActive && (
                    <Button
                        variant="contained"
                        color="secondary"
                        type="danger"
                        style={{
                          position: "absolute",
                          right: 0,
                          top: 0,
                          width: "220px",
                          margin: "24px",
                        }}
                        className={classNames(classes.w100, classes.textTransformNone)}
                        onClick={this.handleOpenLogoutAdminDialog}
                        startIcon={<EditIcon />}
                    >
                      Ukončiť režim Správca
                    </Button>
                )} */}

            <div
              className={classNames(classes.row, classes.justifyContentCenter)}
            >
              <ToolTip label={this.getVer()} place="top">
                <img
                  className={classNames(classes.appLogo)}
                  src={"assets/datamesta_logo_preloading.svg"}
                  alt="datamesta"
                />
              </ToolTip>
            </div>
            <div className={classNames(classes.textCenter)}>
              <Typography
                variant="subtitle1"
                className={classNames(classes.currentCity)}
              >
                {this.state.N_M}
              </Typography>
            </div>
            {PZbutton && (
              <div
                className={classNames(
                  classes.row,
                  classes.justifyContentCenter,
                  classes.mb3
                )}
              >
                <Button
                  variant="outlined"
                  color="primary"
                  size="large"
                  onClick={() => this.handleGoPublicZone()}
                  className={classNames(classes.outlineBtn)}
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <PublicIcon className={classNames(classes.mr3)} />
                  {PZbutton.Title}
                  {isActive && currEdited === PRELOGIN_BTNS ? (
                    <div style={{ visibility: "hidden", width: 30 }}>
                      <Switch
                        onChange={() => this.handleEditButtonData(PZbutton)}
                        checked={PZbutton.Visible === 1}
                        color="primary"
                      />
                    </div>
                  ) : (
                    <ArrowForward fontSize="medium" />
                  )}
                </Button>
              </div>
            )}
            {MZbutton && (
              <div
                className={classNames(
                  classes.row,
                  classes.justifyContentCenter,
                  classes.mb3
                )}
              >
                <Button
                  variant="outlined"
                  color="primary"
                  size="large"
                  onClick={() => this.handleChange(2)}
                  className={classNames(classes.outlineBtn)}
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <PersonIcon className={classNames(classes.mr3)} />
                  {MZbutton.Title}
                  {isActive && currEdited === PRELOGIN_BTNS ? (
                    <Switch
                      onChange={() => this.handleEditButtonData(MZbutton)}
                      checked={MZbutton.Visible === 1}
                      color="primary"
                    />
                  ) : (
                    <ArrowForward fontSize="medium" />
                  )}
                </Button>
              </div>
            )}
            {Pbutton && (
              <div
                className={classNames(
                  classes.row,
                  classes.justifyContentCenter,
                  classes.mb4
                )}
              >
                <Button
                  variant="outlined"
                  color="primary"
                  size="large"
                  onClick={() => this.handleChange(3)}
                  className={classNames(classes.outlineBtn)}
                  style={{ display: "flex", justifyContent: "space-between" }}
                >
                  <ParkingIconOutlined
                    fontSize="small"
                    className={classNames(classes.mr3)}
                  />
                  {Pbutton.Title}
                  {isActive && currEdited === PRELOGIN_BTNS ? (
                    <Switch
                      onChange={() => this.handleEditButtonData(Pbutton)}
                      checked={Pbutton.Visible === 1}
                      color="primary"
                    />
                  ) : (
                    <ArrowForward fontSize="medium" />
                  )}
                </Button>
              </div>
            )}
            {isActive &&
              currEdited !== PRELOGIN_BTNS &&
              this.getEditButton(PRELOGIN_BTNS)}
            {isActive && currEdited === PRELOGIN_BTNS && this.getSaveButton()}
          </div>
        )}
        {this.state.activeStep === 1 && (
          <div className={classes.myZoneWrapper}>
            <div
              className={classNames(
                classes.row,
                classes.navigationBackButton,
                classes.justifyContentStart,
                classNames(classes.mb3)
              )}
            >
              <IconButton
                color="inherit"
                onClick={this.handleBack}
                toolTip={this.props.translate("back")}
                toolTipPlace="right"
                className={classNames(classes.p0)}
                disableRipple
                disableHover
                size="large">
                <ArrowBack
                  variant="fab"
                  style={{ fontSize: 30, color: "#ffffff" }}
                />
              </IconButton>
              <Typography
                variant="subheading"
                className={classNames(classes.ml2)}
                style={{ color: "#bdbdbd", alignSelf: "center" }}
              >
                Verejná zóna
              </Typography>
            </div>
            {this.state.textPublicZone.length > 0 &&
              currEdited !== PUBLIC_ZONE && (
                <div>
                  <Typography
                    variant="caption"
                    className={classNames(classes.myZoneText)}
                  >
                    {this.state.textPublicZone}
                  </Typography>
                </div>
              )}
            {this.state.textPublicZone.length > 0 &&
              currEdited === PUBLIC_ZONE && (
                <Editor
                  onChange={this.handleEditData}
                  value={this.ReactToHtml(this.state.textPublicZone)}
                />
              )}
            <div
              className={classNames(
                classes.row,
                classes.justifyContentCenter,
                classes.myZoneBtnWrapper
              )}
            >
              <Button
                variant="outlined"
                color="primary"
                size="large"
                onClick={() => this.handleGoPublicZone()}
                className={classNames(classes.outlineBtn)}
              >
                VEREJNÁ ZÓNA
              </Button>
            </div>
            {isActive &&
              currEdited !== PUBLIC_ZONE &&
              this.getEditButton(PUBLIC_ZONE)}
            {isActive && currEdited === PUBLIC_ZONE && this.getSaveButton()}
          </div>
        )}
        {this.state.activeStep === 2 && (
          <div className={classes.myZoneWrapper}>
            <div
              className={classNames(
                classes.row,
                classes.navigationBackButton,
                classes.justifyContentStart,
                classNames(classes.mb3)
              )}
            >
              <IconButton
                color="inherit"
                onClick={this.handleBack}
                toolTip={this.props.translate("back")}
                toolTipPlace="right"
                className={classNames(classes.p0)}
                disableRipple
                disableHover
                size="large">
                <ArrowBack
                  variant="fab"
                  style={{ fontSize: 30, color: "#ffffff" }}
                />
              </IconButton>
              <Typography
                variant="subheading"
                className={classNames(classes.ml2)}
                style={{ color: "#bdbdbd", alignSelf: "center" }}
              >
                Moja zóna
              </Typography>
            </div>
            {this.state.textMyZone.length > 0 && currEdited !== MY_ZONE && (
              <div>
                <Typography
                  variant="caption"
                  className={classNames(classes.myZoneText)}
                >
                  {this.state.textMyZone}
                </Typography>
              </div>
            )}
            {this.state.textMyZone.length > 0 && currEdited === MY_ZONE && (
              <Editor
                onChange={this.handleEditData}
                value={this.ReactToHtml(this.state.textMyZone)}
              />
            )}
            <div
              className={classNames(
                classes.row,
                classes.justifyContentCenter,
                classes.myZoneBtnWrapper
              )}
            >
              <Button
                variant="outlined"
                color="primary"
                size="large"
                onClick={() => this.handleGoLogin()}
                className={classNames(classes.outlineBtn)}
              >
                MOJA ZÓNA
              </Button>
            </div>
            {isActive && currEdited !== MY_ZONE && this.getEditButton(MY_ZONE)}
            {isActive && currEdited === MY_ZONE && this.getSaveButton()}
          </div>
        )}
        {this.state.activeStep === 3 && (
          <div className={classes.myZoneWrapper}>
            <div
              className={classNames(
                classes.row,
                classes.navigationBackButton,
                classes.justifyContentStart,
                classNames(classes.mb3)
              )}
            >
              <IconButton
                color="inherit"
                onClick={this.handleBack}
                toolTip={this.props.translate("back")}
                toolTipPlace="right"
                className={classNames(classes.p0)}
                disableRipple
                disableHover
                size="large">
                <ArrowBack
                  variant="fab"
                  style={{ fontSize: 30, color: "#ffffff" }}
                />
              </IconButton>
              <Typography
                variant="subheading"
                className={classNames(classes.ml2)}
                style={{ color: "#bdbdbd", alignSelf: "center" }}
              >
                Parkovanie
                <br />
              </Typography>
            </div>
            {this.state.textParking.length > 0 && currEdited !== PARKING && (
              <div>
                <Typography
                  variant="caption"
                  className={classNames(classes.myZoneText)}
                >
                  {this.state.textParking}
                </Typography>
              </div>
            )}
            {this.state.textParking.length > 0 && currEdited === PARKING && (
              <Editor
                onChange={this.handleEditData}
                value={this.ReactToHtml(this.state.textParking)}
              />
            )}
            {this.state.visibleParkShort && (
              <>
                {(this.state.visibleMapButton ||
                  this.state.mapButtonDisabled) && (
                    <div
                      className={classNames(
                        classes.row,
                        classes.justifyContentCenter,
                        classNames(classes.mt6)
                      )}
                    >
                      <Button
                        className={classNames(
                          classes.whiteBtn,
                          this.state.mapButtonDisabled && classes.buttonDisabled
                        )}
                        onClick={this.handleMap}
                        disabled={this.state.mapButtonDisabled}
                      >
                        <MapIcon
                          className={classNames(classes.mr2)}
                          style={{ width: "37", height: "23" }}
                        />
                        <Typography
                          variant="button"
                          className={classNames(classes.mr6)}
                        >
                          MAPA
                        </Typography>
                        <ArrowForward
                          className={classNames(
                            classes.ml6,
                            classes.whiteBtnArrow
                          )}
                          fontSize="small"
                        />
                      </Button>
                    </div>
                  )}
                <div
                  className={classNames(
                    classes.row,
                    classes.justifyContentCenter,
                    classNames(classes.mt3)
                  )}
                >
                  <Button
                    className={classNames(classes.whiteBtn)}
                    onClick={() => this.handleParkingShort()}
                  >
                    <TicketIcon
                      className={classNames(classes.mr2)}
                      style={{ width: "37", height: "23" }}
                    />
                    <Typography
                      variant="button"
                      className={classNames(classes.mr6)}
                    >
                      LÍSTOK
                    </Typography>
                    <ArrowForward
                      className={classNames(classes.ml6, classes.whiteBtnArrow)}
                      fontSize="small"
                    />
                  </Button>
                </div>
              </>
            )}
            {this.state.visibleParkCardUnReg && (
              <div
                className={classNames(
                  classes.row,
                  classes.justifyContentCenter,
                  classNames(classes.mt3)
                )}
              >
                <Button
                  className={classNames(classes.whiteBtn)}
                  onClick={() => this.handleParkingCardUnReg()}
                >
                  <ParkingCard
                    className={classNames(classes.mr2)}
                    style={{ width: "37", height: "23" }}
                  />
                  <Typography
                    variant="button"
                    className={classNames(classes.mr6)}
                  >
                    KARTA
                  </Typography>
                  <ArrowForward
                    className={classNames(classes.ml6, classes.whiteBtnArrow)}
                    fontSize="small"
                  />
                </Button>
              </div>
            )}
            {this.state.visiblePublicCardVerifyButton &&
              <div
                className={classNames(
                  classes.row,
                  classes.justifyContentCenter,
                  classNames(classes.mt3)
                )}
              >
                <Button
                  className={classNames(classes.whiteBtn)}
                  onClick={() => this.handleValidityCheck()}
                >
                  <ParkingCard
                    className={classNames(classes.mr2)}
                    style={{ width: "37", height: "23" }}
                  />
                  <Typography
                    variant="button"
                    style={{ textAlign: "left", width: '80px' }}
                  >
                    OVERENIE PLATNOSTI
                  </Typography>
                  <ArrowForward
                    className={classNames(classes.ml6, classes.whiteBtnArrow)}
                    fontSize="small"
                  />
                </Button>
              </div>
            }
            {isActive && currEdited !== PARKING && this.getEditButton(PARKING)}
            {isActive && currEdited === PARKING && this.getSaveButton()}
          </div>
        )}
        {/*<footer className={classNames(classes.textCenter)}>*/}
        {/*  <Typography className={classes.footerText}>*/}
        {/*    {this.state.textFooter}*/}
        {/*  </Typography>*/}
        {/*</footer>*/}
        <footer
          style={{
            display: "flex",
            alignContent: "center",
            justifyContent: "center",
          }}
          className={classNames(classes.textCenter)}
        >
          <Typography style={{ width: "60%" }} className={classes.footerText}>
            {this.state.textFooter}
          </Typography>
        </footer>
        <PulseInfoButton
          type="PreLogin"
          N_KLUC={"DM_NEWS"}
          currEdited={"infoText"}
        />
        <Dialog
          modal={false}
          open={this.state.openDialog}
          onClose={this.handleCloseDialog}
          contentText={
            "Informácia: Potvrdením budete odhlásený ako správca a všetky neuložené zmeny budú zahodené."
          }
          title="Administrácia portálu (ukončenie)"
          actions={[
            {
              label: "Zostať prihlásený",
              color: "primary",
              onClick: this.handleCloseDialog,
              keyboardFocused: false,
            },
            {
              label: "Odhlásiť",
              color: "secondary",
              onClick: () => this.setAdminModeActive(false),
              keyboardFocused: true,
            },
          ]}
        />
        <Dialog
          modal={false}
          open={this.state.openSaveDialog}
          onClose={this.handleCloseDialog}
          contentText="Informácia: Potvrdením uložíte zmeny"
          title="Uloženie nastavení"
          actions={[
            {
              label: "Nie",
              color: "primary",
              onClick: this.handleCloseDialog,
              keyboardFocused: false,
            },
            {
              label: "Áno",
              color: "secondary",
              onClick: this.handleSaveSection,
              keyboardFocused: true,
            },
          ]}
        />
      </div>)
    );
  }
}

const mapStateToProps = (state) => {
  return {
    cityOfSetup: state.cityOfSetup.data.Data,
    locale: state.locale.locale,
    visiblePublicZone: state.visiblePublicZone.data.Data,
    visibleParkShort: state.visibleParkShort.data.Data,
    visibleParkCardUnReg: state.visibleParkCardUnReg.data.Data,
    user: state.user,
    isAuth: state.user.data !== null,
    publicZoneButton: state.publicZoneButton.data.Data,
    myZoneButton: state.myZoneButton.data.Data,
    parkingButton: state.parkingButton.data.Data,
    adminMode: state.adminMode,
    versionInfo: state.versionInfo.data,
  };
};

export default withRouter(
  withMeta(
    withLocalizationConsumer(
      connect(mapStateToProps)(withStyles(styles)(PreLogin))
    )
  )
);
