import CircularProgress, { circularProgressClasses } from '@mui/material/CircularProgress';
import Box from '@mui/material/Box';

function CircularProgressCustom(props) {
  return (
    <Box sx={{
      position: 'absolute',
      top: "45%",
      left: "50%",
      transform: "translate(-45%, -45%)",
      zIndex: 1102
    }}>
      <CircularProgress
        variant="determinate"
        sx={{
          color: (theme) =>
            theme.palette.grey[theme.palette.mode === 'light' ? 300 : 800],
        }}
        size={40}
        thickness={4}
        {...props}
        value={100}
      />
      <CircularProgress
        variant="indeterminate"
        disableShrink
        sx={{
          color: (theme) => (theme.palette.mode === 'light' ? '#f25021' : '##88b719'),
          animationDuration: '1000ms',
          position: 'absolute',
          left: 0,
          [`& .${circularProgressClasses.circle}`]: {
            strokeLinecap: 'round',
          },
        }}
        size={40}
        thickness={4}
        value={50}
        {...props}
      />
    </Box>
  );
}

export default CircularProgressCustom;