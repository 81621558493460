/**
 * Naformatuje cislo na dany pocet desatinnych miest. Ak to nie je cislo, nespravi nic.
 * e.g.: 12345,5 -> '12 345,50'
 *
 * @param {number} [number=0]
 * @param {*} [decimals=NUMBER_OF_DECIMALS]
 * @param {string} [localeCode="sk-SK"]
 * @return {*} 
 */
export const formatDecimals = (number = 0, decimals = 2, localeCode = "sk-SK") => {
  let toNumber = + number;
  return isNaN(toNumber) ? number : toNumber.toLocaleString(localeCode, { maximumFractionDigits: decimals, minimumFractionDigits: decimals });
};

/**
 * Vyescapuje specialne znaky na tvorbu regularnych vyrazov
 *
 * @param {*} text
 * @return {*} 
 */
export const escapeRegExp = (text) => {
  return text.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
};

/**
 * Zmaze v texte netlacitelne znaky
 *
 * @param {*} text
 * @return {*} 
 */
export const removeNonPrintableCharacters = (text) => {
  return text.replace(/[\p{C}]/gu, ''); // netlacitelne znakov pre vsetky jazyky v Unicode
  // return text.replace(/[^\x20-\x7E]/g, '');
  // return text.replace(/[\x00-\x1F\x7F-\x9F]/g, '');
};

/**
 * Zmaze v texte vsetky emoji znaky
 *
 * @param {*} text
 * @return {*} 
 */
export const removeEmoji = (text) => {
  return text.replace(/[\u{1F600}-\u{1F64F}\u{1F300}-\u{1F5FF}\u{1F680}-\u{1F6FF}\u{1F700}-\u{1F77F}\u{1F780}-\u{1F7FF}\u{1F800}-\u{1F8FF}\u{1F900}-\u{1F9FF}\u{1FA00}-\u{1FA6F}\u{1FA70}-\u{1FAFF}\u{1FB00}-\u{1FBFF}\u{1FDC0}-\u{1FDCF}\u{1FDD0}-\u{1FDDF}\u{1FDE0}-\u{1FDEF}\u{1FDF0}-\u{1FDFF}\u{1FE00}-\u{1FE0F}\u{1FE10}-\u{1FE1F}\u{1FE20}-\u{1FE2F}\u{1FE30}-\u{1FE4F}\u{1FE50}-\u{1FE6F}\u{1FE70}-\u{1FEFF}\u{1FF00}-\u{1FFEF}\u{1FFF0}-\u{1FFFF}\u{2B50}]/gu, '');
};