import React from 'react';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import withStyles from '@mui/styles/withStyles';
import { alpha } from "@mui/material/styles";
import Radio from '@mui/material/Radio';
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import TextField from '@mui/material/TextField';
import { Box, Button } from '@mui/material';

import ArrowBack from '../../coraWebMComponents/dataDisplay/icons/ArrowBack';
import withRouter from '../../coraWebMComponents/utils/withRouter';
import getSharedStyles from '../../coraWebMComponents/sharedStyles';
import Typography from '../../coraWebMComponents/dataDisplay/Typography';
import IconButton from '../../coraWebMComponents/inputs/IconButton';

import ZstLsDtz from './ZstLsDtz';
import ZstDtz from './ZstDtz';
import routes from '../../routes';

const styles = theme => ({
  ...getSharedStyles(theme),
  btn: {
    // padding: "3px 15px",
    // marginTop: "3px",
    // backgroundColor: theme.palette.secondary.light,
    backgroundColor: alpha(theme.palette.common.black, 0.25),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.black, 0.15),
    },
    borderRadius: theme.shape.borderRadius,
    width: '120px',
    height: '30px',
  },
  btnSubmit: {
    backgroundColor: "#4caf50",
    color: "#ffffff",
  }
});

/**
 * Wraper pre dotazniky spokojnosti
 *
 * @param {*} props
 * @return {*} 
 */
const ZstQuestionnaireComponent = (props) => {

  const { classes, location } = props;
  const { metadata, dotType } = (location.state ?? { metadata: {}, dotType: 0 });

  return (
    <div className='container w-margin w-padding'>
      <div className={classNames(classes.row, classes.w100, classes.justifyContentLeft)}>
        <Button title={`Návrat na Výber služby`}>
          <Link to={routes.ZST} title={`Návrat na Výber služby`}>
            <Box className={classNames(classes.navigationButton, classes.navigationBackButton, classes.textCenter)}>
              <ArrowBack />
            </Box>
          </Link>
        </Button>

      </div>
      {dotType === 0 &&
        <ZstLsDtz classes={classes} />
      }
      {dotType === 1 &&
        <ZstDtz classes={classes}
          metadata={metadata}
        />
      }
    </div>
  );
};

export default withRouter(withStyles(styles)(ZstQuestionnaireComponent));

export const TextComponent = ({ classes, data, onChange, maxChars, rowCount }) => {

  const getInfoText = (text) => {
    const allowChars = maxChars - (text ?? "").length;
    switch (allowChars) {
      case 1:
        return `Zostáva Vám 1 znak`;
      case 2:
      case 3:
      case 4:
        return `Zostávajú Vám ${allowChars} znaky`;
      default:
        return `Zostáva Vám ${allowChars} znakov`;
    }
  };

  return <FormControl id={`fc_${data.questId}`} className={classNames(classes.w100, classes.alignItemsLeft, classes.justifyContentCenter, classes.pt3)}>
    <FormLabel id={`ident_${data.questId}`}>{data.questText}</FormLabel>
    <TextField
      id={`ident_${data.questId}`}
      name={`ident_${data.questId}`}
      multiline={true}
      minRows={rowCount ?? 1}
      maxRows={rowCount + 5 ?? 1}
      fullWidth={true}
      value={data.answerValue}
      onChange={(event) => onChange(event, data.questId)}
    />
    <Typography variant={"caption"}>{getInfoText(data.answerValue)}</Typography>
  </FormControl>;
};

export const OptionGroupComponent = ({ classes, data, onChange, items, inRow }) => {
  if (!Boolean(items) || items.length < 1) {
    return null;
  }
  return <FormControl id={`fc_${data.questId}`} className={classNames(classes.w100, classes.alignItemsLeft, classes.justifyContentCenter, classes.pt3)}>
    <FormLabel id={`ident_${data.questId}`}>{data.questText}</FormLabel>
    <RadioGroup
      aria-labelledby="demo-controlled-radio-buttons-group"
      name={`ident_${data.questId}`}
      value={data.answerValue}
      onChange={(event) => onChange(event, data.questId)}
      row={inRow ?? true}
    >
      {Boolean(items) && items.map(x => <FormControlLabel key={`key_${x.value}`} value={x.value} control={<Radio />} label={x.label} />)}
    </RadioGroup>
  </FormControl>;
};

export const ButtonsComponent = ({ classes, onSubmitClick, onCancelClick, disabledSubmitButton }) => {
  return (
    <div className={classNames(classes.row, classes.w100, classes.alignItemsCenter, classes.pt3)}>
      <IconButton
        onClick={onSubmitClick}
        variant="contained"
        color="inherit"
        className={classNames(classes.btn, classes.btnSubmit)}
        disabled={disabledSubmitButton}
        toolTip=""
        size="large">
        <Typography variant="body2">Odoslať</Typography>
        <NavigateNextIcon />
      </IconButton>
      <IconButton
        onClick={onCancelClick}
        variant="contained"
        color="inherit"
        className={classNames(classes.btn)}
        toolTip=""
        size="large">
        <Typography variant="body2">Odísť</Typography>
      </IconButton>
    </div>
  );
};
