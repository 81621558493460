const actionTypes = {
  SHOW_REQUEST_FAILURE_DIALOG: 'SHOW_REQUEST_FAILURE_DIALOG',
  HIDE_REQUEST_FAILURE_DIALOG: 'HIDE_REQUEST_FAILURE_DIALOG',

  LOGIN_REQUEST: 'LOGIN_REQUEST',
  LOGIN_SUCCESS: 'LOGIN_SUCCESS',
  LOGIN_FAILURE: 'LOGIN_FAILURE',
  LOGOUT_SUCCESS: 'LOGOUT_SUCCESS',

  LOGINEID_REQUEST: 'LOGINEID_REQUEST',
  LOGINEID_SUCCESS: 'LOGINEID_SUCCESS',
  LOGINEID_FAILURE: 'LOGINEID_FAILURE',

  SHOW_MSG: 'SHOW_MSG',
  HIDE_MSG: 'HIDE_MSG',

  SHOW_ERROR_MSG: 'SHOW_ERROR_MSG',
  HIDE_ERROR_MSG: 'HIDE_ERROR_MSG',

  CREATE_CODE_REQUEST: 'CREATE_CODE_REQUEST',
  CREATE_CODE_SUCCESS: 'CREATE_CODE_SUCCESS',
  CREATE_CODE_FAILURE: 'CREATE_CODE_FAILURE',

  SET_LOCALE_REQUEST: 'SET_LOCALE_REQUEST',
  SET_LOCALE_SUCCESS: 'SET_LOCALE_SUCCESS',
  SET_LOCALE_FAILURE: 'SET_LOCALE_FAILURE',

  ADD_ACCOUNT_PERMIT_REQUEST: 'ADD_ACCOUNT_PERMIT_REQUEST',
  ADD_ACCOUNT_PERMIT_SUCCESS: 'ADD_ACCOUNT_PERMIT_SUCCESS',
  ADD_ACCOUNT_PERMIT_FAILURE: 'ADD_ACCOUNT_PERMIT_FAILURE',

  ADD_ACCOUNT_PERSON_REQUEST: 'ADD_ACCOUNT_PERSON_REQUEST',
  ADD_ACCOUNT_PERSON_SUCCESS: 'ADD_ACCOUNT_PERSONT_SUCCESS',
  ADD_ACCOUNT_PERSON_FAILURE: 'ADD_ACCOUNT_PERSON_FAILURE',

  CANCEL_ACCOUNT_PERSON_REQUEST: 'CANCEL_ACCOUNT_PERSON_REQUEST',
  CANCEL_ACCOUNT_PERSON_SUCCESS: 'CANCEL_ACCOUNT_PERSON_SUCCESS',
  CANCEL_ACCOUNT_PERSON_FAILURE: 'CANCEL_ACCOUNT_PERSON_FAILURE',

  GET_VERIFY_EMAIL_REQUEST: 'GET_VERIFY_EMAIL_REQUEST',
  GET_VERIFY_EMAIL_SUCCESS: 'GET_VERIFY_EMAIL_SUCCESS',
  GET_VERIFY_EMAIL_FAILURE: 'GET_VERIFY_EMAIL_FAILURE',

  ADD_VERIFY_EMAIL_REQUEST: 'ADD_VERIFY_EMAIL_REQUEST',
  ADD_VERIFY_EMAIL_SUCCESS: 'ADD_VERIFY_EMAIL_SUCCESS',
  ADD_VERIFY_EMAIL_FAILURE: 'ADD_VERIFY_EMAIL_FAILURE',

  UPD_VERIFY_EMAIL_REQUEST: 'UPD_VERIFY_EMAIL_REQUEST',
  UPD_VERIFY_EMAIL_SUCCESS: 'UPD_VERIFY_EMAIL_SUCCESS',
  UPD_VERIFY_EMAIL_FAILURE: 'UPD_VERIFY_EMAIL_FAILURE',

  GET_LIST_COUNTRIES_REQUEST: 'GET_LIST_COUNTRIES_REQUEST',
  GET_LIST_COUNTRIES_SUCCESS: 'GET_LIST_COUNTRIES_SUCCESS',
  GET_LIST_COUNTRIES_FAILURE: 'GET_LIST_COUNTRIES_FAILURE',

  GET_COUNTRY_REQUEST: 'GET_COUNTRY_REQUEST',
  GET_COUNTRY_SUCCESS: 'GET_COUNTRY_SUCCESS',
  GET_COUNTRY_FAILURE: 'GET_COUNTRY_FAILURE',

  GET_USER_BY_NAME_REQUEST: 'GET_USER_BY_NAME_REQUEST',
  GET_USER_BY_NAME_SUCCESS: 'GET_USER_BY_NAME_SUCCESS',
  GET_USER_BY_NAME_FAILURE: 'GET_USER_BY_NAME_FAILURE',

  GET_ZIADOST_BY_NAME_REQUEST: 'GET_ZIADOST_BY_NAME_REQUEST',
  GET_ZIADOST_BY_NAME_SUCCESS: 'GET_ZIADOST_BY_NAME_SUCCESS',
  GET_ZIADOST_BY_NAME_FAILURE: 'GET_ZIADOST_BY_NAME_FAILURE',

  GET_LIST_PEOPLE_REQUEST: 'GET_LIST_PEOPLE_REQUEST',
  GET_LIST_PEOPLE_SUCCESS: 'GET_LIST_PEOPLE_SUCCESS',
  GET_LIST_PEOPLE_FAILURE: 'GET_LIST_PEOPLE_FAILURE',

  GET_PEOPLE_BY_RC_REQUEST: 'GET_PEOPLE_BY_RC_REQUEST',
  GET_PEOPLE_BY_RC_SUCCESS: 'GET_PEOPLE_BY_RC_SUCCESS',
  GET_PEOPLE_BY_RC_FAILURE: 'GET_PEOPLE_BY_RC_FAILURE',

  GET_PERSON_REQUEST: 'GET_PERSON_REQUEST',
  GET_PERSON_SUCCESS: 'GET_PERSON_SUCCESS',
  GET_PERSON_FAILURE: 'GET_PERSON_FAILURE',

  USER_MODIFY_REQUEST: 'USER_MODIFY_REQUEST',
  USER_MODIFY_SUCCESS: 'USER_MODIFY_SUCCESS',
  USER_MODIFY_FAILURE: 'USER_MODIFY_FAILURE',

  GET_CITY_OF_SETUP_REQUEST: 'GET_CITY_OF_SETUP_REQUEST',
  GET_CITY_OF_SETUP_SUCCESS: 'GET_CITY_OF_SETUP_SUCCESS',
  GET_CITY_OF_SETUP_FAILURE: 'GET_CITY_OF_SETUP_FAILURE',

  GET_IBAN_PARKING_REQUEST: 'GET_IBAN_PARKING_REQUEST',
  GET_IBAN_PARKING_SUCCESS: 'GET_IBAN_PARKING_SUCCESS',
  GET_IBAN_PARKING_FAILURE: 'GET_IBAN_PARKING_FAILURE',

  GET_BY_SQUARE_REQUEST: 'GET_BY_SQUARE_REQUEST',
  GET_BY_SQUARE_SUCCESS: 'GET_BY_SQUARE_SUCCESS',
  GET_BY_SQUARE_FAILURE: 'GET_BY_SQUARE_FAILURE',

  GET_TAXES_BY_SQUARE_REQUEST: 'GET_TAXES_BY_SQUARE_REQUEST',
  GET_TAXES_BY_SQUARE_SUCCESS: 'GET_TAXES_BY_SQUARE_SUCCESS',
  GET_TAXES_BY_SQUARE_FAILURE: 'GET_TAXES_BY_SQUARE_FAILURE',

  GET_LIST_DRIVE_REQUEST: 'GET_LIST_DRIVE_REQUEST',
  GET_LIST_DRIVE_SUCCESS: 'GET_LIST_DRIVE_SUCCESS',
  GET_LIST_DRIVE_FAILURE: 'GET_LIST_DRIVE_FAILURE',

  GET_LIST_ZONE_REQUEST: 'GET_LIST_ZONE_REQUEST',
  GET_LIST_ZONE_SUCCESS: 'GET_LIST_ZONE_SUCCESS',
  GET_LIST_ZONE_FAILURE: 'GET_LIST_ZONE_FAILURE',

  GET_PARKING_ZONE_LABEL_REQUEST: 'PARKING_ZONE_LABEL_REQUEST',
  GET_PARKING_ZONE_LABEL_SUCCESS: 'PARKING_ZONE_LABEL_SUCCESS',
  GET_PARKING_ZONE_LABEL_FAILURE: 'PARKING_ZONE_LABEL_FAILURE',

  GET_LIST_ZONE_PARK_SHORT_REQUEST: 'GET_LIST_ZONE_PARK_SHORT_REQUEST',
  GET_LIST_ZONE_PARK_SHORT_SUCCESS: 'GET_LIST_ZONE_PARK_SHORT_SUCCESS',
  GET_LIST_ZONE_PARK_SHORT_FAILURE: 'GET_LIST_ZONE_PARK_SHORT_FAILURE',

  GET_LIST_CARD_TYPE_REQUEST: 'GET_LIST_CARD_TYPE_REQUEST',
  GET_LIST_CARD_TYPE_SUCCESS: 'GET_LIST_CARD_TYPE_SUCCESS',
  GET_LIST_CARD_TYPE_FAILURE: 'GET_LIST_CARD_TYPE_FAILURE',

  GET_CARD_TYPE_REQUEST: 'GET_CARD_TYPE_REQUEST',
  GET_CARD_TYPE_SUCCESS: 'GET_CARD_TYPE_SUCCESS',
  GET_CARD_TYPE_FAILURE: 'GET_CARD_TYPE_FAILURE',

  GET_LIST_ADRESA_REQUEST: 'GET_LIST_ADRESA_REQUEST',
  GET_LIST_ADRESA_SUCCESS: 'GET_LIST_ADRESA_SUCCESS',
  GET_LIST_ADRESA_FAILURE: 'GET_LIST_ADRESA_FAILURE',

  GET_ADRESA_REQUEST: 'GET_ADRESA_REQUEST',
  GET_ADRESA_SUCCESS: 'GET_ADRESA_SUCCESS',
  GET_ADRESA_FAILURE: 'GET_ADRESA_FAILURE',
  ADRESA_RESET: 'ADRESA_RESET',

  ADD_PARKING_PERMIT_REQUEST: 'ADD_PARKING_PERMIT_REQUEST',
  ADD_PARKING_PERMIT_SUCCESS: 'ADD_PARKING_PERMIT_SUCCESS',
  ADD_PARKING_PERMIT_FAILURE: 'ADD_PARKING_PERMIT_FAILURE',

  ADD_PARKING_MODIFY_REQUEST: 'ADD_PARKING_MODIFY_REQUEST',
  ADD_PARKING_MODIFY_SUCCESS: 'ADD_PARKING_MODIFY_SUCCESS',
  ADD_PARKING_MODIFY_FAILURE: 'ADD_PARKING_MODIFY_FAILURE',

  ADD_PARKING_EXTEND_REQUEST: 'ADD_PARKING_EXTEND_REQUEST',
  ADD_PARKING_EXTEND_SUCCESS: 'ADD_PARKING_EXTEND_SUCCESS',
  ADD_PARKING_EXTEND_FAILURE: 'ADD_PARKING_EXTEND_FAILURE',

  GET_PARKING_CARD_REQUEST: 'GET_PARKING_CARD_REQUEST',
  GET_PARKING_CARD_SUCCESS: 'GET_PARKING_CARD_SUCCESS',
  GET_PARKING_CARD_FAILURE: 'GET_PARKING_CARD_FAILURE',

  GET_LIST_PARKING_CARD_REQUEST: 'GET_LIST_PARKING_CARD_REQUEST',
  GET_LIST_PARKING_CARD_SUCCESS: 'GET_LIST_PARKING_CARD_SUCCESS',
  GET_LIST_PARKING_CARD_FAILURE: 'GET_LIST_PARKING_CARD_FAILURE',

  CANCEL_PARKING_PERMIT_REQUEST: 'CANCEL_PARKING_PERMIT_REQUEST',
  CANCEL_PARKING_PERMIT_SUCCESS: 'CANCEL_PARKING_PERMIT_SUCCESS',
  CANCEL_PARKING_PERMIT_FAILURE: 'CANCEL_PARKING_PERMIT_FAILURE',

  GET_LIST_CARD_STATE_REQUEST: 'GET_LIST_CARD_STATE_REQUEST',
  GET_LIST_CARD_STATE_SUCCESS: 'GET_LIST_CARD_STATE_SUCCESS',
  GET_LIST_CARD_STATE_FAILURE: 'GET_LIST_CARD_STATE_FAILURE',

  GET_PARKING_TICKETS_REQUEST: 'GET_PARKING_TICKETS_REQUEST',
  GET_PARKING_TICKETS_SUCCESS: 'GET_PARKING_TICKETS_SUCCESS',
  GET_PARKING_TICKETS_FAILURE: 'GET_PARKING_TICKETS_FAILURE',

  GET_PARKING_CREDIT_REQUEST: 'GET_PARKING_CREDIT_REQUEST',
  GET_PARKING_CREDIT_SUCCESS: 'GET_PARKING_CREDIT_SUCCESS',
  GET_PARKING_CREDIT_FAILURE: 'GET_PARKING_CREDIT_FAILURE',

  GET_LIST_PARKING_CREDIT_REQUEST: 'GET_LIST_PARKING_CREDIT_REQUEST',
  GET_LIST_PARKING_CREDIT_SUCCESS: 'GET_LIST_PARKING_CREDIT_SUCCESS',
  GET_LIST_PARKING_CREDIT_FAILURE: 'GET_LIST_PARKING_CREDIT_FAILURE',

  GET_CREDIT_DRAW_REQUEST: 'GET_CREDIT_DRAW_REQUEST',
  GET_CREDIT_DRAW_SUCCESS: 'GET_CREDIT_DRAW_SUCCESS',
  GET_CREDIT_DRAW_FAILURE: 'GET_CREDIT_DRAW_FAILURE',

  GET_LIST_CREDIT_DRAW_REQUEST: 'GET_LIST_CREDIT_DRAW_REQUEST',
  GET_LIST_CREDIT_DRAW_SUCCESS: 'GET_LIST_CREDIT_DRAW_SUCCESS',
  GET_LIST_CREDIT_DRAW_FAILURE: 'GET_LIST_CREDIT_DRAW_FAILURE',

  GET_ZONE_TIME_REQUEST: 'GET_ZONE_TIME_REQUEST',
  GET_ZONE_TIME_SUCCESS: 'GET_ZONE_TIME_SUCCESS',
  GET_ZONE_TIME_FAILURE: 'GET_ZONE_TIME_FAILURE',

  GET_ZONE_TIME_MD_REQUEST: 'GET_ZONE_TIME_MD_REQUEST',
  GET_ZONE_TIME_MD_SUCCESS: 'GET_ZONE_TIME_MD_SUCCESS',
  GET_ZONE_TIME_MD_FAILURE: 'GET_ZONE_TIME_MD_FAILURE',

  GET_ZONE_TIME_LIST_REQUEST: 'GET_ZONE_TIME_LIST_REQUEST',
  GET_ZONE_TIME_LIST_SUCCESS: 'GET_ZONE_TIME_LIST_SUCCESS',
  GET_ZONE_TIME_LIST_FAILURE: 'GET_ZONE_TIME_LIST_FAILURE',

  ADD_DRAW_REQUEST: 'ADD_DRAW_REQUEST',
  ADD_DRAW_SUCCESS: 'ADD_DRAW_SUCCESS',
  ADD_DRAW_FAILURE: 'ADD_DRAW_FAILURE',

  CANCEL_DRAW_REQUEST: 'CANCEL_DRAW_REQUEST',
  CANCEL_DRAW_SUCCESS: 'CANCEL_DRAW_SUCCESS',
  CANCEL_DRAW_FAILURE: 'CANCEL_DRAW_FAILURE',

  GET_REQUIRED_PARKING_ADR_REQUEST: 'GET_REQUIRED_PARKING_ADR_REQUEST',
  GET_REQUIRED_PARKING_ADR_SUCCESS: 'GET_REQUIRED_PARKING_ADR_SUCCESS',
  GET_REQUIRED_PARKING_ADR_FAILURE: 'GET_REQUIRED_PARKING_ADR_FAILURE',

  GET_CARD_KAT_REQUEST: 'GET_CARD_KAT_REQUEST',
  GET_CARD_KAT_SUCCESS: 'GET_CARD_KAT_SUCCESS',
  GET_CARD_KAT_FAILURE: 'GET_CARD_KAT_FAILURE',

  GET_LIST_CARD_KAT_REQUEST: 'GET_LIST_CARD_KAT_REQUEST',
  GET_LIST_CARD_KAT_SUCCESS: 'GET_LIST_CARD_KAT_SUCCESS',
  GET_LIST_CARD_KAT_FAILURE: 'GET_LIST_CARD_KAT_FAILURE',

  SET_PERMIT_VERIFY_REQUEST: 'SET_PERMIT_VERIFY_REQUEST',
  SET_PERMIT_VERIFY_SUCCESS: 'SET_PERMIT_VERIFY_SUCCESS',
  SET_PERMIT_VERIFY_FAILURE: 'SET_PERMIT_VERIFY_FAILURE',

  GET_URL_GDPR_REQUEST: 'GET_URL_GDPR_REQUEST',
  GET_URL_GDPR_SUCCESS: 'GET_URL_GDPR_SUCCESS',
  GET_URL_GDPR_FAILURE: 'GET_URL_GDPR_FAILURE',

  GET_URL_CONDITIONS_PC_REQUEST: 'GET_URL_CONDITIONS_PC_REQUEST',
  GET_URL_CONDITIONS_PC_SUCCESS: 'GET_URL_CONDITIONS_PC_SUCCESS',
  GET_URL_CONDITIONS_PC_FAILURE: 'GET_URL_CONDITIONS_PC_FAILURE',

  SET_VISIBLE_ZTP: 'SET_VISIBLE_ZTP',
  GET_VISIBLE_ZTP_REQUEST: 'GET_VISIBLE_ZTP_REQUEST',
  GET_VISIBLE_ZTP_SUCCESS: 'GET_VISIBLE_ZTP_SUCCESS',
  GET_VISIBLE_ZTP_FAILURE: 'GET_VISIBLE_ZTP_FAILURE',

  SET_VISIBLE_D_OD: 'SET_VISIBLE_D_OD',
  GET_VISIBLE_D_OD_REQUEST: 'GET_VISIBLE_D_OD_REQUEST',
  GET_VISIBLE_D_OD_SUCCESS: 'GET_VISIBLE_D_OD_SUCCESS',
  GET_VISIBLE_D_OD_FAILURE: 'GET_VISIBLE_D_OD_FAILURE',

  GET_PAY_RECIPIENT_REQUEST: 'GET_PAY_RECIPIENT_REQUEST',
  GET_PAY_RECIPIENT_SUCCESS: 'GET_PAY_RECIPIENT_SUCCESS',
  GET_PAY_RECIPIENT_FAILURE: 'GET_PAY_RECIPIENT_FAILURE',

  GET_PAY_RECIPIENT_TAXES_REQUEST: 'GET_PAY_RECIPIENT_TAXES_REQUEST',
  GET_PAY_RECIPIENT_TAXES_SUCCESS: 'GET_PAY_RECIPIENT_TAXES_SUCCESS',
  GET_PAY_RECIPIENT_TAXES_FAILURE: 'GET_PAY_RECIPIENT_TAXES_FAILURE',

  GET_VISIBLE_PUBLIC_ZONE_REQUEST: 'GET_VISIBLE_PUBLIC_ZONE_REQUEST',
  GET_VISIBLE_PUBLIC_ZONE_SUCCESS: 'GET_VISIBLE_PUBLIC_ZONE_SUCCESS',
  GET_VISIBLE_PUBLIC_ZONE_FAILURE: 'GET_VISIBLE_PUBLIC_ZONE_FAILURE',

  GET_VISIBLE_PARK_SHORT_REQUEST: 'GET_VISIBLE_PARK_SHORT_REQUEST',
  GET_VISIBLE_PARK_SHORT_SUCCESS: 'GET_VISIBLE_PARK_SHORT_SUCCESS',
  GET_VISIBLE_PARK_SHORT_FAILURE: 'GET_VISIBLE_PARK_SHORT_FAILURE',

  GET_VISIBLE_PARK_CARD_UNREG_REQUEST: 'GET_VISIBLE_PARK_CARD_UNREG_REQUEST',
  GET_VISIBLE_PARK_CARD_UNREG_SUCCESS: 'GET_VISIBLE_PARK_CARD_UNREG_SUCCESS',
  GET_VISIBLE_PARK_CARD_UNREG_FAILURE: 'GET_VISIBLE_PARK_CARD_UNREG_FAILURE',

  GET_VISIBLE_TEMP_ECV_REQUEST: 'GET_VISIBLE_TEMP_ECV_REQUEST',
  GET_VISIBLE_TEMP_ECV_SUCCESS: 'GET_VISIBLE_TEMP_ECV_SUCCESS',
  GET_VISIBLE_TEMP_ECV_FAILURE: 'GET_VISIBLE_TEMP_ECV_FAILURE',

  GET_LIST_TEMP_ECV_REQUEST: 'GET_LIST_TEMP_ECV_REQUEST',
  GET_LIST_TEMP_ECV_SUCCESS: 'GET_LIST_TEMP_ECV_SUCCESS',
  GET_LIST_TEMP_ECV_FAILURE: 'GET_LIST_TEMP_ECV_FAILURE',

  ADD_TEMP_ECV_REQUEST: 'ADD_TEMP_ECV_REQUEST',
  ADD_TEMP_ECV_SUCCESS: 'ADD_TEMP_ECV_SUCCESS',
  ADD_TEMP_ECV_FAILURE: 'ADD_TEMP_ECV_FAILURE',

  CANCEL_TEMP_ECV_REQUEST: 'CANCEL_TEMP_ECV_REQUEST',
  CANCEL_TEMP_ECV_SUCCESS: 'CANCEL_TEMP_ECV_SUCCESS',
  CANCEL_TEMP_ECV_FAILURE: 'CANCEL_TEMP_ECV_FAILURE',

  GET_PASSWORD_PATTERN_REQUEST: 'GET_PASSWORD_PATTERN_REQUEST',
  GET_PASSWORD_PATTERN_SUCCESS: 'GET_PASSWORD_PATTERN_SUCCESS',
  GET_PASSWORD_PATTERN_FAILURE: 'GET_PASSWORD_PATTERN_FAILURE',

  GET_PASSWORD_ERR_MSG_REQUEST: 'GET_PASSWORD_ERR_MSG_REQUEST',
  GET_PASSWORD_ERR_MSG_SUCCESS: 'GET_PASSWORD_ERR_MSG_SUCCESS',
  GET_PASSWORD_ERR_MSG_FAILURE: 'GET_PASSWORD_ERR_MSG_FAILURE',

  GET_LIST_TAXES_DECISION_REQUEST: 'GET_LIST_TAXES_DECISION_REQUEST',
  GET_LIST_TAXES_DECISION_SUCCESS: 'GET_LIST_TAXES_DECISION_SUCCESS',
  GET_LIST_TAXES_DECISION_FAILURE: 'GET_LIST_TAXES_DECISION_FAILURE',

  GET_LIST_PROCEEDINGS_REQUEST: 'GET_LIST_PROCEEDINGS_REQUEST',
  GET_LIST_PROCEEDINGS_SUCCESS: 'GET_LIST_PROCEEDINGS_SUCCESS',
  GET_LIST_PROCEEDINGS_FAILURE: 'GET_LIST_PROCEEDINGS_FAILURE',

  GET_PROCEEDING_DETAIL_REQUEST: 'GET_PROCEEDING_DETAIL_REQUEST',
  GET_PROCEEDING_DETAIL_SUCCESS: 'GET_PROCEEDING_DETAIL_SUCCESS',
  GET_PROCEEDING_DETAIL_FAILURE: 'GET_PROCEEDING_DETAIL_FAILURE',

  GET_LIST_PROPERTY_TAXES_REQUEST: 'GET_LIST_PROPERTY_TAXES_REQUEST',
  GET_LIST_PROPERTY_TAXES_SUCCESS: 'GET_LIST_PROPERTY_TAXES_SUCCESS',
  GET_LIST_PROPERTY_TAXES_FAILURE: 'GET_LIST_PROPERTY_TAXES_FAILURE',

  GET_LIST_INSTALLMENTS_REQUEST: 'GET_LIST_INSTALLMENTS_REQUEST',
  GET_LIST_INSTALLMENTS_SUCCESS: 'GET_LIST_INSTALLMENTS_SUCCESS',
  GET_LIST_INSTALLMENTS_FAILURE: 'GET_LIST_INSTALLMENTS_FAILURE',

  GET_LIST_PAYMENTS_REQUEST: 'GET_LIST_PAYMENTS_REQUEST',
  GET_LIST_PAYMENTS_SUCCESS: 'GET_LIST_PAYMENTS_SUCCESS',
  GET_LIST_PAYMENTS_FAILURE: 'GET_LIST_PAYMENTS_FAILURE',

  GET_LIST_TAXES_ARREARS_REQUEST: 'GET_LIST_TAXES_ARREARS_REQUEST',
  GET_LIST_TAXES_ARREARS_SUCCESS: 'GET_LIST_TAXES_ARREARS_SUCCESS',
  GET_LIST_TAXES_ARREARS_FAILURE: 'GET_LIST_TAXES_ARREARS_FAILURE',

  GET_LIST_SUBSIDIES_RATING_REQUEST: 'GET_LIST_SUBSIDIES_RATING_REQUEST',
  GET_LIST_SUBSIDIES_RATING_SUCCESS: 'GET_LIST_SUBSIDIES_RATING_SUCCESS',
  GET_LIST_SUBSIDIES_RATING_FAILURE: 'GET_LIST_SUBSIDIES_RATING_FAILURE',

  GET_LIST_SUBSIDY_DETAIL_REQUEST: 'GET_LIST_SUBSIDY_DETAIL_REQUEST',
  GET_LIST_SUBSIDY_DETAIL_SUCCESS: 'GET_LIST_SUBSIDY_DETAIL_SUCCESS',
  GET_LIST_SUBSIDY_DETAIL_FAILURE: 'GET_LIST_SUBSIDY_DETAIL_FAILURE',

  GET_LIST_SUBSIDY_RATING_DETAIL_REQUEST: 'GET_LIST_SUBSIDY_RATING_DETAIL_REQUEST',
  GET_LIST_SUBSIDY_RATING_DETAIL_SUCCESS: 'GET_LIST_SUBSIDY_RATING_DETAIL_SUCCESS',
  GET_LIST_SUBSIDY_RATING_DETAIL_FAILURE: 'GET_LIST_SUBSIDY_RATING_DETAIL_FAILURE',

  UPD_SUBSIDY_RATING_REQUEST: 'UPD_SUBSIDY_RATING_REQUEST',
  UPD_SUBSIDY_RATING_SUCCESS: 'UPD_SUBSIDY_RATING_SUCCESS',
  UPD_SUBSIDY_RATING_FAILURE: 'UPD_SUBSIDY_RATING_FAILURE',

  CHANGE_SUBSIDY_RATING: 'CHANGE_SUBSIDY_RATING',

  GET_EGOV_HANDLER_URL_REQUEST: 'GET_EGOV_HANDLER_URL_REQUEST',
  GET_EGOV_HANDLER_URL_SUCCESS: 'GET_EGOV_HANDLER_URL_SUCCESS',
  GET_EGOV_HANDLER_URL_FAILURE: 'GET_EGOV_HANDLER_URL_FAILURE',

  GET_PERSON_EID_REQUEST: 'GET_PERSON_EID_REQUEST',
  GET_PERSON_EID_SUCCESS: 'GET_PERSON_EID_SUCCESS',
  GET_PERSON_EID_FAILURE: 'GET_PERSON_EID_FAILURE',

  GET_PERSON_EID_TYPE_REQUEST: 'GET_PERSON_EID_TYPE_REQUEST',
  GET_PERSON_EID_TYPE_SUCCESS: 'GET_PERSON_EID_TYPE_SUCCESS',
  GET_PERSON_EID_TYPE_FAILURE: 'GET_PERSON_EID_TYPE_FAILURE',

  GET_PUBLIC_ZONE_BUTTON_REQUEST: 'GET_PUBLIC_ZONE_BUTTON_REQUEST',
  GET_PUBLIC_ZONE_BUTTON_SUCCESS: 'GET_PUBLIC_ZONE_BUTTON_SUCCESS',
  GET_PUBLIC_ZONE_BUTTON_FAILURE: 'GET_PUBLIC_ZONE_BUTTON_FAILURE',

  GET_MY_ZONE_BUTTON_REQUEST: 'GET_MY_ZONE_BUTTON_REQUEST',
  GET_MY_ZONE_BUTTON_SUCCESS: 'GET_MY_ZONE_BUTTON_SUCCESS',
  GET_MY_ZONE_BUTTON_FAILURE: 'GET_MY_ZONE_BUTTON_FAILURE',

  GET_PARKING_BUTTON_REQUEST: 'GET_PARKING_BUTTON_REQUEST',
  GET_PARKING_BUTTON_SUCCESS: 'GET_PARKING_BUTTON_SUCCESS',
  GET_PARKING_BUTTON_FAILURE: 'GET_PARKING_BUTTON_FAILURE',

  GET_VISIBLE_PLACES_MAP_BUTTON_REQUEST: 'GET_VISIBLE_PLACES_MAP_BUTTON_REQUEST',
  GET_VISIBLE_PLACES_MAP_BUTTON_SUCCESS: 'GET_VISIBLE_PLACES_MAP_BUTTON_SUCCESS',
  GET_VISIBLE_PLACES_MAP_BUTTON_FAILURE: 'GET_VISIBLE_PLACES_MAP_BUTTON_FAILURE',

  ADMIN_MODE_SET_ACTIVE_STATE: 'ADMIN_MODE_SET_ACTIVE_STATE',
  ADMIN_MODE_SET_CURR_EDITED_SECTION: 'ADMIN_MODE_SET_CURR_EDITED_SECTION',
  ADMIN_MODE_SET_EDITED_DATA: 'ADMIN_MODE_SET_EDITED_DATA',
  ADMIN_MODE_RESET: 'ADMIN_MODE_RESET',
  ADMIN_MODE_UPDATE_OR_ADD_REQUEST: 'ADMIN_MODE_UPDATE_OR_ADD_REQUEST',
  ADMIN_MODE_UPDATE_OR_ADD_SUCCESS: 'ADMIN_MODE_UPDATE_OR_ADD_SUCCESS',
  ADMIN_MODE_UPDATE_OR_ADD_FAILURE: 'ADMIN_MODE_UPDATE_OR_ADD_FAILURE',

  /** Konfiguračné nastavenia pre platobnú bránu Besteron (PH=-4) - Request */
  GET_BESTERON_PAYMENT_GATEWAY_SETTINGS_REQUEST: 'GET_BESTERON_PAYMENT_GATEWAY_SETTINGS_REQUEST',
  /** Konfiguračné nastavenia pre platobnú bránu Besteron (PH=-4) - Success */
  GET_BESTERON_PAYMENT_GATEWAY_SETTINGS_SUCCESS: 'GET_BESTERON_PAYMENT_GATEWAY_SETTINGS_SUCCESS',
  /** Konfiguračné nastavenia pre platobnú bránu Besteron (PH=-4) - Failure */
  GET_BESTERON_PAYMENT_GATEWAY_SETTINGS_FAILURE: 'GET_BESTERON_PAYMENT_GATEWAY_SETTINGS_FAILURE',

  /** Konfiguračné nastavenia pre platby - Request */
  GET_PAYMENT_SETTINGS_REQUEST: 'GET_PAYMENT_SETTINGS_REQUEST',
  /** Konfiguračné nastavenia pre platby - Success */
  GET_PAYMENT_SETTINGS_SUCCESS: 'GET_PAYMENT_SETTINGS_SUCCESS',
  /** Konfiguračné nastavenia pre platby - Failure */
  GET_PAYMENT_SETTINGS_FAILURE: 'GET_PAYMENT_SETTINGS_FAILURE',

  CHANGE_PARKING_PERMIT_DATA: 'CHANGE_PARKING_PERMIT_DATA',

  GET_ECV_LIST_REQUEST: 'GET_ECV_LIST_REQUEST',
  GET_ECV_LIST_SUCCESS: 'GET_ECV_LIST_SUCCESS',
  GET_ECV_LIST_FAILURE: 'GET_ECV_LIST_FAILURE',

  GET_VERSION_INFO_REQUEST: 'GET_VERSION_INFO_REQUEST',
  GET_VERSION_INFO_SUCCESS: 'GET_VERSION_INFO_SUCCESS',
  GET_VERSION_INFO_FAILURE: 'GET_VERSION_INFO_FAILURE',

  GET_LIST_ACTIVE_PARKING_CARD_REQUEST: 'GET_LIST_ACTIVE_PARKING_CARD_REQUEST',
  GET_LIST_ACTIVE_PARKING_CARD_SUCCESS: 'GET_LIST_ACTIVE_PARKING_CARD_SUCCESS',
  GET_LIST_ACTIVE_PARKING_CARD_FAILURE: 'GET_LIST_ACTIVE_PARKING_CARD_FAILURE',

  GET_NOT_AVAILABLE_FREE_PARKING_REQUEST: 'GET_NOT_AVAILABLE_FREE_PARKING_REQUEST',
  GET_NOT_AVAILABLE_FREE_PARKING_SUCCESS: 'GET_NOT_AVAILABLE_FREE_PARKING_SUCCESS',
  GET_NOT_AVAILABLE_FREE_PARKING_FAILURE: 'GET_NOT_AVAILABLE_FREE_PARKING_FAILURE',

  GET_PARKING_PERMITS_LIST_REQUEST: 'GET_PARKING_PERMITS_LIST_REQUEST',
  GET_PARKING_PERMITS_LIST_SUCCESS: 'GET_PARKING_PERMITS_LIST_SUCCESS',
  GET_PARKING_PERMITS_LIST_FAILURE: 'GET_PARKING_PERMITS_LIST_FAILURE',
  PARKING_PERMITS_LIST_RESET: 'PARKING_PERMITS_LIST_RESET',

  GET_ZST_ZS_TREE_REQUEST: 'GET_ZST_ZS_TREE_REQUEST',
  GET_ZST_ZS_TREE_SUCCESS: 'GET_ZST_ZS_TREE_SUCCESS',
  GET_ZST_ZS_TREE_FAILURE: 'GET_ZST_ZS_TREE_FAILURE',

  GET_ZST_ZS_LIST_REQUEST: 'GET_ZST_ZS_LIST_REQUEST',
  GET_ZST_ZS_LIST_SUCCESS: 'GET_ZST_ZS_LIST_SUCCESS',
  GET_ZST_ZS_LIST_FAILURE: 'GET_ZST_ZS_LIST_FAILURE',

  GET_ZST_SLZ_LIST_REQUEST: 'GET_ZST_SLZ_LIST_REQUEST',
  GET_ZST_SLZ_LIST_SUCCESS: 'GET_ZST_SLZ_LIST_SUCCESS',
  GET_ZST_SLZ_LIST_FAILURE: 'GET_ZST_SLZ_LIST_FAILURE',

  GET_ZST_SLZ_FORM_LIST_REQUEST: 'GET_ZST_SLZ_FORM_LIST_REQUEST',
  GET_ZST_SLZ_FORM_LIST_SUCCESS: 'GET_ZST_SLZ_FORM_LIST_SUCCESS',
  GET_ZST_SLZ_FORM_LIST_FAILURE: 'GET_ZST_SLZ_FORM_LIST_FAILURE',

  GET_ZST_C_DTZ_OTZ_LIST_REQUEST: 'GET_ZST_C_DTZ_OTZ_LIST_REQUEST',
  GET_ZST_C_DTZ_OTZ_LIST_SUCCESS: 'GET_ZST_C_DTZ_OTZ_LIST_SUCCESS',
  GET_ZST_C_DTZ_OTZ_LIST_FAILURE: 'GET_ZST_C_DTZ_OTZ_LIST_FAILURE',

  GET_ZST_LS_OTZ_LIST_REQUEST: 'GET_ZST_LS_OTZ_LIST_REQUEST',
  GET_ZST_LS_OTZ_LIST_SUCCESS: 'GET_ZST_LS_OTZ_LIST_SUCCESS',
  GET_ZST_LS_OTZ_LIST_FAILURE: 'GET_ZST_LS_OTZ_LIST_FAILURE',

  GET_ZST_SESSION_ID_REQUEST: 'GET_ZST_SESSION_ID_REQUEST',
  GET_ZST_SESSION_ID_SUCCESS: 'GET_ZST_SESSION_ID_SUCCESS',
  GET_ZST_SESSION_ID_FAILURE: 'GET_ZST_SESSION_ID_FAILURE',

  GET_SLZ_AKT_LIST_REQUEST: 'GET_SLZ_AKT_LIST_REQUEST',
  GET_SLZ_AKT_LIST_SUCCESS: 'GET_SLZ_AKT_LIST_SUCCESS',
  GET_SLZ_AKT_LIST_FAILURE: 'GET_SLZ_AKT_LIST_FAILURE',

  GET_SLZ_USED_LIST_REQUEST: 'GET_SLZ_USED_LIST_REQUEST',
  GET_SLZ_USED_LIST_SUCCESS: 'GET_SLZ_USED_LIST_SUCCESS',
  GET_SLZ_USED_LIST_FAILURE: 'GET_SLZ_USED_LIST_FAILURE',

  GET_SLZ_USED_USER_LIST_REQUEST: 'GET_SLZ_USED_USER_LIST_REQUEST',
  GET_SLZ_USED_USER_LIST_SUCCESS: 'GET_SLZ_USED_USER_LIST_SUCCESS',
  GET_SLZ_USED_USER_LIST_FAILURE: 'GET_SLZ_USED_USER_LIST_FAILURE',

  GET_LIST_PROG_ROZP_REQUEST: 'GET_LIST_PROG_ROZP_REQUEST',
  GET_LIST_PROG_ROZP_SUCCESS: 'GET_LIST_PROG_ROZP_SUCCESS',
  GET_LIST_PROG_ROZP_FAILURE: 'GET_LIST_PROG_ROZP_FAILURE',

  GET_INIT_PROG_ROZP_REQUEST: 'GET_INIT_PROG_ROZP_REQUEST',
  GET_INIT_PROG_ROZP_SUCCESS: 'GET_INIT_PROG_ROZP_SUCCESS',
  GET_INIT_PROG_ROZP_FAILURE: 'GET_INIT_PROG_ROZP_FAILURE',

  GET_CARD_EXTEND_BEFORE_REQUEST: 'GET_CARD_EXTEND_BEFORE_REQUEST',
  GET_CARD_EXTEND_BEFORE_SUCCESS: 'GET_CARD_EXTEND_BEFORE_SUCCESS',
  GET_CARD_EXTEND_BEFORE_FAILURE: 'GET_CARD_EXTEND_BEFORE_FAILURE',

  GET_CARD_EXTEND_AFTER_REQUEST: 'GET_CARD_EXTEND_AFTER_REQUEST',
  GET_CARD_EXTEND_AFTER_SUCCESS: 'GET_CARD_EXTEND_AFTER_SUCCESS',
  GET_CARD_EXTEND_AFTER_FAILURE: 'GET_CARD_EXTEND_AFTER_FAILURE',

  GET_ACTIVE_QUICK_ACTIONS_REQUEST: 'GET_ACTIVE_QUICK_ACTIONS_REQUEST',
  GET_ACTIVE_QUICK_ACTIONS_SUCCESS: 'GET_ACTIVE_QUICK_ACTIONS_SUCCESS',
  GET_ACTIVE_QUICK_ACTIONS_FAILURE: 'GET_ACTIVE_QUICK_ACTIONS_FAILURE',

  GET_LIST_VALID_PARKING_CARD_REQUEST: 'GET_LIST_VALID_PARKING_CARD_REQUEST',
  GET_LIST_VALID_PARKING_CARD_SUCCESS: 'GET_LIST_VALID_PARKING_CARD_SUCCESS',
  GET_LIST_VALID_PARKING_CARD_FAILURE: 'GET_LIST_VALID_PARKING_CARD_FAILURE',

  GET_VISIBLE_ID_NUMBER_SUBSIDY_REQUEST: 'GET_VISIBLE_ID_NUMBER_SUBSIDY_REQUEST',
  GET_VISIBLE_ID_NUMBER_SUBSIDY_SUCCESS: 'GET_VISIBLE_ID_NUMBER_SUBSIDY_SUCCESS',
  GET_VISIBLE_ID_NUMBER_SUBSIDY_FAILURE: 'GET_VISIBLE_ID_NUMBER_SUBSIDY_FAILURE',

  GET_E_FORM_URL_SUBSIDY_REQUEST: 'GET_E_FORM_URL_SUBSIDY_REQUEST',
  GET_E_FORM_URL_SUBSIDY_SUCCESS: 'GET_E_FORM_URL_SUBSIDY_SUCCESS',
  GET_E_FORM_URL_SUBSIDY_FAILURE: 'GET_E_FORM_URL_SUBSIDY_FAILURE',

  GET_MAP_LAYERS_SETTINGS_REQUEST: 'GET_MAP_LAYERS_SETTINGS_REQUEST',
  GET_MAP_LAYERS_SETTINGS_SUCCESS: 'GET_MAP_LAYERS_SETTINGS_SUCCESS',
  GET_MAP_LAYERS_SETTINGS_FAILURE: 'GET_MAP_LAYERS_SETTINGS_FAILURE',

  GET_FILTER_SUBSIDY_OBLST_REQUEST: 'GET_FILTER_SUBSIDY_OBLST_REQUEST',
  GET_FILTER_SUBSIDY_OBLST_SUCCESS: 'GET_FILTER_SUBSIDY_OBLST_SUCCESS',
  GET_FILTER_SUBSIDY_OBLST_FAILURE: 'GET_FILTER_SUBSIDY_OBLST_FAILURE',

  GET_FILTER_SUBSIDY_CLK_REQUEST: 'GET_FILTER_SUBSIDY_CLK_REQUEST',
  GET_FILTER_SUBSIDY_CLK_SUCCESS: 'GET_FILTER_SUBSIDY_CLK_SUCCESS',
  GET_FILTER_SUBSIDY_CLK_FAILURE: 'GET_FILTER_SUBSIDY_CLK_FAILURE',

  GET_VISIBLE_PLAN_BUTTON_REQUEST: 'GET_VISIBLE_PLAN_BUTTON_REQUEST',
  GET_VISIBLE_PLAN_BUTTON_SUCCESS: 'GET_VISIBLE_PLAN_BUTTON_SUCCESS',
  GET_VISIBLE_PLAN_BUTTON_FAILURE: 'GET_VISIBLE_PLAN_BUTTON_FAILURE',

  GET_MAX_HOURS_ONE_TICKET_REQUEST: 'GET_MAX_HOURS_ONE_TICKET_REQUEST',
  GET_MAX_HOURS_ONE_TICKET_SUCCESS: 'GET_MAX_HOURS_ONE_TICKET_SUCCESS',
  GET_MAX_HOURS_ONE_TICKET_FAILURE: 'GET_MAX_HOURS_ONE_TICKET_FAILURE',

  GET_FFI_CONFIG_REQUEST: 'GET_FFI_CONFIG_REQUEST',
  GET_FFI_CONFIG_SUCCESS: 'GET_FFI_CONFIG_SUCCESS',
  GET_FFI_CONFIG_FAILURE: 'GET_FFI_CONFIG_FAILURE',

  GET_FFI_WAYBILL_REQUEST: 'GET_FFI_WAYBILL_REQUEST',
  GET_FFI_WAYBILL_SUCCESS: 'GET_FFI_WAYBILL_SUCCESS',
  GET_FFI_WAYBILL_FAILURE: 'GET_FFI_WAYBILL_FAILURE',

  PROG_ROZP_SEARCH: 'PROG_ROZP_SEARCH',
}

export default actionTypes;