import PropTypes from 'prop-types';
import classNames from 'classnames';

import Typography from '../../coraWebMComponents/dataDisplay/Typography';

/**
 * Titulok k zoznamu sluzieb sluzby
 *
 * @param {*} props
 * @return {*} 
 */
const ZstSlzTitle = (props) => {
  if (!Boolean(props.listZstSlz.data)) {
    return null;
  }

  const uniqueIDs = props.listZstSlz.data.map(x => x.ZstZs.IZstZs).filter((value, index, array) => array.indexOf(value) === index);
  const zstZs = props.listZstZs.data.filter(x => uniqueIDs.includes(x.IZstZs));
  const selectedZs = props.listZstZs.data.filter(x => props.flt.selectedIDs.includes(x.IZstZs));
  const zstZsOrdered = zstZs.sort((a, b) => (Number(a.CZorad ?? 999999999) * 1000000000 + Number(a.IZstZs)) - (Number(b.CZorad ?? 999999999) * 1000000000 + Number(b.IZstZs)));

  return (
    <>
      {
        // ak je zoznam prazdny
        !zstZsOrdered.length && (
          <>
            <Typography variant={"body1"}>
              {selectedZs[0]?.NZstZs}
            </Typography>
            <Typography variant={"caption"} className={classNames(props.classes.textCaption)}>
              {selectedZs[0]?.ZsTxt}
            </Typography>
          </>
        )
      }
      {
        // ak zoznam nie je prazdny
        zstZsOrdered.map(item => {
          return (
            <div key={`iZs${item.IZstZs}`}>
              <Typography variant={"body1"}>
                {item.NZstZs}
              </Typography>
              <Typography variant={"caption"} className={classNames(props.classes.textCaption)}>
                {item.ZsTxt}
              </Typography>
            </div>
          );
        })
      }
    </>
  );
};

ZstSlzTitle.propTypes = {
  classes: PropTypes.object,
  listZstSlz: PropTypes.object,
  listZstZs: PropTypes.object,
};

export default ZstSlzTitle;