import { useState } from 'react';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import MenuItem from '@mui/material/MenuItem';
import InputAdornment from '@mui/material/InputAdornment';
import IconButton from '@mui/material/IconButton';
import useMediaQuery from '@mui/material/useMediaQuery';
import SearchIcon from '@mui/icons-material/Search';

import "./progRozpocetControl.css";

import sk from './sk.json';
import CircularProgressCustom from './CircularProgressCustom';

const LOCALE = sk.progRozpocetControl;

/**
 * Komponnet pre kontrolu nad filtrami a fulltext search
 *
 * @param {*} { 
 *    activeIOrg - ID aktivnej ogranizacia
 *    activeYear - akivny rok
 *    handleFullText - handler fulltext vyhladavania
 *    searchSuccess - priznak uspesneho vyhladania; true: hladanie bolo uspesne; false: hladanie neuspesne
 *    filtersValues - zoznam organizacii a rokov pre filter
 *    handleFilter - handler pre filtrovanie
 *    themeCustom - referencia na custom temu, pre riadenie breakpoints
 * }
 * @return {*} 
 */
const ProgRozpocetControl = ({ activeIOrg, activeYear, handleFullText, searchSuccess, filtersValues, handleFilter, themeCustom }) => {
  const [fullText, setFullText] = useState('');
  const [organization, setOrganization] = useState(activeIOrg); // aktivna ogranizacia pri prvom zobrazeni
  const [year, setYear] = useState(filtersValues.orgList[organization].years.includes(activeYear) ? activeYear : ''); // akivny rok pri prvom zobrazeni
  const matchesThemeMD = useMediaQuery(themeCustom?.breakpoints?.down('xs'));
  const [loading, setLoading] = useState(false); // true: ukaz progress bar

  const handleChangeIOrg = (value) => {
    setOrganization(value);
    if (value !== organization) {
      setYear(filtersValues.orgList[value].years[0] ? filtersValues.orgList[value].years[0] : '');
    }
  };

  const handleSearch = (text) => {
    setLoading(true);
    // fake oddelenie do samostatneho queue (Callback queue). Kvoli tomu, aby sa pockalo na vykreslenie TreeView na obrazovku
    setTimeout(() => {
      handleFullText(text);
      setLoading(false);
    }, 0);
  };

  return (<>
    {loading && (
      // Progres pre fullText
      (<>
        <CircularProgressCustom />
      </>)
    )}
    <Stack className="control-wrapper" spacing={2} direction={matchesThemeMD ? 'column' : 'row'}>
      <Stack className="filter-wrapper" spacing={2} direction={matchesThemeMD ? 'column' : 'row'}>
        <Box sx={{ width: matchesThemeMD ? "100%" : "15em" }}>
          <TextField
            onChange={(e) => { handleChangeIOrg(e.target.value); }}
            label={LOCALE.organizactionLabel}
            value={`${organization}`}
            select
            fullWidth
            size="small"
          >
            {filtersValues.orgList.map(item =>
              <MenuItem key={item.iOrg} value={item.iOrg}>{item.orgName}</MenuItem>
            )}
          </TextField>
        </Box>
        <Box sx={{ width: matchesThemeMD ? "100%" : "6em" }}>
          <TextField
            onChange={(e) => { setYear(e.target.value); }}
            label={LOCALE.yearLabel}
            value={year ? year : ''}
            select fullWidth size="small"
          >
            {
              filtersValues.orgList[organization].years.map(item =>
                <MenuItem key={item} value={item}>{item}</MenuItem>
              )
            }
          </TextField>
        </Box>
        <Box sx={{ width: matchesThemeMD ? "100%" : "10em" }}>
          <Button
            onClick={() => handleFilter(organization, year)}
            aria-label={LOCALE.loadButtonLabel}
            variant={'contained'}
            fullWidth
            disabled={!year ? true : false}
          >
            {LOCALE.loadButtonLabel}
          </Button>
        </Box>
      </Stack>
      <Box sx={{ width: matchesThemeMD ? "100%" : "22em" }}>
        <TextField
          onChange={(e) => { setFullText(e.target.value); }}
          onKeyDown={(e) => {
            if (e.key === 'Enter') {
              handleSearch(fullText);
            }
          }}
          type="search"
          value={fullText}
          label={LOCALE.searchLabel}
          aria-label={LOCALE.searchLabel}
          helperText={searchSuccess ? LOCALE.searchHelperDefault : LOCALE.searchHelperError}
          error={!searchSuccess}
          fullWidth
          size="small"
          InputProps={{
            endAdornment:
              <InputAdornment position="end">
                <IconButton
                  onClick={() => handleSearch(fullText)}
                  aria-label={LOCALE.searchLabel}
                  edge="end"
                  type="button"
                  color='primary'
                  size="large">
                  <SearchIcon />
                </IconButton>
              </InputAdornment>
          }}
        >
        </TextField>
      </Box>
    </Stack>
  </>);
};

export default ProgRozpocetControl;