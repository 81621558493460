import React from 'react';
import PropTypes from 'prop-types';
import Stepper from '@mui/material/Stepper';
import Step from '@mui/material/Step';
import Paper from '@mui/material/Paper';
import StepConnector from '@mui/material/StepConnector';
import StepLabel from '@mui/material/StepLabel';
import StepContent from '@mui/material/StepContent';
import withStyles from '@mui/styles/withStyles';
import Typography from './Typography';
import * as convert from '../../coraWebMComponents/utils/convert';
import getSharedStyles from '../sharedStyles';

const styles = theme => ({
  ...getSharedStyles(theme),
  stepperRoot: {
    padding: '0px'
  },
  stepperConnectorRoot: {
    padding: '0px',
    marginLeft: '0.7rem'
  },
  stepperConnectorLine: {
    minHeight: '20px',
  },
  stepContentRoot: {
    marginTop: '0px'
  },
  labelIconActive: {
    color: theme.palette.secondary.dark + "!important",
    width: '1.5rem!important',
    height: '1.5rem!important',
    marginLeft: '0rem!important'
  },
  labelIconText: {
    display: 'none'
  },
  labelIconRoot: {
    width: '1.15rem',
    height: '1.15rem',
    marginLeft: '0.17rem'
  },
  stepLabelRoot: {

  }
});

const StepIcon = () => (
  <div style={{ width: '24px', height: '24px', background: '#424242', borderRadius: '50px', display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
    <div style={{ width: '16px', height: '16px', background: 'white', borderRadius: '50px', }}>
    </div>
  </div>
);

/**
 * Vertical stepper component
 * @module
 * @param {array} steps - Array of steps
 * @param {string} [className] - Classes of vertical stepper
 * @param {number} [elevation] - elevation for Paper
 * @param {boolean} [elevation] - square for Paper
 */
class VerticalStepperComponent extends React.Component {
  render() {
    const { steps, classes } = this.props;

    const connector = (
      <StepConnector
        classes={{
          line: classes.stepperConnectorLine,
          root: classes.stepperConnectorRoot,
        }}
      />
    );



    return (
      <Paper square={this.props.square} elevation={this.props.elevation}>
        <Stepper
          activeStep={steps.length - 1}
          orientation="vertical"
          classes={{ root: classes.stepperRoot, connector: classes.stepperConnector }}
          className={this.props.className}
          connector={connector}
        >
          {steps.map((x, index) => (
            <Step key={index}>
              <StepLabel
                StepIconProps={{
                  classes: { root: classes.labelIconRoot, text: classes.labelIconText, active: classes.labelIconActive },
                  icon: ((steps.length - 1 === index) && <StepIcon />) || ''
                }}
                classes={{ root: classes.stepLabelRoot }}
              >
                <Typography variant="body1" color={index === steps.length - 1 ? "secondary" : "primary"}>{convert.convertDate(x.D_STAV)}</Typography>
                <Typography variant="body2" color={index === steps.length - 1 ? "secondary" : "primary"}>{x.N_KARTA_STAV}</Typography>
              </StepLabel>
              <StepContent classes={{ root: classes.stepContentRoot }}>
                <Typography variant="caption">{x.TXT_KARTA_STAV}</Typography>
              </StepContent>
            </Step>
          ))}
        </Stepper>
      </Paper>
    );
  }
}

VerticalStepperComponent.propTypes = {
  steps: PropTypes.array.isRequired,
  className: PropTypes.string,
  elevation: PropTypes.number,
  square: PropTypes.bool,
};


export default withStyles(styles)(VerticalStepperComponent);