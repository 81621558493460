import React, { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import reactHtmlParser from 'react-html-parser';
import PropTypes from 'prop-types';
import classNames from "classnames";

import { alpha } from "@mui/material/styles";
import makeStyles from '@mui/styles/makeStyles';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';

import withRouter from '../../coraWebMComponents/utils/withRouter';
import getSharedStyles from "../../coraWebMComponents/sharedStyles";
import ArrowBack from "../../coraWebMComponents/dataDisplay/icons/ArrowBack";

import routes from '../../routes';
import { findDeepMenu } from '../../App';
import * as requestsNoAuth from "../../utils/publicZone/requestsNoAuth";

// Define styles using makeStyles
const useStyles = makeStyles((theme) => ({
  ...getSharedStyles(theme),
  cfButton: {
    backgroundColor: alpha(theme.palette.common.black, 0.25),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.black, 0.15),
    },
    borderRadius: theme.shape.borderRadius,
  },
}));

const OpenDataComponent = (props) => {

  const [htmlData, setHtmlData] = useState(null);
  const [menuID, setMenuID] = useState(null);
  const [backRoute, setBackRoute] = useState(routes.PZ);

  useEffect(() => {
    let foundMenu = findDeepMenu(props.menuTree, (x) => x.Route === props.location.pathname);
    setMenuID(foundMenu?.MenuID);
    if (foundMenu?.NadMenuID) {
      foundMenu = findDeepMenu(props.menuTree, (x) => x.MenuID === foundMenu.NadMenuID);
      if (foundMenu && foundMenu.Route) {
        setBackRoute(foundMenu.Route);
      }
    }
  }, [props.location.pathname, props.menuTree]);

  useEffect(() => {
    const getData = async (id) => {
      try {
        const url = `/api/pz/openData/${id}`;
        const res = await requestsNoAuth.get(url);
        setHtmlData(res.Data);
      } catch (error) {
        console.error(error);
      }
    };
    if (menuID) {
      getData(menuID);
    }
  }, [menuID]);

  const classes = useStyles(); // Use the useStyles hook to apply styles

  if (!menuID) {
    return (<></>);
  }
  return (
    <Stack
      direction="column"
      justifyContent="flex-start"
      alignItems="stretch"
      height="calc(100vh - 65px)"
      sx={{}}
      px={1}
      style={{ marginTop: "65px", boxSizing: "border-box" }}
    >
      <Stack direction={'row'} style={{ boxSizing: "border-box" }}>
        <Button title={`Návrat späť`} size="small" className={classNames(classes.cfButton)}>
          <Link to={backRoute} title={`Návrat späť`}>
            <Box className={classNames(classes.navigationButton, classes.navigationBackButton, classes.textCenter)}
              backgroundColor="transparent">
              <ArrowBack />
            </Box>
          </Link>
        </Button>
      </Stack>
      {htmlData && reactHtmlParser(htmlData)}
      {!htmlData && <div>Stránka nemá definovaný obsah.</div>}
    </Stack>
  );
};

OpenDataComponent.propTypes = {
  menuTree: PropTypes.object.isRequired,
  isAuth: PropTypes.bool
};

export default withRouter(OpenDataComponent);
