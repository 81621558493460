import React from 'react';
import PropTypes from 'prop-types';
import MenuItem from '@mui/material/MenuItem/MenuItem';

/**
 * Menu item component
 * @module
 * @param {func} [onClick] - Menu item click handler function
 */
class MenuItemComponent extends React.Component {
  render() {
    return (
      <MenuItem
        key={this.props.key}
        style={this.props.style}
        value={this.props.value}
        onClick={this.props.onClick}
        classes={this.props.classes}
      >
        {this.props.children}
      </MenuItem>
    )
  }
}

MenuItemComponent.propTypes = {
  key: PropTypes.string,
  value: PropTypes.any,
  onClick: PropTypes.func.isRequired,
  style: PropTypes.object,
  classes: PropTypes.object,
}

export default MenuItemComponent;