import React from 'react';
import PropTypes from 'prop-types';
import withStyles from '@mui/styles/withStyles';
import classNames from 'classnames';
import sortBy from 'lodash/sortBy';
import { AutoSizer } from 'react-virtualized';
import { connect } from 'react-redux';
import { isNil } from "lodash";

import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';

import FlatButton from '../../coraWebMComponents/inputs/FlatButton';
import getSharedStyles from "../../coraWebMComponents/sharedStyles";
import { ColumnFlagParser } from '../../coraWebMComponents/utils/helper';
import SwitchUpDownArrow from '../../coraWebMComponents/inputs/SwitchUpDownArrow';
import DialogHeader from '../../coraWebMComponents/surfaces/DialogHeader';

import * as adminModeUtils from '../../utils/adminModeUtils.js';
import { Transition } from './support';
import VirtualizedTable from './VirtualizedTable';
import * as userActions from '../../actions/userActions';

const styles = theme => ({
  ...getSharedStyles(theme),
  root: {
    // borderBottom: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing(2),
    boxSizing: "border-box"
  },
  data1: {
    width: '100%',
    height: '100%',
    border: `1px solid ${theme.palette.divider}`,
    boxSizing: "border-box"
  },
  helpBoxWrapper: {
    backgroundColor: theme.palette.grey[200],
    boxSizing: "border-box",
    borderRadius: "0.3rem",
  },
  helpBoxTitle: {
  },
  helpBoxInfoIcon: {
    color: theme.palette.grey[400]
  },
  helpBoxItem: {
    marginBottom: '-0.2rem',
  },
  helpBoxLegenda: {
    width: "2.5rem",
    marginBottom: '-0.2rem',
  },
  helpBoxGraph: {
    height: '0.3rem',
  },
});

class AdminModeSpColumnsComponent extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoading: true,
      spColumns: null,
      rows: null,
      sortDir: null, // smer filtrovania nastaveneho stlpca na sortovanie
      sortISpFrmSql: null, // ID stlpca nastaveneho na sortovanie
    };
  }

  getColumns(classes) {
    const columns = [];

    columns.push({
      name: 'PorMriez',
      header: 'Poradie',
      width: 100,
      cell: rowData => (
        <TextField
          id="outlined-bare"
          className={classes.textField}
          margin="normal"
          variant="standard"
          value={rowData['PorMriez']}
          onChange={this.handleChange(rowData.id, 'PorMriez')}
        />
      ),
      cellProps: { align: 'left' }
    });

    columns.push({
      name: 'TtMriez',
      header: 'Titulok',
      width: 300,
      cell: rowData => (
        <TextField
          id="outlined-bare"
          className={classes.textField}
          margin="normal"
          variant="standard"
          value={rowData['TtMriez']}
          onChange={this.handleChange(rowData.id, 'TtMriez')}
        />
      ),
      cellProps: { align: 'left' }
    });

    // columns.push({
    //   name: 'TtMriez',
    //   header: 'Titulok v miežke',
    //   width: 300,
    //   cell: rowData => (
    //     <TextField
    //       id="outlined-bare"
    //       className={classes.textField}
    //       margin="normal"
    //       // variant="outlined"
    //       value={rowData['TtMriez']}
    //       onChange={this.handleChange(rowData.id, 'TtMriez')} />),
    //   cellProps: { align: 'left' }
    // });

    columns.push({
      name: 'LMriez',
      header: <div>Zobraz<br />v miežke</div>,
      cell: rowData => (
        <Checkbox
          checked={rowData['LMriez']}
          onChange={this.handleChange(rowData.id, 'LMriez')} />
      ),
      width: 72,
      cellProps: { style: { paddingRight: 0 } },
    });

    columns.push({
      name: 'LDet',
      header: <div>Zobraz<br />v detaile</div>,
      cell: rowData => (
        <Checkbox
          checked={rowData['LDet']}
          onChange={this.handleChange(rowData.id, 'LDet')} />
      ),
      width: 72,
      cellProps: { style: { paddingRight: 0 } },
    });

    const sirName = 'SirMriez';
    columns.push({
      name: sirName,
      header: <div>Šírka<br />v miežke</div>,
      width: 100,
      cell: rowData => (
        <TextField
          id="outlined-bare"
          className={classes.textField}
          margin="normal"
          variant="standard"
          value={rowData[sirName]}
          onChange={this.handleChange(rowData.id, sirName)}
        />
      ),
      cellProps: { align: 'left' }
    });

    const cZorad = 'CZorad';
    columns.push({
      name: cZorad,
      header: <div>Zoradenie</div>,
      width: 100,
      cell: rowData => (
        rowData.LZorad && // len ak je zoradenie v stlpci povolene
        <SwitchUpDownArrow
          direction={rowData.ISpFrmSql === this.state.sortISpFrmSql ? this.state.sortDir : null}
          onClick={() => this.handleSortClick(rowData.id, cZorad)}
        />
      ),
      cellProps: { align: 'center' }
    });
    return columns;
  }

  handleSortClick = (id, name) => {
    const { spColumns } = this.state;
    const rowData = spColumns.find(x => x.ISpFrmSql === id);
    let newLVzost;

    if (rowData.ISpFrmSql === this.state.sortISpFrmSql) {
      newLVzost = !this.state.sortDir;
    }
    else {
      newLVzost = true;
    }

    rowData['LVzost'] = newLVzost;
    rowData[name] = 0; // 0=najvyssia priorita zoradenia
    this.setState({ sortDir: newLVzost, sortISpFrmSql: rowData.ISpFrmSql });

    spColumns.forEach(rowData => {
      // vsetkym ostatnym stlpcom vynuluj sortovanie, sortujeme len podla jedneho stlpca
      if (rowData.ISpFrmSql !== id) {
        rowData.CZorad = null;
        rowData.LVzost = true;
      }
    });
    const rows = spColumns.map(rowData => {
      const row = { ...rowData, id: rowData['ISpFrmSql'] };
      return row;
    });
    this.setState({ spColumns });
    this.setState({ rows });
  };

  handleChange = (id, name) => event => {
    const { spColumns } = this.state;
    const rowData = spColumns.find(x => x.ISpFrmSql === id);
    switch (event.target.type) {
      case 'checkbox':
        if (name === 'LMriez' || name === 'LDet') {
          const fieldName = "I" + name.substring(1);
          const cfp = new ColumnFlagParser(rowData[fieldName]);
          if (cfp.lVisible !== event.target.checked) {
            cfp.lVisible = event.target.checked;
            rowData[fieldName] = cfp.iColumnFlag;
          }
        }
        rowData[name] = event.target.checked;
        break;

      default:
        rowData[name] = event.target.value;
        break;
    }
    this.setState({ spColumns });
    const rows = spColumns.map(rowData => {
      const row = { ...rowData, id: rowData['ISpFrmSql'] };
      return row;
    });
    this.setState({ rows });
  };

  /**
     * Nastavi rovnake hodnoty tym stlpcom, ktore su editovane jednym parametrom, ale hodnota sa ma nastavit do viacerych stlpcov. 
     * napr.: poradie, nazov
     *
     * @memberof AdminModeSpColumnsComponent
     */
  consolidateColumns = () => {
    const { spColumns } = this.state;
    spColumns.forEach(rowData => {
      rowData.PorDet = rowData.PorMriez;
      rowData.TtDet = rowData.TtMriez;
      rowData.SirDet = null; // v detaile nenastavujeme nikdy sirku
    });

    this.setState({ spColumns });
  };

  handleSaveClick = async () => {
    try {
      // console.log(`AdminModeSpColumns[${this.props.params.id}].handleSaveClick.start`);
      this.setState({ isLoading: true });
      this.consolidateColumns();
      const data = await adminModeUtils.postSpColumns(this.props.primaryField, this.state.spColumns);
      // console.log(`AdminModeSpColumns[${this.props.params.id}].handleSaveClick: postSpColumns {res: ${data}}`, this.state.spColumns);
      this.setState({ spColumns: null, isLoading: false });
      this.props.onClose();
      // console.log(`AdminModeSpColumns[${this.props.params.id}].handleSaveClick.end`);
      this.props.showMsg(data.UserMessage);
      await this.props.reloadSchema();
    }
    catch (e) {
      this.props.showErrorMsg("Error: Chyba pri komunikácii s databázou");
      // console.log(`Error: ${e.message}`);
    }
  };

  async componentDidMount() {
    // console.log(`AdminModeSpColumns[${this.props.params.id}].componentDidMount.start`);

    if (this.state.spColumns) {
      // console.log(`AdminModeSpColumns[${this.props.params.id}].componentDidMount: noload data {spColumns.length: ${this.state.spColumns.length}}`);
    }
    else {
      const data = await adminModeUtils.getSpColumns(this.props.primaryField);
      // let newLocal = 0;
      try {
        // newLocal = data.Items.length;
        // newLocal = data.Data.Items.length;
      } catch (error) {

      }

      // console.log(`AdminModeSpColumns[${this.props.params.id}].componentDidMount.getSpColumns: [res=${newLocal}]`);
      this.setState({ spColumns: sortBy(data.Data.Items, rowData => rowData['PorMriez'] ? rowData['PorMriez'] : 1000000000 + rowData['ISpFrmSql']), isLoading: false }, () => {
        // console.log(`AdminModeSpColumns[${this.props.params.id}].componentDidMount: loaded data {spColumns.length: ${this.state.spColumns.length}}`);
      });

      const rows = sortBy(data.Data.Items, rowData => rowData['PorMriez'] ? rowData['PorMriez'] : 1000000000 + rowData['ISpFrmSql'])
        .map(rowData => {
          const row = { ...rowData, id: rowData['ISpFrmSql'] };
          return row;
        });
      this.setState({ rows: rows });
      // console.log("didMount data: ", rows);

      if (!this.props.initSort?.disabled) { //nastav init filter len ak je povoleny
        const sortDir = this.props.initSort?.dir === 'asc' ? true : false; //konverzia na logiku LVzost: true=ASC, false=DESC
        const initSortColumn = rows.find((row) => row.SpFrmSql.NStl === this.props.initSort?.field); //najdi podla nazvu riadok 
        const sortISpFrmSql = initSortColumn.ISpFrmSql;
        this.setState({ sortDir: sortDir, sortISpFrmSql: sortISpFrmSql });
      }
    }

    // console.log(`AdminModeSpColumns[${this.props.params.id}].componentDidMount.end`);
  }

  render() {

    // console.log(`AdminModeSpColumns[${this.props.params.id}].render.start`);

    if (!this.state.spColumns) {
      // console.log(`AdminModeSpColumns[${this.props.params.id}].render: {spColumns: null}`);
      // return <CircularProgress loading={this.state.isLoading} />;
      return null;
    }

    const { classes, _params } = this.props;
    // const isDetail = params.id === 'spDetCols';
    // const porName = isDetail ? 'PorDet' : 'PorMriez';
    // const porName = ['PorMriez', 'PorDet'];
    const columns = this.getColumns(classes);

    const rows = this.state.rows?.filter(x => isNil(x.SpFrmSql?.NZobrMriez) && isNil(x.SpFrmSql?.NZobrDet));
    // console.log(`AdminModeSpColumns[${this.props.params.id}].render: {spColumns.length: ${this.state.spColumns.length}}`);

    const HelpBanner = ({ helpData }) => {
      return (
        <Stack mt={1} p={1} flexDirection="column" className={classes.helpBoxWrapper} >
          <Stack
            flexDirection="row"
            alignItems="center"
            justifyContent="space-between"
            gap={1}
            className={classes.helpBoxWrapper}
            position="relative"
          >
            <Typography variant="caption" className={classes.helpBoxTitle} whiteSpace="nowrap">
              Orientačné šírky stĺpcov
            </Typography>
            <InfoOutlinedIcon fontSize="large" className={classes.helpBoxInfoIcon} />
          </Stack>
          {helpData.map((width, index) => (
            <Stack key={index} flexDirection="row" alignItems="center" className={classes.helpBoxItem} >
              <Typography variant="body2" className={classes.helpBoxLegenda}>{width}</Typography>
              <Box
                className={classes.helpBoxGraph}
                sx={{
                  width: `${width}px`,
                  backgroundColor: `hsl(200, ${40 + index * Math.floor((100 - 40) / (helpData.length - 1))}%, 40%)`,
                }}
              ></Box>
            </Stack>
          ))}
        </Stack>
      );
    };

    return (<>
      {/* <CircularProgress loading={this.state.isLoading} /> */}
      <Dialog
        open={this.props.params.isOpen}
        onClose={this.props.onClose}
        scroll='paper'
        maxWidth={'md'}
        aria-labelledby="scroll-dialog-title"
        fullWidth={true}
        fullScreen
        TransitionComponent={Transition}
      >
        <DialogTitle id="scroll-dialog-title"
        //  className={classes.root}
        >
          <DialogHeader title={this.props.params.title} variant="h6" onClose={this.props.onClose} />
          <HelpBanner helpData={[50, 100, 150, 200, 300, 400]} />
        </DialogTitle>
        <DialogContent>
          <Box className={classNames(classes.data1)}>
            <AutoSizer>
              {({ width, height }) => (
                // <form className={classes.container} noValidate autoComplete="off">
                (<VirtualizedTable
                  data={rows}
                  columns={columns}
                  width={width}
                  height={height - 10} //HACK: 10 - aby vyska containera ciara sedela s vyskou obsahu, lebo sa vyara nepresne
                  includeHeaders={true}
                  fixedRowCount={1}
                  rowHeight={40}
                // orderBy={columns[0]?.name}
                // orderDirection={SortDirection.ASC}
                // isCellHovered={(column, rowData, hoveredColumn, hoveredRowData) => rowData.id && rowData.id === hoveredRowData.id}
                // cellProps={(column, rowData) => {

                //   const res = rowData && rowData.id
                //     ? {}
                //     : { align: 'center' };
                //   return res;
                // }}
                />)
                // </form>
              )}
            </AutoSizer>
          </Box>
        </DialogContent>
        <DialogActions>
          <FlatButton
            key='Cancel'
            label='Zrušiť'
            color='primary'
            onClick={this.props.onClose}
          >
            {`Zrušiť`}
          </FlatButton>
          <FlatButton
            key='Save'
            label='Uložiť'
            color='secondary'
            onClick={this.handleSaveClick}
          >
            {`Uložiť`}
          </FlatButton>
        </DialogActions>
      </Dialog>
    </>);
  }
}

AdminModeSpColumnsComponent.propTypes = {
  primaryField: PropTypes.string.isRequired,
  params: PropTypes.object.isRequired,
  initSort: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
  reloadSchema: PropTypes.func,
};

const mapStateToProps = () => ({
});

const mapDispatchToProps = dispatch => ({
  showMsg: (msg) => { dispatch(userActions.showMsg(msg)); },
  showErrorMsg: (errorMsg) => { dispatch(userActions.showErrorMsg(errorMsg)); }
});

export default withStyles(styles)(connect(mapStateToProps, mapDispatchToProps)(AdminModeSpColumnsComponent));