import ArrowDownward from '@mui/icons-material/ArrowDownward';
import PropTypes from 'prop-types';

const ArrowDownwardComponent = (props) => {
  return (
    <ArrowDownward
      style={props.style}
      color={props.color}
      fontSize={props.fontSize}
      variant={props.variant}
    />
  );
};

ArrowDownwardComponent.propTypes = {
  style: PropTypes.object,
  color: PropTypes.string,
  fontSize: PropTypes.string,
  variant: PropTypes.string,
};

export default ArrowDownwardComponent;