import ArrowUpward from '@mui/icons-material/ArrowUpward';
import PropTypes from 'prop-types';

const ArrowUpwardComponent = (props) => {
  return (
    <ArrowUpward
      style={props.style}
      color={props.color}
      fontSize={props.fontSize}
      variant={props.variant}
    />
  );
};

ArrowUpwardComponent.propTypes = {
  style: PropTypes.object,
  color: PropTypes.string,
  fontSize: PropTypes.string,
  variant: PropTypes.string,
};

export default ArrowUpwardComponent;