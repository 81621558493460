import { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import withStyles from '@mui/styles/withStyles';

import getSharedStyles from '../../coraWebMComponents/sharedStyles';
import Paper from '../../coraWebMComponents/surfaces/Paper';
import Typography from '../../coraWebMComponents/dataDisplay/Typography';
import Button from '../../coraWebMComponents/inputs/Button';

import * as userActions from '../../actions/userActions';
import { nbWithSpaces } from './Taxes';
import Installment from './Installment';

const styles = theme => ({
  ...getSharedStyles(theme),
});

const ProceedingsInstallments = (props) => {

  const { itemProceeding, classes } = props;

  const [selectedInstallments, setSelectedInstallments] = useState([]);

  //Chceme povolit vyberat splatky len po sebe
  const handleSetSelectedInstallments = (index) => {
    const isCurrentSelected = selectedInstallments.includes(index);
    const isPreviousSelected = selectedInstallments.includes(index - 1);
    const isNextSelected = selectedInstallments.includes(index + 1);

    let firstIndex = itemProceeding.installments.filter(x => x.LUhr).length;

    if (isCurrentSelected) {
      if (selectedInstallments.length === 1) {
        setSelectedInstallments([]);
      } else if (!isNextSelected) {
        setSelectedInstallments(selectedInstallments.filter(installment => installment !== index));
      } else {
        props.dispatch(userActions.showErrorMsg('Nie je možné zrušiť výber splátky, ak za ňou nasleduje vybraná splátka.'));
      }
    } else {
      if (selectedInstallments.length === 0 && index === firstIndex) {
        setSelectedInstallments([index]);
      } else if (isPreviousSelected) {
        setSelectedInstallments([...selectedInstallments, index]);
      } else {
        props.dispatch(userActions.showErrorMsg('Nie je možné vybrať splátku, ak splátka pred ňou nie je vybraná.'));
      }
    }
  };

  const calculateTotalPrice = () => {
    let totalPrice = 0;
    selectedInstallments.forEach(index => {
      totalPrice += itemProceeding.installments[index].PREDPIS;
    });
    return totalPrice;
  };

  const handleButtonClick = () => {
    if (selectedInstallments.length > 0) {
      props.openAlert('openAlertPayment', { itemProceeding, selectedInstallments });
    }
  };

  const totalPrice = calculateTotalPrice();
  return <Paper elevation={2} key={itemProceeding.I_KON} className={classNames(classes.ziadostCard, classes.mb3)}>
    <div className={classes.cardContent}>
      <div className={classNames(classes.row, classes.alignItemsCenter, classes.justifyContentBetween, classes.mb2)}>
        <Typography variant="subtitle2" style={{ fontWeight: 'bold' }}>{itemProceeding.N_PRJ_E}</Typography>
      </div>
      {itemProceeding.installments.map((itemInstallment, index) =>
        <Installment
          itemInstallment={itemInstallment}
          index={index}
          setSelected={handleSetSelectedInstallments}
          isSelected={selectedInstallments.includes(index)} />
      )}
      {!itemProceeding.L_UHR_PB &&
        <Button
          disabled={totalPrice <= 0}
          variant="contained"
          color="secondary"
          size="large"
          type="success"
          className={classNames(classes.w100, classes.mt2, classes.row, classes.justifyContentBetween)}
          onClick={() => handleButtonClick()}
        >
          <span style={{ fontWeight: "normal" }}>Platba</span>
          {nbWithSpaces(totalPrice.toFixed(2))}{' '}{itemProceeding.MENA === "EUR" ? "€" : ''}
        </Button>
      }
    </div>
  </Paper>;
};

ProceedingsInstallments.propTypes = {
  itemProceeding: PropTypes.object.isRequired,
  openAlert: PropTypes.func,
  dispatch: PropTypes.func,
};

export default withStyles(styles)(ProceedingsInstallments);