import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { SortDirection } from "react-virtualized";

import Stack from '@mui/material/Stack';

import CircularProgressV2 from "../../coraWebMComponents/feedBack/progress/CircularProgressV2";
import withWidth from '../../coraWebMComponents/views/withWidth';

import DetailDialog from "./DetailDialog";
import PodFrm from "./PodFrm";
import ComboFilterDialog from "./ComboFilterDialog";
import { getDetailTitle, getGridVisibleColumns } from "./support";
import FrmHeader from "./FrmHeader";
import Grid from "./Grid";
import OptionsButton from "./OptionsButton";
import ExportBtnDialog from "./ExportBtnDialog";
import ScrollTopButton from "../info/ScrollTopButton";
import { createDevButton, getInitDevFlt } from "./DevInfo";

export const NUMBER_OF_PAGE = 10;

const FrmComponent = (props) => {
  const { schema, primaryField, frmData, getFrmData, getChartData, postExportData, backRoute, reloadSchema, isMobile } = props;

  /** Úprava schémy po načítaní zo servera */
  const getInitFrmState = (props) => {
    const { schema } = props;
    const cboFltValues = {};
    const { comboFilters } = schema.Args;

    comboFilters.map((x) => {
      let actValue = "";
      if (x.noItem && !x.noItem.disabled && x.noItem.value) {
        actValue = x.noItem.value;
      } else {
        actValue = x.dataItems[0].value;
      }
      cboFltValues[x.id] = actValue;
      return x;
    });
    return { sort: [], cboFltValues, searchText: null };
  };

  const [detailOpen, setDetailOpen] = useState(false);
  const [selectedId, setSelectedId] = useState(-1);
  const [podFrmId, setPodFrmId] = useState(null);
  const [cboFltId, setCboFltId] = useState(null);
  const [frmState, setFrmState] = useState(getInitFrmState(props));
  const [showScrollTopButton, setShowScrollTopButton] = useState(false);
  const [showScrollTopRef, setShowScrollTopRef] = useState(null);
  const [loadingExport, setLoadingExport] = useState(false);
  const [devFlt, setDevFlt] = useState(getInitDevFlt(schema.Frms));

  const getFilter = (cboFltValues = frmState.cboFltValues, searchText = frmState.searchText) => {
    const { schema } = props;
    const { comboFilters } = schema.Args;

    const filter = { filters: [] };
    comboFilters.map((x) => {
      const actValue = cboFltValues[x.id];
      if (actValue && !(x.noItem && !x.noItem.disabled && x.noItem.value && x.noItem.value === actValue)) {
        filter.filters.push({ field: x.identif, operator: "eq", value: actValue });
      }
      return x;
    });
    if (Boolean(searchText) && searchText.length > 0) {
      let flt = { operator: "contains", value: searchText, left: "(" };
      let fltAct;
      const columns = getGridVisibleColumns(schema.Grid, true);
      columns
        .filter((x) => x.type === "text")
        .map((x) => {
          fltAct = { field: x.field, ...flt };
          filter.filters.push(fltAct);
          flt = { operator: "contains", value: searchText, join: true };
          return x;
        });
      if (fltAct) fltAct.right = ")";
    }
    return filter;
  };

  const getSort = (sort) => (sort.length > 0 ? sort : schema.initSort);

  const handleSetDevFilter = (fldName, bValue) => {
    if (devFlt[fldName] !== bValue) {
      setDevFlt((prev) => ({ ...prev, [fldName]: bValue }));
    }
  };

  // const handleSetDevFilter = (fldName, bValue) => {
  //   if (devFlt[fldName] !== bValue) {
  //     devFlt[fldName] = bValue;
  //     tsetDevFlt(devFlt);
  //   }
  // };

  const createHeaderRowOptionsButton = () => {
    if (window.config.isDev) {
      const devProps = { devFilter: devFlt, setDevFilter: handleSetDevFilter, loadMoreData };
      return createDevButton(primaryField, schema, frmData, devProps);
    }
    return "";
  };

  const createBodyRowOptionsButton = (rowData) => {
    const { schema } = props;
    const customUI = schema.Args;
    const pkValue = rowData["id"];
    const items = [];
    if (!customUI.detail.disabled) {
      items.push({
        itemText: "Detail",
        onClick: () => handleDetailDialogOpen(pkValue),
        disabled: false,
        itemIcon: 'InfoIcon',
      });
    }

    schema.Frms.map((x) => {
      const data = rowData[`FrmPod${x.Id}`]?.items;
      const disabled = !(data && data.length > 0);
      if (!disabled) {
        items.push({
          itemText: x.Name,
          onClick: () => handlePodFrmDialogOpen(x.Id, pkValue),
          disabled,
          itemIcon: 'DescriptionIcon',
        });
      }
      // items.push({
      //   itemText: x.Name,
      //   onClick: () => this.handlePodFrmDialogOpen(x.Identif, pkValue),
      //   disabled,
      // });
      return x;
    });
    return <OptionsButton items={items} customColor={true} />;
  };

  /** Udalosť Otvorenie detailu */
  const handleDetailDialogOpen = (id) => {
    setDetailOpen(true);
    setSelectedId(id);
  };

  /** Udalosť Zatvorenie detailu */
  const handleDetailClose = () => {
    setDetailOpen(false);
    setSelectedId(-1);
  };

  /** Udalosť Otvorenie podradeného formulára */
  const handlePodFrmDialogOpen = (id, selectedId) => {
    setPodFrmId(id);
    setSelectedId(selectedId);
  };

  /** Udalosť Zatvorenie podradeného formulára */
  const handlePodFrmClose = () => {
    setPodFrmId(null);
    setSelectedId(-1);
  };

  /** Udalosť Nastavenie SearchFilter */
  const handleSearchFilterChange = async (searchText) => {
    const newFrmState = { ...frmState, searchText };
    setFrmState(newFrmState);
    await getFrmData(
      getFilter(newFrmState.cboFltValues, newFrmState.searchText),
      getSort(newFrmState.sort),
      10,
      true
    );
  };

  const handleJsonExportBtnClick = () => {
    const jsonData = JSON.stringify(frmData.data, null, 2);
    exportData(jsonData, "data.json", "application/json");
  };

  const arrayToCSV = (objArray) => {
    const array = typeof objArray !== "object" ? JSON.parse(objArray) : objArray;
    let str = `${Object.keys(array[0])
      .map((value) => `"${value}"`)
      .join(",")}\r\n`;

    for (let i = 0; i < array.length; i++) {
      let line = "";
      for (let index in array[i]) {
        if (line !== "") line += ",";

        let value = array[i][index];
        if (typeof value === "string") {
          value = `"${value.replace(/"/g, '""')}"`;
        } else if (value === null) {
          value = "";
        }

        line += value;
      }

      str += `${line}\r\n`;
    }

    return str;
  };

  const handleCsvExportBtnClick = () => {
    const csvData = arrayToCSV(frmData.data);
    exportData(csvData, "data.csv", "text/csv");
  };

  const handleLoadChartData = async () => {
    const { cboFltValues, searchText } = frmState;
    const filter = getFilter(cboFltValues, searchText);
    return await getChartData(filter);
  };

  const handleGetExportData = async (fileType) => {
    setLoadingExport(true);
    const filter = getFilter(frmState.cboFltValues, frmState.searchText);
    const sort = getSort(frmState.sort);
    const { blob, filename } = await postExportData(primaryField, filter, sort, fileType, schema);
    setLoadingExport(false);
    exportData(blob, filename, blob.type);
  };

  // handleGetExportData = async (fileType) => {
  //   this.setState((prev) => ({ ...prev, loadingExport: true }));
  //   const { primaryField, schema } = this.props;
  //   const { cboFltValues, searchText } = this.state.frmState;
  //   const filter = this.getFilter(cboFltValues, searchText);
  //   const sort = this.getSort(this.state.frmState.sort);
  //   const { blob, filename } = await this.props.postExportData(primaryField, filter, sort, fileType, schema);
  //   this.setState((prev) => ({ ...prev, loadingExport: false }));
  //   this.exportData(blob, filename, blob.type);
  // };

  const exportData = (data, fileName, type) => {
    const blob = new Blob([data], { type });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = fileName;
    a.click();
    window.URL.revokeObjectURL(url);
  };

  /** Udalosť Otvorenie CboFltDialog */
  const handleCboFltDialogOpen = (cboFltId) => {
    setCboFltId(cboFltId);
  };

  /** Udalosť Zatvorenie CboFltDialog */
  const handleCboFltDialogClose = () => {
    setCboFltId(null);
  };



  /** Udalosť výberu z objektu ComboFilter */
  const handleComboFilterChange = async (name, value) => {
    const { cboFltValues } = frmState;

    let actValue = cboFltValues[name];
    if (!actValue || actValue !== value) {
      cboFltValues[name] = value;
      const newFrmState = { ...frmState, cboFltValues };
      setFrmState(newFrmState);
      setCboFltId(null);
      await getFrmData(
        getFilter(newFrmState.cboFltValues, newFrmState.searchText),
        getSort(newFrmState.sort),
        10,
        true
      );
    } else {
      setCboFltId(null);
    }
  };

  // handleComboFilterChange = async (name, value) => {
  //   const { cboFltValues } = this.state.frmState;

  //   let actValue = cboFltValues[name];
  //   if (!actValue || actValue !== value) {
  //     cboFltValues[name] = value;

  //     const frmState = { ...this.state.frmState, cboFltValues };
  //     this.setState({ ...this.state, frmState, cboFltId: null });
  //     await this.props.getFrmData(
  //       this.getFilter(cboFltValues, frmState.searchText),
  //       this.getSort(frmState.sort),
  //       10,
  //       true
  //     );
  //   } else {
  //     this.setState({ ...this.state, cboFltId: null });
  //   }
  // };

  /** Udalosť Nastavenie triedenia */
  const handleSortChange = async (sortBy, sortDirection = SortDirection.ASC) => {
    let sort = [];
    if (sortBy) {
      sort = [
        {
          field: sortBy,
          dir: sortDirection === SortDirection.DESC ? "desc" : "asc",
          disabled: false,
        },
      ];
    }
    const newFrmState = { ...frmState, sort };
    setFrmState(newFrmState);
    await getFrmData(
      getFilter(newFrmState.cboFltValues, newFrmState.searchText),
      getSort(newFrmState.sort),
      10,
      true
    );
  };


  const onEnd = async (take = NUMBER_OF_PAGE) => {
    if (!frmData.isLoading && frmData.hasMore) {
      const filter = getFilter(frmState.cboFltValues, frmState.searchText);
      const sort = getSort(frmState.sort);
      await props.getFrmData(filter, sort, take);
    }
  };

  const onResize = async () => {
    if (
      window.innerHeight > document.body.offsetHeight &&
      !frmData.isLoading &&
      frmData.hasMore
    ) {
      // await onEnd();
    }
  };

  const loadMoreData = async () => {
    await onEnd(NUMBER_OF_PAGE);
  };

  const onScroll = (event) => {
    const target = event.current;
    const windowsHeight = target?.props?.height;
    const scrollTop = target?.state?.scrollTop | 0; // cele cislo
    const position = target?.props?.rowCount * target?.props?.rowHeight - windowsHeight;

    if (target?.props?.rowCount < 40 || scrollTop >= position - target?.props?.rowHeight) {
      loadMoreData(NUMBER_OF_PAGE);
    }

    if (target?.state?.scrollTop > 10 && !showScrollTopButton) {
      setShowScrollTopButton(true);
      setShowScrollTopRef(target);
    }
    if (target?.state?.scrollTop <= 10 && showScrollTopButton) {
      setShowScrollTopButton(false);
      setShowScrollTopRef(target);
    }
  };

  const onScrollMobile = async (event) => {
    const target = event.target;

    if (Math.abs(target.scrollHeight - target.scrollTop - target.clientHeight) < 32) { // 32 = vyska riadku
      await onEnd(NUMBER_OF_PAGE);
    }
    if (target.scrollTop > 10 && !showScrollTopButton) {
      setShowScrollTopButton(true);
      setShowScrollTopRef(target);
    }
    if (target.scrollTop <= 10 && showScrollTopButton) {
      setShowScrollTopButton(false);
      setShowScrollTopRef(target);
    }
  };

  async function decreaseNumberLinearly(valueUpdater, initialValue = 100, targetValue = 0, duration = 100, steps = 15) {
    const stepSize = (initialValue - targetValue) / steps;
    const timeInterval = duration / steps;

    for (let i = 0; i <= steps; i += 1) {
      valueUpdater(initialValue - i * stepSize);
      await new Promise(resolve => setTimeout(resolve, timeInterval));
    }
  }

  const handleScrollTopButtonClick = async (target) => {
    // pre WebGrid
    if (target?.state?.scrollTop) {
      target.state.scrollTop = 0;
    }

    // pre MobileGrid
    if (target.scrollTop) {
      // target.scrollTop = 0;
      decreaseNumberLinearly(newValue => {
        target.scrollTop = newValue;
      });
    }

    // Skry tlačidlo po posune na vrch
    setShowScrollTopButton(false);
  };

  useEffect(() => {
    const loadData = async () => {
      const { cboFltValues, searchText } = frmState;
      const filter = getFilter(cboFltValues, searchText);
      const sort = getSort(frmState.sort);

      await getFrmData(filter, sort, 10, true);
    };

    loadData();
    window.addEventListener("resize", onResize);
    window.addEventListener("scroll", onScroll);

    return () => {
      window.removeEventListener("resize", onResize);
      window.removeEventListener("scroll", onScroll);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  if (!frmState) {
    return null;
  }

  const pkFieldName = primaryField.split('-').pop();
  const customUI = schema.Args;
  const { comboFilters } = customUI;
  const { isLoading, data } = frmData;
  const { cboFltValues } = frmState;
  const dataCboFlt = { showDialog: false, fltMeta: {}, fltValue: null };

  if (null != cboFltId) {
    const fltMeta = comboFilters.find((x) => x.id === cboFltId);
    if (null != fltMeta) {
      dataCboFlt.fltValue = cboFltValues[fltMeta.id];
      dataCboFlt.fltMeta = fltMeta;
      dataCboFlt.showDialog = true;
    }
  }

  const formatMask =
    customUI && customUI.detail && customUI.detail.label
      ? customUI.detail.label
      : schema.NFrm;

  //metadata pre zobrazenie detailu
  const dataDetail = {
    isOpen: false,
    columns: [],
    rowData: null,
    rowTitle: schema.NFrm,
  };

  if (detailOpen) {
    if (data.length > 0 && selectedId >= 0) {
      const rowData = data.find(
        (x) => x[pkFieldName] === selectedId
      );
      if (null != rowData) {
        dataDetail.isOpen = true;
        dataDetail.columns = schema.Detail;
        dataDetail.rowData = rowData;
        dataDetail.rowTitle = formatMask;
      }
    }
  }

  //metadata pre zobrazenie podradeného formulára
  const dataPodFrm = {
    isOpen: false,
    selectedFrm: null,
    rowData: null,
    recordTitle: null,
  };

  if (podFrmId) {
    if (data.length > 0 && selectedId > 0) {
      const rowData = data.find(
        (x) => x[pkFieldName] === selectedId
      );
      const selectedFrm = schema.Frms.find(
        (x) => x.Id === podFrmId
      );
      if (null != rowData) {
        dataPodFrm.isOpen = true;
        dataPodFrm.rowData = rowData;
        dataPodFrm.selectedFrm = selectedFrm;
        dataPodFrm.recordTitle = getDetailTitle(formatMask, rowData);
      }
    }
  }

  if (dataPodFrm.isOpen) {
    return (
      <PodFrm
        isMobile={isMobile}
        onBackClick={handlePodFrmClose}
        selectedFrm={dataPodFrm.selectedFrm}
        rowData={dataPodFrm.rowData}
        recordTitle={dataPodFrm.recordTitle}
      />
    );
  }

  const gridRowOptions = { createHeaderButton: createHeaderRowOptionsButton, createBodyButton: createBodyRowOptionsButton };
  const frmPodFields = Object.keys(devFlt).filter(x => devFlt[x] === true).map(x => x);

  return (
    <>
      <CircularProgressV2 loading={isLoading} top={"90%"} customColorPrimary={"#7a7a7a"} />
      <CircularProgressV2 loading={loadingExport} top={"55%"} customColorPrimary={"#7a7a7a"} />

      {/* Sipka pre scroll nahor */}
      {showScrollTopButton && (
        <ScrollTopButton onClick={() => handleScrollTopButtonClick(showScrollTopRef)} />
      )}

      {
        !dataPodFrm.isOpen && (
          <Stack
            // flexGrow="1"
            direction="column"
            justifyContent="flex-start"
            alignItems="stretch"
            height="calc(100vh - 65px)"
            sx={{}}
            px={1}
            style={{
              marginTop: "65px",
              // padding: "0 /*0.375rem*/",
              // backgroundColor: "#B2FF59",
              // backgroundColor: "inherit",
              // position: "relative",
              // width: "100%",
              // height: "100%",
              // overflow: "hidden",
              boxSizing: "border-box"
            }}
          >
            {!isMobile && (
              <>
                <FrmHeader
                  primaryField={primaryField}
                  schema={schema}
                  frmData={frmData}
                  frmState={frmState}
                  onComboFilterClick={handleCboFltDialogOpen}
                  onSearchFilterClick={handleSearchFilterChange}
                  handleCsvExportBtnClick={handleCsvExportBtnClick}
                  handleJsonExportBtnClick={handleJsonExportBtnClick}
                  postExportData={handleGetExportData}
                  isMobile={isMobile}
                  backRoute={backRoute}
                  reloadSchema={reloadSchema}
                  loadChartData={handleLoadChartData}
                />
                <div
                // style={{ /* marginTop: '65px',*/ padding: '0 /*0.375rem*/', backgroundColor: '#4aa' }}
                >
                  <Grid
                    primaryField={primaryField}
                    schema={schema}
                    frmData={frmData}
                    isMobile={isMobile}
                    onSortClick={handleSortChange}
                    gridRowOptions={gridRowOptions}
                    onScrollEnd={onScroll}
                    handleDetailDialogOpen={customUI.detail.disabled ? () => { } : handleDetailDialogOpen}
                    frmState={frmState}
                    frmPodFields={frmPodFields}
                  />
                </div>
              </>
            )}
            {isMobile && (
              <>
                <FrmHeader
                  primaryField={primaryField}
                  schema={schema}
                  frmData={frmData}
                  frmState={frmState}
                  onComboFilterClick={handleCboFltDialogOpen}
                  onSearchFilterClick={handleSearchFilterChange}
                  isMobile={isMobile}
                  backRoute={backRoute}
                  reloadSchema={reloadSchema}
                  loadChartData={handleLoadChartData}
                />
                <Grid
                  primaryField={primaryField}
                  schema={schema}
                  frmData={frmData}
                  isMobile={isMobile}
                  onScrollEnd={onScrollMobile}
                  onSortClick={handleSortChange}
                  gridRowOptions={gridRowOptions}
                  handleDetailDialogOpen={customUI.detail.disabled ? () => { } : handleDetailDialogOpen}
                  frmState={frmState}
                  frmPodFields={frmPodFields}
                />
              </>
            )}
            <ComboFilterDialog
              isOpen={dataCboFlt.showDialog}
              closeClick={handleCboFltDialogClose}
              fltMeta={dataCboFlt.fltMeta}
              value={dataCboFlt.fltValue}
              onClick={handleComboFilterChange}
            />
            <ExportBtnDialog
              handleCsvExportBtnClick={handleCsvExportBtnClick}
              handleJsonExportBtnClick={handleJsonExportBtnClick}
              postExportData={handleGetExportData}
            />
          </Stack>
        )
      }
      {
        dataDetail.isOpen && (
          <DetailDialog
            closeClick={handleDetailClose}
            detailProps={dataDetail}
            pkValue={selectedId}
          />
        )
      }
    </>
  );
};

FrmComponent.propTypes = {
  primaryField: PropTypes.string.isRequired,
  schema: PropTypes.object.isRequired,
  frmData: PropTypes.object.isRequired,
  getFrmData: PropTypes.func.isRequired,
  getChartData: PropTypes.func.isRequired,
  postExportData: PropTypes.func.isRequired,
  backRoute: PropTypes.string,
  reloadSchema: PropTypes.func,
};

export default withWidth()(FrmComponent);
