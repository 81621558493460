
import React, { useState } from "react";
import classNames from "classnames";
import PropTypes from "prop-types";

import makeStyles from '@mui/styles/makeStyles';
import { alpha } from "@mui/material/styles";
import MenuItem from "@mui/material/MenuItem";
import { Typography } from "@mui/material";
import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import FilterIcon from '@mui/icons-material/FilterAltOutlined';
import FilterNoneIcon from '@mui/icons-material/FilterAltOffOutlined';

import Menu from "../../coraWebMComponents/dataDisplay/Menu";
import FilterListIcon from "../../coraWebMComponents/dataDisplay/icons/FilterListIcon";

import { getCboFltState } from "./support";

const useStyles = makeStyles((theme) => ({
  riRoot: {
    display: "inline-flex",
    alignItems: "center",
  },
  riBtn: {
    backgroundColor: "transparent",
    borderRadius: theme.shape.borderRadius,
    opacity: 0.7,
    transition: 'opacity 0.3s ease',
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.black, 0.10),
      opacity: 1,
      cursor: "pointer",
    },
  },
  riIcon: {
    width: "1.8rem",
    textAlign: "center",
  },
  rimItemIcon: {
    color: alpha(theme.palette.common.black, 0.87),
  },
  rimText: {
    color: alpha(theme.palette.common.black, 0.87),
    fontSize: theme.palette.secondary.fontSize,
    textTransform: "none",
  },
  fltHasValue: {
    color: "green",
  },
  fltNoHasValue: {
    color: alpha(theme.palette.common.black, 0.87),
  },
}));

const ComboFiltersButtonComponent = ({ comboFilters, cboFltValues, onComboFilterClick, anchorOrigin = { vertical: "top", horizontal: "right" }, transformOrigin = { vertical: "top", horizontal: "right" }, className }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState(null);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <Box className={classNames(classes.riRoot, className)}>
      <Stack className={classes.riBtn} width={"2.5rem"} height={"2.5rem"} alignItems="center" justifyContent="center">
        <Stack
          title="Filter"
          onClick={handleMenu} flexDirection="row" justifyContent="flex-end">
          <FilterListIcon className={classes.riIcon} />
        </Stack>
      </Stack>

      <Menu
        className={classes.rim}
        anchorEl={anchorEl}
        anchorOrigin={anchorOrigin}
        transformOrigin={transformOrigin}
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {comboFilters &&
          comboFilters
            .filter((flt) => flt.dataItems && flt.dataItems.length > 0)
            .map((flt) => {
              const value = cboFltValues[flt.id];
              const { hasValue, text } = getCboFltState(flt, value);
              return (
                <MenuItem
                  className={classes.rimItem}
                  key={`cboFlt${flt.id}`}
                  onClick={() => {
                    handleClose();
                    onComboFilterClick(flt.id);
                  }}
                >
                  <Button
                    variant="text"
                    className={classNames(classes.rimBtn, hasValue ? classes.fltHasValue : classes.fltNoHasValue)}
                    toolTip={flt.title}
                    startIcon={
                      hasValue ? (
                        <FilterIcon style={{ width: "24", height: "24" }} viewBox="0 0 24 24" className={classes.fltHasValue} />
                      ) : (
                        <FilterNoneIcon style={{ width: "24", height: "24" }} viewBox="0 0 24 24" className={classNames(classes.rimItemIcon, classes.fltNoHasValue)} />
                      )
                    }
                    endIcon={<NavigateNextIcon className={classes.rimItemIcon} />}
                  >
                    <Typography className={classNames(classes.rimText, hasValue ? classes.fltHasValue : classes.fltNoHasValue)} title={flt.title}>
                      {text}
                    </Typography>
                  </Button>
                </MenuItem>
              );
            })}
      </Menu>
    </Box>
  );
};

ComboFiltersButtonComponent.propTypes = {
  comboFilters: PropTypes.array.isRequired,
  cboFltValues: PropTypes.object.isRequired,
  onComboFilterClick: PropTypes.func.isRequired,
  anchorOrigin: PropTypes.objectOf(
    PropTypes.shape({
      vertical: PropTypes.string,
      horizontal: PropTypes.string,
    })
  ),
  transformOrigin: PropTypes.objectOf(
    PropTypes.shape({
      vertical: PropTypes.string,
      horizontal: PropTypes.string,
    })
  ),
  className: PropTypes.string,
};

export default ComboFiltersButtonComponent;
