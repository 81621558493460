import React from "react";
import PropTypes from "prop-types";

import makeStyles from '@mui/styles/makeStyles';
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import CloseIcon from '@mui/icons-material/Close';
import Button from "@mui/material/Button";
import InsertDriveFileIcon from "@mui/icons-material/InsertDriveFile";

import IconButton from "../../coraWebMComponents/inputs/IconButton";
import getSharedStyles from "../../coraWebMComponents/sharedStyles";

import { Transition } from "./support";

const useStyles = makeStyles((theme) => ({
  ...getSharedStyles(theme),
  root: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing(2),
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

const ComboFilterDialogComponent = ({
  closeClick,
  isOpen,
  handleJsonExportBtnClick,
  handleCsvExportBtnClick,
  postExportData,
}) => {
  const classes = useStyles();

  if (!isOpen) return null;

  return (
    (<Dialog
      open={isOpen}
      onClose={closeClick}
      scroll="paper"
      maxWidth="sm"
      aria-labelledby="scroll-dialog-title"
      fullWidth={false}
      TransitionComponent={Transition}
    >
      <DialogTitle
        id="scroll-dialog-title"
        className={classes.root}
      >
        {/* <Typography variant="h6">{label}</Typography> */}
        <IconButton
          aria-label="Close"
          className={classes.closeButton}
          onClick={closeClick}
          toolTip="Zavieť dialógové okno"
          size="large">
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <div>
          <IconButton
            onClick={handleJsonExportBtnClick}
            toolTip="Stiahnuť JSON súbor"
            size="large">
            <InsertDriveFileIcon />
          </IconButton>
          <Button
            onClick={handleJsonExportBtnClick}
            color="black"
          >
            JSON
          </Button>
        </div>
        <div>
          <IconButton
            onClick={handleCsvExportBtnClick}
            toolTip="Stiahnuť CSV súbor"
            size="large">
            <InsertDriveFileIcon />
          </IconButton>
          <Button
            onClick={handleCsvExportBtnClick}
            color="black"
          >
            CSV
          </Button>
        </div>
        <div>
          <IconButton
            onClick={() => postExportData("xlsx")}
            toolTip="Stiahnuť XLSX súbor"
            size="large">
            <InsertDriveFileIcon />
          </IconButton>
          <Button
            onClick={() => postExportData("xlsx")}
            color="black"
          >
            XLSX
          </Button>
        </div>
        <div>
          <IconButton
            onClick={() => postExportData("pdf")}
            toolTip="Stiahnuť PDF súbor"
            size="large">
            <InsertDriveFileIcon />
          </IconButton>
          <Button
            onClick={() => postExportData("pdf")}
            color="black"
          >
            PDF
          </Button>
        </div>
      </DialogContent>
    </Dialog>)
  );
};

ComboFilterDialogComponent.propTypes = {
  closeClick: PropTypes.func.isRequired,
  isOpen: PropTypes.bool.isRequired,
  handleCsvExportBtnClick: PropTypes.func.isRequired,
  handleJsonExportBtnClick: PropTypes.func.isRequired,
  postExportData: PropTypes.func.isRequired,
  // onClick: PropTypes.func,
};

export default ComboFilterDialogComponent;
