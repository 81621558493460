import { React, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';

import withStyles from '@mui/styles/withStyles';

import CircularProgress from '../../coraWebMComponents/feedBack/progress/CircularProgress';
import Typography from '../../coraWebMComponents/dataDisplay/Typography';
import getSharedStyles from '../../coraWebMComponents/sharedStyles';
import IconButton from '../../coraWebMComponents/inputs/IconButton';
import ArrowBack from '../../coraWebMComponents/dataDisplay/icons/ArrowBack';
import ScheludeIcon from '../../coraWebMComponents/dataDisplay/icons/ScheludeIcon';
import ScheludeTwoToneIcon from '../../coraWebMComponents/dataDisplay/icons/ScheludeTwoToneIcon';
import CarIcon from '../../coraWebMComponents/dataDisplay/icons/CarIcon';
import PlaceIcon from '../../coraWebMComponents/dataDisplay/icons/PlaceIcon';
import AlarmOnIcon from '../../coraWebMComponents/dataDisplay/icons/AlarmOnIcon';
import TimerIcon from '../../coraWebMComponents/dataDisplay/icons/TimerIcon';
import DeleteIcon from '../../coraWebMComponents/dataDisplay/icons/DeleteIcon';
import Dialog from '../../coraWebMComponents/feedBack/Dialog';
import withRouter from '../../coraWebMComponents/utils/withRouter';
import * as convert from '../../coraWebMComponents/utils/convert';

import * as parkingActions from '../../actions/parkingActions';
import * as userActions from '../../actions/userActions';

const styles = theme => ({
  ...getSharedStyles(theme),
  addButton: {
    borderRadius: '7px'
  }
});

const CreditDraw = (props) => {
  const [isLoad, setIsLoad] = useState(false);
  const [openAlert, setOpenAlert] = useState(false);
  const creditDraw = props.location?.state?.creditDraw;
  const showDelete = props.location?.state?.showDelete;
  const { classes } = props;

  const handleCancelDraw = async () => {
    setIsLoad(true);
    setOpenAlert(false);
    const res = await props.dispatch(parkingActions.cancelDraw(creditDraw.I_KREDIT_CERP));

    if (res) {
      await props.dispatch(parkingActions.getListCreditDraw(creditDraw.I_KARTA, creditDraw.I_KREDIT));
    }

    setIsLoad(false);

    if (res) {
      props.dispatch(userActions.showMsg("Čerpanie kreditu bolo zrušené."));
      props.navigate(-1);
    } else {
      props.dispatch(userActions.showErrorMsg("Čerpanie kreditu sa nepodarilo zrušiť!"));
    }
  };
  useEffect(() => {
    const getParkingZoneLabel = async () => {
      await props.dispatch(parkingActions.getParkingZoneLabel(props.locale));
    };
    getParkingZoneLabel();
  }, [props.locale]);
  return (
    (<div>
      {creditDraw &&
        <div className='container' style={{ marginTop: '70px', marginBottom: '20px' }}>
          <CircularProgress loading={props.isLoading || isLoad} />
          <IconButton
            className={classNames(classes.backButton, classes.navigationBackButton, classes.ml1, classes.mt4)}
            color="inherit"
            onClick={() => props.navigate(-1)}
            toolTip="Späť"
            toolTipPlace="right"
            size="large">
            <ArrowBack
              variant='fab'
              style={{ fontSize: 30 }}
            />
          </IconButton>
          <div className={classNames(classes.w100, classes.alignItemsCenter, classes.row, classes.mt3)}  >
            <div className={classNames(classes.row, classes.alignItemsCenter, classes.ml4)}>
              <TimerIcon fontSize='large' style={{ marginRight: '12px' }} />
              <Typography variant='body1' >Kredit</Typography>
            </div>
            <Typography variant='body2' className={classNames(classes.mr4)}>{creditDraw.N_KREDIT_TYP}</Typography>
          </div>
          <div className={classNames(classes.w100, classes.alignItemsCenter, classes.row, classes.mt3)}  >
            <div className={classNames(classes.row, classes.alignItemsCenter, classes.ml4)}>
              <CarIcon fontSize='large' style={{ marginRight: '12px' }} />
              <Typography variant='body1' >EČV</Typography>
            </div>
            <Typography variant='body2' className={classNames(classes.mr4)}>{creditDraw.ECV}</Typography>
          </div>
          <div className={classNames(classes.w100, classes.alignItemsCenter, classes.row, classes.mt3)}  >
            <div className={classNames(classes.row, classes.alignItemsCenter, classes.ml4)}>
              <ScheludeIcon fontSize='large' style={{ marginRight: '12px' }} />
              <Typography variant='body1' >Od</Typography>
            </div>
            <Typography variant='body2' className={classNames(classes.mr4)}>{convert.convertDateTime(creditDraw.D_OD)}</Typography>
          </div>
          <div className={classNames(classes.w100, classes.alignItemsCenter, classes.row, classes.mt3)}  >
            <div className={classNames(classes.row, classes.alignItemsCenter, classes.ml4)}>
              <ScheludeTwoToneIcon fontSize='large' style={{ marginRight: '12px' }} />
              <Typography variant="body1" >Do</Typography>
            </div>
            <Typography variant="body2" className={classNames(classes.mr4)}>{convert.convertDateTime(creditDraw.D_DO)}</Typography>
          </div>
          <div className={classNames(classes.w100, classes.alignItemsCenter, classes.row, classes.mt3)}  >
            <div className={classNames(classes.row, classes.alignItemsCenter, classes.ml4)}>
              <PlaceIcon fontSize='large' style={{ marginRight: '12px' }} />
              <Typography variant="body1" >{props.parkingZoneLabel}</Typography>
            </div>
            <Typography variant="body2" className={classNames(classes.mr4)}>{creditDraw.N_ZONA}</Typography>
          </div>
          <div className={classNames(classes.w100, classes.alignItemsCenter, classes.row, classes.mt3)}  >
            <div className={classNames(classes.row, classes.alignItemsCenter, classes.ml4)}>
              <AlarmOnIcon fontSize='large' style={{ marginRight: '12px' }} />
              <Typography variant="body1" >Zarátané</Typography>
            </div>
            <Typography variant="body2" className={classNames(classes.mr4)}>{creditDraw.C_CERP} {creditDraw.N_MJ ? creditDraw.N_MJ : 'hod.'}</Typography>
          </div>

          {showDelete &&
            <div className={classNames(classes.w100, classes.alignItemsCenter, classes.row, classes.mt3)}  >
              <div></div>
              <IconButton
                className={classNames(classes.backButton, classes.mr4)}
                color="inherit"
                onClick={() => setOpenAlert(true)}
                toolTip="Zrušiť čerpanie kreditu"
                toolTipPlace="left"
                size="large">
                <DeleteIcon fontSize='large' />
              </IconButton>
            </div>
          }
          <Dialog
            open={openAlert}
            onClose={() => setOpenAlert(false)}
            contentText='Prajete si zrušiť čerpanie kreditu?'
            title='Otázka'
            actions={[
              {
                label: 'Nie',
                color: 'primary',
                onClick: () => setOpenAlert(false),
                keyboardFocused: true
              },
              {
                label: 'Áno',
                color: 'primary',
                onClick: handleCancelDraw,
                keyboardFocused: false
              }
            ]}
          />
        </div >
      }</div>)
  );
};

const mapStateToProps = (state) => {
  return {
    creditDraw: state.creditDraw.data.Data,
    isLoading: state.creditDraw.isLoading,
    parkingZoneLabel: state.parkingZoneLabel.data,
    locale: state.locale.locale
  };
};

export default connect(mapStateToProps)(withRouter(withStyles(styles)(CreditDraw)));
