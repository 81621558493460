import React from 'react';
import { connect } from "react-redux";

import withStyles from '@mui/styles/withStyles';

import IconButton from "../../coraWebMComponents/inputs/IconButton";
import InfoIcon from "../../coraWebMComponents/dataDisplay/icons/InfoIcon";
import { withLocalizationConsumer } from "../../coraWebMComponents/localization/withLocalization";
import getSharedStyles from "../../coraWebMComponents/sharedStyles";

import InfoDialog from "./InfoDialog";

const styles = theme => ({
  ...getSharedStyles(theme),
});
class InfoButton extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isInfoOpen: false,
      visible: false,
    };
  }

  handleSetVisible = (value) => {
    this.setState({ visible: value });
  };

  handleOpenInfo = () => {
    this.setState({ isInfoOpen: true });
  };
  handleCloseInfo = () => {
    this.setState({ isInfoOpen: false });
  };
  render() {
    const { type } = this.props;
    return (
      <>
        {this.state.visible &&
          <>
            <IconButton
              color="secondary"
              onClick={this.handleOpenInfo}
              toolTip="Informácia"
              toolTipPlace="top"
              size="large"
              style={
                type === 'Login' && window.innerHeight < 750 ? {
                  float: "right",
                  bottom: -10,
                  right: -10,

                } : this.props.style ? this.props.style : {
                  position: "fixed",
                  bottom: 10,
                  right: 10
                }}
            >
              <InfoIcon style={type === 'PreLogin' ?
                { width: '28px', height: '28px', color: '#bdbdbd' }
                :
                { width: '28px', height: '28px' }}
                variant='fab' />
            </IconButton>
          </>}
        <InfoDialog title={this.props.title} open={this.state.isInfoOpen} handleClose={this.handleCloseInfo} N_KLUC={this.props.N_KLUC} currEdited={this.props.currEdited} handleSetVisible={this.handleSetVisible}>
        </InfoDialog>
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    adminMode: state.adminMode,
  };
};
export default withLocalizationConsumer(connect(mapStateToProps)(withStyles(styles)(InfoButton)));
