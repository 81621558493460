import React from 'react';
import { merge } from "lodash";

import { PieChart } from '@mui/x-charts/PieChart';

import { getColorsAttribute } from "../charts/helper";

const PieChartComponent = props => {
  const { isMobile, className, _style,
    dataSet, series, width, height = 400, colors,
    marginProps, legendProps,
    ...otherProps } = props;

  var defaultSeries = series.map(x => merge({
    data: dataSet,
    innerRadius: 20,
    cornerRadius: 4,
    paddingAngle: 0,
    cx: '50%', //posun kolaca horizontal
    cy: '50%', //posun kolaca horizontal
    arcLabelRadius: "80%",
    arcLabel: (item) => `${item.value}`,
    arcLabelMinAngle: 20, //min. uhol pre zobrazenie arcLabel, na uzsich ako 20 stupnov sa skryje popisok
    highlightScope: {
      highlighted: "item",
      faded: 'global',
    },
    highlighted: {
      // innerRadius: 30, // odsadenie "highlighted" vyseku od stredu kruhu
      // additionalRadius: 20, dodatocne znizenie(-)/zvysenie(+) "highlighted" vyseku na okraji kruhu (ignorovane ak je outerRadius)
      cornerRadius: 4, // zaoblenie okrajov "highlighted" vysekov
      arcLabelRadiu: "80%", // odsadenie popiskov "highlighted" vysekov od stredu kruhu
      paddingAngle: 0, // medzera medzi "highlighted" vysekmi 
    },
    faded: {
      innerRadius: 30, // odsadenie "faded" vyseku od stredu kruhu
      additionalRadius: -10, // dodatocne znizenie(-)/zvysenie(+) "faded" vyseku na okraji kruhu (ignorovane ak je outerRadius)
      cornerRadius: 4, // zaoblenie okrajov "faded" vysekov
      arcLabelRadiu: "80%", // odsadenie popiskov "faded" vysekov od stredu kruhu
      paddingAngle: 0, // medzera medzi "faded" vysekmi 
    }
  }, x));

  const margin = isMobile
    ? { top: 0, right: 0, bottom: 200, left: 0 }
    : { top: 20, right: 20, bottom: 20, left: 20, ...marginProps };

  const chartSetting = merge({
    series: defaultSeries,
    width: width,
    height: height,
    margin: margin,
    colors: getColorsAttribute(colors),
    skipAnimation: false,
    disableAxisListener: false,
    tooltip: {
      trigger: 'item', // "axis", "none"
      // itemContent: (v) => console.log("v:", v) || <Box sx={{ backgroundColor: "rgba(0, 0, 0, 0.7)", color: "white", p: 1, borderRadius: "0.2rem" }}>{v.series.data[v.itemData.dataIndex].id}: {v.series.data[v.itemData.dataIndex].value}</Box>,
    },
    slotProps: {
      legend: merge({
        direction: isMobile ? 'row' : 'column',
        position: {
          vertical: isMobile ? 'bottom' : 'middle',
          horizontal: isMobile ? 'middle' : 'right', //ak je right, potom aj padding sa nastavuje cez right (padding left nereaguje)
        },
        itemGap: 8,
        markGap: 10,
        padding: {
          top: 0,
          right: 0,
          bottom: 0,
          left: 0
        },
        itemMarkWidth: 36,
        itemMarkHeight: 20,
        labelStyle: {
          fontSize: "1rem",
          fill: "black",
        },
      }, legendProps),
    },
  }, otherProps);

  return (
    // <Box
    //   className={classes.chartContainer}
    //   style={style}
    //   width={"100%"}
    //   height={"100%"}
    // >
    // </Box>
    (<PieChart
      sx={className}
      {...chartSetting}
    />)
  );
};

PieChartComponent.propTypes = {};

export default (PieChartComponent);