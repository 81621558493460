import React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import withStyles from '@mui/styles/withStyles';
import getSharedStyles from "../sharedStyles";
import classNames from "classnames";

const styles = (theme) => ({
  ...getSharedStyles(theme),
  tabIndicator: {
    backgroundColor: theme.palette.secondary.main,
    height: "100%",
    borderRadius: "7px",
  },
  tabFullWidth: {
    [theme.breakpoints.down('sm')]: {
      width: "50%!important",
      minWidth: "50%!important",
    },
  },
  tabSelected: {
    color: "white",
    position: "relative",
    zIndex: 1,
  },
  switchWrapper: {
    [theme.breakpoints.down('sm')]: {
      width: "100%!important",
    },
  },
  switchWrapperFull: {
    width: "100%!important",
  },
  tabsRoot: {
    //width: "50%",
    height: "50px",
    padding: '0 20px'
  },
  tabsWrapperRoot: {
    backgroundColor: "white",
    //color: theme.palette.secondary.main,
    borderRadius: "7px",
    boxShadow: "0px 0px 4px 0px rgba(0,0,0,0.17)",
    WebkitBoxShadow: "0px 0px 4px 0px rgba(0,0,0,0.17)",
    MozBoxShadow: "0px 0px 4px 0px rgba(0,0,0,0.17)",
  },
});

class SwitchComponent extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      <div className={this.props.mobileFullWidth ? classes.switchWrapper : classes.switchWrapperFull}>
        <Tabs
          value={this.props.value}
          onChange={this.props.onChange}
          //indicatorColor="secondary"
          //textColor="primary"
          classes={{ root: classes.tabsWrapperRoot, indicator: this.props.indicatorClasses ? this.props.indicatorClasses : classes.tabIndicator }}
          variant="fullWidth"
        >
          <Tab
            label={this.props.onLabel}
            classes={{ root: classNames(this.props.mobileFullWidth && classes.tabFullWidth, classes.tabsRoot), selected: classes.tabSelected }}
          //style={{ backgroundColor: this.props.value === 0 ? '#212121' : '#eeeeee', fontWeight: "bold" }}
          />
          <Tab
            label={this.props.offLabel}
            classes={{ root: classNames(this.props.mobileFullWidth && classes.tabFullWidth, classes.tabsRoot), selected: classes.tabSelected }}
          //style={{ backgroundColor: this.props.value !== 0 ? '#212121' : '#eeeeee', fontWeight: "bold" }}
          />
        </Tabs>
      </div>
    );
  }
}

SwitchComponent.propTypes = {
  color: PropTypes.string,
  onLabel: PropTypes.string,
  offLabel: PropTypes.string,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  checked: PropTypes.bool,
  value: PropTypes.number,
  classes: PropTypes.object,
  indicatorClasses: PropTypes.object,
  mobileFullWidth: PropTypes.bool,
};

export default withStyles(styles)(SwitchComponent);
