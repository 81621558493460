import { useEffect, useState, useMemo } from 'react';
import { connect } from 'react-redux';

import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import Paper from '@mui/material/Paper';
import { createTheme, adaptV4Theme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';

import "./progRozpocet.css";

import { convertDateTime } from '../../coraWebMComponents/utils/convert';

import TreeViewComponent from './TreeViewComponent';
import RowDetail from './RowDetail';
import ProgRozpocetControl from './ProgRozpocetControl';
import { formatDecimals, escapeRegExp, removeNonPrintableCharacters } from './helper';
import sk from './sk.json';
import * as rozpocetActions from '../../actions/rozpocetActions';

const LOCALE_CODE = "sk-SK";
const LOCALE = sk.progRozpocet;
const REG_EXP_ASCENTS = /[\u0300-\u036f]/g; //vsetky HTML tagy <...>
const REG_EXP_NBSP = /\u00A0/g; //vsetky nedelitelne mezdiery &nbsp;

/**
 * Hlavny riadiaci komponent PR
 *
 * @param {*} { 
 * initProgRozp - init data z reducera
 * getListProgRozp - dispatch reducera na ziskanie poloziek PR
 * }
 * @return {*} 
 */
const ProgRozpocet = ({ initProgRozp, getListProgRozp }) => {
  const [prData, setPrData] = useState(); // surove data z API/JSON
  const [organization, setOrganization] = useState(initProgRozp.activeIOrg); // ID aktivnej ogranizacie
  const [year, setYear] = useState(initProgRozp.activeYear); // akivny rok
  const [incomes, setIncomes] = useState({}); // PRIJMY data
  const [expenses, setExpenses] = useState({});  // VYDAVKY data
  const [incomesNodes, setIncomesNodes] = useState([]); // uzly pre PRIJMY
  const [expensesNodes, setExpensesNodes] = useState([]); // uzly pre VYDAVKY
  const [activateFilter, setActivateFilter] = useState(false); // zmena v hodnote: bol aktivovany filter na data
  const [searchState, setSearchState] = useState({
    fullText: null, // hladany text pre fulltext search
    searchSuccess: true, // true: hladanie bolo uspesne; false: hladanie neuspesne
    searchedIncomesNodes: null, // uzly obsahujuce hladany text pri fulltext search - PRIJMY
    searchedExpansesNodes: null, // uzly obsahujuce hladany text pri fulltext search - VYDAVKY
  });
  // const [loading, setLoading] = useState(false); // true: ukaz progress bar

  const {
    orgList,
    activeIOrg,
    activeYear,
    maxIncomesLevel,
    maxExpensesLevel,
    maxOrgIncomesLevel,
    maxOrgExpensesLevel,
    updateDate,
    autoExpandToLevel,
    breakPointXs,
    numberOfDecimals } = initProgRozp;

  const NUMBER_OF_DECIMALS = numberOfDecimals;

  // Custom XS hranica pre zmenu medzi riadkovym a stlpcovym zobrazenim. Pretoze statandartne je XS=600px, co je malo
  const themeCustom = createTheme(adaptV4Theme({
    breakpoints: {
      values: {
        xs: breakPointXs,
      },
    },
  }));

  const handleFilter = (newIOrg, newYear) => {
    setYear(newYear);
    setOrganization(newIOrg);
    setSearchState({ fullText: '', searchSuccess: true, searchedIncomesNodes: null, searchedExpansesNodes: null });
    setActivateFilter((prev) => !prev);
  };

  // najdi uzly, kde sa nachadza fullText, s ohladom na max. povoleny level
  const findNodesWithFullText = (searchedText = null, data = {}, maxLevel = 1) => {
    let searchedNodes = null; // 'null' znamena ze nema hladat; '[]' znamena ze hladal, ale nic nenasiel, alebo nasiel v root uzle
    let searchSuccessfull = false; // potrebne na odlisenie ak sa naslo len v root uzle a nikde inde, lebo vtedy sa vracia tiez prazdne pole [], rovnako ako ked sa nic nenajde
    let rowText;
    let strOptimized;
    let matches;

    // nastav '' ak je searchedText nill alebo true
    searchedText = searchedText === true || !searchedText ? '' : searchedText;
    searchedText = removeNonPrintableCharacters(searchedText);
    if (searchedText !== '') {
      searchedNodes = [];

      // vytvor reg. vyraz, z hladaneho textu zrus diakritiku a velke pismena v hladanom texte
      searchedText = escapeRegExp(searchedText);
      const regExp = new RegExp(searchedText?.normalize("NFD").replace(REG_EXP_ASCENTS, "").toLowerCase(), 'gi');

      // najdi vsetkych predkov
      const generateAllParents = (parentId) => {
        const parts = parentId?.split('.');
        const allParrents = [];

        parts && parts.reduce((prev, curr) => {
          const combined = prev ? `${prev}.${curr}` : curr;
          allParrents.push(combined);
          return combined;
        }, '');

        return allParrents;
      };

      // zisti ci sa hladany retazec vyskytuje v zazname. Ak ano, pusni ID jeho rodica do searchedNodes
      const evaluateLevel = (level) => {
        if (level.detail) {
          level.detail.forEach((item) => {
            rowText = `${item.title} ${formatDecimals(item.R_SCHVAL, NUMBER_OF_DECIMALS, LOCALE_CODE)} € ${formatDecimals(item.R_SKUT, NUMBER_OF_DECIMALS, LOCALE_CODE)} € ${formatDecimals(item.R_UPRAV, NUMBER_OF_DECIMALS, LOCALE_CODE)} € ${formatDecimals(item.percentoPlnenia, NUMBER_OF_DECIMALS, LOCALE_CODE)}%`;
            // zrus diakritiku, velkost pismen a nezlomitelne medzery
            strOptimized = rowText.normalize("NFD").replace(REG_EXP_ASCENTS, "").toLowerCase().replace(REG_EXP_NBSP, " ");
            matches = [...strOptimized.matchAll(regExp)];
            if (matches.length) {
              let allParrents = generateAllParents(item.parrentId);
              searchedNodes.push(...allParrents);
              searchSuccessfull = true;
            }
          });
        }
      };

      // prejdi vsetky levely (ale len po max. povoleny)
      for (const key in data) {
        data[key].level <= maxLevel && evaluateLevel(data[key]);
      }
    }

    // vrat pole bez duplikatov ak prebehlo hladanie, inac vrat 'null' (ak sa nehladalo)
    if (searchedNodes !== null) {
      return { searchSuccessfull: searchSuccessfull, searchResult: [...new Set(searchedNodes)] };
    } else {
      return { searchSuccessfull: false, searchResult: null };
    }
  };

  // vyhladaj uzly pri fulltext search
  const handleFullText = (searchedText) => {
    // setLoading(true)
    let searchResult = {};
    const { searchSuccessfull: searchSuccessfullIncomes, searchResult: searchedIncomesNodes } = findNodesWithFullText(searchedText, incomes, maxIncomesLevel);
    const { searchSuccessfull: searchSuccessfullExpanses, searchResult: searchedExpansesNodes } = findNodesWithFullText(searchedText, expenses, maxExpensesLevel);

    if (!searchSuccessfullIncomes && searchedIncomesNodes?.length === 0 && !searchSuccessfullExpanses && searchedExpansesNodes?.length === 0) {
      searchResult.searchSuccess = false;
    } else {
      searchResult.searchSuccess = true;
    }

    searchResult.searchedIncomesNodes = searchedIncomesNodes;
    searchResult.searchedExpansesNodes = searchedExpansesNodes;
    searchResult.fullText = searchedText;
    setSearchState(searchResult);
  };

  /**
   * Získanie zoznamu poloziek pre Programový rozpočet
   */
  useEffect(() => {
    async function fetchData() {
      return await getListProgRozp(organization, year);
    }

    async function fetchDataAndSetPrData() {
      const result = await fetchData();
      setPrData(result);
    }

    fetchDataAndSetPrData();
    // eslint-disable-next-line react-hooks/exhaustive-deps 
  }, [activateFilter]);

  // hranicna sirka okna pre zlom medzi portrait/landscape zobrazenim
  const matchesThemeMD = useMediaQuery(themeCustom?.breakpoints?.down('xs'));

  // Oprav JavaScript bug - chybu v desatinnom cisle
  const fixCalculation = (calculation) => {
    calculation.R_SCHVAL = Number(calculation?.R_SCHVAL?.toFixed(5));
    calculation.R_UPRAV = Number(calculation?.R_UPRAV?.toFixed(5));
    calculation.R_SKUT = Number(calculation?.R_SKUT?.toFixed(5));
    return calculation;
  };

  // Vypocet percenta plnenia
  const getPercentage = (R_SKUT = 0, R_UPRAV = 1) => {
    return Number((R_UPRAV === 0 ? 0 : (R_SKUT / R_UPRAV) * 100).toFixed(2));
  };

  // Vygeneruj title pre 9. level
  const getTitleL9 = (item = {}) => {
    return `${item.C_KL_E3} | ${item.C_KL_E446 ? item.C_KL_E446 : ""} | ${item.C_AKC} | ${item.N_R}`;
  };

  // Vypocet Prijmy
  useMemo(() => {
    // setLoading(true)
    let incomesNodesTemp = [];
    let keysLevel1 = ["P"];
    let keysLevel2 = [];
    let keysLevel6 = [];
    let keysLevel7 = [];
    let keysLevel8 = [];
    let keyL1 = keysLevel1[0];
    let incomesTemp = {
      level1: {
        detail: [{ R_SCHVAL: 0, R_UPRAV: 0, R_SKUT: 0, title: LOCALE.level1Title }],
        level: 1
      },
    };
    let data = prData || [];
    // data = []

    // console.time("P-time")

    if (data.length > 0) {
      // Init Level 1 - R_DR
      incomesTemp.level1 = {};
      incomesTemp.level1.detail = [];
      incomesTemp.level1.level = 1;

      // Level 1. calculation
      let dataLevel1 = data.filter((item) => item.R_DR === keyL1 && (item.R_SCHVAL !== 0 || item.R_UPRAV !== 0 || item.R_SKUT !== 0));
      let calculation = dataLevel1
        .reduce((prev, cur) => ({
          R_SCHVAL: cur.R_SCHVAL + prev.R_SCHVAL,
          R_UPRAV: cur.R_UPRAV + prev.R_UPRAV,
          R_SKUT: cur.R_SKUT + prev.R_SKUT,
        }), { R_SCHVAL: 0, R_UPRAV: 0, R_SKUT: 0 });
      calculation = {
        ...calculation,
        title: LOCALE.level1Title,
        parrentId: null,
        id: keyL1,
        level: 1,
        sortKey: keyL1
      };
      calculation = fixCalculation(calculation);
      calculation.percentoPlnenia = getPercentage(calculation.R_SKUT, calculation.R_UPRAV);
      incomesTemp.level1.detail.push({ ...calculation, children: dataLevel1 });
      incomesNodesTemp.push(calculation.id);

      // Init Level 2 - I_R_DR
      incomesTemp.level2 = {};
      incomesTemp.level2.detail = [];
      incomesTemp.level2.level = 2;
      keysLevel2 = [...new Set(dataLevel1.map(item => item.I_R_DR))];

      // Init Level 6 - C_KL_E1
      incomesTemp.level6 = {};
      incomesTemp.level6.detail = [];
      incomesTemp.level6.level = 6;
      // najdi vsetky kluce v datach
      keysLevel6 = [...new Set(dataLevel1.map(item => item.C_KL_E1))];

      // Init Level 7 - C_KL_E2
      incomesTemp.level7 = {};
      incomesTemp.level7.detail = [];
      incomesTemp.level7.level = 7;
      keysLevel7 = [...new Set(dataLevel1.map(item => item.C_KL_E2))];

      // Init Level 8 - C_KL_E3
      incomesTemp.level8 = {};
      incomesTemp.level8.detail = [];
      incomesTemp.level8.level = 8;
      keysLevel8 = [...new Set(dataLevel1.map(item => item.C_KL_E3))];

      // Init Level 9 - C_KL_E3
      incomesTemp.level9 = {};
      incomesTemp.level9.detail = [];
      incomesTemp.level9.level = 9;

      // Level 2. calculation
      for (let keyL2 of keysLevel2) {
        let filteredData = dataLevel1.filter((item) => item.I_R_DR === keyL2);//.sort((a, b) => {
        // return a.I_R_DR - b.I_R_DR
        // });
        if (filteredData?.length) {
          let calculation = filteredData
            .reduce((prev, cur) => ({
              R_SCHVAL: cur.R_SCHVAL + prev.R_SCHVAL,
              R_UPRAV: cur.R_UPRAV + prev.R_UPRAV,
              R_SKUT: cur.R_SKUT + prev.R_SKUT,
            }), { R_SCHVAL: 0, R_UPRAV: 0, R_SKUT: 0 });
          calculation = {
            ...calculation,
            title: filteredData[0].N_R_DR,
            parrentId: keyL1,
            id: `${keyL1}.${keyL2}`,
            level: 2,
            sortKey: keyL2
          };
          calculation = fixCalculation(calculation);
          calculation.percentoPlnenia = getPercentage(calculation.R_SKUT, calculation.R_UPRAV);
          incomesTemp.level2.detail.push({ ...calculation, children: filteredData });
          incomesNodesTemp.push(calculation.id);
        } else {
          continue;
        }

        // Level 6. calculation
        // najdi index prvku v detaile, ktory ma pozadovane kluce
        let dataLevel6Id = incomesTemp.level2.detail.findIndex((item) => item.id === `${keyL1}.${keyL2}`);
        // vyber data pre najdeny prvok
        let dataLevel6 = incomesTemp.level2.detail[dataLevel6Id]?.children;

        for (let keyL6 of keysLevel6) {
          let filteredData = dataLevel6?.filter((item) => item.I_R_DR === keyL2 && item.C_KL_E1 === keyL6);
          if (filteredData?.length) {
            let calculation = filteredData
              .reduce((prev, cur) => ({
                R_SCHVAL: cur.R_SCHVAL + prev.R_SCHVAL,
                R_UPRAV: cur.R_UPRAV + prev.R_UPRAV,
                R_SKUT: cur.R_SKUT + prev.R_SKUT,
              }), { R_SCHVAL: 0, R_UPRAV: 0, R_SKUT: 0 });
            calculation = {
              ...calculation,
              title: `${keyL6} | ${filteredData[0].N_KL_E1}`,
              parrentId: `${keyL1}.${keyL2}`,
              id: `${keyL1}.${keyL2}.${keyL6}`,
              level: 6,
              sortKey: Number(keyL6)
            };
            calculation = fixCalculation(calculation);
            calculation.percentoPlnenia = getPercentage(calculation.R_SKUT, calculation.R_UPRAV);
            incomesTemp.level6.detail.push({ ...calculation, children: filteredData });
            incomesNodesTemp.push(calculation.id);
          } else {
            continue;
          }

          // Level 7. calculation
          let dataLevel7Id = incomesTemp.level6.detail.findIndex((item) => item.id === `${keyL1}.${keyL2}.${keyL6}`);
          let dataLevel7 = incomesTemp.level6.detail[dataLevel7Id]?.children;

          for (let keyL7 of keysLevel7) {
            let filteredData = dataLevel7?.filter((item) => item.I_R_DR === keyL2 && item.C_KL_E1 === keyL6 && item.C_KL_E2 === keyL7);
            if (filteredData?.length) {
              let calculation = filteredData
                .reduce((prev, cur) => ({
                  R_SCHVAL: cur.R_SCHVAL + prev.R_SCHVAL,
                  R_UPRAV: cur.R_UPRAV + prev.R_UPRAV,
                  R_SKUT: cur.R_SKUT + prev.R_SKUT,
                }), { R_SCHVAL: 0, R_UPRAV: 0, R_SKUT: 0 });
              calculation = {
                ...calculation,
                title: `${keyL7} | ${filteredData[0].N_KL_E2}`,
                parrentId: `${keyL1}.${keyL2}.${keyL6}`,
                id: `${keyL1}.${keyL2}.${keyL6}.${keyL7}`,
                level: 7,
                sortKey: Number(keyL7)
              };
              calculation = fixCalculation(calculation);
              calculation.percentoPlnenia = getPercentage(calculation.R_SKUT, calculation.R_UPRAV);
              incomesTemp.level7.detail.push({ ...calculation, children: filteredData });
              incomesNodesTemp.push(calculation.id);
            } else {
              continue;
            }

            // Level 8. calculation
            let dataLevel8Id = incomesTemp.level7.detail.findIndex((item) => item.id === `${keyL1}.${keyL2}.${keyL6}.${keyL7}`);
            let dataLevel8 = incomesTemp.level7.detail[dataLevel8Id]?.children;

            for (let keyL8 of keysLevel8) { //item.E_KL_UROVEN === 4 &&
              let filteredData = dataLevel8?.filter((item) => item.I_R_DR === keyL2 && item.C_KL_E1 === keyL6 && item.C_KL_E2 === keyL7 && item.C_KL_E3 === keyL8);
              if (filteredData?.length) {
                let calculation = filteredData
                  .reduce((prev, cur) => ({
                    R_SCHVAL: cur.R_SCHVAL + prev.R_SCHVAL,
                    R_UPRAV: cur.R_UPRAV + prev.R_UPRAV,
                    R_SKUT: cur.R_SKUT + prev.R_SKUT,
                  }), { R_SCHVAL: 0, R_UPRAV: 0, R_SKUT: 0 });
                calculation = {
                  ...calculation,
                  title: `${keyL8} | ${filteredData[0].N_KL_E3}`,
                  parrentId: `${keyL1}.${keyL2}.${keyL6}.${keyL7}`,
                  id: `${keyL1}.${keyL2}.${keyL6}.${keyL7}.${keyL8}`,
                  level: 8,
                  sortKey: Number(keyL8)
                };
                calculation = fixCalculation(calculation);
                calculation.percentoPlnenia = getPercentage(calculation.R_SKUT, calculation.R_UPRAV);
                incomesTemp.level8.detail.push({ ...calculation, children: filteredData });
                incomesNodesTemp.push(calculation.id);

                // Level 9. calculation
                let calculationL9 = filteredData
                  .map(cur => ({
                    R_SCHVAL: cur.R_SCHVAL,
                    R_UPRAV: cur.R_UPRAV,
                    R_SKUT: cur.R_SKUT,
                    title: getTitleL9(cur),
                    percentoPlnenia: getPercentage(cur.R_SKUT, cur.R_UPRAV),
                    parrentId: `${keyL1}.${keyL2}.${keyL6}.${keyL7}.${keyL8}`,
                    id: `${keyL1}.${keyL2}.${keyL6}.${keyL7}.${keyL8}.${cur.I_R}`,
                    level: 9,
                    sortKey: Number(cur.C_KL_E446)
                  }));
                incomesTemp.level9.detail.push(...calculationL9);
              } else {
                continue;
              }
            }
          }
        }
      }

      // console.timeEnd("P-time")
    }
    // setLoading(false)
    setIncomes(incomesTemp);
    setIncomesNodes(incomesNodesTemp);
  }, [prData]);

  // Vypocet Vydavky
  useMemo(() => {
    // setLoading(true)
    let expensesNodesTemp = [];
    let keysLevel1 = ["V"];
    let keysLevel2 = [];
    let keysLevel3 = [];
    let keysLevel4 = [];
    let keysLevel5 = [];
    let keysLevel6 = [];
    let keysLevel7 = [];
    let keysLevel8 = [];
    let keyL1 = keysLevel1[0];
    let expensesTemp = {
      level1: {
        detail: [{ R_SCHVAL: 0, R_UPRAV: 0, R_SKUT: 0, title: LOCALE.level1Title }],
        level: 1
      },
    };
    let data = prData || [];

    // console.time("V-time")

    if (data.length > 0) {
      // Init Level 1 - R_DR
      expensesTemp.level1 = {};
      expensesTemp.level1.detail = [];
      expensesTemp.level1.level = 1;

      // Level 1. calculation
      let dataLevel1 = data.filter((item) => item.R_DR === keyL1 && (item.R_SCHVAL !== 0 || item.R_UPRAV !== 0 || item.R_SKUT !== 0));
      let calculation = dataLevel1
        .reduce((prev, cur) => ({
          R_SCHVAL: cur.R_SCHVAL + prev.R_SCHVAL,
          R_UPRAV: cur.R_UPRAV + prev.R_UPRAV,
          R_SKUT: cur.R_SKUT + prev.R_SKUT,
        }), { R_SCHVAL: 0, R_UPRAV: 0, R_SKUT: 0 });
      calculation = {
        ...calculation,
        title: LOCALE.level1Title,
        parrentId: null,
        id: keyL1,
        level: 1,
        sortKey: keyL1
      };
      calculation = fixCalculation(calculation);
      calculation.percentoPlnenia = getPercentage(calculation.R_SKUT, calculation.R_UPRAV);
      // expensesTemp.level1.keys = [keyL1]
      expensesTemp.level1.detail.push({ ...calculation, children: dataLevel1 });
      expensesNodesTemp.push(calculation.id);

      // Init Level 2 - I_R_DR
      expensesTemp.level2 = {};
      expensesTemp.level2.detail = [];
      expensesTemp.level2.level = 2;
      keysLevel2 = [...new Set(dataLevel1.map(item => item.I_R_DR))];

      // Init Level 3 - C_R_PRG (I_R_PRG)
      expensesTemp.level3 = {};
      expensesTemp.level3.detail = [];
      expensesTemp.level3.level = 3;
      keysLevel3 = [...new Set(dataLevel1.map(item => item.C_R_PRG))];

      // Init Level 5 - C_R_AK
      expensesTemp.level4 = {};
      expensesTemp.level4.detail = [];
      expensesTemp.level4.level = 4;
      keysLevel4 = [...new Set(dataLevel1.map(item => item.C_R_AK))];

      // Init Level 5 - I_KL_FUNK
      expensesTemp.level5 = {};
      expensesTemp.level5.detail = [];
      expensesTemp.level5.level = 5;
      keysLevel5 = [...new Set(dataLevel1.map(item => item.I_KL_FUNK))];

      // Init Level 6 - C_KL_E1
      expensesTemp.level6 = {};
      expensesTemp.level6.detail = [];
      expensesTemp.level6.level = 6;
      // najdi vsetky kluce v datach
      keysLevel6 = [...new Set(dataLevel1.map(item => item.C_KL_E1))];

      // Init Level 7 - C_KL_E2
      expensesTemp.level7 = {};
      expensesTemp.level7.detail = [];
      expensesTemp.level7.level = 7;
      keysLevel7 = [...new Set(dataLevel1.map(item => item.C_KL_E2))];

      // Init Level 8 - C_KL_E3
      expensesTemp.level8 = {};
      expensesTemp.level8.detail = [];
      expensesTemp.level8.level = 8;
      keysLevel8 = [...new Set(dataLevel1.map(item => item.C_KL_E3))];

      // Init Level 9 - C_KL_E3
      expensesTemp.level9 = {};
      expensesTemp.level9.detail = [];
      expensesTemp.level9.level = 9;

      // Level 2. calculation
      for (let keyL2 of keysLevel2) {
        let filteredData = dataLevel1.filter((item) => item.I_R_DR === keyL2);//.sort((a, b) => {
        //   return a.I_R_DR - b.I_R_DR
        // });
        if (filteredData?.length) {
          let calculation = filteredData
            .reduce((prev, cur) => ({
              R_SCHVAL: cur.R_SCHVAL + prev.R_SCHVAL,
              R_UPRAV: cur.R_UPRAV + prev.R_UPRAV,
              R_SKUT: cur.R_SKUT + prev.R_SKUT,
            }), { R_SCHVAL: 0, R_UPRAV: 0, R_SKUT: 0 });
          calculation = {
            ...calculation,
            title: `${filteredData[0].N_R_DR}`,
            parrentId: keyL1,
            id: `${keyL1}.${keyL2}`,
            level: 2,
            sortKey: keyL2
          };
          calculation = fixCalculation(calculation);
          calculation.percentoPlnenia = getPercentage(calculation.R_SKUT, calculation.R_UPRAV);
          expensesTemp.level2.detail.push({ ...calculation, children: filteredData });
          expensesNodesTemp.push(calculation.id);
        } else {
          continue;
        }

        // Level 3. calculation
        // najdi index prvku v detaile, ktory ma pozadovane kluce
        let dataLevel3Id = expensesTemp.level2.detail.findIndex((item) => item.id === `${keyL1}.${keyL2}`);
        // vyber data pre najdeny prvok
        let dataLevel3 = expensesTemp.level2.detail[dataLevel3Id]?.children;

        for (let keyL3 of keysLevel3) {
          let filteredData = dataLevel3.filter((item) => item.I_R_DR === keyL2 && item.C_R_PRG === keyL3);//.sort((a, b) => {
          //   return a.C_R_PRG - b.C_R_PRG
          // });
          if (filteredData?.length) {
            let calculation = filteredData
              .reduce((prev, cur) => ({
                R_SCHVAL: cur.R_SCHVAL + prev.R_SCHVAL,
                R_UPRAV: cur.R_UPRAV + prev.R_UPRAV,
                R_SKUT: cur.R_SKUT + prev.R_SKUT,
              }), { R_SCHVAL: 0, R_UPRAV: 0, R_SKUT: 0 });
            calculation = {
              ...calculation,
              title: `${LOCALE.level3TitleStart} ${keyL3}: ${filteredData[0].N_R_PRG}`,
              parrentId: `${keyL1}.${keyL2}`,
              id: `${keyL1}.${keyL2}.${keyL3}`,
              level: 3,
              sortKey: Number(keyL3)
            };
            calculation = fixCalculation(calculation);
            calculation.percentoPlnenia = getPercentage(calculation.R_SKUT, calculation.R_UPRAV);
            expensesTemp.level3.detail.push({ ...calculation, children: filteredData });
            expensesNodesTemp.push(calculation.id);
          } else {
            continue;
          }

          // Level 4. calculation
          // najdi index prvku v detaile, ktory ma pozadovane kluce
          let dataLevel4Id = expensesTemp.level3.detail.findIndex((item) => item.id === `${keyL1}.${keyL2}.${keyL3}`);
          // vyber data pre najdeny prvok
          let dataLevel4 = expensesTemp.level3.detail[dataLevel4Id]?.children;

          for (let keyL4 of keysLevel4) {
            let filteredData = dataLevel4?.filter((item) => item.I_R_DR === keyL2 && item.C_R_PRG === keyL3 && item.C_R_AK === keyL4);//.sort((a, b) => {
            //   return a.C_R_AK - b.C_R_AK
            // });
            if (filteredData?.length) {
              let calculation = filteredData
                .reduce((prev, cur) => ({
                  R_SCHVAL: cur.R_SCHVAL + prev.R_SCHVAL,
                  R_UPRAV: cur.R_UPRAV + prev.R_UPRAV,
                  R_SKUT: cur.R_SKUT + prev.R_SKUT,
                }), { R_SCHVAL: 0, R_UPRAV: 0, R_SKUT: 0 });
              calculation = {
                ...calculation,
                title: `${keyL4} | ${filteredData[0].N_R_AK}`,
                parrentId: `${keyL1}.${keyL2}.${keyL3}`,
                id: `${keyL1}.${keyL2}.${keyL3}.${keyL4}`,
                level: 4,
                sortKey: Number(keyL4)
              };
              calculation = fixCalculation(calculation);
              calculation.percentoPlnenia = getPercentage(calculation.R_SKUT, calculation.R_UPRAV);
              expensesTemp.level4.detail.push({ ...calculation, children: filteredData });
              expensesNodesTemp.push(calculation.id);
            } else {
              continue;
            }

            // Level 5. calculation
            // najdi index prvku v detaile, ktory ma pozadovane kluce
            let dataLevel5Id = expensesTemp.level4.detail.findIndex((item) => item.id === `${keyL1}.${keyL2}.${keyL3}.${keyL4}`);
            // vyber data pre najdeny prvok
            let dataLevel5 = expensesTemp.level4.detail[dataLevel5Id]?.children;

            for (let keyL5 of keysLevel5) {
              let filteredData = dataLevel5?.filter((item) => item.I_R_DR === keyL2 && item.C_R_PRG === keyL3 && item.C_R_AK === keyL4 && item.I_KL_FUNK === keyL5);//.sort((a, b) => {
              //   return a.I_KL_FUNK - b.I_KL_FUNK
              // });
              if (filteredData?.length) {
                let calculation = filteredData
                  .reduce((prev, cur) => ({
                    R_SCHVAL: cur.R_SCHVAL + prev.R_SCHVAL,
                    R_UPRAV: cur.R_UPRAV + prev.R_UPRAV,
                    R_SKUT: cur.R_SKUT + prev.R_SKUT,
                  }), { R_SCHVAL: 0, R_UPRAV: 0, R_SKUT: 0 });
                calculation = {
                  ...calculation,
                  title: `${filteredData[0].C_KL_FUNK} | ${filteredData[0].N_KL_FUNK}`,
                  parrentId: `${keyL1}.${keyL2}.${keyL3}.${keyL4}`,
                  id: `${keyL1}.${keyL2}.${keyL3}.${keyL4}.${keyL5}`,
                  level: 5,
                  sortKey: Number(keyL5)
                };
                calculation = fixCalculation(calculation);
                calculation.percentoPlnenia = getPercentage(calculation.R_SKUT, calculation.R_UPRAV);
                expensesTemp.level5.detail.push({ ...calculation, children: filteredData });
                expensesNodesTemp.push(calculation.id);
              } else {
                continue;
              }

              // Level 6. calculation
              // najdi index prvku v detaile, ktory ma pozadovane kluce
              let dataLevel6Id = expensesTemp.level5.detail.findIndex((item) => item.id === `${keyL1}.${keyL2}.${keyL3}.${keyL4}.${keyL5}`);
              // vyber data pre najdeny prvok
              let dataLevel6 = expensesTemp.level5.detail[dataLevel6Id]?.children;

              for (let keyL6 of keysLevel6) {
                let filteredData = dataLevel6?.filter((item) => item.I_R_DR === keyL2 && item.C_R_PRG === keyL3 && item.C_R_AK === keyL4 && item.I_KL_FUNK === keyL5 && item.C_KL_E1 === keyL6);
                if (filteredData?.length) {
                  let calculation = filteredData
                    .reduce((prev, cur) => ({
                      R_SCHVAL: cur.R_SCHVAL + prev.R_SCHVAL,
                      R_UPRAV: cur.R_UPRAV + prev.R_UPRAV,
                      R_SKUT: cur.R_SKUT + prev.R_SKUT,
                    }), { R_SCHVAL: 0, R_UPRAV: 0, R_SKUT: 0 });
                  calculation = {
                    ...calculation,
                    title: `${keyL6} | ${filteredData[0].N_KL_E1}`,
                    parrentId: `${keyL1}.${keyL2}.${keyL3}.${keyL4}.${keyL5}`,
                    id: `${keyL1}.${keyL2}.${keyL3}.${keyL4}.${keyL5}.${keyL6}`,
                    level: 6,
                    sortKey: Number(keyL6)
                  };
                  calculation = fixCalculation(calculation);
                  calculation.percentoPlnenia = getPercentage(calculation.R_SKUT, calculation.R_UPRAV);
                  expensesTemp.level6.detail.push({ ...calculation, children: filteredData });
                  expensesNodesTemp.push(calculation.id);
                } else {
                  continue;
                }

                // Level 7. calculation
                let dataLevel7Id = expensesTemp.level6.detail.findIndex((item) => item.id === `${keyL1}.${keyL2}.${keyL3}.${keyL4}.${keyL5}.${keyL6}`);
                let dataLevel7 = expensesTemp.level6.detail[dataLevel7Id]?.children;

                for (let keyL7 of keysLevel7) {
                  let filteredData = dataLevel7?.filter((item) => item.I_R_DR === keyL2 && item.C_R_PRG === keyL3 && item.C_R_AK === keyL4 && item.I_KL_FUNK === keyL5 && item.C_KL_E1 === keyL6 && item.C_KL_E2 === keyL7);
                  if (filteredData?.length) {
                    let calculation = filteredData
                      .reduce((prev, cur) => ({
                        R_SCHVAL: cur.R_SCHVAL + prev.R_SCHVAL,
                        R_UPRAV: cur.R_UPRAV + prev.R_UPRAV,
                        R_SKUT: cur.R_SKUT + prev.R_SKUT,
                      }), { R_SCHVAL: 0, R_UPRAV: 0, R_SKUT: 0 });
                    calculation = {
                      ...calculation,
                      title: `${keyL7} | ${filteredData[0].N_KL_E2}`,
                      parrentId: `${keyL1}.${keyL2}.${keyL3}.${keyL4}.${keyL5}.${keyL6}`,
                      id: `${keyL1}.${keyL2}.${keyL3}.${keyL4}.${keyL5}.${keyL6}.${keyL7}`,
                      level: 7,
                      sortKey: Number(keyL7)
                    };

                    calculation = fixCalculation(calculation);
                    calculation.percentoPlnenia = getPercentage(calculation.R_SKUT, calculation.R_UPRAV);
                    expensesTemp.level7.detail.push({ ...calculation, children: filteredData });
                    expensesNodesTemp.push(calculation.id);
                  } else {
                    continue;
                  }

                  // Level 8. calculation
                  let dataLevel8Id = expensesTemp.level7.detail.findIndex((item) => item.id === `${keyL1}.${keyL2}.${keyL3}.${keyL4}.${keyL5}.${keyL6}.${keyL7}`);
                  let dataLevel8 = expensesTemp.level7.detail[dataLevel8Id]?.children;

                  for (let keyL8 of keysLevel8) {
                    let filteredData = dataLevel8?.filter((item) => item.I_R_DR === keyL2 && item.C_R_PRG === keyL3 && item.C_R_AK === keyL4 && item.I_KL_FUNK === keyL5 && item.C_KL_E1 === keyL6 && item.C_KL_E2 === keyL7 && item.C_KL_E3 === keyL8);
                    if (filteredData?.length) {
                      let calculation = filteredData
                        .reduce((prev, cur) => ({
                          R_SCHVAL: cur.R_SCHVAL + prev.R_SCHVAL,
                          R_UPRAV: cur.R_UPRAV + prev.R_UPRAV,
                          R_SKUT: cur.R_SKUT + prev.R_SKUT,
                        }), { R_SCHVAL: 0, R_UPRAV: 0, R_SKUT: 0 });
                      calculation = {
                        ...calculation,
                        title: `${keyL8} | ${filteredData[0].N_KL_E3}`,
                        parrentId: `${keyL1}.${keyL2}.${keyL3}.${keyL4}.${keyL5}.${keyL6}.${keyL7}`,
                        id: `${keyL1}.${keyL2}.${keyL3}.${keyL4}.${keyL5}.${keyL6}.${keyL7}.${keyL8}`,
                        level: 8,
                        sortKey: Number(keyL8)
                      };
                      calculation = fixCalculation(calculation);
                      calculation.percentoPlnenia = getPercentage(calculation.R_SKUT, calculation.R_UPRAV);
                      expensesTemp.level8.detail.push({ ...calculation, children: filteredData });
                      expensesNodesTemp.push(calculation.id);

                      // Level 9. calculation
                      let calculationL9 = filteredData
                        .map(curent => ({
                          R_SCHVAL: curent.R_SCHVAL,
                          R_UPRAV: curent.R_UPRAV,
                          R_SKUT: curent.R_SKUT,
                          title: getTitleL9(curent),
                          percentoPlnenia: getPercentage(curent.R_SKUT, curent.R_UPRAV),
                          parrentId: `${keyL1}.${keyL2}.${keyL3}.${keyL4}.${keyL5}.${keyL6}.${keyL7}.${keyL8}`,
                          id: `${keyL1}.${keyL2}.${keyL3}.${keyL4}.${keyL5}.${keyL6}.${keyL7}.${keyL8}.${curent.I_R}`,
                          level: 9,
                          sortKey: Number(curent.C_KL_E446)
                        }));
                      expensesTemp.level9.detail.push(...calculationL9);
                    } else {
                      continue;
                    }
                  }
                }
              }
            }
          }
        }
      }

      // console.timeEnd("V-time")
    }
    // setLoading(false)
    setExpenses(expensesTemp);
    setExpensesNodes(expensesNodesTemp);
  }, [prData]);

  // vypocet Prijmy - Vydavky
  let sumary = useMemo(() => {
    let incomesDetail = incomes?.level1?.detail[0];
    let expensesDetail = expenses?.level1?.detail[0];
    return ({
      "level1": {
        "detail": [
          {
            "R_SCHVAL": incomesDetail?.R_SCHVAL - expensesDetail?.R_SCHVAL,
            "R_UPRAV": incomesDetail?.R_UPRAV - expensesDetail?.R_UPRAV,
            "R_SKUT": incomesDetail?.R_SKUT - expensesDetail?.R_SKUT,
            "title": LOCALE.level1PVTitle,
            "parrentId": null,
            "id": "PV",
            "level": 1,
            "sortKey": "PV",
            "percentoPlnenia": getPercentage(incomesDetail?.R_SKUT - expensesDetail?.R_SKUT, incomesDetail?.R_UPRAV - expensesDetail?.R_UPRAV)
          }
        ]
      }
    });
  }, [incomes, expenses]);

  // console.log("🚀 ~ ProgRozpocet.js ~ incomes:", incomes)
  // console.log("🚀 ~ ProgRozpocet.js ~ expenses:", expenses)
  // console.log("incomesNodes:", incomesNodes);
  // console.log("expensesNodes:", expensesNodes);
  // console.log(">>> ProgRozpocet render");

  return <>
    {/* {isListLoading && (
      <>
        <CircularProgressCustom />
      </>
    )} */}

    <Paper elevation={3}>
      <Box p={1}>
        {/* <Typography className='pr-main-title' align={matchesThemeMD ? 'center' : 'left'} variant='h5' m={"0.5em 0em 1em"} >{LOCALE.topTitle}</Typography> */}
        <Box className="pr-filters">
          <ProgRozpocetControl
            activeIOrg={activeIOrg}
            activeYear={activeYear}
            filtersValues={{ orgList }}
            handleFilter={handleFilter}
            handleFullText={handleFullText}
            searchSuccess={searchState.searchSuccess}
            themeCustom={themeCustom}
          />
        </Box>
        <Box className='pr-incomes-wrapper'>
          <Box className='pr-incomes-title-wrapper' display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
            <Typography className='pr-incomes-title' variant='h6'>{LOCALE.incomesTitle}</Typography>
            <Stack className='pr-date' spacing={matchesThemeMD ? 0 : 1} direction={matchesThemeMD ? 'column' : 'row'}>
              <Typography variant='body2' textAlign={'right'}>{LOCALE.calculationDate}</Typography>
              <Typography variant='body2' textAlign={'right'} >{convertDateTime(updateDate)}</Typography>
            </Stack>
          </Box>
          <TreeViewComponent classes={"pr-incomes-tree-view-wrapper"}
            type="INC"
            data={incomes}
            maxLevel={organization === 0 ? maxIncomesLevel : maxOrgIncomesLevel}
            expandedNodes={incomesNodes}
            searchedNodes={searchState.searchedIncomesNodes}
            RowDetail={RowDetail}
            updateDate={updateDate}
            autoExpandToLevel={autoExpandToLevel > maxIncomesLevel ? maxIncomesLevel : autoExpandToLevel}
            themeCustom={themeCustom}
            fullText={searchState.fullText}
          />
        </Box>

        <Box className='pr-expanses-wrapper'>
          <Box className='pr-expanses-title-wrapper' display="flex" flexDirection="row" justifyContent="space-between" alignItems="center">
            <Typography className='pr-expanses-title' variant='h6'>{LOCALE.expensesTitle}</Typography>
            <Stack className='pr-date' spacing={matchesThemeMD ? 0 : 1} direction={matchesThemeMD ? 'column' : 'row'}>
              <Typography variant='body2' textAlign={'right'}>{LOCALE.calculationDate}</Typography>
              <Typography variant='body2' textAlign={'right'}>{convertDateTime(updateDate)}</Typography>
            </Stack>
          </Box>
          <TreeViewComponent classes={"pr-expanses-tree-view-wrapper"}
            type="EXP"
            data={expenses}
            maxLevel={organization === 0 ? maxExpensesLevel : maxOrgExpensesLevel}
            expandedNodes={expensesNodes}
            searchedNodes={searchState.searchedExpansesNodes}
            RowDetail={RowDetail}
            updateDate={updateDate}
            autoExpandToLevel={autoExpandToLevel > maxExpensesLevel ? maxExpensesLevel : autoExpandToLevel}
            themeCustom={themeCustom}
            fullText={searchState.fullText}
          />
        </Box>

        <Box className='pr-inexp-wrapper'>
          <Box className='pr-inexp-title-wrapper'>
            <Typography className='pr-inexp-title' variant='h6'>{LOCALE.incomesExpensesTitle}</Typography>
          </Box>
          <TreeViewComponent
            classes={"pr-inexp-tree-view-wrapper"}
            type="SUM"
            data={sumary}
            expandedNodes={expensesNodes}
            RowDetail={RowDetail}
            themeCustom={themeCustom} />
        </Box>
      </Box>
    </Paper>
  </>;
};

ProgRozpocet.propTypes = {
};

const mapStateToProps = (state) => ({
  initProgRozp: state.initProgRozp.data,
  // isListLoading: state.listProgRozp.isLoading,
});

const mapDispatchToProps = (dispatch) => ({
  getListProgRozp: (orgId, year) => dispatch(rozpocetActions.getListProgRozp(orgId, year)),
});

// export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ProgRozpocet));
export default connect(mapStateToProps, mapDispatchToProps)(ProgRozpocet);