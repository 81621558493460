import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import MenuItem from '@mui/material/MenuItem';
import Typography from "@mui/material/Typography";

import DogIcon from '../../coraWebMComponents/dataDisplay/icons/DogIcon';
import PersonIcon from '../../coraWebMComponents/dataDisplay/icons/PersonIcon';
import PeopleIcon from '../../coraWebMComponents/dataDisplay/icons/PeopleIcon';
import ParkingIcon from '../../coraWebMComponents/dataDisplay/icons/ParkingIcon';
import ParkingCard from '../../coraWebMComponents/dataDisplay/icons/ParkingCard';
import MapIcon from '../../coraWebMComponents/dataDisplay/icons/MapIcon';
import ParkingPermit from '../../coraWebMComponents/dataDisplay/icons/ParkingPermit';
import AddBlackIcon from '../../coraWebMComponents/dataDisplay/icons/AddBlackIcon';
import DescriptionIcon from '../../coraWebMComponents/dataDisplay/icons/DescriptionIcon';
import EuroIcon from '../../coraWebMComponents/dataDisplay/icons/EuroIcon';
import TicketIcon from '../../coraWebMComponents/dataDisplay/icons/TicketIcon';
import SetingsIcon from '../../coraWebMComponents/dataDisplay/icons/SetingsIcon';
import SubsidiesIcon from '../../coraWebMComponents/dataDisplay/icons/SubsidiesIcon';
import SecurityIcon from '../../coraWebMComponents/dataDisplay/icons/SecurityIcon';
import BudgetIcon from '../../coraWebMComponents/dataDisplay/icons/BudgetIcon';
import AdminSegmentationIcon from '../../coraWebMComponents/dataDisplay/icons/AdminSegmentationIcon';
import DebtorsIcon from '../../coraWebMComponents/dataDisplay/icons/DebtorsIcon';
import GraveIcon from '../../coraWebMComponents/dataDisplay/icons/GraveIcon';
import CitizensIcon from '../../coraWebMComponents/dataDisplay/icons/CitizensIcon';
import TowingIcon from '../../coraWebMComponents/dataDisplay/icons/TowingIcon';
import PublicationIcon from '../../coraWebMComponents/dataDisplay/icons/PublicationIcon';
import OrganizationIcon from '../../coraWebMComponents/dataDisplay/icons/OrganizationIcon';
import FirmIcon from '../../coraWebMComponents/dataDisplay/icons/FirmIcon';
import ShopIcon from '../../coraWebMComponents/dataDisplay/icons/ShopIcon';
import SelfGovIcon from '../../coraWebMComponents/dataDisplay/icons/SelfGovIcon';
import EducationIcon from '../../coraWebMComponents/dataDisplay/icons/EducationIcon';
import SocialServiceIcon from '../../coraWebMComponents/dataDisplay/icons/SocialServiceIcon';
import ConstructionOfficeIcon from '../../coraWebMComponents/dataDisplay/icons/ConstructionOfficeIcon';
import ElectionIcon from '../../coraWebMComponents/dataDisplay/icons/ElectionIcon';
import DefectsIcon from '../../coraWebMComponents/dataDisplay/icons/DefectsIcon';
import ImportantObjectsIcon from '../../coraWebMComponents/dataDisplay/icons/ImportantObjectsIcon';
import PetitionsIcon from '../../coraWebMComponents/dataDisplay/icons/PetitionsIcon';
import InfoIcon from "../../coraWebMComponents/dataDisplay/icons/InfoIcon";
import RegistryRecordsIcon from "../../coraWebMComponents/dataDisplay/icons/RegistryRecordsIcon";
import OpenDataIcon from "../../coraWebMComponents/dataDisplay/icons/OpenDataIcon";
import CatalogOpenDataIcon from "../../coraWebMComponents/dataDisplay/icons/CatalogOpenDataIcon";
import Button from "../../coraWebMComponents/inputs/Button";

const getIcon = (icon) => {
  const style = { width: '18px', height: '18px' };
  switch (icon) {
    case 'InfoIcon':
      return <InfoIcon style={style} />;
    case 'PersonIcon':
      return <PersonIcon style={style} />;
    case 'ParkingIcon':
      return <ParkingIcon style={style} />;
    case 'ParkingCard':
      return <ParkingCard style={style} />;
    case 'ParkingPermit':
      return <ParkingPermit />;
    case 'DogIcon':
      return <DogIcon style={{ ...style }} />;
    case 'AddBlackIcon':
      return <AddBlackIcon style={{ ...style }} />;
    case 'DescriptionIcon':
      return <DescriptionIcon style={style} />;
    case 'EuroIcon':
      return <EuroIcon style={style} />;
    case 'PeopleIcon':
      return <PeopleIcon style={style} />;
    case 'TicketIcon':
      return <TicketIcon style={style} />;
    case 'SetingsIcon':
      return <SetingsIcon style={style} />;
    case 'MapIcon':
      return <MapIcon style={{ ...style, stroke: "#000" }} />;
    case 'SubsidiesPublicIcon':
      return <SubsidiesIcon style={{ ...style, stroke: "#000" }} />;
    case 'SecurityIcon':
      return <SecurityIcon style={{ ...style, stroke: "#000" }} />;
    case 'BudgetIcon':
      return <BudgetIcon style={{ ...style, stroke: "#000" }} />;
    case 'AdminSegmentationIcon':
      return <AdminSegmentationIcon style={{ ...style, stroke: "#000" }} />;
    case 'DebtorsIcon':
      return <DebtorsIcon style={{ ...style, stroke: "#000" }} />;
    case 'GraveIcon':
      return <GraveIcon style={{ ...style, stroke: "#000" }} />;
    case 'CitizensIcon':
      return <CitizensIcon style={{ ...style, stroke: "#000" }} />;
    case 'TowingIcon':
      return <TowingIcon style={{ ...style, stroke: "#000" }} />;
    case 'PublicationIcon':
      return <PublicationIcon style={{ ...style, stroke: "#000" }} />;
    case 'OrganizationIcon':
      return <OrganizationIcon style={{ ...style, stroke: "#000" }} />;
    case 'FirmIcon':
      return <FirmIcon style={{ ...style, stroke: "#000" }} />;
    case 'ShopIcon':
      return <ShopIcon style={{ ...style, stroke: "#000" }} />;
    case 'SelfGovIcon':
      return <SelfGovIcon style={{ ...style, stroke: "#000" }} />;
    case 'EducationIcon':
      return <EducationIcon style={{ ...style, stroke: "#000" }} />;
    case 'SocialServiceIcon':
      return <SocialServiceIcon style={{ ...style, stroke: "#000" }} />;
    case 'ConstructionOfficeIcon':
      return <ConstructionOfficeIcon style={{ ...style, stroke: "#000" }} />;
    case 'ElectionIcon':
      return <ElectionIcon style={{ ...style, stroke: "#000" }} />;
    case 'DefectsIcon':
      return <DefectsIcon style={{ ...style, stroke: "#000" }} />;
    case 'ImportantObjectsIcon':
      return <ImportantObjectsIcon style={{ ...style, stroke: "#000" }} />;
    case 'PetitionsIcon':
      return <PetitionsIcon style={{ ...style, stroke: "#000" }} />;
    case 'RegistryRecordsIcon':
      return <RegistryRecordsIcon style={{ ...style, stroke: "#000" }} />;
    case 'OpenDataIcon':
      return <OpenDataIcon style={{ ...style, stroke: "#000" }} />;
    case 'CatalogOpenDataIcon':
      return <CatalogOpenDataIcon style={{ ...style, stroke: "#000" }} />;
    default:
      return <InfoIcon style={style} />;
  }
};

export class OptionsButtonMenuItemComponent extends React.Component {
  render() {
    const { classes, itemText, disabled, onClick, itemIcon } = this.props;

    if (disabled) {
      return (
        <MenuItem
          className={classNames(classes.rimItem)}
          onClick={() => onClick()} disabled>
          <Button
            variant="text"
            className={classNames(classes.textTransformNone, classes.bgColorTransparent)}
            startIcon={getIcon(itemIcon)}
          // startIcon={<InfoIcon />}
          >
            {itemText}
          </Button>
        </MenuItem >
      );
    }
    return (
      <MenuItem
        className={classNames(classes.rimItem)}
        onClick={() => onClick()}>
        <Button
          variant={"caption"}
          className={classNames(classes.textTransformNone, classes.bgColorTransparent)}
          startIcon={getIcon(itemIcon)}
        // startIcon={<InfoIcon />}
        >
          <Typography className={classNames(classes.rimText)} title={itemText}>
            {itemText}
          </Typography>
        </Button>
      </MenuItem>
    );
  }
}

OptionsButtonMenuItemComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  itemText: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool
};

export default OptionsButtonMenuItemComponent;
