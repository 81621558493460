import React from 'react';
import classNames from 'classnames';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';
import Snackbar from '@mui/material/Snackbar';
import SnackbarContent from '@mui/material/SnackbarContent';
import withStyles from '@mui/styles/withStyles';

const styles = theme => ({
  margin: {
    margin: theme.spacing(1),
  },
  installButton: {
    backgroundColor: theme.palette.primary.light,
    color: theme.palette.primary.contrastText,
    fontSize: theme.typography.subtitle1.fontSize,
  },
  icon: {
    fontSize: 20,
  },
  appIcon: {
    opacity: 0.9,
    marginRight: theme.spacing(1),
  },
  message: {
    display: 'flex',
    alignItems: 'center',
  },
});

let deferredPrompt;

/**
 * InstallButton component
 */
class InstallButton extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      open: false
    };
  }

  componentDidMount = () => {
    window.addEventListener('beforeinstallprompt', this.handleInstall);
  };
  componentWillUnmount() {
    window.removeEventListener('beforeinstallprompt', this.handleInstall);
  }

  handleInstall = (e) => {
    e.preventDefault();
    deferredPrompt = e;
    this.setState({ open: true });
  };

  handleInstallClick = () => {
    this.handleInstallClose();
    if (deferredPrompt == null) {
      return;
    }
    deferredPrompt.prompt();
  };

  handleInstallClose = () => {
    this.setState({ open: false });
  };

  handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    this.handleInstallClose();
  };

  render() {
    const { classes } = this.props;
    return (
      (<div style={{ opacity: 0.8 }}>
        <Snackbar
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
          open={this.state.open}
          autoHideDuration={null}
          onClose={this.handleClose}
        >
          <SnackbarContent
            className={classes.installButton}
            aria-describedby="client-snackbar"
            message={
              <span id="client-snackbar" className={classes.message} onClick={this.handleInstallClick}>
                <img src={'assets/datamesta_logo_32x32.png'} alt='datamesta' className={classNames(classes.icon, classes.appIcon)} />
                &nbsp;Pridať na plochu
              </span>
            }
            action={[
              <IconButton
                key="close"
                aria-label="Close"
                color="inherit"
                className={classes.close}
                onClick={this.handleClose}
                size="large">
                <CloseIcon className={classes.icon} />
              </IconButton>
            ]}
          />
        </Snackbar>
      </div>)
    );
  }
}



export default withStyles(styles)(InstallButton);