import RemoveIcon from '@mui/icons-material/Remove';
import PropTypes from 'prop-types';

const RemoveIconComponent = (props) => {
  return (
    <RemoveIcon
      style={props.style}
      color={props.color}
      fontSize={props.fontSize}
      variant={props.variant}
    />
  );
};

RemoveIconComponent.propTypes = {
  style: PropTypes.object,
  color: PropTypes.string,
  fontSize: PropTypes.string,
  variant: PropTypes.string,
};

export default RemoveIconComponent;