/**
 * Oreze text na dany pocet znakov a prida na koniec '...'
 * e.g.: 'abcdefgh', 5 -> 'abcde...'
  *
  *
  * @param {*} text
  * @param {*} maxLength
  * @return {*} 
  */
export const truncateText = (text, maxLength) => {
  if (typeof text !== 'string') {
    console.error(`helper.js > truncateText(): Vstupný text musí byť typu string (${maxLength}).`);
    return text;
  }
  if (text.length > maxLength) {
    return text.slice(0, maxLength) + '...';
  } else {
    return text;
  }
};
