import actionTypes from '../../actions/actionTypes';

const _initState = {
  isLoading: false,
  isLoaded: false,
  data: null
};

const ffiWaybill = (state = _initState, action) => {
  switch (action.type) {
    case actionTypes.GET_FFI_WAYBILL_REQUEST:
      return { ...state, isLoading: true };

    case actionTypes.GET_FFI_WAYBILL_SUCCESS:
      let { isLoading, isLoaded, data } = _initState;
      if (Boolean(action.res)) {
        isLoaded = true;
        data = action.res;
      }
      return { isLoading, isLoaded, data };

    case actionTypes.GET_FFI_WAYBILL_FAILURE:
      return _initState;

    default:
      return state;
  }
};

export default ffiWaybill;
