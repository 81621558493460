import { Link } from "react-router-dom";
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';

import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';

import Typography from '../../coraWebMComponents/dataDisplay/Typography';
import CircularProgress from '../../coraWebMComponents/feedBack/progress/CircularProgress';

/**
 * Baner - aktualne sluzby
 *
 * @param {*} { classes, matchesThemeMD, listSlzAkt, onClick }
 * @return {*} 
 */
const ZstActiveServices = ({ classes, _matchesThemeMD, listSlzAkt, onClick }) => {
  const date = new Date();
  // date.setDate(date.getDate() + 0 ) //posun datumu pre ladiace ucely
  const currentYear = date.getFullYear();
  const nextYear = currentYear + 1;
  const previousYear = currentYear - 1;
  const currentMonthStr = (date.getMonth() + 1).toString().padStart(2, '0');
  // const nextMonth = ((date.getMonth() + 1) % 12) + 1
  const currentDayStr = date.getDate().toString().padStart(2, '0');
  const currentFullDatum = +`${currentYear}${currentMonthStr}${currentDayStr}`;
  const currentDate = +`${currentMonthStr}${currentDayStr}`;

  const formatDates = (data) => {
    // format (M)MDD - 111 je 11.01; 1101 je 01.11; posledné 2 znaky reprezentujú deň a dalšie 1 alebo 2 zmaky su mesiac
    let newData = data;
    newData.forEach(item => {
      let itemDOd = item.DOd;
      let itemDDo = item.DDo;
      // fixni datumy v priestupny rok na 29.2. ak je v databaze 28.2.
      if (currentDate === 229 && itemDOd === 228) {
        itemDOd = 229;
      }
      if (currentDate === 229 && itemDDo === 228) {
        itemDDo = 229;
      }

      let denFrom = itemDOd.toString().slice(-2); // Extrahuj den
      let mesiacFrom = Math.floor(itemDOd / 100); // Extrahuj mesiac
      let str4From = `${mesiacFrom < 10 ? '0' : ''}${mesiacFrom}${denFrom}`;

      let denTo = itemDDo.toString().slice(-2); // Extrahuj den
      let mesiacTo = Math.floor(itemDDo / 100); // Extrahuj mesiac
      let str4To = `${mesiacTo < 10 ? '0' : ''}${mesiacTo}${denTo}`;

      // ak NIE JE prechod cez novy rok
      if (itemDOd <= itemDDo) {
        item.fullDateFrom = +`${currentYear}${str4From}`;
        item.fullDateTo = +`${currentYear}${str4To}`;
      } else {
        // ak JE prechod cez novy rok
        // ak je aktualny den medzi zaciatkom platnosti a koncom roka
        if (currentDate >= itemDOd && currentDate <= 1231) {
          item.fullDateFrom = +`${currentYear}${str4From}`;
          item.fullDateTo = +`${nextYear}${str4To}`;
        } else {
          // ak je aktualny den medzi zaciatkom roka a koncom platnosti
          item.fullDateFrom = +`${previousYear}${str4From}`;
          item.fullDateTo = +`${currentYear}${str4To}`;

        }
      }
    });
    return newData;
  };

  let listToShow = formatDates(listSlzAkt?.data);
  listToShow = listToShow.filter((item) =>
    item.fullDateFrom <= currentFullDatum && currentFullDatum <= item.fullDateTo
  );

  const LinkItem = ({ id, text = "", onClick = () => { }, _item = {} }) => {
    return (
      <Box id={id}>
        <Link
          component="button"
          variant="caption"
          onClick={() => onClick()}
          className={classNames(classes.textDecorationNone)}
        >
          <Typography variant="caption" className={classNames(classes.uxTextCaption)}>
            {text}
          </Typography>
        </Link>
      </Box>
    );
  };

  if (listToShow.length === 0) {
    return null;
  }

  return (
    <Stack
      // sx={{ backgroundColor: "#559" }}
      mt={1}
      spacing={1}
    >
      <CircularProgress loading={listSlzAkt.isLoading} />
      {
        listToShow?.map((item, key) => {
          return <LinkItem
            key={key}
            id={item.ISlzAkt}
            text={item?.Slz?.NSlz.trim()}
            item={item}
            onClick={() => onClick(item.Slz.ISlz, item.Slz.ZstZs.IZstZs)}
          />;
        }
        )
      }
    </Stack>
  );
};

ZstActiveServices.propTypes = {
  classes: PropTypes.object,
  matchesThemeMD: PropTypes.bool
};

const mapStateToProps = (state) => ({
  listSlzAkt: state.listSlzAkt,
});

const mapDispatchToProps = () => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(ZstActiveServices);