import _ from 'lodash';
import * as requests from '../coraWebMComponents/utils/requests';

export const getZstZsTree = async () => {
  try {
    return await requests.get(`/api/zst/zsTree`);
  }
  catch (error) {
    throw error;
  }
};

export const getZstZsList = async () => {
  try {

    const filter = { filters: [] };
    filter.filters.push({ field: "Zrus", operator: "eq", value: false });
    filter.filters.push({ field: "LAkt", operator: "eq", value: true });
    filter.filters.push({ field: "LZobraz", operator: "eq", value: true });

    const sort = { sorts: [] };
    sort.sorts.push({ "Field": "CZorad", "Dir": "ASC" });
    sort.sorts.push({ "Field": "IZstZs", "Dir": "ASC" });

    return await requests.get(`/api/meta/form/IZstZs?filter=${JSON.stringify(filter)}&sort=${JSON.stringify(sort)}&page=1&limit=999`);
  }
  catch (error) {
    throw error;
  }
};

export const getZstSlzList = async (flt) => {
  try {

    const filter = { filters: [] };
    const sort = { sorts: [] };

    const { searchText, selectedIDs } = flt;

    if (selectedIDs.length > 0) {
      let fltPom = { field: "ZstZs.IZstZs", operator: "eq", left: "(" };
      let fltAct;
      selectedIDs.map((x) => {
        fltAct = { value: x, ...fltPom };
        filter.filters.push(fltAct);
        fltPom = { field: "ZstZs.IZstZs", operator: "eq", join: true };
        return x;
      });
      if (fltAct)
        fltAct.right = ")";
    }

    if (Boolean(searchText) && searchText.length > 0) {
      filter.filters.push({ field: "NSlz", operator: "contains", value: searchText });
    }

    if (filter.filters.length > 0) {

      filter.filters.push({ field: "Zrus", operator: "eq", value: false });

      sort.sorts.push({ "Field": "ZstZs.CZorad", "Dir": "ASC" });
      sort.sorts.push({ "Field": "ZstZs.IZstZs", "Dir": "ASC" });
      sort.sorts.push({ "Field": "CZorad", "Dir": "ASC" });
      sort.sorts.push({ "Field": "ISlz", "Dir": "ASC" });
    } else {
      filter.filters.push({ field: "ISlz", operator: "eq", value: 0 });
    }

    return await requests.get(`/api/meta/form/ISlz?filter=${JSON.stringify(filter)}&sort=${JSON.stringify(sort)}&page=1&limit=999`);
  }
  catch (error) {
    throw error;
  }
};

export const getZstCDtzOtzList = async () => {
  try {

    const filter = { filters: [{ field: "Zrus", operator: "eq", value: false }] };

    const sort = { sorts: [{ "Field": "IDtzOtz", "Dir": "ASC" }] };

    return await requests.get(`/api/meta/form/IDtzOtz?filter=${JSON.stringify(filter)}&sort=${JSON.stringify(sort)}&page=1&limit=999`);
  }
  catch (error) {
    throw error;
  }
};

export const getZstLsOtzList = async () => {
  try {

    const filter = { filters: [] };
    filter.filters.push({ field: "Zrus", operator: "eq", value: false });
    filter.filters.push({ field: "LZobraz", operator: "eq", value: true });

    const sort = { sorts: [] };
    sort.sorts.push({ "Field": "CZorad", "Dir": "ASC" });
    sort.sorts.push({ "Field": "ILsOtz", "Dir": "ASC" });

    return await requests.get(`/api/meta/form/ILsOtz?filter=${JSON.stringify(filter)}&sort=${JSON.stringify(sort)}&page=1&limit=999`);
  }
  catch (error) {
    throw error;
  }
};

export const saveZstDtz = async (nDtzOtz, txtDtz, nZstZs, nSlz, nFeForm) => {
  try {
    let body = {
      NDtzOtz: nDtzOtz,
      TxtDtz: txtDtz,
      NZstZs: nZstZs,
      NSlz: nSlz,
      NFeForm: nFeForm
    };
    const res = await requests.post('/api/zst/saveZstDtz', body);
    return res;
  }
  catch (error) {
    throw error;
  }
};

export const SaveZstLsDtz = async (body) => {
  try {
    return await requests.post('/api/zst/SaveZstLsDtz', body);
  }
  catch (error) {
    throw error;
  }
};

export const NotifyOpenSlz = async (iSlz) => {
  try {
    return await requests.put(`/api/zst/openSlz?iSlz=${iSlz}`);
  }
  catch (error) {
    throw error;
  }
};

export const getSlzUsedList = async (byUser) => {
  try {
    let res = await requests.get(`/api/zst/usedSlz?byUser=${byUser}`);
    return res;
  }
  catch (error) {
    throw error;
  }
};

export const getSlzAktList = async () => {
  try {
    return await requests.get(`/api/zst/slzAkt`);
  }
  catch (error) {
    throw error;
  }
};

/**
 * Vytvorenie záznamu v tabuľke E_PORTAL.SP_OVER s dátami pre uloženie do sessionStorage eFormFillera.
 * @param {*} data Data pre uloženie do sessionStorage eFormFillera
 * @returns Objekt SpOverVm
 */
export const createSpOver = async (data) => {
  try {
    const args = JSON.stringify(data);
    let body = {
      IApl: 4,
      Args: args,
      Pozn: args,
      ExpTime: 5
    };
    return await requests.post(`/api/zst/createSpOver`, body);
  }
  catch (error) {
    throw error;
  }
};

export const getFFiConfig = async () => {

  try {
    return await requests.get(`/api/zst/ffiConfig`);
  }
  catch (error) {
    throw error;
  }
};

/** Handler konfigurácie FormFillera - bezpečný, doplnené DEFAULT hodnoty */
export class FFiConfig {
  /** Konštruktor */
  constructor(cfg) {
    this.value = cfg ??
    {
      externalFFi: { disabled: true, url: null }
    };
  }

  /** Indikátor externého FormFillera */
  get isExternal() { return !_.isNil(this.value.externalFFi) && !this.value.externalFFi.disabled && !_.isNil(this.value.externalFFi.url); }

  /** Url externého FormFillera */
  get externalUrl() {
    return this.isExternal ? this.value.externalFFi.url : null;
  }
};

export const getFFiWaybill = async (iFeForm) => {
  const url = `/api/zst/ffiWaybill/${iFeForm}`;
  try {
    const response = await fetch(`${window.config.url}${url}`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
        Authorization: requests.getAuthorizationHeader(),
      }
    });
    if (response.status === 200) {
      const res = await response.json();
      const resData = res?.Data;
      return resData;
    }
  }
  catch (error) {
    throw error;
  }
};
