// import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';

import RemoveIcon from '../dataDisplay/icons/RemoveIcon';
import ArrowUpwardIcon from '../dataDisplay/icons/ArrowUpwardIcon';
import ArrowDownwardIcon from '../dataDisplay/icons/ArrowDownwardIcon';
import ToolTip from './ToolTip';

/**
 * 3-stavovy prepinac so sipkou hore/dole + neutralna poloha
 *
 * @param {*} direction ('asc'/'up'/true, 'desc'/'down'/false)
 * @param {*} onClick
 * @return {*} 
 */
const SwitchUpDownArrow = ({ direction, onClick, label = { up: 'Vzostupne', down: 'Zostupne', neutral: 'Neaktívne' }, color="primary" }) => {
  let Icon;
  let ariaLabel;

  switch (direction) {
    case 'asc':
    case 'up':
    case true:
      ariaLabel = label.up;
      Icon = <ArrowUpwardIcon color={color}/>;
      break;
    case 'desc':
    case 'down':
    case false:
      ariaLabel = label.down;
      Icon = <ArrowDownwardIcon color={color} />;
      break;
    default:
      ariaLabel = label.neutral;
      Icon = <RemoveIcon />;
      break;
  }

  return (
    <ToolTip label={ariaLabel} place={"right"}>
      <IconButton size="small" aria-label={ariaLabel} onClick={onClick} variant="outlined" sx={{backgroundColor: direction !== null ? "#eee" : "inherit"}} >
        {Icon}
      </IconButton>
    </ToolTip>
  );
};

export default SwitchUpDownArrow;