import React from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';

import withStyles from '@mui/styles/withStyles';
import InputAdornment from '@mui/material/InputAdornment';
import Clear from '@mui/icons-material/Clear';

import Paper from '../../coraWebMComponents/surfaces/Paper';
import IconButton from '../../coraWebMComponents/inputs/IconButton';
import Button from '../../coraWebMComponents/inputs/Button';
import ArrowForward from '../../coraWebMComponents/dataDisplay/icons/ArrowForward';
import Typography from '../../coraWebMComponents/dataDisplay/Typography';
import Divider from '../../coraWebMComponents/dataDisplay/Divider';
import ArrowBack from '../../coraWebMComponents/dataDisplay/icons/ArrowBack';
import InfoIcon from '../../coraWebMComponents/dataDisplay/icons/InfoIcon';
import TextField from '../../coraWebMComponents/inputs/TextField';
import RaisedButton from '../../coraWebMComponents/inputs/RaisedButton';
import Autocomplete from '../../coraWebMComponents/inputs/Autocomplete';
import CircularProgress from '../../coraWebMComponents/feedBack/progress/CircularProgress';
import Switch from '../../coraWebMComponents/inputs/Switch';
import DatePickerV5 from '../../coraWebMComponents/inputs/DatePickerV5';
import Attachment from '../../coraWebMComponents/inputs/Attachment';
import AttachmentFile from '../../coraWebMComponents/inputs/AttachmentFile';
import AttachmentInput from '../../coraWebMComponents/inputs/AttachmentInput';
import FileInput from '../../coraWebMComponents/inputs/FileInput';
import SelectField from '../../coraWebMComponents/inputs/SelectField';
import Dialog from '../../coraWebMComponents/feedBack/Dialog';
import { addOffset, convertDate, } from '../../coraWebMComponents/utils/convert';
import { isValidEcv } from '../../coraWebMComponents/utils/validator';
import getSharedStyles from '../../coraWebMComponents/sharedStyles';
import { withLocalizationConsumer } from '../../coraWebMComponents/localization/withLocalization';
import MapIcon from "../../coraWebMComponents/dataDisplay/icons/MapIcon";
import withRouter from '../../coraWebMComponents/utils/withRouter';
import withWidth from '../../coraWebMComponents/views/withWidth';

import * as userActions from '../../actions/userActions';
import { downloadPDF } from '../../utils/parkingUtils';
import * as parkingActions from '../../actions/parkingActions';
import * as ciselnikyActions from '../../actions/ciselnikyActions';
import routes from '../../routes';
import ImageModal from '../attachments/ImageModal';

let _sizeAttachs = 0;
const _autocompleteCountCharStart = window.config.autocompleteCountCharStart;
const _autocompleteTimeDelay = window.config.autocompleteTimeDelayInMs;
let txtInfoPrilohy;

const _initState = {
  I_ZIADOST: 0,
  I_ZIADOST_TYP: 1,
  I_ZIADOST_STAV: 1,
  I_O: null,
  I_O_TYP: 1,
  MENO: '',
  PF: '',
  D_NAR: '',
  ICO_RC: '',
  N_M: '',
  I_ADR: null,
  adresa: {
    I_ADR: null,
    ADRESA: '',
    ZOZ_I_ZONA: ''
  },
  CB: '',
  L_VLASTNIK: true,
  L_DRZITEL: true,
  ECV: '',
  I_KARTA_TYP: null,
  I_POHON: null,
  I_KARTA_KAT: null,
  I_O_ADR_TYP: null,
  adresaDisabled: false,
  L_ZTP: false,
  D_EVID: null,
  I_ZDROJ: 1,
  I_PARK_MIESTO_GEO: null,
  ATTACHS_ZTP: [],
  ATTACHS_TP: [],
  ATTACHS_FILE: [],
  MIESTO: '',
  D_OD: null,
  POZN: '',
  errorTextEcv: '',
  errorTextKateg: '',
  errorTextDrive: '',
  errorTextAdresa: '',
  errorTextCardType: '',
  errorTextAttach_ztp: '',
  errorTextAttachTp: '',
  errorTextMiesto: '',
  errorTextPozn: '',
  activeStep: 1,
  skipped: new Set(),
  loading: true,
  L_REQUIRED_ADR: 0,//ak je viac ako nula, tak adresa je nepovinna
  bShowZtp: false,
  bShowD_OD: false,
  bShowAdrTpEmpty: false,
  bShowM: false,
  textRezident: '',
  textAbonent: '',
  textPriloha: '',
  textRezidentMesta: '',
  textTypPopis: '',
  textInfoPrilohy: '',
  doublePermitAllert: false,
  allertInfoTypKarty: false,
  infoPrilohy: false,
  zozIZones: '',
  maxDate: new Date(),
  disableNext: false
};

const styles = theme => ({
  ...getSharedStyles(theme),
  root: {

  },
  customSwitchIndicator: {
    backgroundColor: '#424242!important',
    height: '100%'
  },
  backToCardsLink: {
    marginLeft: '15px',
    padding: '20px 0 10px 0',
    [theme.breakpoints.up('md')]: {
      marginLeft: '0px',
      padding: '20px 0 20px 0',
    },
  },
  backButton: {
    backgroundColor: 'transparent!important',
    paddingLeft: '0px',
  },
  infoButton: {
    padding: '12px 14px',
  }
});

class ParkingPermit extends React.Component {
  constructor(props) {
    super(props);
    this.state = _initState;
    this.bottomRef = React.createRef();
  };
  componentDidUpdate(prevProps, prevState) {
    if (this.state.activeStep !== prevState.activeStep && this.state.activeStep === 4) {
      window.scrollTo(0, 0);
    }
  }

  componentDidMount = async () => {
    const { selectedParkPlace, parkMiesto } = this.props.location.state ?? {};
    let txtR, txtA, txtP, txtRM;

    if (!this.props.data) {
      await this.props.dispatch(ciselnikyActions.getVisibleZtp());
      await this.props.dispatch(ciselnikyActions.getVisibleD_OD());
      await this.props.dispatch(ciselnikyActions.getCityOfSetup());
      await this.props.dispatch(parkingActions.getRequiredParkingAdr());
      await this.props.dispatch(ciselnikyActions.getListDrive(''));
      await this.props.dispatch(ciselnikyActions.getListCardKat());
      await this.props.dispatch(ciselnikyActions.getVisiblePlacesMapButton());
      txtR = await this.props.translateWithLocaleAsync(this.props.locale, 'DM_PARKING_PERMIT_RESIDENT');
      txtA = await this.props.translateWithLocaleAsync(this.props.locale, 'DM_PARKING_PERMIT_ABONENT');
      txtP = await this.props.translateWithLocaleAsync(this.props.locale, 'DM_PARKING_PERMIT_ATTACHS');
      txtRM = await this.props.translateWithLocaleAsync(this.props.locale, 'DM_PARKING_PERMIT_RESIDENT_CITY');
      var bShwZtp = false;
      if (this.props.visibleZtp.Hodn) {
        if (this.props.visibleZtp.Hodn.trim() === '1')
          bShwZtp = true;
      }

      var bShwD_OD = false;
      if (this.props.visibleD_OD.Hodn) {
        if (this.props.visibleD_OD.Hodn.trim() === '1')
          bShwD_OD = true;
      }

      var bShowM = false;
      if (this.props.visiblePlacesMapButton.HODN) {
        if (this.props.visiblePlacesMapButton.HODN.trim() === '1')
          bShowM = true;
      }

      if (this.props.listCardKat.length === 1)
        this.handleChange('I_KARTA_KAT', this.props.listCardKat[0].I_KARTA_KAT);

      const maxDat = new Date();
      maxDat.setFullYear(maxDat.getFullYear() + 10);
      this.setState({
        I_O: this.props.person.I_O,
        I_O_TYP: this.props.person.I_O_TYP,
        MENO: this.props.person.MENO,
        PF: this.props.person.PF,
        D_NAR: convertDate(this.props.person.D_NAR),
        ICO_RC: this.props.person.ICO_RC,
        N_M: this.props.cityOfSetup.HODN,
        L_REQUIRED_ADR: parseInt(this.props.requiredParkingAdr.HODN),
        bShowZtp: bShwZtp,
        bShowD_OD: bShwD_OD,
        bShowM: bShowM,
        textRezident: txtR,
        textAbonent: txtA,
        textPriloha: txtP,
        textRezidentMesta: txtRM,
        maxDate: maxDat
      });
    } else {
      this.setState({
        ...this.props.data,
      }, () => {
        this.scrollToBottom();
      });
      this.props.dispatch(parkingActions.changeParkingPermitData(null));
    }
    if (selectedParkPlace) {
      this.setState({
        MIESTO: parkMiesto.cParkMiesto,
        I_PARK_MIESTO_GEO: parkMiesto.key
      });

      const kat = this.props.listCardKat.find(kat => kat.I_KARTA_KAT === 3);
      if (kat) {
        this.handleChange('I_KARTA_KAT', kat.I_KARTA_KAT);
      }
    }
    this.setState({ loading: false });
  };

  scrollToBottom = () => {
    if (this.bottomRef.current) {
      this.bottomRef.current.scrollIntoView();
    }
  };

  handleChange = async (name, value) => {
    switch (name) {
      case 'ECV':
        value = value.toUpperCase();
        value = value.trim();
        this.setState({ errorTextEcv: value ? isValidEcv(value) : 'Evidené číslo vozidla je povinný údaj!' });
        break;
      case 'I_POHON':
        this.setState({ I_POHON: value.I_POHON, errorTextPohon: value ? '' : 'Typ pohonu motorového vozidla je povinný údaj!' });
        break;
      case 'I_KARTA_KAT':
        this.setState({ I_KARTA_KAT: value, errorTextKateg: value ? '' : 'Kategória parkovacej katry je povinný údaj!' });
        this.setKateg(value);

        if (value === 1) {
          txtInfoPrilohy = await this.props.translateWithLocaleAsync(this.props.locale, 'DM_PARKING_PERMIT_ATTACHS_RESIDENT');
        }
        else if (value === 2 || value === 5) {
          txtInfoPrilohy = await this.props.translateWithLocaleAsync(this.props.locale, 'DM_PARKING_PERMIT_ATTACHS_ABONENT');
        }
        else if (value === 3) {
          txtInfoPrilohy = await this.props.translateWithLocaleAsync(this.props.locale, 'DM_PARKING_PERMIT_ATTACHS_VPM');
        }
        else if (value === 4) {
          txtInfoPrilohy = await this.props.translateWithLocaleAsync(this.props.locale, 'DM_PARKING_PERMIT_ATTACHS_RZBA');
        }
        else if (value === 6) {
          txtInfoPrilohy = await this.props.translateWithLocaleAsync(this.props.locale, 'DM_PARKING_PERMIT_ATTACHS_RESIDENT_CITY');
        }
        else txtInfoPrilohy = '';
        this.setState({ textInfoPrilohy: txtInfoPrilohy[0] });

        break;
      case 'adresa':
        if (value) {
          if (value.I_ADR) {

            this.setState({ I_ADR: value.I_ADR, adresa: value, errorTextAdresa: '', I_KARTA_TYP: null });
            let zozIZona = 'all';

            if (this.state.L_REQUIRED_ADR < 2) {
              if (value.ZOZ_I_ZONA)
                zozIZona = value.ZOZ_I_ZONA;
            }
            this.props.dispatch(ciselnikyActions.getListCardType(zozIZona, this.props.person.I_O_TYP, this.state.I_KARTA_KAT ? this.state.I_KARTA_KAT : 0));
          }
          else
            await this.setKateg(this.state.I_KARTA_KAT);
        }
        else
          await this.setKateg(this.state.I_KARTA_KAT);

        break;
      case 'I_KARTA_TYP':
        this.setState({ I_KARTA_TYP: value.I_KARTA_TYP, errorTextCardType: value ? '' : 'Typ karty je povinný údaj!', textTypPopis: this.GetTypPopis(value) });
        break;
      case 'CB':
        if (value.length > 5)
          value = value.substr(0, 5);

        break;
      case 'MIESTO':
        this.setState({ errorTextMiesto: value ? '' : 'Číslo parkovacieho miesta je povinný údaj!', I_PARK_MIESTO_GEO: null });
        break;
      case 'POZN':
        this.setState({ errorTextPozn: value ? (value.length > 3997 ? 'Maximálny počet znakov 4000!' : '') : '' });
        break;
      default:
    }
    this.setState({ [name]: value });
  };

  GetTypPopis = (iKartaTyp) => {
    let retTxt = null;
    if (this.props.listCardType.length > 0) {
      this.props.listCardType.forEach(getPopis);

      function getPopis(value) {
        if (value.I_KARTA_TYP === iKartaTyp)
          retTxt = value.POPIS;

      }
    }
    return retTxt;
  };

  handleSearchAdresa = (searchText) => {
    switch (this.state.I_KARTA_KAT) {
      case 1:
      case 4:
        if (this.state.L_VLASTNIK) {
          this.props.dispatch(ciselnikyActions.getListAddressPerson(searchText, this.props.person.I_O, this.state.I_O_ADR_TYP));
        } else {
          this.props.dispatch(ciselnikyActions.getListAddressByZoneList(searchText, this.state.zozIZones));
        }
        break;
      case 3:
        if (this.state.L_VLASTNIK)
          this.props.dispatch(ciselnikyActions.getListAddressByIAdrList(searchText, this.props.person.I_ADR_ALL || '0'));
        else
          this.props.dispatch(ciselnikyActions.getListAddressByZoneList(searchText, this.state.zozIZones));
        break;
      default:
        this.props.dispatch(ciselnikyActions.getListAddress(searchText));
    }
  };

  handleCloseAlertDouble = () => {
    this.setState({ doublePermitAllert: false });
  };

  handleOpenAllertInfoTypKarty = () => {
    this.setState({ allertInfoTypKarty: true });
  };

  handleCloseAllertInfoTypKarty = () => {
    this.setState({ allertInfoTypKarty: false });
  };

  handleOpenInfoPrilohy = () => {
    this.setState({ infoPrilohy: true });
  };

  handleCloseInfoPrilohy = () => {
    this.setState({ infoPrilohy: false });
  };

  handleAdd = async (testDouble) => {
    this.setState({ loading: true });
    const Ecv = this.state.ECV;
    const Ipohon = this.state.I_POHON;
    const AttachsZtp = this.state.ATTACHS_ZTP;
    const AttachsTp = this.state.ATTACHS_TP;
    const AttachsFile = this.state.ATTACHS_FILE;
    const I_karta_typ = this.state.I_KARTA_TYP;
    const I_karta_kat = this.state.I_KARTA_KAT;
    const errorTextEcv = Ecv ? isValidEcv(Ecv) : (I_karta_kat === 4 || I_karta_kat === 5 ? '' : 'Evidené číslo vozidla je povinný údaj!');
    const errorTextPohon = Ipohon || I_karta_kat === 4 || I_karta_kat === 5 ? '' : 'Typ pohonu motorového vozidla je povinný údaj!';
    const errorTextAttachTp = _sizeAttachs === 0 && I_karta_kat !== 4 && I_karta_kat !== 5 ? 'Prílohy sú povinné!' : this.state.errorTextAttachTp;
    const errorTextAttachZtp = AttachsZtp.length === 0 && this.state.L_ZTP ? 'Fotografia preukazu ŤZP a EPP je povinná!' : '';
    const errorTextCardType = I_karta_typ ? '' : 'Typ karty je povinný údaj!';
    const errorTextKateg = I_karta_kat ? '' : 'Kategória parkovacej katry je povinný údaj!';
    const errorTextAdresa = !this.state.I_ADR && this.state.L_REQUIRED_ADR === 0 ? 'Adresa karty je povinný údaj!' : '';
    const errorTextMiesto = this.state.I_KARTA_KAT === 3 && !this.state.MIESTO ? 'Číslo parkovacieho miesta je povinný údaj!' : '';
    const doublePermitAllert = testDouble === 0 ? await this.props.dispatch(parkingActions.unikParkingPermit(this.state)) : false;
    const errorTextPozn = this.state.POZN ? (this.state.POZN.length > 3997 ? 'Maximálny počet znakov 4000!' : '') : '';

    this.setState({ errorTextEcv, errorTextPohon, errorTextAttachZtp, errorTextAdresa, errorTextCardType, errorTextAttachTp, errorTextKateg, errorTextMiesto, doublePermitAllert, errorTextPozn });

    if (!errorTextEcv &&
      !errorTextPohon &&
      !errorTextAttachTp &&
      !errorTextAttachZtp &&
      !errorTextAdresa &&
      !errorTextCardType &&
      !errorTextKateg &&
      !errorTextMiesto &&
      !doublePermitAllert &&
      !errorTextPozn) {

      let AttachsZtpNew = AttachsZtp;
      for (let i = 0; i < AttachsZtp.length; i++) {
        AttachsZtpNew[i].data = AttachsZtp[i].data.replace(/^.+,/, '');
      }

      let AttachsTpNew = AttachsTp;
      for (let i = 0; i < AttachsTp.length; i++) {
        AttachsTpNew[i].data = AttachsTp[i].data.replace(/^.+,/, '');
      }

      let AttachsFileNew = AttachsFile;
      for (let i = 0; i < AttachsFile.length; i++) {
        AttachsFileNew[i].data = AttachsFile[i].data.replace(/^.+,/, '');
      }

      const data = {
        ...this.state,
        ATTACHS_ZTP: AttachsZtpNew, ATTACHS_TP: AttachsTpNew, ATTACHS_FILE: AttachsFileNew,
        D_EVID: addOffset(new Date()),
        D_OD: this.state.D_OD ? addOffset(new Date(this.state.D_OD)) : null,
      };

      const res = await this.props.dispatch(parkingActions.AddParkingPermit(data));

      if (res) {
        this.setState(_initState);
        this.setState({ activeStep: 3 });
      }
      _sizeAttachs = 0;
    }
    else {
      if (errorTextEcv || errorTextPohon || errorTextAttachTp || errorTextKateg)
        this.setState({ activeStep: 1 });
      else
        this.setState({ activeStep: 2 });
    }
    this.setState({ loading: false });
  };

  handleNext = () => {
    const { activeStep } = this.state;
    let { skipped } = this.state;
    if (this.isStepSkipped(activeStep)) {
      skipped = new Set(skipped.values());
      skipped.delete(activeStep);
    }
    this.setState({
      activeStep: activeStep + 1,
      skipped,
    });
  };

  handleGoToReview = () => {
    const { activeStep } = this.state;
    let { skipped } = this.state;
    if (this.isStepSkipped(activeStep)) {
      skipped = new Set(skipped.values());
      skipped.delete(activeStep);
    }
    this.setState({
      activeStep: 4,
      skipped,
    });
  };

  handleGoBackFromReview = () => {
    const { activeStep } = this.state;
    let { skipped } = this.state;
    if (this.isStepSkipped(activeStep)) {
      skipped = new Set(skipped.values());
      skipped.delete(activeStep);
    }
    this.setState({
      activeStep: 2,
      skipped,
    });
  };

  handleBack = () => {
    if (this.state.activeStep === 1) {
      this.setState(_initState);
      this.props.navigate(routes.HOME);
    }
    else {
      this.setState(state => ({
        activeStep: state.activeStep - 1,
      }));
    }
  };

  isStepSkipped = (step) => {
    return this.state.skipped.has(step);
  };

  handleGoHome = () => {
    this.setState(_initState);
    this.props.navigate(routes.PARKING);
  };

  handleChangeSwitch = name => event => {
    this.setState({ [name]: event.target.checked });
  };

  setKateg = async (value) => {
    await this.setNullAdr();

    if (!value)
      return;

    const L_TP = this.props.listCardKat.find(x => x.I_KARTA_KAT === value).L_TP;
    const L_PP = this.props.listCardKat.find(x => x.I_KARTA_KAT === value).L_PP;

    const I_O_ADR_TYP = (L_TP && !L_PP) || (!L_TP && !L_PP) ? L_TP ? 1 : 2 : null;

    const shouldCheckPobyt = [1, 4, 6].includes(value);

    if (shouldCheckPobyt) {
      await this.props.dispatch(ciselnikyActions.getListAddressPerson(null, this.props.person.I_O, I_O_ADR_TYP));

      if (this.props.listAdresaCount === 0) {
        const errorTextAdresa = [1, 4].includes(value) ?
          'Vaša adresa trvalého, resp. prechodného pobytu nie je zaradená do žiadnej zóny!'
          :
          'Vaša adresa trvalého, resp.prechodného pobytu nepatrí mestu!';

        this.setState({ errorTextAdresa, adresaDisabled: true });
        return;
      }
    }

    let ZOZ_I_ZONA = value === 6 ? 'some' : 'all';
    let bShowAdrTpEmp = false;

    if ([4, 5].includes(value))
      this.setState({ ECV: '', I_POHON: null, ATTACHS_TP: [], ATTACHS_FILE: [], errorTextEcv: '', errorTextDrive: '', errorTextAttachTp: '' });

    if (value === 3) {
      ZOZ_I_ZONA = '';
      if (this.props.person.I_ADR_ALL) {
        let I_ADR_ALL = this.props.person.I_ADR_ALL.split(',');

        if (I_ADR_ALL.length === 1) {
          await this.props.dispatch(ciselnikyActions.getAdress(this.props.person.I_ADR_ALL));
          this.setState({ I_ADR: this.props.person.I_ADR_ALL, adresa: this.props.adresa, errorTextAdresa: '' });

          if (this.props.adresa.ZOZ_I_ZONA) {
            ZOZ_I_ZONA = this.props.adresa.ZOZ_I_ZONA;
          }
        } else if (I_ADR_ALL.length > 1) {
          for (let i = 0; i < I_ADR_ALL.length; i++) {
            await this.props.dispatch(ciselnikyActions.getAdress(I_ADR_ALL[i]));

            if (this.props.adresa.ZOZ_I_ZONA) {
              if (ZOZ_I_ZONA !== '') {
                ZOZ_I_ZONA += ',';
              }
              ZOZ_I_ZONA += this.props.adresa.ZOZ_I_ZONA;
            }
          }
        }
      }

      if (ZOZ_I_ZONA === '' || ZOZ_I_ZONA === ',') {
        ZOZ_I_ZONA = '9999999';
        bShowAdrTpEmp = true;
      }
    }

    if ([1, 4].includes(value)) {
      ZOZ_I_ZONA = '';

      for (let i = 0; i < this.props.listAdresaCount; i++) {
        const address = this.props.listAdresa[i];

        if (address.ZOZ_I_ZONA) {
          if (ZOZ_I_ZONA !== '') {
            ZOZ_I_ZONA += ',';
          }
          ZOZ_I_ZONA += address.ZOZ_I_ZONA;
        }
      }
    }

    if (value === 1) {
      if (this.props.person.I_ADR) {
        await this.props.dispatch(ciselnikyActions.getAdress(this.props.person.I_ADR));
      }
      if (this.props.adresa) {
        this.setState({ I_ADR: this.props.person.I_ADR, adresa: this.props.adresa, errorTextAdresa: '' });
      }
    }

    // Rezident mesta - adresu predplname len ak nerozlisujeme typ pobytu
    if ((value === 6 && ((!L_PP && !L_TP) || (L_PP && L_TP)))) {
      if (this.props.person.I_ADR) {
        await this.props.dispatch(ciselnikyActions.getAdress(this.props.person.I_ADR));
      }
      if (this.props.adresa) {
        this.setState({ I_ADR: this.props.person.I_ADR, adresa: this.props.adresa, errorTextAdresa: '' });
      }
    }

    if (ZOZ_I_ZONA === '') {
      this.props.dispatch(userActions.showErrorMsg("Adresa nie je zaradená do žiadnej zony, vyberte inú kategóriu"));
      this.setState({ disableNext: true });
      return;
    }

    await this.props.dispatch(ciselnikyActions.getListCardType(ZOZ_I_ZONA, this.props.person.I_O_TYP, value));
    this.setState({ bShowAdrTpEmpty: bShowAdrTpEmp, zozIZones: ZOZ_I_ZONA === 'all' || ZOZ_I_ZONA === '9999999' ? '' : ZOZ_I_ZONA, adresaDisabled: false, I_O_ADR_TYP, disableNext: false });
  };

  setNullAdr = async () => {
    await this.props.dispatch(ciselnikyActions.resetAddress());
    this.setState({
      I_ADR: null,
      adresa: { I_ADR: null, ADRESA: '', ZOZ_I_ZONA: '' },
      I_KARTA_TYP: null,
      textTypPopis: null,
    });
  };

  openModal = (imgSrc) => {
    this.setState({ showModal: true, currentImage: imgSrc });
  };

  closeModal = () => {
    this.setState({ showModal: false, currentImage: '' });
  };

  handleFileClick = (file) => {
    if (file.type === 'application/pdf') {
      downloadPDF(file.data, file.name);
    } else if (['image/jpeg', 'image/jpg', 'image/png'].includes(file.type)) {
      this.openModal(`${file.data}`);
    }
  };

  handleChangeImageZtp = (attach) => {
    this.setState(prevState => ({ ATTACHS_ZTP: [...prevState.ATTACHS_ZTP, attach], errorTextAttachZtp: '' }));
  };

  handleRemoveImageZtp = (index) => {
    this.setState(prevState => ({
      ATTACHS_ZTP: [
        ...prevState.ATTACHS_ZTP.slice(0, index),
        ...prevState.ATTACHS_ZTP.slice(index + 1)
      ]
    }));
  };

  handleMap = () => {
    this.props.dispatch(parkingActions.changeParkingPermitData(this.state));
    this.props.navigate(routes.MAP);
  };

  handleChangeImageTp = (attach) => {
    this.setState(prevState => ({ ATTACHS_TP: [...prevState.ATTACHS_TP, attach] }));
    this.validAttachs(true, attach.size);
  };

  handleRemoveImageTp = (index) => {
    this.setState(prevState => ({
      ATTACHS_TP: [
        ...prevState.ATTACHS_TP.slice(0, index),
        ...prevState.ATTACHS_TP.slice(index + 1)
      ]
    }));

    const item = this.state.ATTACHS_TP[index];
    this.validAttachs(false, item.size);
  };

  handleChangeFile = (attach) => {
    const ATTACHS_FILE = this.state.ATTACHS_FILE;
    for (let i = 0; i < ATTACHS_FILE.length; i++) {
      if (ATTACHS_FILE[i].name === attach.name) {
        return;
      }
    }
    this.setState(prevState => ({ ATTACHS_FILE: [...prevState.ATTACHS_FILE, attach] }));
    this.validAttachs(true, attach.size);
  };

  handleRemoveFile = (index) => {
    this.setState(prevState => ({
      ATTACHS_FILE: [
        ...prevState.ATTACHS_FILE.slice(0, index),
        ...prevState.ATTACHS_FILE.slice(index + 1)
      ]
    }));

    const item = this.state.ATTACHS_FILE[index];
    this.validAttachs(false, item.size);
  };

  validAttachs = (add, sizeItem) => {
    if (add)
      _sizeAttachs = _sizeAttachs + sizeItem;
    else
      _sizeAttachs = _sizeAttachs - sizeItem;

    let txtErr = '';
    if (_sizeAttachs <= 0)
      txtErr = 'Prílohy sú povinné!';

    if (_sizeAttachs > window.config.maxUploadSize) {
      let sizeInMB = (window.config.maxUploadSize / 1024) / 1024;
      txtErr = `Prekročený limit na prílohy! Max. ${sizeInMB}MB!`;
    }

    this.setState({ errorTextAttachTp: txtErr });
  };


  render() {
    const { classes, isMobile } = this.props;
    const { showModal, currentImage } = this.state;
    return (<>
      <div className='container w-margin'>
        <ImageModal show={showModal} onClose={this.closeModal} image={currentImage} />
        <CircularProgress loading={this.state.loading} />
        <Paper className={classes.paperCard} elevation={isMobile ? 0 : 2}>
          <React.Fragment>
            <div >
              <div className={classNames(classes.row, classes.navigationBackButton, classes.alignItemsCenter, classes.justifyContentBetween, classes.mb2)}>
                {(this.state.activeStep === 2 || this.state.activeStep === 4) &&
                  <div >
                    <IconButton
                      className={classNames(classes.backButton)}
                      color="inherit"
                      aria-label="Späť"
                      onClick={this.state.activeStep === 2 ? this.handleBack : this.handleGoBackFromReview}
                      toolTip="Späť"
                      toolTipPlace="bottom"
                      size="large">
                      <ArrowBack
                        fontSize='default'
                        variant='fab'
                      />
                    </IconButton>
                  </div>
                }
                {(this.state.activeStep === 1 || this.state.activeStep === 2) && <Typography variant="subtitle1">Žiadosť o vydanie</Typography>}
                {this.state.activeStep === 4 && <Typography variant="subtitle1">Náhľad</Typography>}
                <Typography variant="body1" className={classNames(classes.mlAuto)} style={{ opacity: this.state.activeStep === 4 || this.state.activeStep === 3 ? "0" : "1" }}><b>{this.state.activeStep}</b>/2</Typography>
              </div>
              <Divider />
            </div>

            {(this.state.activeStep === 1 || this.state.activeStep === 4) &&
              <div >
                <SelectField
                  disabled={this.state.activeStep === 4}
                  errorText={this.state.errorTextKateg}
                  error={this.state.errorTextKateg ? true : false}
                  floatingLabelText=" Kategória"
                  value={this.state.I_KARTA_KAT}
                  onChange={this.handleChange}
                  fullwidth={true}
                  schema={{ label: 'N_KARTA_KAT', value: 'I_KARTA_KAT' }}
                  data={this.props.listCardKat}
                  name='I_KARTA_KAT'
                  className={classNames(classes.mt3)}
                />
                {this.state.I_KARTA_KAT !== 4 && this.state.I_KARTA_KAT !== 5 &&
                  <div>
                    <Typography variant="subtitle2" className={classNames(classes.textCenter, classes.mb3, classes.mt4)}>
                      Údaje o vozidle
                    </Typography>
                    <div className={classes.row + ' ' + classes.alignItemsCenter}>
                      <Switch
                        disabled={this.state.activeStep === 4}
                        color='secondary'
                        checked={this.state.L_DRZITEL}
                        onChange={this.handleChangeSwitch('L_DRZITEL')}
                        value='L_DRZITEL'
                        label={this.state.L_DRZITEL ? 'Som držiteľom vozidla' : 'Nie som držiteľom vozidla'}
                      />
                    </div>
                    <div >
                      <TextField
                        disabled={this.state.activeStep === 4}
                        label="EČV"
                        fullwidth={true}
                        value={this.state.ECV}
                        errorText={this.state.errorTextEcv}
                        error={this.state.errorTextEcv ? true : false}
                        onChange={this.handleChange}
                        name='ECV'
                        variant="standard"
                      />
                    </div>
                    <div className={classes.mt0}>
                      <SelectField
                        disabled={this.state.activeStep === 4}
                        errorText={this.state.errorTextPohon}
                        error={this.state.errorTextPohon ? true : false}
                        floatingLabelText="Pohon"
                        value={this.state.I_POHON}
                        onChange={this.handleChange}
                        fullwidth={true}
                        schema={{ label: 'N_POHON', value: 'I_POHON' }}
                        data={this.props.listDrive}
                        name='I_POHON'
                        className={classNames(classes.mt3)}
                      />
                    </div>
                  </div>
                }
                <div className={classNames(classes.mt3, classes.row, classes.alignItemsCenter)}>
                  <Typography style={{ color: "rgba(0, 0, 0, 0.54)", fontSize: "1.07rem", lineHeight: "1" }}>Prílohy</Typography>
                  <div className={classNames(classes.row)}>
                    <FileInput
                      disabled={this.props.disabled || this.state.activeStep === 4}
                      onChange={this.handleChangeFile}
                      mimetype={"application/pdf, image/jpeg, image/jpg"}
                      title={'súbory typu PDF, JPG, JPEG'}
                      iconType={2}
                    />
                    <AttachmentInput
                      disabled={this.props.disabled || this.state.activeStep === 4}
                      onChange={this.handleChangeImageTp}
                      iconType={1}
                    />
                    {this.state.I_KARTA_KAT !== null &&
                      <IconButton
                        color="secondary"
                        onClick={this.handleOpenInfoPrilohy}
                        toolTip="Informácia"
                        toolTipPlace="right-start"
                        className={classes.infoButton}
                        size="large"
                      >
                        <InfoIcon color='secondary' style={{ width: '28px', height: '28px' }} variant='fab' />
                      </IconButton>
                    }
                  </div>
                </div>
                <div className='attachments'>
                  {this.state.ATTACHS_TP.map((image, index) => {
                    return <Attachment
                      disabled={this.props.disabled || this.state.activeStep === 4}
                      key={index}
                      image={image}
                      onClick={() => { this.handleFileClick(image); }}
                      onRemove={() => this.handleRemoveImageTp(index)}
                    />;
                  }
                  )}
                </div>
                <div className='attachments'>
                  {this.state.ATTACHS_FILE.map((file, index) =>
                    <div key={index}
                      onClick={() => this.handleFileClick(file)}
                      style={{
                        cursor: 'pointer !important',
                        display: 'inline-block !important',
                        marginRight: '10px'
                      }}
                    >

                      <AttachmentFile
                        disabled={this.props.disabled || this.state.activeStep === 4}
                        key={index}
                        image={file}
                        onClick={() => this.handleFileClick(file)}

                        onRemove={() => this.handleRemoveFile(index)}
                      />
                    </div>
                  )}
                </div>
                {this.state.errorTextAttachTp &&
                  <Typography style={{ color: 'red' }}>{this.state.errorTextAttachTp}</Typography>
                }
                {this.state.activeStep !== 4 && <div>
                  {this.state.textRezident.length > 0 &&
                    <div className={classNames(classes.ml3, classes.mr3, classes.mt2)} >
                      <Typography variant="caption" >
                        {this.state.textRezident}
                      </Typography>
                    </div>
                  }
                  {this.state.textAbonent.length > 0 &&
                    <div className={classNames(classes.ml3, classes.mr3, classes.mt2)}>
                      <Typography variant="caption" >
                        {this.state.textAbonent}
                      </Typography>
                    </div>
                  }
                  {this.state.textPriloha.length > 0 &&
                    <div className={classNames(classes.ml3, classes.mr3, classes.mt2)}>
                      <Typography variant="caption" >
                        {this.state.textPriloha}
                      </Typography>
                    </div>
                  }
                  {this.state.textRezidentMesta.length > 0 &&
                    <div className={classNames(classes.ml3, classes.mr3, classes.mt2)}>
                      <Typography variant="caption" >
                        {this.state.textRezidentMesta}
                      </Typography>
                    </div>
                  }
                </div>}
                {this.state.activeStep !== 4 && <div className='center' style={{ marginTop: '20px', marginBottom: '20px' }}>
                  <Button
                    color="primary"
                    aria-label="Ďalej"
                    disabled={this.state.disableNext}
                    onClick={this.handleNext}
                    style={{ backgroundColor: 'transparent!important' }}
                  >
                    <img style={{ width: 90, height: 90, ...(this.state.disableNext ? { opacity: 0.4 } : {}) }} src={'assets/arrow_forward-white.svg'} alt='Ďalej' />
                  </Button>
                </div>}
              </div>
            }
            {(this.state.activeStep === 2 || this.state.activeStep === 4) &&
              <div>
                {this.state.I_O_TYP === 1 &&
                  <div >
                    <Typography variant="subtitle2" className={classNames(classes.textCenter, classes.mt3, classes.mb2)}>
                      Osobné údaje
                    </Typography>
                    <div>
                      <TextField
                        disabled={true}
                        fullwidth={true}
                        label="Meno"
                        value={this.state.MENO}
                        name='MENO'
                        onChange={this.handleChange}
                        variant="standard"
                      />
                    </div>
                    <div className={classes.mt0}>
                      <TextField
                        disabled={true}
                        fullwidth={true}
                        label="Priezvisko"
                        value={this.state.PF}
                        name='PF'
                        className={classNames(classes.mt2)}
                        onChange={this.handleChange}
                        variant="standard"
                      />
                    </div>
                    <div className={classes.mt0}>
                      <TextField
                        disabled={true}
                        label="Dátum narodenia"
                        fullwidth={true}
                        value={this.state.D_NAR}
                        name='D_NAR'
                        className={classNames(classes.mt2)}
                        onChange={this.handleChange}
                        variant="standard"
                      />
                    </div>
                    <div className={classes.mt0}>
                      <TextField
                        disabled={true}
                        label="Rodné číslo"
                        fullwidth={true}
                        value={this.state.ICO_RC}
                        name='ICO_RC'
                        className={classNames(classes.mt2)}
                        onChange={this.handleChange}
                        variant="standard"
                      />
                    </div>
                    {this.state.bShowZtp &&
                      <div>
                        <div className={classes.row + ' ' + classes.alignItemsCenter} style={{ marginTop: '15px' }}>
                          <Switch
                            disabled={this.state.activeStep === 4}
                            color='secondary'
                            checked={this.state.L_ZTP}
                            onChange={this.handleChangeSwitch('L_ZTP')}
                            value='L_ZTP'
                            label={this.state.L_ZTP ? 'Som osoba ŤZP' : 'Nie som osoba ŤZP'}
                          />
                        </div>
                        {this.state.L_ZTP &&
                          <div>
                            <div className='row' style={{ alignItems: 'center', justifyContent: 'start' }}>
                              <AttachmentInput
                                disabled={this.props.disabled || this.state.activeStep === 4}
                                onChange={this.handleChangeImageZtp}
                              />
                              <Typography >fotografia preukazu ŤZP a EPP</Typography>
                            </div>
                            {this.state.errorTextAttachZtp &&
                              <Typography style={{ color: 'red' }}> {this.state.errorTextAttachZtp}</Typography>
                            }
                            <div className='attachments'>
                              {this.state.ATTACHS_ZTP.map((image, index) => {
                                return < Attachment
                                  disabled={this.props.disabled || this.state.activeStep === 4}
                                  key={index}
                                  image={image}
                                  onClick={() => { this.handleFileClick(image); }}
                                  onRemove={() => this.handleRemoveImageZtp(index)}
                                />;
                              }
                              )}
                            </div>
                          </div>
                        }
                      </div>
                    }
                  </div>
                }
                {this.state.I_O_TYP === 2 &&
                  <div >
                    <Typography variant="subtitle2" className={classNames(classes.textCenter, classes.mt3, classes.mb2)}>
                      Údaje o spoločnosti, živnosti:
                    </Typography>
                    <div >
                      <TextField
                        disabled={true}
                        fullwidth={true}
                        label="Názov firmy"
                        value={this.state.PF}
                        name='PF'
                        variant="standard"
                      />
                    </div>
                    <div className={classes.mt0}>
                      <TextField
                        disabled={true}
                        label="IČO"
                        fullwidth={true}
                        value={this.state.ICO_RC}
                        name='ICO_RC'
                        className={classNames(classes.mt2)}
                        onChange={this.handleChange}
                        variant="standard"
                      />
                    </div>

                  </div>
                }
                <Typography variant="subtitle2" className={classNames(classes.textCenter, classes.mt3)}>
                  Zóna parkovania
                </Typography>
                {([1, 3, 4].includes(this.state.I_KARTA_KAT)) &&
                  <div className={classes.row + ' ' + classes.alignItemsCenter} style={{ marginTop: '15px' }}>
                    <Switch
                      disabled={this.state.activeStep === 4}
                      color='secondary'
                      checked={this.state.L_VLASTNIK}
                      onChange={this.handleChangeSwitch('L_VLASTNIK')}
                      value='L_VLASTNIK'
                      label={this.state.L_VLASTNIK ? 'Som majiteľ nehnuteľnosti' : 'Nie som majiteľ nehnuteľnosti'}
                    />
                  </div>
                }
                <div >
                  <TextField
                    disabled={true}
                    label="Obec"
                    fullwidth={true}
                    value={this.state.N_M}
                    name='N_M'
                    onChange={this.handleChange}
                    variant="standard"
                  />
                </div>
                {this.state.I_KARTA_KAT && this.state.L_REQUIRED_ADR < 2 &&
                  <div className={classes.mt0}>
                    <Autocomplete
                      disabled={this.state.activeStep === 4 || this.state.adresaDisabled}
                      errorText={this.state.errorTextAdresa}
                      error={this.state.errorTextAdresa ? true : false}
                      data={this.props.listAdresa}
                      schema={{ label: 'ADRESA', value: 'I_ADR' }}
                      onChange={this.handleSearchAdresa}
                      onSelect={this.handleChange}
                      selectedItem={this.state.adresa}
                      label="Ulica (časť obce), súpisné/orientačné číslo"
                      name='adresa'
                      className={classNames(classes.mt2)}
                      countCharStart={_autocompleteCountCharStart}
                      timeDelay={_autocompleteTimeDelay}
                    />
                  </div>}
                <div className={classes.mt0}>
                  <TextField
                    disabled={this.state.activeStep === 4}
                    label="Číslo bytu"
                    fullwidth={true}
                    value={this.state.CB}
                    onChange={this.handleChange}
                    name='CB'
                    className={classNames(classes.mt2)}
                    variant="standard"
                  />
                </div>
                {this.state.I_KARTA_KAT === 3 &&
                  <>
                    <div className={classes.mt2}>
                      {this.state.bShowM &&
                        <Button
                          type="success"
                          variant="contained"
                          color="secondary"
                          size="large"
                          className={classNames(classes.w100)}
                          onClick={this.handleMap}
                        >
                          <MapIcon
                            className={classNames(classes.mr2)}
                          />
                          Výber miesta z mapy
                        </Button>
                      }
                      <TextField
                        disabled={this.state.activeStep === 4}
                        label="Číslo parkovacieho miesta"
                        errorText={this.state.errorTextMiesto}
                        error={this.state.errorTextMiesto ? true : false}
                        fullwidth={true}
                        value={this.state.MIESTO}
                        onChange={this.handleChange}
                        name='MIESTO'
                        className={classNames(classes.mt1)}
                        inputProps={{
                          endAdornment: (
                            <InputAdornment style={{ position: 'absolute', right: '0px' }}>
                              <Button
                                disabled={false}
                                style={{ width: '14px', height: '14px', minWidth: '22px', minHeight: '22px' }}
                                onClick={() => { this.setState({ MIESTO: '' }); }}
                              >
                                <Clear />
                              </Button>
                            </InputAdornment>
                          ),
                        }}
                        variant="standard"
                      />
                    </div>
                  </>
                }
                <div className={classNames(classes.row, classes.mt0)}>
                  <SelectField
                    disabled={[2, 5, 6].includes(this.state.I_KARTA_KAT) ? false : (this.state.I_KARTA_KAT && (this.state.L_REQUIRED_ADR > 0 || (this.state.L_REQUIRED_ADR === 0 && this.state.I_ADR)) ? false : true) || this.state.activeStep === 4}
                    errorText={this.state.errorTextCardType}
                    error={this.state.errorTextCardType ? true : false}
                    floatingLabelText="Typ karty"
                    value={this.state.I_KARTA_TYP}
                    onChange={this.handleChange}
                    fullwidth={true}
                    schema={{ label: 'N_KARTA_TYP', value: 'I_KARTA_TYP' }}
                    data={this.props.listCardType}
                    name='I_KARTA_TYP'
                    className={classNames(classes.mt3)}
                  />

                  {this.state.textTypPopis &&
                    <div className={classNames(classes.mt4)}>
                      <IconButton
                        color="secondary"
                        onClick={this.handleOpenAllertInfoTypKarty}
                        toolTip="Informácia"
                        toolTipPlace="left"
                        size="large"
                      >
                        <InfoIcon color='secondary' fontSize='large' variant='fab' />
                      </IconButton>
                    </div>
                  }
                </div>
                {this.state.bShowD_OD &&
                  <>
                    <DatePickerV5
                      onlyMobileVersion={true}
                      name='D_OD'
                      label="Dátum začatia platnosti"
                      format="D.M.YYYY"
                      shouldRespectLeadingZeros={true}
                      fullWidth={true}
                      value={this.state.D_OD}
                      // defaultValue={}
                      onChange={this.handleChange}
                      clearable={true}
                      keyboards={true}
                      disableFuture={false}
                      disablePast={true}
                      maxDate={this.state.maxDate.toDateString()}
                      className={classNames(classes.mt2)}
                      closeOnSelect={false}
                      disabled={this.state.activeStep === 4}
                      disableOpenPicker={false}
                      displayWeekNumber={false}
                      formatDensity={"dense"}
                      localeText={{}}
                      openTo={"year"}
                      reduceAnimations={true}
                      // shouldDisableYear={date => {
                      //   const year = date.get('year');
                      //   return year === 2023;
                      // }}
                      showDaysOutsideCurrentMonth={true}
                      yearsOrder="desc"
                    // actions={[]}
                    />
                  </>
                }
                {this.state.bShowAdrTpEmpty &&
                  <Typography style={{ color: 'red' }} className={classNames(classes.mt3, classes.textCenter)}>Vaša adresa trvalého, resp. prechodného pobytu nie je zaradená do žiadnej zóny!</Typography>
                }
                <TextField
                  disabled={this.state.activeStep === 4}
                  label="Poznámka"
                  fullwidth={true}
                  value={this.state.POZN}
                  errorText={this.state.errorTextPozn}
                  error={this.state.errorTextPozn ? true : false}
                  onChange={this.handleChange}
                  multiLine={true}
                  name='POZN'
                  className={classNames(classes.mt2)}
                  variant="standard"
                />
                <div className={classNames(classes.col)} style={{ display: "flex", flexDirection: "column", justifyContent: "center", alignItems: "center" }}>
                  {this.state.activeStep === 2 && <RaisedButton style={{ width: '80%', maxWidth: '350px', padding: '13px 30px', border: "1px solid grey", backgroundColor: "white" }} onClick={this.handleGoToReview} color="primary" aria-label="Náhľad">
                    <div className={classNames(classes.row, classes.w100, classes.alignItemsCenter, classes.justifyContentBetween)}>
                      <Typography variant="button" style={{ color: '#000' }}>Náhľad</Typography>
                      <img src={'assets/nahlad_button.svg'} alt='Náhľad' style={{ width: '40px', height: '40px', color: "black" }} />
                    </div>
                  </RaisedButton>}
                  <RaisedButton
                    style={{ width: '80%', maxWidth: '350px', padding: '13px 30px' }}
                    onClick={() => this.handleAdd(0)}
                  >
                    <div className={classNames(classes.row, classes.w100, classes.alignItemsCenter, classes.justifyContentBetween)}>
                      <Typography variant="button" style={{ color: '#fff' }}>
                        Odoslať
                      </Typography>
                      <ArrowForward
                        fontSize='large'
                      />
                    </div>
                  </RaisedButton>
                </div>
              </div>
            }
            {this.state.activeStep === 3 &&
              <div>
                <div className={classNames(classes.mb4, classes.navigationBackButton)}>
                  <Button
                    color="inherit"
                    aria-label="Späť"
                    onClick={this.handleGoHome}
                  >
                    <ArrowBack
                      fontSize='default'
                      variant='fab'
                    />
                    <Typography variant="body2" className={classNames(classes.ml2)}>Späť na parkovacie karty</Typography>
                  </Button>
                </div>
                <div className='center'>
                  <img style={{ width: 140, height: 140 }} className={classNames(classes.mb4)} src={'assets/check_green.svg'} alt='ŽIADOSŤ ODOSLANÁ' />
                </div>
                <div className={classNames(classes.mt3, classes.textCenter)}>
                  <Typography className={classNames(classes.textCenter, classes.fontBold)} style={{ color: 'green' }}>
                    VAŠA ŽIADOSŤ O <br />
                    PARKOVACIU KARTU <br />
                    BOLA ÚSPEŠNE ODOSLANÁ
                  </Typography>
                </div>
                <div className={classNames(classes.mt4, classes.mb4)}>
                  <Typography className={classNames(classes.textCenter)}>
                    POČKAJTE PROSÍM <br />
                    NA OVERENIE VAŠEJ ŽIADOSTI <br />
                    INFORMAČNÝM SYSTÉMOM MESTA.
                  </Typography>
                </div>
                <div className={classNames(classes.mt4)}>
                  <Typography className={classNames(classes.textCenter)}>
                    STAV VAŠEJ ŽIADOSTI MÔŽETE <br />
                    SLEDOVAŤ NA ZÁLOŽKE <br />
                    "PARKOVACIE KARTY"
                  </Typography>
                </div>

              </div>
            }

          </React.Fragment>
        </Paper>
        <Dialog
          open={this.state.doublePermitAllert}
          onClose={this.handleCloseAlertDouble}
          contentText={"Informácia: Žiadosť o parkovaciu kartu s rovnakými údajmi ste už zaevidovali. Jej schválenie podlieha kontrole príslušným zodpovedným pracovníkom. Stav môžete sledovať na záložke <b>Parkovacie karty</b>.<br><br>Chcete ju napriek tomu zaevidovať?"}
          title={'Zaevidovať duplicitnú žiadosť?'}
          actions={[
            {
              label: 'Nie',
              color: 'primary',
              onClick: this.handleCloseAlertDouble,
              keyboardFocused: true
            },
            {
              label: 'Áno',
              color: 'primary',
              onClick: () => this.handleAdd(1),
              keyboardFocused: false
            }
          ]}
        />
        <Dialog
          open={this.state.allertInfoTypKarty}
          onClose={this.handleCloseAllertInfoTypKarty}
          contentText={this.state.textTypPopis}
          title={'Informácia'}
          actions={[
            {
              label: 'Zrušiť',
              color: 'primary',
              onClick: this.handleCloseAllertInfoTypKarty,
              keyboardFocused: true
            },
          ]}
        />
        <Dialog
          open={this.state.infoPrilohy}
          onClose={this.handleCloseInfoPrilohy}
          contentText={this.state.textInfoPrilohy}
          title={'Informácia'}
          actions={[
            {
              label: 'Zrušiť',
              color: 'primary',
              onClick: this.handleCloseInfoPrilohy,
              keyboardFocused: true
            },
          ]}
        />

      </div >
      <div ref={this.bottomRef}></div>
    </>);
  }
}

const mapStateToProps = (state) => {
  return {
    person: state.person.data,
    cityOfSetup: state.cityOfSetup.data.Data,
    visibleZtp: state.visibleZtp.data.Data,
    visibleD_OD: state.visibleD_OD.data.Data,
    requiredParkingAdr: state.requiredParkingAdr.data.Data,
    listDrive: state.listDrive.data,
    listCardType: state.listCardType.data,
    listCardKat: state.listCardKat.data,
    listAdresa: state.listAdresa.data,
    listAdresaCount: state.listAdresa.total,
    adresa: state.adresa.data.Data,
    cardKat: state.cardKat.data.Data,
    locale: state.locale.locale,
    data: state.parkingPermitData,
    visiblePlacesMapButton: state.placesMapButton.data.Data,
  };
};

export default withRouter(withLocalizationConsumer(connect(mapStateToProps)(withStyles(styles)(withWidth()(ParkingPermit)))));