import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { saveAs } from 'file-saver';

import CircularProgress2 from '@mui/material/CircularProgress';
import withStyles from '@mui/styles/withStyles';
import Box from '@mui/material/Box';

import DownloadIcon from '../../coraWebMComponents/dataDisplay/icons/DownloadIcon';
import CircularProgress from '../../coraWebMComponents/feedBack/progress/CircularProgress';
import Typography from '../../coraWebMComponents/dataDisplay/Typography';
import getSharedStyles from '../../coraWebMComponents/sharedStyles';
import ArrowBack from '../../coraWebMComponents/dataDisplay/icons/ArrowBack';
import AddOutlineIcon from '../../coraWebMComponents/dataDisplay/icons/AddOutlineIcon';
import PdfIcon from '../../coraWebMComponents/dataDisplay/icons/PdfIcon';
import Button from '../../coraWebMComponents/inputs/Button';
import IconButton from '../../coraWebMComponents/inputs/IconButton';
import FormModal from '../../coraWebMComponents/inputs/FormModal';
import DatePickerV5 from '../../coraWebMComponents/inputs/DatePickerV5';
import CarIcon from '../../coraWebMComponents/dataDisplay/icons/CarIcon';
import * as validator from '../../coraWebMComponents/utils/validator';
import * as convert from '../../coraWebMComponents/utils/convert';
import Paper from '../../coraWebMComponents/surfaces/Paper';
import { withLocalizationConsumer } from '../../coraWebMComponents/localization/withLocalization';
import InfiniteList from '../../coraWebMComponents/dataDisplay/InfiniteList';
import PlaceIcon from '../../coraWebMComponents/dataDisplay/icons/PlaceIcon';
import ScheludeIcon from '../../coraWebMComponents/dataDisplay/icons/ScheludeIcon';
import ScheludeTwoToneIcon from '../../coraWebMComponents/dataDisplay/icons/ScheludeTwoToneIcon';
import ReNewIcon from '../../coraWebMComponents/dataDisplay/icons/ReNewIcon';
import FilterListIcon from '../../coraWebMComponents/dataDisplay/icons/FilterListIcon';
import * as requests from '../../coraWebMComponents/utils/requests';
import SelectField from '../../coraWebMComponents/inputs/SelectField';
import TextField from '../../coraWebMComponents/inputs/TextField';
import Chip from '../../coraWebMComponents/inputs/Chip';
import DateRangeIcon from '../../coraWebMComponents/dataDisplay/icons/DateRangeIcon';
import Divider from '../../coraWebMComponents/dataDisplay/Divider';
import withRouter from '../../coraWebMComponents/utils/withRouter';

import * as parkingActions from '../../actions/parkingActions';
import * as ciselnikyActions from '../../actions/ciselnikyActions';
import * as userActions from '../../actions/userActions';
import routes from '../../routes';
import { processFilter } from '../../utils/parkingUtils';

const styles = theme => ({
  ...getSharedStyles(theme),
  addButton: {
    backgroundColor: '#4caf50',
    borderRadius: '30px',
    height: '55px',
    "&:hover": {
      backgroundColor: '#4caf50'
    }
  },
  formButton: {
    margin: 0,
    borderRadius: '0px',
  }
});

class ParkingTickets extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoad: true,
      openDetail: false,
      openFilter: false,
      txtTitle: '',
      i_doc_park: null,
      i_zona: null,
      l_uhr_pb: false,
      n_zona: '',
      ecv: '',
      dOd: '',
      dDo: '',
      cena: null,
      filterIZona: null,
      filterNZona: '',
      filterEcv: '',
      filterDod: null,
      filterDdo: null,
      frmIZona: null,
      frmEcv: '',
      frmDod: null,
      frmDdo: null,
      errorTextEcv: '',
      errorTextDate: '',
    };
  }

  componentDidMount = async () => {
    await this.props.dispatch(ciselnikyActions.getListZone());
    await this.props.dispatch(parkingActions.getParkingZoneLabel(this.props.locale));
    const txtTitle = await this.props.translateWithLocaleAsync('sk-SK', 'DM_PARKING_TICKET');
    await this.props.dispatch(parkingActions.getParkingTickets('', '', '', '', 1));
    this.setState({ txtTitle, isLoad: false });
    window.addEventListener('resize', this.handleResize);
    this.fitTicketsToPage();
  };

  componentDidUpdate = async (prevProps) => {
    if (prevProps.locale !== this.props.locale) {
      await this.props.dispatch(parkingActions.getParkingZoneLabel(this.props.locale));
    }
  };

  handleResize = async () => {
    if ((window.innerHeight > document.body.offsetHeight) && !this.props.isLoading && this.props.hasMore) {
      await this.onEnd();
    }
  };

  componentWillUnmount() {
    window.removeEventListener('resize', this.handleResize);
  }

  onEnd = async () => {
    if (!this.props.isLoading && this.props.hasMore) {
      await this.props.dispatch(parkingActions.getParkingTickets(this.state.filterIZona, this.state.filterEcv, this.state.filterDod, this.state.filterDdo, this.props.page + 1));
    }
  };

  fitTicketsToPage = async () => {
    while ((window.innerHeight > document.body.offsetHeight) && !this.props.isLoading && this.props.hasMore) {
      await this.onEnd();
    }
  };

  handleFilter = async () => {
    let n_zona = this.getNZona(this.state.frmIZona);
    this.setState({
      filterIZona: this.state.frmIZona,
      filterNZona: n_zona,
      filterEcv: this.state.frmEcv,
      filterDod: this.state.frmDod,
      filterDdo: this.state.frmDdo,
      openFilter: false
    });
    await this.props.dispatch(parkingActions.getParkingTickets(this.state.frmIZona, this.state.frmEcv, this.state.frmDod, this.state.frmDdo, 1));
    this.fitTicketsToPage();
  };

  handleDeleteFilter = async (opt) => {
    switch (opt) {
      case 1:
        this.setState({ filterIZona: null, filterNZona: '' });
        await this.props.dispatch(parkingActions.getParkingTickets(null, this.state.filterEcv, this.state.filterDod, this.state.filterDdo, 1));
        break;
      case 2:
        this.setState({ filterEcv: '' });
        await this.props.dispatch(parkingActions.getParkingTickets(this.state.filterIZona, '', this.state.filterDod, this.state.filterDdo, 1));
        break;
      case 3:
        this.setState({ filterDod: null });
        await this.props.dispatch(parkingActions.getParkingTickets(this.state.filterIZona, this.state.filterEcv, null, this.state.filterDdo, 1));
        break;
      case 4:
        this.setState({ filterDdo: null });
        await this.props.dispatch(parkingActions.getParkingTickets(this.state.filterIZona, this.state.filterEcv, this.state.filterDod, null, 1));
        break;
      default:
    }
    this.fitTicketsToPage();
  };

  handleChange = (name, value) => {
    switch (name) {
      case 'frmEcv':
        value = value.toUpperCase();
        value = value.trim();
        this.setState({ errorTextEcv: value ? validator.isValidEcv(value) : '' });
        break;
      case 'frmDod':
        this.setState({ errorTextDate: value && this.state.frmDdo ? (value > this.state.frmDdo ? 'Dátum od je väčší ako dátum do!' : '') : '' });
        break;
      case 'frmDdo':
        this.setState({ errorTextDate: value && this.state.frmDod ? (value < this.state.frmDod ? 'Dátum od je väčší ako dátum do!' : '') : '' });
        break;
      default:
    }
    this.setState({ [name]: value });
  };

  handleShowDetail = (rec) => {
    this.setState({
      openDetail: true,
      i_doc_park: rec.I_DOC_PARK,
      i_zona: rec.I_ZONA,
      l_uhr_pb: rec.L_UHR_PB,
      n_zona: rec.N_ZONA,
      ecv: rec.ECV,
      dOd: rec.D_OD,
      dDo: rec.D_DO,
      cena: rec.CENA,
    });
  };

  handleShowFilter = () => {
    this.setState({
      frmIZona: this.state.filterIZona,
      frmEcv: this.state.filterEcv,
      frmDod: this.state.filterDod,
      frmDdo: this.state.filterDdo,
      openFilter: true
    });
  };

  handleCloseFilter = () => {
    this.setState({
      errorTextEcv: '',
      errorTextDate: '',
      openFilter: false
    });
  };

  handleGoBack = () => {
    this.setState({ openDetail: false });
  };

  handleReport = async (iDocPark) => {
    this.setState({ isLoad: true });

    try {
      const data = await requests.getBlob(`/api/parking/invoice/${iDocPark}`);
      saveAs(data, `parkovaciListok_${this.state.ecv}.pdf`);
      this.props.dispatch(userActions.showMsg("Sťahovanie súboru bolo úspešne dokončené."));
    }
    catch (error) {
      console.error(error);
      this.props.dispatch(userActions.showErrorMsg("Sťahovanie súboru zlyhalo."));
    }

    this.setState({ isLoad: false });
  };

  handleReParking = () => {
    this.props.navigate({ pathname: routes.PZ_PARKING_SHORT, search: `?zoneID=${this.state.i_zona}&ecv=${this.state.ecv}` });
  };

  handleZip = async () => {
    let username = this.props.prihlaseny.n_uz.split('@')[0].replace('.', '_');
    this.setState({ isLoad: true });
    if (this.props.parkingTickets.length === 0) {
      this.props.dispatch(userActions.showErrorMsg("Neboli vybrané žiadne záznamy."));
    } else {
      try {
        const processedFilter = processFilter(this.state.filterIZona, this.state.filterEcv, this.state.filterDod, this.state.filterDdo);

        const data = await requests.getBlob(`/api/parking/invoiceZip?filter=${JSON.stringify(processedFilter)}`);
        if (data.size === 0) {
          this.props.dispatch(userActions.showErrorMsg("Neboli nájdené žiadne doklady o zaplatení."));
          this.setState({ isLoad: false });
          return;
        }
        saveAs(data, `${username}.zip`);
        this.props.dispatch(userActions.showMsg("Sťahovanie súboru bolo úspešne dokončené."));
      }
      catch (error) {
        console.error(error);
        this.props.dispatch(userActions.showErrorMsg("Sťahovanie súboru zlyhalo."));
      }
    }
    this.setState({ isLoad: false });
  };

  getNZona = (iZona) => {
    let nZona = '';
    if (!iZona)
      return nZona;

    this.props.listZone.forEach(findZone);

    function findZone(value) {
      if (value.I_ZONA === iZona)
        nZona = value.N_ZONA;
    }

    return nZona;
  };

  render() {
    const { classes } = this.props;
    const fMelements =
      <div >
        <Typography variant="h6" className={classNames(classes.ml4, classes.mr4, classes.mt4, classes.mb1)}>Filtrovanie</Typography>

        <div className={classNames(classes.w100, classes.row, classes.alignItemsCenter, classes.mb4)}>
          <PlaceIcon style={{ fontSize: 40, marginRight: '7px', marginLeft: '7px' }} />
          <SelectField
            floatingLabelText={this.props.parkingZoneLabel}
            value={this.state.frmIZona}
            onChange={this.handleChange}
            fullwidth={true}
            schema={{ label: 'N_ZONA', value: 'I_ZONA' }}
            data={this.props.listZone}
            name='frmIZona'
            className={classNames(classes.mr4, classes.w100)}
          />
        </div>
        <div className={classNames(classes.row, classes.alignItemsCenter, classes.mb4)}>
          <CarIcon style={{ fontSize: 40, marginRight: '7px', marginLeft: '7px' }} />
          <TextField
            label="EČV"
            fullwidth={true}
            value={this.state.frmEcv}
            onChange={this.handleChange}
            name='frmEcv'
            className={classNames(classes.mr4)}
            errorText={this.state.errorTextEcv}
            error={this.state.errorTextEcv ? true : false}
            variant="standard"
          />
        </div>
        <div className={classNames(classes.w100, classes.row, classes.alignItemsCenter, classes.mb4)}>
          <DateRangeIcon style={{ fontSize: 40, marginRight: '7px', marginLeft: '7px' }} />
          <DatePickerV5
            onlyMobileVersion={true}
            sx={{}}
            name='frmDod'
            label="Dátum od"
            format="D.M.YYYY"
            fullWidth={true}
            value={this.state.frmDod == null ? null : this.state.frmDod.toString()}
            // defaultValue={}
            onChange={this.handleChange}
            clearable={true}
            keyboards={true}
            // disableFuture={false}
            // disablePast={true}
            maxDate={new Date()}
            className={classNames(classes.mr4)}
            closeOnSelect={false}
            // disabled={}
            disableOpenPicker={false}
            displayWeekNumber={false}
            formatDensity={"dense"}
            localeText={{}}
            // openTo={"year"}
            reduceAnimations={true}
            // shouldDisableYear={date => {
            //   const year = date.get('year');
            //   return year === 2023;
            // }}
            showDaysOutsideCurrentMonth={true}
            yearsOrder="desc"
            placement="auto"
          // actions={[]}
          />
        </div>
        <div className={classNames(classes.w100, classes.row, classes.alignItemsCenter, classes.mb4)}>
          <DateRangeIcon style={{ fontSize: 40, marginRight: '7px', marginLeft: '7px' }} />
          <DatePickerV5
            onlyMobileVersion={true}
            sx={{}}
            name='frmDdo'
            label="Dátum do"
            format="D.M.YYYY"
            fullWidth={true}
            value={this.state.frmDdo == null ? null : this.state.frmDdo.toString()}
            // defaultValue={}
            onChange={this.handleChange}
            clearable={true}
            keyboards={true}
            // disableFuture={false}
            // disablePast={true}
            maxDate={new Date()}
            className={classNames(classes.mr4)}
            closeOnSelect={false}
            // disabled={}
            disableOpenPicker={false}
            displayWeekNumber={false}
            formatDensity={"dense"}
            localeText={{}}
            // openTo={"year"}
            reduceAnimations={true}
            // shouldDisableYear={date => {
            //   const year = date.get('year');
            //   return year === 2023;
            // }}
            showDaysOutsideCurrentMonth={true}
            yearsOrder="desc"
            placement="auto"
          // actions={[]}
          />
        </div>

        {this.state.errorTextDate &&
          <div>
            <Typography variant="body1" style={{ textAlign: 'center', color: 'red', marginBottom: '10px' }} >{this.state.errorTextDate}</Typography>
          </div>
        }

        <Divider variant='fullWidth' style={{ height: '2px' }} />

        <div className={classNames(classes.w100, classes.row)}>
          <Button
            variant="text"
            color="primary"
            size="large"
            className={classNames(classes.w100, classes.formButton)}
            onClick={() => this.handleCloseFilter()}
            style={{ height: '45px' }}
          ><Typography variant="body1" >Zrušiť</Typography></Button>
          <Button
            disabled={this.state.errorTextEcv || this.state.errorTextDate ? true : false}
            variant="contained"
            color="secondary"
            size="large"
            className={classNames(classes.w100, classes.formButton)}
            onClick={() => this.handleFilter()}
            style={{ height: '45px' }}
          ><Typography variant="body1" color="inherit">Filtrovať</Typography></Button>
        </div>
      </div>;

    return (
      <Box className='container'
        style={{ marginTop: '70px', marginBottom: '20px' }}
      >
        <CircularProgress loading={this.state.isLoad} />
        {!this.state.openDetail &&
          <div>
            <div className={classNames(classes.row, classes.mt2)} >
              <Button
                variant="contained"
                color="secondary"
                size="large"
                className={classNames(classes.w100, classes.addButton, classes.mr4, classes.ml4, classes.mb3, classes.mt2, classes.justifyContentStart)}
                onClick={() => this.props.navigate({ pathname: routes.PZ_PARKING_SHORT })}
                style={{ padding: '8px 4px' }}
              >
                <AddOutlineIcon style={{ backgroundColor: '#4caf50', color: 'white', width: '50px', height: '50px', marginRight: '20px', borderRadius: '30px' }} />
                PARKOVAŤ
              </Button>
            </div>
            <Typography variant="body1" className={classNames(classes.textCenter)}>{this.state.txtTitle}</Typography>
            <div
              style={{ alignItems: 'start' }}
              className={classNames(classes.w100, classes.justifyContentBetween, classes.dFlex)}
            >

              <IconButton
                className={classNames(classes.ml4, classes.mt1, classes.mb1)}
                color="inherit"
                onClick={() => this.handleZip()}
                size="large"
                toolTip="Stiahnuť ZIP súbor"
                toolTipPlace="top"
              >
                <Paper elevation={2} className={classNames(classes.paperWrapper, classes.pl1, classes.pt1, classes.pr1)}>
                  <DownloadIcon />
                </Paper>
              </IconButton>

              <div
                style={{ flexWrap: 'wrap', flexDirection: 'row-reverse' }}
                className={classNames(classes.mt1, classes.mlAuto, classes.dFlex)}
              >
                {this.state.filterIZona &&
                  <Chip
                    icon={<PlaceIcon />}
                    label={this.state.filterNZona}
                    onDelete={() => this.handleDeleteFilter(1)}
                    style={{ backgroundColor: '#fff' }}
                    className={classNames(classes.ml2, classes.mt1, classes.mb1)}
                  />
                }
                {this.state.filterEcv &&
                  <Chip
                    icon={<CarIcon />}
                    label={this.state.filterEcv}
                    onDelete={() => this.handleDeleteFilter(2)}
                    style={{ backgroundColor: '#fff' }}
                    className={classNames(classes.ml2, classes.mt1, classes.mb1)}
                  />
                }
                {this.state.filterDod &&
                  <Chip
                    icon={<DateRangeIcon />}
                    label={' od ' + convert.dateToDateStr(this.state.filterDod)}
                    onDelete={() => this.handleDeleteFilter(3)}
                    style={{ backgroundColor: '#fff' }}
                    className={classNames(classes.ml2, classes.mt1, classes.mb1)}
                  />
                }
                {this.state.filterDdo &&
                  <Chip
                    icon={<DateRangeIcon />}
                    label={' do ' + convert.dateToDateStr(this.state.filterDdo)}
                    onDelete={() => this.handleDeleteFilter(4)}
                    style={{ backgroundColor: '#fff' }}
                    className={classNames(classes.ml2, classes.mt1, classes.mb1)}
                  />
                }
              </div>

              <IconButton
                className={classNames(classes.mr4, classes.mt1, classes.mb1)}
                color="inherit"
                onClick={() => this.handleShowFilter(true)}
                toolTip="Filter"
                toolTipPlace="top"
                size="large">
                < FilterListIcon
                  className={classNames(classes.paperWrapper, classes.pl1, classes.pt1, classes.pr1)}
                />
              </IconButton>

            </div>
            <InfiniteList
              onEnd={this.onEnd}
            >
              {this.props.parkingTickets.map((x) =>
                <Ticket
                  key={x.I_DOC_PARK.toString()}
                  data={x}
                  classes={classes}
                  onShowDetail={() => this.handleShowDetail(x)}
                />
              )}
              {this.props.isLoading &&
                <div style={{
                  display: 'flex',
                  justifyContent: 'center'
                }}>
                  <CircularProgress2 />
                </div>
              }
            </InfiniteList>
          </div>
        }
        {
          this.state.openDetail &&
          <div>
            <div className={classNames(classes.row, classes.navigationBackButton, classes.w100, classes.alignItemsCenter, classes.mt4)} >
              <IconButton
                className={classNames(classes.ml3)}
                color="inherit"
                onClick={() => this.handleGoBack()}
                toolTip="Späť"
                toolTipPlace="right"
                size="large">
                <ArrowBack
                  variant='fab'
                  style={{ fontSize: 30 }}
                />
              </IconButton>
            </div>
            <div className={classNames(classes.w100, classes.alignItemsCenter, classes.row, classes.mt3)}  >
              <div className={classNames(classes.row, classes.alignItemsCenter, classes.ml4)}>
                <PlaceIcon fontSize='large' style={{ marginRight: '12px' }} />
                <Typography variant='body1' >{this.props.parkingZoneLabel}</Typography>
              </div>
              <Typography variant='body2' className={classNames(classes.mr4)}>{this.state.n_zona}</Typography>
            </div>
            <div className={classNames(classes.w100, classes.alignItemsCenter, classes.row, classes.mt3)}  >
              <div className={classNames(classes.row, classes.alignItemsCenter, classes.ml4)}>
                <CarIcon fontSize='large' style={{ marginRight: '12px' }} />
                <Typography variant='body1' >EČV</Typography>
              </div>
              <Typography variant='body2' className={classNames(classes.mr4)}>{this.state.ecv}</Typography>
            </div>
            <div className={classNames(classes.w100, classes.alignItemsCenter, classes.row, classes.mt3)}  >
              <div className={classNames(classes.row, classes.alignItemsCenter, classes.ml4)}>
                <ScheludeIcon fontSize='large' style={{ marginRight: '12px' }} />
                <Typography variant='body1' >Od</Typography>
              </div>
              <Typography variant='body2' className={classNames(classes.mr4)}>{convert.convertDateTime(this.state.dOd)}</Typography>
            </div>
            <div className={classNames(classes.w100, classes.alignItemsCenter, classes.row, classes.mt3)}  >
              <div className={classNames(classes.row, classes.alignItemsCenter, classes.ml4)}>
                <ScheludeTwoToneIcon fontSize='large' style={{ marginRight: '12px' }} />
                <Typography variant="body1" >Do</Typography>
              </div>
              <Typography variant="body2" className={classNames(classes.mr4)}>{convert.convertDateTime(this.state.dDo)}</Typography>
            </div>
            {this.state.l_uhr_pb &&
              <Paper className={classNames(this.props.classes.ml4, this.props.classes.mr4, this.props.classes.mt4)} style={{ borderRadius: '12px' }} elevation={3}>
                <React.Fragment>
                  <Button
                    onClick={() => this.handleReport(this.state.i_doc_park)}
                    style={{ borderRadius: '12px' }}
                    className={classNames(classes.w100)}
                    disabled={this.state.cena === 0}
                  >
                    <div className={classNames(this.props.classes.w100, this.props.classes.row, this.props.classes.ml3, this.props.classes.mr3)} >
                      <Typography variant="body2" className={classNames(this.props.classes.mt2, this.props.classes.mb2)}>DOKLAD O ZAPLATENÍ</Typography>
                      <PdfIcon className={classNames(this.props.classes.mt2, this.props.classes.mb2)} style={{ color: '#d50000' }} />
                    </div>
                  </Button>
                </React.Fragment>
              </Paper >
            }
            <Paper className={classNames(this.props.classes.ml4, this.props.classes.mr4, this.props.classes.mt2)} style={{ borderRadius: '12px' }} elevation={3}>
              <React.Fragment>
                <Button
                  onClick={() => this.handleReParking()}
                  style={{ borderRadius: '12px' }}
                  className={classNames(classes.w100)}
                >
                  <div className={classNames(this.props.classes.w100, this.props.classes.row, this.props.classes.ml3, this.props.classes.mr3)} >
                    <Typography variant="body2" className={classNames(this.props.classes.mt2, this.props.classes.mb2)}>OPAKOVAŤ PARKOVANIE</Typography>
                    <ReNewIcon className={classNames(this.props.classes.mt2, this.props.classes.mb2)} style={{ color: '#4caf50' }} />
                  </div>
                </Button>
              </React.Fragment>
            </Paper >
          </div>
        }
        <FormModal
          open={this.state.openFilter}
          onClose={() => this.handleCloseFilter()}
          elements={fMelements}
        />
      </Box >
    );
  }
};

class Ticket extends React.Component {
  render() {
    return (
      <div className={classNames(this.props.classes.mb3)} onClick={() => this.props.onShowDetail(this.props.data.I_DOC_PARK)}>
        <Paper className={classNames(this.props.classes.ml2, this.props.classes.mr2)} style={{ borderRadius: '12px', cursor: 'pointer' }} elevation={2}>
          <React.Fragment>
            <div className={classNames(this.props.classes.w100, this.props.classes.row, this.props.classes.alignItemsCenter)} >
              <div className={classNames(this.props.classes.w100, this.props.classes.row, this.props.classes.alignItemsCenter,
                this.props.classes.justifyContentStart, this.props.classes.ml3, this.props.classes.mt3)} >
                <CarIcon className={classNames(this.props.classes.mr1)} />
                <Typography variant="body2" className={classNames(this.props.classes.mr1, this.props.classes.mt1)}>{this.props.data.ECV}</Typography>
              </div>
              <div className={classNames(this.props.classes.w100, this.props.classes.row, this.props.classes.alignItemsCenter,
                this.props.classes.justifyContentStart, this.props.classes.ml2, this.props.classes.mt3, this.props.classes.mr2)} >
                <PlaceIcon className={classNames(this.props.classes.mr1)} />
                <Typography variant="body2" className={classNames(this.props.classes.mr1, this.props.classes.mt1)}>{this.props.data.N_ZONA}</Typography>
              </div>
            </div>
            <div className={classNames(this.props.classes.w100, this.props.classes.row, this.props.classes.alignItemsCenter)} >
              <div className={classNames(this.props.classes.w100, this.props.classes.row, this.props.classes.alignItemsCenter,
                this.props.classes.justifyContentStart, this.props.classes.ml3, this.props.classes.mt3, this.props.classes.mb3)} >
                <ScheludeIcon className={classNames(this.props.classes.mr1)} />
                <Typography variant="caption" style={{ fontWeight: 'bold' }} className={classNames(this.props.classes.mr1)}>od</Typography>
                <Typography variant="caption" style={{ fontWeight: 'bold' }}  >{convert.convertDateTime(this.props.data.D_OD)}</Typography>
              </div>
              <div className={classNames(this.props.classes.w100, this.props.classes.row, this.props.classes.alignItemsCenter,
                this.props.classes.justifyContentStart, this.props.classes.ml2, this.props.classes.mt3, this.props.classes.mb3, this.props.classes.mr1)} >
                <ScheludeTwoToneIcon className={classNames(this.props.classes.mr1)} />
                <Typography variant="caption" style={{ fontWeight: 'bold' }} className={classNames(this.props.classes.mr1)}>do</Typography>
                <Typography variant="caption" style={{ fontWeight: 'bold' }} >{convert.convertDateTime(this.props.data.D_DO)}</Typography>
              </div>
            </div>

          </React.Fragment>
        </Paper >
      </div >
    );
  }
}

Ticket.propTypes = {
  onShowDetail: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    parkingTickets: state.parkingTickets.data,
    isLoading: state.parkingTickets.isLoading,
    prihlaseny: state.user.data,
    page: state.parkingTickets.page,
    hasMore: state.parkingTickets.hasMore,
    listZone: state.listZone.data,
    parkingZoneLabel: state.parkingZoneLabel.data,
    locale: state.locale.locale
  };
};

export default withRouter(withLocalizationConsumer(connect(mapStateToProps)(withStyles(styles)(ParkingTickets))));