import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import makeStyles from '@mui/styles/makeStyles';
import Dialog from "@mui/material/Dialog";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import ListItemText from "@mui/material/ListItemText";

import getSharedStyles from "../../coraWebMComponents/sharedStyles";
import DialogHeader from '../../coraWebMComponents/surfaces/DialogHeader';

import { Transition, LABEL_NAME, VALUE_NAME } from "./support";

const useStyles = makeStyles((theme) => ({
  ...getSharedStyles(theme),
  root: {
    margin: 0,
    padding: theme.spacing(2),
    alignItems: "center",
  },
  lbl: {
    fontSize: theme.typography.pxToRem(13),
    fontWeight: theme.typography.fontWeightLight,
    color: theme.palette.text.secondary,
  },
  titleContainer: {
    justifyContent: "center",
  },
}));

const ComboFilterDialogComponent = ({ closeClick, fltMeta, isOpen, onClick, value }) => {
  const classes = useStyles();

  if (!isOpen) return null;

  const { id, label, dataItems, noItem } = fltMeta;

  let iOrder = 0;
  const addItem = (val, lbl) => (
    <ListItem
      button
      alignItems="center"
      key={`${val}${++iOrder}`}
      selected={value === val}
      onClick={() => onClick(id, val)}
      className={classNames(classes.row)}
      style={val === value ? { backgroundColor: 'rgb(246, 255, 245)' } : null}
      divider
    >
      <ListItemText primary={lbl} className={classNames(classes.lbl)} />
    </ListItem>
  );

  return (
    (<Dialog
      open={isOpen}
      onClose={closeClick}
      scroll="paper"
      maxWidth="sm"
      aria-labelledby="scroll-dialog-title"
      fullWidth={true}
      TransitionComponent={Transition}
    >
      <DialogTitle id="scroll-dialog-title" className={classes.root}>
        <DialogHeader title={label} onClose={closeClick} variant={"h6"} classes={classes} />
      </DialogTitle>
      <DialogContent>
        <List component="nav">
          {noItem && !noItem.disabled && noItem.topPosition && addItem(noItem.value, noItem.label)}
          {dataItems.map((cboItem) => addItem(cboItem[VALUE_NAME], cboItem[LABEL_NAME]))}
          {noItem && !noItem.disabled && !noItem.topPosition && addItem(noItem.value, noItem.label)}
        </List>
      </DialogContent>
    </Dialog>)
  );
};

ComboFilterDialogComponent.propTypes = {
  className: PropTypes.string,
  closeClick: PropTypes.func.isRequired,
  fltMeta: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onClick: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string.isRequired, PropTypes.number.isRequired]),
};

export default ComboFilterDialogComponent;
