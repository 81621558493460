import React from 'react';
import {
  Route, Navigate, createHashRouter,
  createRoutesFromElements, useLocation, Outlet, RouterProvider, useParams
} from 'react-router-dom';
import PropTypes from 'prop-types';
import withMeta from '../utils/withMeta';
import Routes from '../../routes';
import CircularProgress from '../feedBack/progress/CircularProgress';
import HttpsRedirect from '../utils/httpsRedirect';
import AppOffline from '../views/AppOffline';

export const AuthLayout = ({ userData, ...props }) => {
  const hasRole = userData !== null;
  const location = useLocation()
  if (hasRole) {
    return <Outlet />;
  }
  return <Navigate to={Routes.PRELOGIN} state={{ from: location }} replace />;
};


const MetaRoute = ({ isAuth, componentFactory, reducer, filterFactory, akcFactory, meta, currentElFactory, customRoutes, ...props }) => {
  const AppBarWrapper = props.appBarWrapper;

  const routes = createHashRouter(createRoutesFromElements(
    <Route element={AppBarWrapper}>

      {customRoutes}

      {/* 404 not found */}
      <Route
        path={"*"}
        element={
          <Navigate
            to={"/"}
            replace
          />
        }
      />
    </Route>
  ));

  if (window.self !== window.top) {
    return <div style={{ textAlign: "center", margin: "25vh" }}>
      Aplikáciu nie je možné spúštať v rámci iFrame.
    </div>
  }

  if (meta.isAppOffline) return <AppOffline />

  if (meta.menu.length === 0 && !meta.menuError) {
    return <CircularProgress loading={true} />
  }

  return (
    <HttpsRedirect>
      <RouterProvider router={routes}>

      </RouterProvider>
    </HttpsRedirect>
  );
}

MetaRoute.propTypes = {
  componentFactory: PropTypes.func,
  filterFactory: PropTypes.func,
  isAuth: PropTypes.bool.isRequired,
  reducer: PropTypes.func,
  akcFactory: PropTypes.func,
  currentElFactory: PropTypes.func
}

export default withMeta(MetaRoute);