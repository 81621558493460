import React from "react";
import PropTypes from "prop-types";

import Stack from '@mui/material/Stack';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

import IconButton from "../../coraWebMComponents/inputs/IconButton";

/**
 * Button pre navrat na zaciatok stranky po scrolovani
 *
 * @param {*} { onClick, right, bottom, backgroundColor }
 * @return {*} 
 */
const ScrollTopButton = ({ onClick, right = '1.2rem', bottom = '4rem', backgroundColor = "#BBDEFB" }) => {
  return (<>
    <Stack
      width={"2.5rem"}
      height={"2.5rem"}
      alignItems="center"
      justifyContent="center"
      sx={{
        backgroundColor: { backgroundColor },
        color: "blue",
        position: 'fixed',
        bottom: { bottom },
        right: { right },
        zIndex: "1000",
        borderRadius: "50%",
        opacity: 0.6,
        transition: 'opacity 0.3s ease',
        '&:hover': {
          opacity: 0.95,
        },
      }}
    >
      {/* <Stack> */}
      <IconButton onClick={onClick} toolTip="Na začiatok" toolTipPlace="top" size="large">
        <KeyboardArrowUpIcon />
      </IconButton>

      {/* </Stack> */}
    </Stack >
  </>);
};

ScrollTopButton.propTypes = {
  onClick: PropTypes.func.isRequired,

};

export default ScrollTopButton;
