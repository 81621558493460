import { useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';

import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';
import Divider from '@mui/material/Divider';

import Paper from '../../coraWebMComponents/surfaces/Paper';
import Button from '../../coraWebMComponents/inputs/Button';
import Typography from '../../coraWebMComponents/dataDisplay/Typography';
import MoreVertIcon from '../../coraWebMComponents/dataDisplay/icons/MoreVertIcon';
import IconButton from '../../coraWebMComponents/inputs/IconButton';
import MenuItem from '../../coraWebMComponents/dataDisplay/MenuItem';
import Menu from '../../coraWebMComponents/dataDisplay/Menu';
import DescriptionIcon from '../../coraWebMComponents/dataDisplay/icons/DescriptionIcon';
import InfoIcon from '../../coraWebMComponents/dataDisplay/icons/InfoIcon';
import * as convert from '../../coraWebMComponents/utils/convert';
import  withWidth  from '../../coraWebMComponents/views/withWidth';

import { truncateText } from "./helper";
import { C_STAV } from "../../constants/subsidiesConsts";

const DetailRow = ({ classes, style, title, value, isMobile, dateWarning = null, dateWarningColor = "#f00203" }) => {
  if (!Boolean(value) || value.length < 1) {
    return null;
  }

  return (
    <Stack
      flexDirection={isMobile ? "column" : "row"}
      alignItems="baseline"
      className={classNames(classes.w100, classes.justifyContentBetween, classes.boxSizingBorderBox)}
      gap={isMobile ? 0 : 3}
    >
      <Box
        className={classNames(classes.alignItemsLeft, classes.boxSizingBorderBox)}
      >
        <Typography variant={"body1"}>{title}</Typography>
      </Box>
      <Box
        className={classNames(classes.alignItemsRight, classes.boxSizingBorderBox)}
      >
        <Box
          title={dateWarning && `Upozornenie: blížiaci sa termín alebo po termíne.`}
        >
          <Typography
            variant="body2"
            className={classNames(isMobile ? classes.textLeft : classes.textRight)}
            style={{
              ...style,
              ...(dateWarning && {
                backgroundColor: "#FFCCBC",
                padding: "0.5rem",
                borderRadius: "0.4rem",
                color: dateWarningColor,
              })
            }}
          >
            {value}
          </Typography>
        </Box>
      </Box>
    </Stack >
  );
};

const Subsidy = ({ data, classes, onShowDetail, onShowUrl, getColor, dateWarning = false, showDateTo = true, ...props }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { isMobile } = props;
  const printedValue = (C_STAV.find(item => item.I_STAV === data.I_STAV))?.printedValue ?? C_STAV[0].printedValue;

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const hideButton = () => {
    let res = false;
    const d_do = new Date(data.D_DO);
    // d_do.setDate(d_do.getDate() + 1);
    const d_od = new Date(data.D_OD);
    const now = new Date();
    if (d_do < now) res = true;
    if (d_od > now) res = true;
    return res;
  };

  return (
    (<div className={classNames()}>
      <Paper elevation={2} className={classNames(classes.ziadostCard, classes.mb3)}>
        <div className={classes.ziadostCardContent}>
          <div className={classNames(classes.row, classes.alignItemsCenter, classes.justifyContentStart, classes.mb2, classes.subsidiesRatingHeader)}>
            <DescriptionIcon style={{ width: "30", height: "30" }} />
            <div className={classNames(classes.ml2)}>
              <Typography variant="button" style={{ fontWeight: 'bold' }}>{data.N_PROJ}</Typography>
            </div>
            <div style={{ marginLeft: 'auto' }}>
              <IconButton
                onClick={handleMenu}
                color="inherit"
                toolTip="Viac"
                toolTipPlace="left"
                size="large">
                <MoreVertIcon />
              </IconButton>

              <Menu
                id={'menuSubsidyRating'}
                anchorEl={anchorEl}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                open={Boolean(anchorEl)}
                onClose={handleClose}
              >
                <MenuItem onClick={() => onShowDetail(false)}>
                  <InfoIcon style={{ marginRight: '7px' }} />
                  <Typography style={{ marginRight: '22px' }}>Obsah žiadosti</Typography>
                </MenuItem>
                {
                  data.FileList.length > 0 && <Divider />
                }
                {
                  data.FileList.map(file => (
                    <MenuItem key={file.I_FILE} onClick={() => onShowUrl(file.I_FILE)}>
                      <DescriptionIcon style={{ marginRight: '7px' }} />
                      <Typography style={{ marginRight: '22px' }} >E Form v náhľade</Typography>
                      <Typography style={{ marginRight: '22px' }} >{truncateText(file.N_FILE, 20)}</Typography>
                    </MenuItem>
                  ))
                }
              </Menu>
            </div>
          </div>

          <Stack
            flexDirection={"column"}
            boxSizing={"border-box"}
            justifyContent={"space-between"}
            pt={3}
            gap={isMobile ? 3 : 1}
            flexBasis={"100%"}
          // px={1}
          >
            <Box >
              <DetailRow classes={classes} title="Hodnotiaci&nbsp;celok" value={data.N_CLK} isMobile={isMobile} />
            </Box>
            <Box >
              <DetailRow classes={classes} title="Oblasť" value={data.N_OBLST} isMobile={isMobile} />
            </Box>
            {data.L_ZOBR_O &&
              <Box >
                <DetailRow classes={classes} title="Meno žiadateľa" value={data.N_O_ZIAD} isMobile={isMobile} />
              </Box>
            }
            <Box >
              <DetailRow
                classes={classes}
                style={{ color: getColor() }}
                title="Stav"
                value={printedValue}
                isMobile={isMobile}
              />
            </Box>
            {
              showDateTo && (
                <Box>
                  <DetailRow
                    classes={classes}
                    style={{ color: getColor() }}
                    title="Dátum a čas do"
                    value={convert.dataToLocaleStr(data.D_DO)}
                    isMobile={isMobile}
                    dateWarning={dateWarning}
                    dateWarningColor={C_STAV.find(item => item.I_STAV === data.I_STAV)?.color}
                  />
                </Box>
              )
            }

            {props.visibleIdNumberSubsidy?.HODN === "1" &&
              <Box >
                <DetailRow classes={classes} title="Identifikačné číslo dotácie" value={data.I_DT} isMobile={isMobile} />
              </Box>
            }
          </Stack>

          {!hideButton() &&
            <div className={classes.row}>
              <Button variant="contained" color="secondary" size="large" type="success"
                className={classNames(classes.w100, classes.mt2)}
                onClick={() => onShowDetail(true)} >
                {data.I_STAV === 1 ? 'Hodnotiť' : 'Upraviť'}
              </Button>
            </div>
          }
        </div>
      </Paper>
    </div>)
  );
};

Subsidy.propTypes = {
  key: PropTypes.number,
  data: PropTypes.object,
  classes: PropTypes.object,
  onShowDetail: PropTypes.func,
  onShowUrl: PropTypes.func,
  getColor: PropTypes.func,
  dateWarning: PropTypes.bool,
  showDateTo: PropTypes.bool
};

const mapStateToProps = (state) => ({
  visibleIdNumberSubsidy: state.visibleIdNumberSubsidy.data.Data,
});

export default (connect(mapStateToProps)(withWidth()(Subsidy)));
