import React from 'react';
import Link from '@mui/material/Link';
import PropTypes from 'prop-types';

class ButtonLinkComponent extends React.Component {
  render() {
    return (
      <Link
        component="button"
        underline='none'
        variant={this.props.variant}
        onClick={this.props.onClick}
        color={this.props.color}
        style={this.props.style}
        className={this.props.className}
      >
        {this.props.children}
      </Link>
    );
  }
}

ButtonLinkComponent.propTypes = {
  onClick: PropTypes.func.isRequired,
  color: PropTypes.string,
  variant: PropTypes.string,
  style: PropTypes.object,
  className: PropTypes.string,
}

export default ButtonLinkComponent;

