import { combineReducers } from 'redux';

import user from './user/user';
import userByName from './user/userByName';
import personEid from './user/personEid';
import ziadostByName from './account/ziadostByName';
import peopleByRc from './account/peopleByRc';
import verifyEmail from './account/verifyEmail';
import msg from './user/msg';
import locale from './user/locale';
import errorMsg from './user/errorMsg';
import errorDialog from './errorDialog';
import listCountries from './ciselniky/listCountries';
import listDrive from './ciselniky/listDrive';
import listZone from './ciselniky/listZone';
import listZoneParkShort from './ciselniky/listZoneParkShort';
import parkingZoneLabel from './parking/parkingZoneLabel';
import zoneTime from './ciselniky/zoneTime';
import zoneTimeMaxDate from './ciselniky/zoneTimeMaxDate';
import zoneTimeList from './ciselniky/zoneTimeList';
import listCardState from './ciselniky/listCardState';
import listCardKat from './ciselniky/listCardKat';
import listCardType from './ciselniky/listCardType';
import cardType from './ciselniky/cardType';
import listAdresa from './ciselniky/listAdresa';
import adresa from './ciselniky/adresa';
import listPeople from './account/listPeople';
import person from './account/person';
import country from './ciselniky/country';
import cityOfSetup from './ciselniky/cityOfSetup';
import urlGdpr from './ciselniky/urlGdpr';
import passwordPattern from './ciselniky/passwordPattern';
import passwordErrMsg from './ciselniky/passwordErrMsg';
import egovHandlerUrl from './ciselniky/egovHandlerUrl';
import urlConditionsPC from './ciselniky/urlConditionsPC';
import visibleZtp from './ciselniky/visibleZtp';
import visibleD_OD from './ciselniky/visibleD_OD';
import visiblePublicZone from './ciselniky/visiblePublicZone';
import visibleParkShort from './ciselniky/visibleParkShort';
import visibleTempEcv from './ciselniky/visibleTempEcv';
import visibleParkCardUnReg from './ciselniky/visibleParkCardUnReg';
import payRecipient from './ciselniky/payRecipient';
import cardKat from './ciselniky/cardKat';
import ibanParking from './ciselniky/ibanParking';
import publicZoneButton from './ciselniky/publicZoneButton';
import myZoneButton from './ciselniky/myZoneButton';
import parkingButton from './ciselniky/parkingButton';
import listParkingCard from './parking/listParkingCard';
import parkingCard from './parking/parkingCard';
import parkingTickets from './parking/parkingTickets';
import listParkingCredit from './parking/listParkingCredit';
import parkingCredit from './parking/parkingCredit';
import listCreditDraw from './parking/listCreditDraw';
import listTempEcv from './parking/listTempEcv';
import creditDraw from './parking/creditDraw';
import requiredParkingAdr from './parking/requiredParkingAdr';
import listTaxesDecision from './taxes/listTaxesDecision';
import listProceedings from './taxes/listProceedings';
import proceeding from './taxes/proceeding';
import listPropertyTaxes from './taxes/listPropertyTaxes';
import listOrder from './publicZone/requiredPublic/listOrder';
import listInstallments from './taxes/listInstallments';
import listPayments from './taxes/listPayments';
import listTaxesArrears from './taxes/listTaxesArrears';
import listContract from './publicZone/requiredPublic/listContract';
import listContractBill from './publicZone/requiredPublic/listContractBill';
import listPurchBill from './publicZone/requiredPublic/listPurchBill';
import listOrgOrder from './publicZone/requiredPublic/listOrgOrder';
import listOrgContract from './publicZone/requiredPublic/listOrgContract';
import listOrgContractBill from './publicZone/requiredPublic/listOrgContractBill';
import listOrgPurchBill from './publicZone/requiredPublic/listOrgPurchBill';
import listSubsidies from './publicZone/requiredPublic/listSubsidies';
import listCardTypeEShop from './publicZone/listCardTypeEShop';
import bySquare from './parking/bySquare';
import payRecipientTaxes from './ciselniky/payRecipientTaxes';
import taxesBySquare from './taxes/taxesBySquare';
import listSubsidiesRating from './subsidiesRating/listSubsidiesRating';
import listSubsidyDetail from './subsidiesRating/listSubsidyDetail';
import listSubsidyRatingDetail from './subsidiesRating/listSubsidyRatingDetail';
import adminMode from './adminMode/adminMode';
import paymentGatewaySettings from './ciselniky/paymentGatewaySettings';
import paymentSettings from './ciselniky/paymentSettings';
import parkingPermitData from './parking/parkingPermitData';
import placesMapButton from './ciselniky/placesMapButton';
import ECVList from './parking/ECVList';
import versionInfo from './ciselniky/versionInfo';
import ticketPriceSums from './publicZone/ticketPriceSums';
import listActiveParkingCard from './parking/listActiveParkingCard';
import notAvailableFreeParking from './ciselniky/notAvailableFreeParking';
import parkingPermitsList from './parking/parkingPermitsList';
import zstZsTree from './locator/zstZsTree';
import listZstZs from './locator/listZstZs';
import listZstSlz from './locator/listZstSlz';
import listZstCDtzOtz from './locator/listZstCDtzOtz';
import listZstLsOtz from './locator/listZstLsOtz';
import zstSessionId from './locator/zstSessionId';
import listSlzAkt from './locator/listSlzAkt';
import listSlzUsed from './locator/listSlzUsed';
import listSlzUsedUser from './locator/listSlzUsedUser';
import listProgRozp from './rozpocet/listProgRozp';
import initProgRozp from './rozpocet/initProgRozp';
import cardExtendBefore from './ciselniky/cardExtendBefore';
import cardExtendAfter from './ciselniky/cardExtendAfter';
import ticketTimeSums from './publicZone/ticketTimeSums';
import activeQuickActions from './account/activeQuickActions';
import listValidParkingCard from './parking/listValidParkingCard';
import visibleIdNumberSubsidy from './ciselniky/visibleIdNumberSubsidy';
import eFormUrlSubsidy from './ciselniky/eFormUrlSubsidy';
import listSubsidyOblst from './subsidiesRating/listSubsidyOblst';
import listSubsidyClk from './subsidiesRating/listSubsidyClk';
import mapLayersSettings from './parking/mapLayersSettings';
import visiblePlanButton from './ciselniky/visiblePlanButton';
import maxHoursOneTicket from './ciselniky/maxHoursOneTicket';
import ffiConfig from './locator/ffiConfig';
import ffiWaybill from './locator/ffiWaybill';

const rootReducer = combineReducers({
  user,
  msg,
  locale,
  errorMsg,
  errorDialog,
  listCountries,
  listPeople,
  verifyEmail,
  country,
  userByName,
  personEid,
  ziadostByName,
  peopleByRc,
  person,
  cityOfSetup,
  urlGdpr,
  passwordPattern,
  passwordErrMsg,
  urlConditionsPC,
  egovHandlerUrl,
  visibleZtp,
  visibleD_OD,
  visiblePublicZone,
  visibleParkShort,
  visibleTempEcv,
  visibleParkCardUnReg,
  payRecipient,
  cardKat,
  ibanParking,
  listDrive,
  listZone,
  listZoneParkShort,
  parkingZoneLabel,
  zoneTime,
  zoneTimeList,
  zoneTimeMaxDate,
  listCardType,
  cardType,
  listAdresa,
  adresa,
  listParkingCard,
  parkingCard,
  parkingTickets,
  parkingPermitData,
  listParkingCredit,
  parkingCredit,
  listCreditDraw,
  listTempEcv,
  creditDraw,
  listCardState,
  listCardKat,
  listOrder,
  listContract,
  listContractBill,
  listPurchBill,
  listOrgOrder,
  listOrgContract,
  listOrgContractBill,
  listOrgPurchBill,
  bySquare,
  payRecipientTaxes,
  requiredParkingAdr,
  listCardTypeEShop,
  listSubsidies,
  listTaxesDecision,
  listProceedings,
  proceeding,
  listPropertyTaxes,
  listInstallments,
  listPayments,
  listTaxesArrears,
  taxesBySquare,
  listSubsidiesRating,
  listSubsidyDetail,
  listSubsidyRatingDetail,
  publicZoneButton,
  myZoneButton,
  parkingButton,
  adminMode,
  paymentGatewaySettings,
  paymentSettings,
  placesMapButton,
  ECVList,
  versionInfo,
  ticketPriceSums,
  listActiveParkingCard,
  notAvailableFreeParking,
  parkingPermitsList,
  zstZsTree,
  listZstZs,
  listZstSlz,
  listZstCDtzOtz,
  listZstLsOtz,
  zstSessionId,
  listSlzAkt,
  listSlzUsed,
  listSlzUsedUser,
  listProgRozp,
  initProgRozp,
  cardExtendBefore,
  cardExtendAfter,
  ticketTimeSums,
  activeQuickActions,
  listValidParkingCard,
  visibleIdNumberSubsidy,
  eFormUrlSubsidy,
  listSubsidyOblst,
  listSubsidyClk,
  mapLayersSettings,
  visiblePlanButton,
  maxHoursOneTicket,
  ffiConfig,
  ffiWaybill
});

export default rootReducer;