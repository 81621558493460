import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';

import withStyles from '@mui/styles/withStyles';
import InputAdornment from '@mui/material/InputAdornment';

import Typography from '../../../coraWebMComponents/dataDisplay/Typography';
import getSharedStyles from '../../../coraWebMComponents/sharedStyles';
import IconButton from '../../../coraWebMComponents/inputs/IconButton';
import Button from '../../../coraWebMComponents/inputs/Button';
import TextField from '../../../coraWebMComponents/inputs/TextField';
import { withLocalizationConsumer } from '../../../coraWebMComponents/localization/withLocalization';
import storage from '../../../coraWebMComponents/utils/withStorage';
import DatePickerV5 from '../../../coraWebMComponents/inputs/DatePickerV5';
import ScheludeIcon from '../../../coraWebMComponents/dataDisplay/icons/ScheludeIcon';
import withMeta from "../../../coraWebMComponents/utils/withMeta";
import withRouter from '../../../coraWebMComponents/utils/withRouter';
import { dateTimeToDate, addOffset } from '../../../coraWebMComponents/utils/convert';
import ButtonFab from '../../../coraWebMComponents/inputs/ButtonFab';
import EditIcon from "../../../coraWebMComponents/dataDisplay/icons/EditIcon";

import TimeDialog from '../../parking/TimeDialog';
import * as userActions from '../../../actions/userActions';
import * as publicUtils from '../../../utils/publicZone/publicUtils';
import {
  dateToNumber,
  getCMinJ,
  getMinutesFromTimeString,
  getPlannedParkingData,
  getTimeIntervals,
  getCurrentOrNextIntervalBeginning,
  getNowZoneTimeMinutes,
  getTotalMinutesOfDay,
  getOverlapValidatedMinutes,
  minutesToString,
  getInfo1,
  dateStringToNumber,
  dateDiffInDays,
  getExtendedEndTime,
  isOkMaxTimeOneTicket,
  generPayName,
  validateMinutesPlanIntervalBounds,
  validateMinutesPlanMaxHoursOneTicket,
  modifyPlannedTime,
  ZoneTimeList, // eslint-disable-line no-unused-vars
  TicketPriceSums, // eslint-disable-line no-unused-vars
  TicketTimeSums // eslint-disable-line no-unused-vars
} from '../../../utils/publicZone/parkingTicketUtils';

const styles = theme => ({
  ...getSharedStyles(theme),
  payBtn: {
    backgroundColor: '#4caf50',
    borderRadius: '30px',
    height: '55px'
  }
});

/**
  * @param {Object} params - Parametre usePlannedParking
  * @param {function(Object): void} params.dispatch
  * @param {Number} params.maxHoursOneTicket - Maximálny počet hodín pre jedno parkovanie
  * @param {TicketPriceSums} params.ticketPriceSums - Sumy cien lístkov po dňoch pre danú zónu a EČV
  * @param {TicketTimeSums} params.ticketTimeSums - Sumy časov lístkov po dňoch pre danú zónu a EČV
  * @param {function(string): string} params.translate
  * @param {ZoneTimeList} params.zoneTimeList - Zoznam údajov o parkovacej zóne
*/
const PlannedParking = ({
  dispatch,
  maxHoursOneTicket,
  ticketPriceSums,
  ticketTimeSums,
  translate,
  zoneTimeList,
  handleOpenPaymentMessagesDialog,
  ...props }) => {
  const [isOpenTimeDialog, setIsOpenTimeDialog] = useState(0);
  const [timeData, setTimeData] = useState({
    minutesFrom: -1,
    minutesTo: -1,
    dateFrom: dateTimeToDate(new Date()),
    dateTo: dateTimeToDate(new Date())
  });
  const [error, setError] = useState({
    date: ''
  });

  useEffect(() => {
    setTimeData({
      minutesFrom: -1,
      minutesTo: -1,
      dateFrom: dateTimeToDate(new Date()),
      dateTo: dateTimeToDate(new Date())
    });
  }, [zoneTimeList]);

  const handleShowTimeDialog = (value) => {
    setIsOpenTimeDialog(value);
    if (value === 0) {
      const isPlanOverlapZone = zoneTimeList.some(item => item.D_OD === "00:00" && item.D_DO2 === "24:00");

      // Ak je plánované parkovanie cez polnoc, chceme čas potiahnuť v prípade dosiahnutia limitu do konca času parkovania
      if (isPlanOverlapZone) {
        const res = getExtendedEndTime(timeData, zoneTimeList, ticketPriceSums, ticketTimeSums);

        if (res) {
          dispatch(userActions.showMsg(`Dosiahnutý limit pre cenu parkovania. Bol Vám automaticky predĺžený čas parkovania.`));
          setTimeData(res);
        }
      }
    }
  };

  const getDialogData = () => {
    const result = {
      showTimeDialog: isOpenTimeDialog,
      dateTitle: null,
      dateValue: null,
      timeTitle: null,
      timeValue: "-",
      captionOdDo: null,
      captionCena: null,
      btnMinutes: null,
      btnHours: null,
      disableMinutes: false
    };

    let isToTime, date, minutes;
    switch (isOpenTimeDialog) {
      case 1:
        isToTime = false;
        date = timeData.dateFrom;
        minutes = timeData.minutesFrom;
        result.dateTitle = translate('parkingShort.plan.dateFrom');
        result.timeTitle = translate('parkingShort.plan.timeFrom');
        break;
      case 2:
        isToTime = true;
        date = timeData.dateTo;
        minutes = timeData.minutesTo;
        result.dateTitle = translate('parkingShort.plan.dateTo');
        result.timeTitle = translate('parkingShort.plan.timeTo');
        break;
      default:
        return result;
    }
    if (minutes === -1) {
      initMinutes(isToTime);
    }

    const plannedParkingData = getPlannedParkingData(timeData, zoneTimeList, ticketPriceSums, ticketTimeSums);
    const totalMinutes = plannedParkingData.items.find(x => x.dateNumber === dateToNumber(date))?.dayMinutes ?? 0;
    const cMinJCurrent = getCMinJ(date, totalMinutes, zoneTimeList, ticketTimeSums);

    if (cMinJCurrent === 60) {
      result.disableMinutes = true;
    }
    const zoneTime = zoneTimeList.find(item => dateStringToNumber(item.DATUM) === dateToNumber(date));

    result.dateValue = `${('00' + date.getDate()).slice(-2)}.`;
    result.dateValue += `${('00' + (date.getMonth() + 1)).slice(-2)}`;
    result.dateValue += `.${date.getFullYear()}`;
    result.timeValue = minutesToString(minutes);
    result.captionOdDo = getInfo1(zoneTime, translate);
    result.btnMinutes = `${cMinJCurrent} min.`;
    result.btnHours = `1 ${translate('hour')}`;
    return result;
  };

  const initMinutes = (isToTime) => {
    const date = isToTime ? timeData.dateTo : timeData.dateFrom;
    const zoneTime = zoneTimeList.find(item => dateToNumber(new Date(item.DATUM)) === dateToNumber(date));

    let minutes = -1;
    const nowMinutes = getNowZoneTimeMinutes(zoneTimeList);
    minutes = getMinutesFromTimeString(zoneTime.D_OD);
    const timeIntervals = getTimeIntervals(zoneTime);
    const interval = getCurrentOrNextIntervalBeginning(timeIntervals, new Date());

    if (interval) {
      minutes = getTotalMinutesOfDay(interval);
    }
    if (nowMinutes >= 0 && dateStringToNumber(zoneTime.DATUM) === dateToNumber(new Date()) && nowMinutes > minutes) {
      minutes = nowMinutes;
    }

    /* Kontrola maximálneho času na jeden lístok - ak sa parkuje na viac dní, mohol by byť čas z initMinutes väčší ako maximum */
    if (!isOkMaxTimeOneTicket(isToTime, minutes, timeData, maxHoursOneTicket, zoneTimeList, ticketPriceSums, ticketTimeSums)) {
      maxHoursOneTicketWarning();
      return;
    }

    minutes = validateMinutesPlanIntervalBounds(isToTime, true, minutes, zoneTimeList, timeData);
    minutes = validateMinutesPlanMaxHoursOneTicket(
      isToTime,
      true,
      minutes,
      maxHoursOneTicket,
      timeData,
      zoneTimeList,
      maxHoursOneTicketWarning,
      ticketPriceSums,
      ticketTimeSums);
    const { minutesFrom, minutesTo, dateTo } = getOverlapValidatedMinutes(isToTime, minutes, zoneTime, timeData);

    setTimeData({ ...timeData, minutesFrom, minutesTo, dateTo });
  };

  const handleModifyTime = (isToTime, buttonId) => {
    const timeDataTemp = modifyPlannedTime(
      isToTime,
      buttonId,
      timeData,
      zoneTimeList,
      ticketPriceSums,
      ticketTimeSums,
      maxHoursOneTicket,
      maxHoursOneTicketWarning);
    if (!timeDataTemp) return;

    checkTimeValidity(timeDataTemp);
    setTimeData(timeDataTemp);
  };

  const handleDatePickersChange = (isToTime, value) => {
    const zoneTime = zoneTimeList.find(item => dateStringToNumber(item.DATUM) === dateToNumber(value));
    if (!zoneTime) return;

    if (isToTime) {
      setTimeData({ ...timeData, dateTo: value, minutesTo: -1 });
    } else {
      setTimeData({ ...timeData, dateFrom: value, minutesFrom: -1 });
    }
  };

  /** Pridanie parkovacieho lístka. Tu prebieha iba kontrola, či už neexistuje v danom
      čase platný lístok alebo či používateľ nemá platnú parkovaciu kartu */
  const addParking = async () => {
    const isValid = props.validateForm();

    if (isValid) {
      if (props.hasValidParkingCard()) return;
      props.setIsLoading(true);

      const timeFrom = new Date(timeData.dateFrom);
      timeFrom.setHours(timeData.minutesFrom / 60);
      timeFrom.setMinutes(timeData.minutesFrom % 60);

      const timeTo = new Date(timeData.dateTo);
      timeTo.setHours(timeData.minutesTo / 60);
      timeTo.setMinutes(timeData.minutesTo % 60);

      const previousTicketDateTimeTo = await publicUtils.checkParkingTicket(
        props.iZone,
        props.ECV,
        addOffset(timeFrom).toJSON(),
        addOffset(timeTo).toJSON()
      );

      if (previousTicketDateTimeTo) {
        const currentTicketTimeTo = new Date(timeData.dateTo);
        currentTicketTimeTo.setHours(timeData.minutesTo / 60);
        currentTicketTimeTo.setMinutes(timeData.minutesTo % 60);

        if (currentTicketTimeTo <= previousTicketDateTimeTo) {
          let message = `${translate('parkingShort.dialogContextNotify1')} `;
          message += previousTicketDateTimeTo?.toLocaleString("sk-SK");
          message += ` ${translate('parkingShort.dialogContextNotify2')}`;

          props.handleOpenNotify(message);
          props.setIsLoading(false);
          return;
        } else {
          const previousMinutes = previousTicketDateTimeTo.getHours() * 60 + previousTicketDateTimeTo.getMinutes();

          const timeDataTemp = {
            ...timeData,
            minutesFrom: previousMinutes,
            dateFrom: previousTicketDateTimeTo
          };

          let message = `${translate('parkingShort.dialogContextCheck1')} `;
          message += previousTicketDateTimeTo?.toLocaleString("sk-SK");
          message += ` ${translate('parkingShort.dialogContextCheck2')}`;
          /* Funkcia si berie globálne premenné z času definovania, treba poslať timeData ako parameter */
          props.handleOpenExtend(message, () => addParkingChecked(timeDataTemp));
          setTimeData(timeDataTemp);
          props.setIsLoading(false);
          return;
        }
      }

      /* Už skontrolované, zaevidujeme lístok */
      addParkingChecked(timeData);
    }
  };

  /** Pridanie parkovacieho lístka už po kontrole */
  const addParkingChecked = async (timeDataTemp) => {
    props.handleCloseExtend();

    props.storage.setItemLocal('DM_ECV', props.ECV);
    props.storage.setItemLocal('DM_EMAIL', props.email);

    const payData = { 'items': [], 'totalAmount': 0, 'totalAmountDec': 0, currencyCode: 'EUR' };
    const plannedParkingData = getPlannedParkingData(timeDataTemp, zoneTimeList, ticketPriceSums, ticketTimeSums);

    const timeFrom = new Date(timeDataTemp.dateFrom);
    timeFrom.setHours(timeDataTemp.minutesFrom / 60);
    timeFrom.setMinutes(timeDataTemp.minutesFrom % 60);

    const timeTo = new Date(timeDataTemp.dateTo);

    const lastItem = plannedParkingData.items[plannedParkingData.items.length - 1];
    if (lastItem && lastItem.dDo === "24:00" && timeDataTemp.minutesTo === 1440) {
      timeTo.setHours(23);
      timeTo.setMinutes(59);
      timeTo.setSeconds(59);
    } else {
      timeTo.setHours(timeDataTemp.minutesTo / 60);
      timeTo.setMinutes(timeDataTemp.minutesTo % 60);
    }

    const data = {
      ECV: props.ECV,
      I_ZONA: props.iZone,
      EMAIL: props.email,
      LANGUAGE: props.language,
      D_OD: addOffset(timeFrom),
      D_DO: addOffset(timeTo),
      CENA: plannedParkingData.totalPrice
    };

    plannedParkingData.items.forEach(item => {
      if (item.dayPrice > 0) {
        const name = generPayName(props.ECV, item.date, item.minutesFrom, item.minutesTo, translate);

        const amountDec = Number(item.dayPrice);
        payData.totalAmountDec = Number(payData.totalAmountDec) + amountDec;

        const amount = Math.round(amountDec * 100);
        payData.totalAmount = Number(payData.totalAmount) + amount;

        payData.items.push({ name, amount, amountDec });
      }
    });

    props.dispatchTicket(payData, data);
  };

  const checkTimeValidity = (timeData) => {
    let errorTextDate = '';
    const dateTimeFrom = new Date(timeData.dateFrom);
    dateTimeFrom.setHours(timeData.minutesFrom / 60, timeData.minutesFrom % 60);

    const dateTimeTo = new Date(timeData.dateTo);
    dateTimeTo.setHours(timeData.minutesTo / 60, timeData.minutesTo % 60);

    if (dateTimeFrom > dateTimeTo && timeData.minutesFrom >= 0 && timeData.minutesTo >= 0) {
      errorTextDate = translate('parkingShort.plan.errMess');
    }

    setError({ ...error, date: errorTextDate });
  };

  const maxHoursOneTicketWarning = () => dispatch(userActions.showErrorMsg("Dosiahnutý maximálny čas pre jedno parkovanie."));

  const disablePayButton = () => {
    const totalTime = getPlannedParkingData(timeData, zoneTimeList, ticketPriceSums, ticketTimeSums).totalMinutes;
    if (!isTimeDataFilled() || totalTime === 0) return true; else return false;
  };

  const disableTimePicker = (isToTime) => {
    const dateNumber = dateToNumber(isToTime ? timeData.dateTo : timeData.dateFrom);

    if (dateToNumber(new Date()) > dateNumber) {
      return true;
    }

    const zoneTime = zoneTimeList.find(item => dateStringToNumber(item.DATUM) === dateNumber);
    if (!zoneTime || !zoneTime.D_OD || !zoneTime.D_DO) return true;

    const nowMinutes = getNowZoneTimeMinutes(zoneTimeList);
    const end = getMinutesFromTimeString(zoneTime?.D_DO3 ?? zoneTime?.D_DO2 ?? zoneTime.D_DO);
    if (nowMinutes >= 0 && dateToNumber(new Date()) === dateNumber && nowMinutes > end) {
      return true;
    }

    return false;
  };

  const disableTimePickers = () => {
    const diff = dateDiffInDays(timeData.dateFrom, timeData.dateTo);
    if (zoneTimeList.length === 0) return true;
    const zoneTime = zoneTimeList.find(item => dateStringToNumber(item.DATUM) === dateToNumber(timeData.dateFrom));
    if (!zoneTime) return true;

    const isPlanOverlapZone = zoneTime.D_OD === "00:00" && zoneTime.D_DO2 === "24:00";

    return (isPlanOverlapZone && diff > 1) || (diff < 0 ? true : false);
  };

  const isTimeDataFilled = () => timeData.minutesFrom >= 0 && timeData.minutesTo >= 0 && timeData.dateFrom && timeData.dateTo;

  const getFormattedMinutes = () => {
    if (!isTimeDataFilled()) return '00:00';
    const plannedParkingData = getPlannedParkingData(timeData, zoneTimeList, ticketPriceSums, ticketTimeSums);
    return minutesToString(plannedParkingData.totalMinutes);
  };

  const getFormattedPrice = () => {
    if (!isTimeDataFilled()) return '0,00';
    const plannedParkingData = getPlannedParkingData(timeData, zoneTimeList, ticketPriceSums, ticketTimeSums);
    return `${plannedParkingData.totalPrice.toFixed(2)}`;
  };

  const maxDateTo = () => {
    if (zoneTimeList.length === 0) return new Date();

    const maxDate = new Date(zoneTimeList.at(-1)['DATUM']);
    const zoneTime = timeData.dateFrom ?
      zoneTimeList.find(item => dateToNumber(new Date(item.DATUM)) === dateToNumber(timeData.dateFrom)) : null;

    if (zoneTime && zoneTime.D_OD === "00:00" && zoneTime.D_DO2 === "24:00") {
      const minutesTo = getMinutesFromTimeString(zoneTime.D_DO);

      if (timeData.minutesFrom >= 0 && timeData.minutesFrom < minutesTo) {
        return new Date(timeData.dateFrom);
      }
      return new Date(timeData.dateFrom).setDate(timeData.dateFrom.getDate() + 1);
    }

    return maxDate;
  };

  const { classes } = props;

  return (
    <>
      {/*PICKER OD */}
      <div className={classNames(classes.row, classes.alignItemsCenter, classes.mb3)}>
        {/*PICKER NA DATUM*/}
        <DatePickerV5
          onlyMobileVersion={true}
          name='dateOd'
          label={translate('parkingShort.plan.dateFrom')}
          format={props.locale === "sk-SK" ? "D.M.YYYY" : undefined} // potrebne aby sa akceptoval shouldRespectLeadingZeros={true}
          shouldRespectLeadingZeros={true}
          fullWidth={false}
          value={timeData.dateFrom?.toString()}
          // defaultValue={timeData.dateFrom?.toString()}
          onChange={(_name, value) => handleDatePickersChange(false, value)}
          clearable={false}
          keyboards={true}
          disableFuture={false}
          disablePast={true}
          className={classNames(classes.mr4)}
          closeOnSelect={false}
          disabled={!(zoneTimeList?.length > 0)}
          disableOpenPicker={false}
          displayWeekNumber={false}
          maxDate={zoneTimeList.length > 0 ? new Date(zoneTimeList.at(-1)['DATUM']) : new Date()}
          formatDensity={"dense"}
          localeText={{}}
          openTo={"year"}
          reduceAnimations={true}
          showDaysOutsideCurrentMonth={true}
          yearsOrder="desc"
          actions={['today', 'cancel', 'accept']}
        />
        {/*PICKER NA CAS */}
        <div className={classNames(classes.mr4)}>
          <TextField
            name='timeOd'
            type="text"
            onChange={() => { }}
            label={translate('parkingShort.plan.timeFrom')}
            value={minutesToString(timeData.minutesFrom)}
            disabled={disableTimePickers() || disableTimePicker(false)}
            fullwidth={false}
            inputProps={{
              endAdornment: (
                <InputAdornment>
                  <IconButton
                    disabled={disableTimePickers() || disableTimePicker(false)}
                    disableTooltip={true}
                    size="small"
                    onClick={_event => handleShowTimeDialog(1)}
                  >
                    <ScheludeIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            variant="standard"
          />
        </div>
      </div>
      {/*PICKER DO */}
      <div className={classNames(classes.row, classes.alignItemsCenter, classes.mb3)}>
        {/*PICKER NA DATUM */}
        <DatePickerV5
          onlyMobileVersion={true}
          name='dateDo'
          label={translate('parkingShort.plan.dateTo')}
          format={props.locale === "sk-SK" ? "D.M.YYYY" : undefined} // potrebne aby sa akceptoval shouldRespectLeadingZeros={true}
          shouldRespectLeadingZeros={true}
          fullWidth={false}
          value={timeData.dateTo?.toString()}
          // defaultValue={timeData.dateTo?.toString()}
          onChange={(_name, value) => handleDatePickersChange(true, value)}
          clearable={false}
          keyboards={true}
          disableFuture={false}
          disablePast={true}
          className={classNames(classes.mr4)}
          closeOnSelect={false}
          disabled={!(zoneTimeList?.length > 0)}
          disableOpenPicker={false}
          displayWeekNumber={false}
          maxDate={maxDateTo()}
          formatDensity={"dense"}
          localeText={{}}
          openTo={"year"}
          reduceAnimations={true}
          showDaysOutsideCurrentMonth={true}
          yearsOrder="desc"
          actions={['today', 'cancel', 'accept']}
        />
        {/*PICKER NA CAS */}
        <div className={classNames(classes.mr4)}>
          <TextField
            name='timeDo'
            type="text"
            onChange={() => { }}
            label={translate('parkingShort.plan.timeTo')}
            value={minutesToString(timeData.minutesTo)}
            disabled={disableTimePickers() || disableTimePicker(true)}
            fullwidth={false}
            inputProps={{
              endAdornment: (
                <InputAdornment>
                  <IconButton
                    disabled={disableTimePickers() || disableTimePicker(true)}
                    disableTooltip={true}
                    size="small"
                    onClick={_event => handleShowTimeDialog(2)}
                  >
                    <ScheludeIcon />
                  </IconButton>
                </InputAdornment>
              ),
            }}
            variant="standard"
          />
        </div>
      </div>
      {/* ERROR TEXT */}
      {error.date &&
        <div>
          <Typography variant="body1" style={{ textAlign: 'center', color: 'red', marginBottom: '10px' }} >{error.date}</Typography>
        </div>}
      {/* CAS HH:MM */}
      <Typography variant="h4" style={{ textAlign: 'center', fontWeight: 'bold' }}>{getFormattedMinutes()}</Typography>
      {/* TEXT CELKOVY SPOPLATNENY CAS */}
      <div style={{ textAlign: 'center' }}>
        <Typography variant="caption" className={classNames(classes.textCenter, classes.mb4,)}>{translate('parkingShort.plan.titlePaidTime')}</Typography>
      </div>
      {/* BUTTON NA PLATBU */}
      <div className={classNames(classes.row, classes.alignItemsCenter, classes.mb4)}  >
        <Button
          disabled={disablePayButton()}
          variant="contained"
          color="secondary"
          size="large"
          className={classNames(classes.payBtn, classes.w100)}
          onClick={addParking}
        >
          {translate('pay')}   {getFormattedPrice()} €
        </Button>

        {props.adminMode?.isActive &&
          <ButtonFab
            toolTip="Nastavenie oznamov o výsledku platby"
            toolTipPlace="right-start"
            style={{ backgroundColor: '#ffffff', flexShrink: 0, margin: '0 10px 0 10px' }}
            size="small"
            onClick={handleOpenPaymentMessagesDialog}
          >
            <EditIcon />
          </ButtonFab>

          // </ToolTip>
        }

      </div >
      {/* DIALOG NA VYKLIKAVANIE CASU */}
      < TimeDialog
        handleClose={() => handleShowTimeDialog(0)}
        handleModifyTime={handleModifyTime}
        data={getDialogData()}
      />
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    zoneTimeList: state.zoneTimeList.data.Data?.Items ?? [],
    ticketPriceSums: state.ticketPriceSums.data,
    ticketTimeSums: state.ticketTimeSums.data,
    maxHoursOneTicket: state.maxHoursOneTicket.data,
    adminMode: state.adminMode,
  };
};

export default withRouter(withMeta(storage(withLocalizationConsumer(connect(mapStateToProps)(withStyles(styles)(PlannedParking))))));