import { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import MomentUtils from '@date-io/moment';
import moment from 'moment';
import 'moment/locale/de';
import 'moment/locale/sk';
import 'moment/locale/en-gb';
import { isNil } from "lodash";
import classNames from 'classnames';
// import { MuiPickersUtilsProvider } from 'material-ui-pickers';

import withStyles from '@mui/styles/withStyles';
import InputAdornment from '@mui/material/InputAdornment';
import Stack from '@mui/material/Stack';

import CircularProgress from '../../../coraWebMComponents/feedBack/progress/CircularProgress';
import Typography from '../../../coraWebMComponents/dataDisplay/Typography';
import getSharedStyles from '../../../coraWebMComponents/sharedStyles';
import IconButton from '../../../coraWebMComponents/inputs/IconButton';
import ArrowBack from '../../../coraWebMComponents/dataDisplay/icons/ArrowBack';
import ButtonFab from '../../../coraWebMComponents/inputs/ButtonFab';
import PlaceIcon from '../../../coraWebMComponents/dataDisplay/icons/PlaceIcon';
import CarIcon from '../../../coraWebMComponents/dataDisplay/icons/CarIcon';
import TextField from '../../../coraWebMComponents/inputs/TextField';
import EmailIcon from '../../../coraWebMComponents/dataDisplay/icons/EmailIcon';
import SelectFilterField from '../../../coraWebMComponents/inputs/SelectFilterField';
import { withLocalizationConsumer } from '../../../coraWebMComponents/localization/withLocalization';
import storage from '../../../coraWebMComponents/utils/withStorage';
import SwitchComponent from '../../../coraWebMComponents/inputs/CustomSwitch';
import MapIcon from '../../../coraWebMComponents/dataDisplay/icons/MapIcon';
import withMeta from "../../../coraWebMComponents/utils/withMeta";
import withRouter from '../../../coraWebMComponents/utils/withRouter';
import Dialog from '../../../coraWebMComponents/feedBack/Dialog';
import { useDidMount } from '../../../coraWebMComponents/utils/hooks';
import { isValidEcv, isValidMail } from '../../../coraWebMComponents/utils/validator';
import SelectField from '../../../coraWebMComponents/inputs/SelectField';
import EditIcon from "../../../coraWebMComponents/dataDisplay/icons/EditIcon";
import InfoIcon from '../../../coraWebMComponents/dataDisplay/icons/InfoIcon';
import CancelOutlinedIcon from '../../../coraWebMComponents/dataDisplay/icons/CancelOutlinedIcon';
import CheckCircleOutlineIcon from '../../../coraWebMComponents/dataDisplay/icons/CheckCircleOutlineIcon';

import ECVDialog from '../ECVDialog';
import NowParking from './NowParking';
import PlannedParking from './PlannedParking';
import * as ciselnikyActions from '../../../actions/ciselnikyActions';
import * as parkingActions from '../../../actions/parkingActions';
import * as userActions from '../../../actions/userActions';
import * as publicActions from '../../../actions/publicZone/publicActions';
import * as adminModeUtils from '../../../utils/adminModeUtils';
import routes from '../../../routes';
import { ZoneTimeList } from '../../../utils/publicZone/parkingTicketUtils'; // eslint-disable-line no-unused-vars
import { adminModeSetCurrEdited, adminModeSetEditedData, adminModeUpdateOrAdd } from "../../../actions/adminModeActions";
import { PUBLIC_ZONE, IFrmTxt } from "../../../constants/adminConsts";

const LANGUAGE_LIST = [
  { "LANGUAGE": "sk-SK", "N_LANGUAGE": "Slovenčina", "ICON": "assets/svk-flag-icon.svg" },
  { "LANGUAGE": "en-US", "N_LANGUAGE": "English", "ICON": "assets/uk-flag-icon.svg" },
  { "LANGUAGE": "de-DE", "N_LANGUAGE": "Deutsch", "ICON": "assets/ger-flag-icon.svg" }
];

const styles = theme => ({
  ...getSharedStyles(theme),
});

/**
 * @param {Object} params - Parametre ParkingTicket
 * @param {async function(Object): void} params.dispatch HACK: dispatch nie je async, ale await funguje ak chceme awaitnúť výsledok dispatchnutej akcie a v handleECVBlur, aby sa zobrazil loading 
 * @param {boolean} params.isAuth - Či je používateľ prihlásený
 * @param {function(Object): void} params.navigate
 * @param {ZoneTimeList} params.zoneTimeList - Zoznam údajov o parkovacej zóne
 * @param {Object} params.paymentSettings - Nastavenia platby
 * @param {function(string): string} params.translate
 */
const ParkingTicket = ({
  dispatch,
  isAuth,
  navigate,
  zoneTimeList,
  paymentSettings,
  translate,
  ...props }) => {
  const [tabIndex, setTabIndex] = useState(0);
  const [language, setLanguage] = useState(props.locale);
  const [iZone, setIZone] = useState(0);
  const [ECV, setECV] = useState('');
  const [email, setEmail] = useState('');
  const [showMapButton, setShowMapButton] = useState(false);
  const [mapButtonDisabled, setMapButtonDisabled] = useState(false);
  const [notify, setNotify] = useState({ open: false, text: '' });
  const [extend, setExtend] = useState({ open: false, text: '', addParkingHandler: () => { } });
  const [freeParkingZonesDialog, setFreeParkingZonesDialog] = useState({ open: false, toChange: [] });
  const [changeFieldNameDialog, setChangeFieldNameDialog] = useState({
    open: false, value: { sk: "", en: "", de: "" }
  });
  const [paymentMessagesDialog, setPaymentMessagesDialog] = useState({ open: false, toChange: {} });
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState({ zone: '', ECV: '', email: '' });
  const [errorTextParkingSuccess, setErrorTextParkingSuccess] = useState('');
  const [errorTextParkingVerify, setErrorTextParkingVerify] = useState('');
  const [errorTextParkingFailure, setErrorTextParkingFailure] = useState('');

  useEffect(() => {
    const getParkingZoneLabel = async () => {
      await dispatch(parkingActions.getParkingZoneLabel(props.locale));
    };
    getParkingZoneLabel();
  }, [props.locale]);

  const fetchLabels = async () => {
    const locales = ["sk-SK", "en-US", "de-DE"];

    const [skLabel, enLabel, deLabel] = await Promise.all(
      locales.map((locale) => dispatch(parkingActions.getParkingZoneLabel(locale, false)))
    );

    setChangeFieldNameDialog({
      open: false,
      value: {
        sk: skLabel.Data.TxtFrm,
        en: enLabel.Data.TxtFrm,
        de: deLabel.Data.TxtFrm
      }
    });

    await dispatch(parkingActions.getParkingZoneLabel(props.locale));
  };


  useDidMount(async () => {
    setIsLoading(true);

    const search = window.location.href.split('?').pop();
    const params = new URLSearchParams(search);
    const iZoneParam = params.get("zoneID");
    const ECVParam = params.get("ecv");

    if (!paymentSettings.isLoaded) {
      dispatch(ciselnikyActions.getPaymentSettings());
    }

    const listZone = await dispatch(ciselnikyActions.getListZoneParkShort(iZoneParam));
    fetchLabels();
    dispatch(ciselnikyActions.getVisibleParkShort());
    dispatch(ciselnikyActions.getNotAvailableFreeParking());
    dispatch(ciselnikyActions.getVisiblePlanButton());
    dispatch(ciselnikyActions.getMaxHoursOneTicket());

    if (isAuth) {
      dispatch(parkingActions.getECVList());
      dispatch(parkingActions.getActiveParkingCard(props.person.I_O));
    }

    let ECV = ECVParam ? ECVParam : props.storage.getItemLocal('DM_ECV');
    let email = props.storage.getItemLocal('DM_EMAIL');

    setECV(ECV ?? '');
    setEmail(isAuth ? props.user.data.n_uz : email ? email : '');
    setShowMapButton(props.meta.menu.find(x => x.MenuID === 4004016)?.Visible === 1);
    setMapButtonDisabled(props.meta.menu.find(x => x.MenuID === 4004016)?.Visible === 2);

    if (props.location.state?.zona) {
      await handleChangeZone(null, props.location.state.zona);
    }

    if (listZone.length === 1) {
      await handleChangeZone(null, listZone[0].I_ZONA);
    }

    moment.updateLocale('de', {
      weekdaysShort: ["So", "Mo", "Di", "Mi", "Do", "Fr", "Sa"]
    });
    setIsLoading(false);
  });

  const handleBack = () => {
    if (isAuth) {
      navigate({ pathname: routes.PARKING_TICKET });
    } else {
      navigate(routes.PRELOGIN);
    }
  };

  const handleOpenNotify = (text) => {
    setNotify({ text, open: true });
  };

  const handleCloseNotify = () => {
    setNotify({ text: '', open: false });
  };

  const handleOpenExtend = (text, addParkingHandler) => {
    setExtend({ text, open: true, addParkingHandler });
  };

  const handleCloseExtend = () => {
    setExtend({ text: '', open: false, addParkingHandler: () => { } });
  };

  const getFreeParkingZonesDialogContent = () => {
    return (
      <>
        <SelectField
          floatingLabelText={props.parkingZoneLabel}
          value={freeParkingZonesDialog.toChange}
          onChange={handleChangeFreeParkingZones}
          fullwidth={true}
          schema={{ label: 'N_ZONA', value: 'I_ZONA' }}
          data={props.listZone}
          icon={<PlaceIcon />}
          multiple={true}
          wrap={true}
        />
      </>
    );
  };

  const getChangeFieldNameContent = () => {
    return (
      <>
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", marginBottom: "20px" }}>
          <TextField
            value={changeFieldNameDialog.value.sk || ''}
            fullwidth={true}
            onChange={(name, value) => { handleChangeChangeFieldName(name, value, 'sk'); }}
            name='changeFieldNameSK'
            variant="standard"
            inputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <img
                    style={{ height: '20px', marginRight: '5px' }}
                    src={'assets/svk-flag-icon.svg'}
                    alt={'sk'}
                  />
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center", marginBottom: "20px" }}>
          <TextField
            value={changeFieldNameDialog.value.en || ''}
            fullwidth={true}
            onChange={(name, value) => { handleChangeChangeFieldName(name, value, 'en'); }}
            name='changeFieldNameEN'
            variant="standard"
            inputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <img
                    style={{ height: '20px', marginRight: '5px' }}
                    src={'assets/uk-flag-icon.svg'}
                    alt={'sk'}
                  />
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div style={{ display: "flex", flexDirection: "row", alignItems: "center" }}>
          <TextField
            value={changeFieldNameDialog.value.de || ''}
            fullwidth={true}
            onChange={(name, value) => { handleChangeChangeFieldName(name, value, 'de'); }}
            name='changeFieldNameDE'
            variant="standard"
            inputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <img
                    style={{ height: '20px', marginRight: '5px' }}
                    src={'assets/ger-flag-icon.svg'}
                    alt={'sk'}
                  />
                </InputAdornment>
              ),
            }}
          />
        </div>
      </>
    );
  };

  const getPaymentMessagesDialogContent = () => {
    return (
      <Stack flexDirection={"column"} spacing={3}>
        <TextField
          label={"Platba prebehla úspešne"}
          fullwidth={true}
          value={paymentMessagesDialog.toChange?.parkingSuccess}
          errorText={errorTextParkingSuccess}
          error={errorTextParkingSuccess ? true : false}
          onChange={handleChangeParkingSuccess}
          name='parkingSuccess'
          variant="standard"
          inputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <CheckCircleOutlineIcon style={{ width: 30, height: 30, color: "#008000" }} />
              </InputAdornment>
            ),
          }}
        />
        <TextField
          label={"Platba sa nepodarila"}
          fullwidth={true}
          value={paymentMessagesDialog.toChange?.parkingFailure}
          errorText={errorTextParkingFailure}
          error={errorTextParkingFailure ? true : false}
          onChange={handleChangeParkingFailure}
          name='parkingFailure'
          variant="standard"
          inputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <CancelOutlinedIcon style={{ width: 30, height: 30, color: "#FF3333" }} />
              </InputAdornment>
            ),
          }}
        />
        <TextField
          label={"Overenie platby sa nepodarilo"}
          fullwidth={true}
          value={paymentMessagesDialog.toChange?.parkingVerify}
          errorText={errorTextParkingVerify}
          error={errorTextParkingVerify ? true : false}
          onChange={handleChangeParkingVerify}
          name='parkingVerify'
          variant="standard"
          inputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <InfoIcon style={{ width: 30, height: 30, color: "#ef6c00" }} />
              </InputAdornment>
            ),
          }}
        />
      </Stack>
    );
  };

  // doplni do pola 'dataEdited' objekt 'value', ak uz existuje len ho zaktualizuje
  const updateDataEdited = (dataEdited = [], value) => {
    // Najdeme index existujuceho prvku s rovnakym N_KLUC
    const index = dataEdited.findIndex(item => item.N_KLUC === value.N_KLUC);

    if (index !== -1) {
      // Ak existuje, aktualizujeme existujuci prvok
      const updatedData = [...dataEdited];
      updatedData[index] = value;
      return updatedData;
    } else {
      // Ak neexistuje, pridame novy prvok do pola
      return [...dataEdited, value];
    }
  };

  const isValidMessage = (message) => {
    let errorString = '';
    const regex = /<[^>]+>/g;

    if (message.length < 2) {
      errorString += 'Minimálna dĺžka správy sú 2 znaky.\n';
    }

    if (message.length > 4000) {
      errorString += 'Maximálna dĺžka správy je 4000 znakov.\n';
    }

    if (regex.test(message)) {
      errorString += 'HTML tagy nie sú povolené.\n';
    }
    return errorString;
  };

  // zaktualizuj text pre: uspesnu platbu
  const handleChangeParkingSuccess = (_event, value) => {
    const { dataEdited } = props.adminMode;
    setPaymentMessagesDialog({ ...paymentMessagesDialog, toChange: { ...paymentMessagesDialog.toChange, parkingSuccess: value } });
    const errorTextParkingSuccess = typeof value === "string" && isValidMessage(value);
    setErrorTextParkingSuccess(errorTextParkingSuccess);

    dispatch(adminModeSetEditedData(updateDataEdited(dataEdited || [],
      {
        TxtFrm: value.replace(/<[^>]+>/g, ''),
        ID: null,
        locale: language,
        N_KLUC: "DM_BESTERON_RES_OK",
        IFrmTxt: 128,
      },
    )));
  };

  // zaktualizuj text pre: neuspesnu platbu
  const handleChangeParkingFailure = (_event, value) => {
    const { dataEdited } = props.adminMode;
    setPaymentMessagesDialog({ ...paymentMessagesDialog, toChange: { ...paymentMessagesDialog.toChange, parkingFailure: value } });
    const errorTextParkingFailure = typeof value === "string" && isValidMessage(value);
    setErrorTextParkingFailure(errorTextParkingFailure);

    dispatch(adminModeSetEditedData(updateDataEdited(dataEdited || [],
      {
        TxtFrm: value.replace(/<[^>]+>/g, ''),
        ID: null,
        locale: language,
        N_KLUC: "DM_BESTERON_RES_ER",
        IFrmTxt: 129,
      },
    )));
  };

  // zaktualizuj text pre: neuspesnu platbu z dovodu neoverenia
  const handleChangeParkingVerify = (event, value) => {
    const { dataEdited } = props.adminMode;
    setPaymentMessagesDialog({ ...paymentMessagesDialog, toChange: { ...paymentMessagesDialog.toChange, parkingVerify: value } });
    const errorTextParkingVerify = typeof value === "string" && isValidMessage(value);
    setErrorTextParkingVerify(errorTextParkingVerify);

    dispatch(adminModeSetEditedData(updateDataEdited(dataEdited || [],
      {
        TxtFrm: value.replace(/<[^>]+>/g, ''),
        ID: null,
        locale: language,
        N_KLUC: "DM_BESTERON_RES_NOT_CONFIRM",
        IFrmTxt: 130,
      },
    )));
  };

  const handleOpenPaymentMessagesDialog = async () => {
    let parkingSuccess = await props.translateWithLocaleAsync(props.locale, 'DM_BESTERON_RES_OK');
    let parkingVerify = await props.translateWithLocaleAsync(props.locale, 'DM_BESTERON_RES_NOT_CONFIRM');
    let parkingFailure = await props.translateWithLocaleAsync(props.locale, 'DM_BESTERON_RES_ER');

    setPaymentMessagesDialog({
      open: true,
      toChange: {
        parkingSuccess,
        parkingVerify,
        parkingFailure
      }
    });

    dispatch(adminModeSetCurrEdited(PUBLIC_ZONE));
    dispatch(adminModeSetEditedData(null));

    setErrorTextParkingSuccess('');
    setErrorTextParkingFailure('');
    setErrorTextParkingVerify('');
  };

  const handleClosePaymentMessagesDialog = () => {
    setPaymentMessagesDialog({ open: false, toChange: {} });
    setErrorTextParkingSuccess('');
    setErrorTextParkingFailure('');
    setErrorTextParkingVerify('');
  };

  const handleSavePaymentMessages = async () => {
    const { dataEdited } = props.adminMode;
    setIsLoading(true);

    const res = await dispatch(adminModeUpdateOrAdd(IFrmTxt, dataEdited));
    dispatch(adminModeSetCurrEdited(null));
    if (res) {
      dataEdited && props.showMsg("Zmeny boli uložené"); // len ak doslo k editovaniu dat
    }
    else {
      props.showErrorMsg("Error: Chyba pri komunikácii s databázou");
    }

    handleClosePaymentMessagesDialog();
    setIsLoading(false);
  };

  const handleChangeFreeParkingZones = (_event, value) => {
    setFreeParkingZonesDialog({ ...freeParkingZonesDialog, toChange: value });
  };

  const handleChangeChangeFieldName = (name, value, locale) => {
    setChangeFieldNameDialog({
      ...changeFieldNameDialog,
      value: { ...changeFieldNameDialog.value, [locale]: value }
    });
  };


  const handleOpenFreeParkingZonesDialog = () => {
    setFreeParkingZonesDialog({ open: true, toChange: props.noFreeParkingZoneList });
  };

  const handleCloseFreeParkingZonesDialog = () => {
    setFreeParkingZonesDialog({ open: false, toChange: [] });
  };

  const handleOpenChangeFieldNameDialog = () => {
    setChangeFieldNameDialog({ ...changeFieldNameDialog, open: true });
  };

  const handleCloseChangeFieldNameDialog = () => {
    setChangeFieldNameDialog({ ...changeFieldNameDialog, open: false });
  };

  const handleSaveFreeParkingZones = async () => {
    setIsLoading(true);
    await adminModeUtils.updateSpDefOb(4003001013, freeParkingZonesDialog.toChange.toString());
    handleCloseFreeParkingZonesDialog();
    setIsLoading(false);
  };

  const handleSaveChangeFieldName = async () => {
    setIsLoading(true);
    const resSK = await dispatch(parkingActions.postParkingZoneLabel(changeFieldNameDialog.value.sk, "sk-SK", false));
    const resEN = await dispatch(parkingActions.postParkingZoneLabel(changeFieldNameDialog.value.en, "en-US", false));
    const resDE = await dispatch(parkingActions.postParkingZoneLabel(changeFieldNameDialog.value.de, "de-DE", false));
    console.log(resSK, resEN, resDE);
    if (resSK && resEN && resDE) {
      props.showMsg("Požiadavka úspešne spracovaná.");
    }
    else {
      props.showErrorMsg("Požiadavka neúspešne spracovaná");
    }
    await fetchLabels();
    handleCloseChangeFieldNameDialog();
    setIsLoading(false);
  };

  const getActiveParkCard = () => props.activeParkingCards?.find(x =>
    (x.ECV_TMP === ECV) || (!x.ECV_TMP && x.ECV === ECV)
  ) || null;

  const validateForm = () => {
    const errorTextZone = iZone ? '' : this.props.parkingZoneLabel + translate('isRequired');
    const errorECV = isValidEcv(ECV, language);
    const errorTextECV = ECV ? errorECV : translate('ECV') + translate('isRequired');
    const errorEmail = isValidMail(email, language);
    const errorTextEmail = email ? errorEmail : translate('mail') + translate('isRequired');

    setError({ zone: errorTextZone, ECV: errorTextECV, email: errorTextEmail });

    return errorTextZone === '' && errorTextECV === '' && errorTextEmail === '';
  };

  const getTicketTimeSums = async (zoneTimeListTemp, iZoneTemp, ECVTemp) => {
    const iZoneCurrent = iZoneTemp ?? iZone;
    const zoneTimeListCurrent = zoneTimeListTemp ?? zoneTimeList;
    if (!zoneTimeListCurrent || zoneTimeListCurrent.length === 0) return;

    const ECVCurrent = ECVTemp ?? ECV;
    const shouldFetch = zoneTimeListCurrent.some(x => x.C_MIN_J2 !== null);

    if (!shouldFetch) {
      return;
    }

    await dispatch(publicActions.getTicketTimeSums(iZoneCurrent, ECVCurrent));
  };

  const getTicketPriceSums = async (zoneTimeListTemp, iZoneTemp, ECVTemp) => {
    const iZoneCurrent = iZoneTemp ?? iZone;
    const zoneTimeListCurrent = zoneTimeListTemp ?? zoneTimeList;
    if (!zoneTimeListCurrent || zoneTimeListCurrent.length === 0) return;

    const ECVCurrent = ECVTemp ?? ECV;
    const shouldFetch = zoneTimeListCurrent.some(x => x.LIMIT !== null);

    if (!shouldFetch) {
      return;
    }

    await dispatch(publicActions.getTicketPriceSums(iZoneCurrent, ECVCurrent));
  };

  const handleMap = () => {
    navigate(routes.MAP, { state: { from: "PARKING_SHORT" } });
  };

  const handleSwitchChange = (_event, value) => {
    setTabIndex(value);
  };

  const handleLanguageChange = (_event, value) => {
    dispatch(userActions.setLocale(value.trim()));
    setLanguage(value);
  };

  const handleChangeZone = async (_event, value) => {
    setIsLoading(true);
    const zoneTimeList = await dispatch(ciselnikyActions.getZoneTimeList(value, 2));

    await getTicketPriceSums(zoneTimeList, value);
    await getTicketTimeSums(zoneTimeList, value);

    setIZone(value);
    setIsLoading(false);
  };

  const handleECVBlur = async () => {
    if (ECV && iZone) {
      setIsLoading(true);
      await getTicketTimeSums();
      await getTicketPriceSums();
      setIsLoading(false);
    }
  };

  const handleChangeECV = (_event, value) => {
    const errorECV = isValidEcv(value, language);
    const errorTextECV = value ? errorECV : translate('ECV') + translate('isRequired');
    setError({ ...error, ECV: errorTextECV });

    value = value.toUpperCase();
    value = value.trim();
    setECV(value);
  };

  const handleSetECV = async (value) => {
    setIsLoading(true);
    await getTicketTimeSums(null, null, value);
    await getTicketPriceSums(null, null, value);
    setECV(value);
    setIsLoading(false);
  };

  const handleChangeEmail = (_event, value) => {
    const errorEmail = isValidMail(value, language);
    const errorTextEmail = value ? errorEmail : translate('mail') + translate('isRequired');
    setError({ ...error, email: errorTextEmail });

    setEmail(value);
  };

  const dispatchTicket = async (payData, data) => {
    /** ID projektu  4003 - Parkovanie */
    const _PrjId = 4003;

    /** Zdroj platby 2 - Parkovací lístok */
    const _PayType = 2;

    let dataPay;
    let iDocPark = 0;

    const gatewayHandler = paymentSettings.settings?.getPaymentGatewayHandler(_PrjId);

    // už iba hodnota 1 (0 pre pôvodné riešenie sa už nepoužíva)
    const payMethod = gatewayHandler.payMethod;

    if (payData.totalAmountDec === 0) {
      iDocPark = await dispatch(publicActions.addParkingShort(data));
      if (iDocPark > 0) {
        dispatch(userActions.showMsg("Parkovanie úspešne zaevidované."));
      }
    } else if (payData.totalAmountDec > 0) {
      if (!isNil(gatewayHandler.minSum) && gatewayHandler.minSum > 0.00) {
        if (payData.totalAmountDec < gatewayHandler.minSum) {
          dispatch(userActions.showErrorMsg('Nepostačujúca suma pre realizáciu platby.'));
          setIsLoading(false);
          return;
        }
      }

      iDocPark = await dispatch(publicActions.addParkingShort(data));
      if (iDocPark > 0) {
        // povodne riesenie
        dataPay = { VS: `9${iDocPark.toString().padStart(9, 0)}`, CENA: data.CENA, LANG: data.LANGUAGE.substring(3, 5), EMAIL: data.EMAIL };

        const prjId = _PrjId;
        const payType = _PayType;
        const iPk = iDocPark;
        const vs = dataPay.VS;
        const language = dataPay.LANG;
        const email = dataPay.EMAIL;

        // sprievodka
        const waybill = { payMethod, prjId, payType, iPk, vs, language, email, 'transactionId': null };

        dataPay = { ...dataPay, waybill, payData };
      }
      if (iDocPark > 0) {
        navigate(routes.PAYMENT_GATEWAY, { state: dataPay });
      } else {
        dispatch(userActions.showErrorMsg(translate('parkingShort.failure')));
      }
    }
    setIsLoading(false);
  };

  const hasValidParkingCard = () => {
    const card = getActiveParkCard();

    if (card) {
      const zoneList = card.ZOZ_I_ZONA?.split(',').map(x => Number(x));
      const kartaTypZoneList = card.kartaTyp?.ZOZ_I_ZONA?.split(',').map(x => Number(x));

      if (zoneList?.includes(iZone) || kartaTypZoneList?.includes(iZone)) {
        handleOpenNotify("V danej zóne existuje platné parkovacie oprávnenie.");
        return true;
      }
    }

    return false;
  };

  const disableZonePicker = () => {
    const search = window.location.href.split('?').pop();
    const params = new URLSearchParams(search);
    const iZoneParam = params.get("zoneID");
    const ECVParam = params.get("ecv");

    return iZoneParam && !ECVParam;
  };

  const { classes } = props;
  moment.locale(props.locale);
  return (
    (
      // <MuiPickersUtilsProvider utils={MomentUtils} locale={props.locale}>
      <div className='container w-padding' style={{ marginTop: '70px' }}>
        <CircularProgress loading={isLoading} />
        <div className={classNames(classes.row, classes.navigationBackButton, classes.w100, classes.alignItemsCenter, classes.mt3)} >
          {/*SIPKA SPAT*/}
          <IconButton
            className={classNames(classes.p0)}
            color="inherit"
            onClick={handleBack}
            toolTip={translate('back')}
            toolTipPlace="right"
            disableHover
            disableRipple
            size="large">
            <ArrowBack
              variant='fab'
              style={{ fontSize: 30 }}
            />
          </IconButton>
          {/*VYBER JAZYKA*/}
          <div>
            <SelectFilterField
              floatingLabelText="Výber jazyka"
              required={true}
              value={language}
              onChange={handleLanguageChange}
              fullwidth={true}
              showIcon={true}
              iconStyle={{ height: '20px', marginRight: '5px' }}
              schema={{ label: 'N_LANGUAGE', value: 'LANGUAGE', icon: 'ICON' }}
              data={LANGUAGE_LIST}
              name='LANGUAGE'
            />
          </div>
        </div>
        {/*PARKOVACI LISTOK NADPIS*/}
        <Typography variant="h6" className={classNames(classes.mt3, classes.mb3)}>
          {translate('parkingShort.title')}
        </Typography>
        {/*VYBER ZONY*/}
        <div className={classNames(classes.row, classes.mb3)}>
          {props.adminMode?.isActive &&
            <ButtonFab
              toolTip="Zmena názvu poľa"
              toolTipPlace="right-start"
              style={{ backgroundColor: '#ffffff', flexShrink: 0, marginRight: '10px' }}
              size="small"
              onClick={handleOpenChangeFieldNameDialog}
            >
              <EditIcon />
            </ButtonFab>}
          <SelectField
            disabled={disableZonePicker()}
            errorText={error.zone}
            error={error.zone ? true : false}
            floatingLabelText={props.parkingZoneLabel}
            value={iZone}
            onChange={handleChangeZone}
            fullwidth={true}
            schema={{ label: 'N_ZONA', value: 'I_ZONA' }}
            data={props.listZone}
            name='I_ZONA'
            icon={<PlaceIcon style={{ color: "#000" }} />}
            required
          />
          {props.adminMode?.isActive &&
            <ButtonFab
              toolTip="Zoznam zón bez nároku na bezplatné parkovanie"
              toolTipPlace="right-start"
              style={{ backgroundColor: '#ffffff', flexShrink: 0, marginRight: '10px' }}
              size="small"
              onClick={handleOpenFreeParkingZonesDialog}
            >
              <EditIcon />
            </ButtonFab>}
          {showMapButton && (
            <ButtonFab
              style={{ backgroundColor: '#ffffff', flexShrink: 0 }}
              size="small"
              disabled={mapButtonDisabled}
              onClick={handleMap}>
              <MapIcon />
            </ButtonFab>
          )}
        </div>
        {/*FIELD NA ECV*/}
        <div className={classNames(classes.row, classes.alignItemsCenter, classes.mb3)}>
          <TextField
            label={translate('ECV')}
            fullwidth={true}
            value={ECV}
            errorText={error.ECV}
            error={error.ECV ? true : false}
            onChange={handleChangeECV}
            name='ECV'
            onBlur={handleECVBlur}
            inputProps={{
              startAdornment: (
                <InputAdornment position="start" style={{ marginLeft: '-2px' }}>
                  <CarIcon />
                </InputAdornment>
              ),
            }}
            variant="standard"
          />
          {/*DIALÓG NA PRIDÁVANIE A VÝBER EČV*/}
          {isAuth && <ECVDialog
            ECV={ECV}
            disabled={mapButtonDisabled}
            language={language}
            handleChangeECV={handleSetECV} />}
        </div>
        {/*FIELD NA EMAIL*/}
        <div className={classNames(classes.row, classes.alignItemsCenter, classes.mb3)}>
          <TextField
            type="email"
            label={translate('parkingShort.email')}
            fullwidth={true}
            value={email}
            errorText={error.email}
            error={error.email ? true : false}
            onChange={handleChangeEmail}
            name='EMAIL'
            inputProps={{
              startAdornment: (
                <InputAdornment position="start" style={{ marginLeft: '-2px' }}>
                  <EmailIcon />
                </InputAdornment>
              ),
            }}
            variant="standard"
          />
        </div>
        {/*SWITCH TERAZ/NAPLANOVAT*/}
        {props.visiblePlanButton && <div className={classNames(classes.row, classes.alignItemsCenter, classes.mb3)}>
          <SwitchComponent
            onLabel={translate('parkingShort.modeNow')}
            offLabel={translate('parkingShort.modePlan')}
            value={tabIndex}
            onChange={handleSwitchChange}
          />
        </div>}
        {/* PARKOVANIE TERAZ */
          tabIndex === 0 &&
          <NowParking
            validateForm={validateForm}
            hasValidParkingCard={hasValidParkingCard}
            setIsLoading={setIsLoading}
            iZone={iZone}
            ECV={ECV}
            email={email}
            language={language}
            handleOpenNotify={handleOpenNotify}
            handleOpenExtend={handleOpenExtend}
            handleCloseExtend={handleCloseExtend}
            dispatchTicket={dispatchTicket}
            getActiveParkCard={getActiveParkCard}
            handleOpenPaymentMessagesDialog={handleOpenPaymentMessagesDialog}
          />}
        {/* PARKOVANIE NAPLÁNOVAŤ */
          tabIndex === 1 &&
          <PlannedParking
            validateForm={validateForm}
            hasValidParkingCard={hasValidParkingCard}
            setIsLoading={setIsLoading}
            iZone={iZone}
            ECV={ECV}
            email={email}
            language={language}
            handleOpenNotify={handleOpenNotify}
            handleOpenExtend={handleOpenExtend}
            handleCloseExtend={handleCloseExtend}
            dispatchTicket={dispatchTicket}
            handleOpenPaymentMessagesDialog={handleOpenPaymentMessagesDialog}
          />
        }


        {/* DIALÓG NA OZNAM O PARKOVANÍ */}
        <Dialog
          open={notify.open}
          onClose={handleCloseNotify}
          contentText={notify.text}
          title={translate('warning')}
          actions={[
            {
              label: translate('back'),
              color: 'primary',
              onClick: handleCloseNotify,
              keyboardFocused: true
            }
          ]}
        />

        {/* DIALÓG NA OTÁZKU O PREDĹŽENÍ PARKOVANIA */}
        <Dialog
          open={extend.open}
          onClose={handleCloseExtend}
          contentText={extend.text}
          title={translate('warning')}
          actions={[
            {
              label: translate('back'),
              color: 'primary',
              onClick: handleCloseExtend,
              keyboardFocused: true
            },
            {
              label: translate('extend'),
              color: 'primary',
              onClick: extend.addParkingHandler,
              keyboardFocused: false
            }
          ]}
        />

        {/* ADMIN DIALÓG NA SPRÁVU ZÓN S BEZPLATNÝM PARKOVANÍM */}
        <Dialog
          open={freeParkingZonesDialog.open}
          onClose={handleCloseFreeParkingZonesDialog}
          title="Zoznam zón bez nároku na bezplatné parkovanie"
          content={getFreeParkingZonesDialogContent()}
          actions={[
            {
              label: "Zavrieť",
              color: "primary",
              onClick: () => handleCloseFreeParkingZonesDialog(),
            },
            {
              label: "Uložiť",
              color: "secondary",
              onClick: () => handleSaveFreeParkingZones(),
              disabled: false
            },
          ]}
        />

        {/* ADMIN DIALÓG NA ZMENU LABELU ZONA */}
        <Dialog
          open={changeFieldNameDialog.open}
          onClose={handleCloseChangeFieldNameDialog}
          title="Zmena názvu poľa"
          content={getChangeFieldNameContent()}
          actions={[
            {
              label: "Zavrieť",
              color: "primary",
              onClick: () => handleCloseChangeFieldNameDialog(),
            },
            {
              label: "Uložiť",
              color: "secondary",
              onClick: () => handleSaveChangeFieldName(),
              disabled: false
            },
          ]}
        />


        {/* ADMIN DIALÓG NA SPRÁVU TEXTOV O VYSLEDKU PLATBY */}
        <Dialog
          open={paymentMessagesDialog.open}
          onClose={handleClosePaymentMessagesDialog}
          title="Nastavenie oznamov o výsledku platby"
          content={getPaymentMessagesDialogContent()}
          actions={[
            {
              label: "Zavrieť",
              color: "primary",
              onClick: () => handleClosePaymentMessagesDialog(),
            },
            {
              label: "Uložiť",
              color: "secondary",
              onClick: () => handleSavePaymentMessages(),
              disabled: errorTextParkingSuccess || errorTextParkingVerify || errorTextParkingFailure
            },
          ]}
        />
      </div>
      // {/* </MuiPickersUtilsProvider> */}
    )
  );
};

const mapStateToProps = (state) => {
  return {
    listZone: state.listZoneParkShort.data,
    parkingZoneLabel: state.parkingZoneLabel.data,
    zoneTimeList: state.zoneTimeList.data.Data?.Items,
    isAuth: state.user.data !== null,
    user: state.user,
    paymentSettings: state.paymentSettings,
    locale: state.locale.locale,
    person: state.person.data,
    adminMode: state.adminMode,
    visiblePlanButton: state.visiblePlanButton.data,
    activeParkingCards: state.listActiveParkingCard.data,
    noFreeParkingZoneList: state.notAvailableFreeParking.data,
  };
};

const mapDispatchToProps = dispatch => ({
  dispatch,
  showMsg: (msg) => { dispatch(userActions.showMsg(msg)); },
  showErrorMsg: (errorMsg) => { dispatch(userActions.showErrorMsg(errorMsg)); },
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withMeta(storage(withLocalizationConsumer((withStyles(styles)(ParkingTicket)))))));