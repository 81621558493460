import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import classNames from 'classnames';

import withStyles from '@mui/styles/withStyles';

import getSharedStyles from '../../coraWebMComponents/sharedStyles';
import Paper from '../../coraWebMComponents/surfaces/Paper';
import IconButton from '../../coraWebMComponents/inputs/IconButton';
import Typography from '../../coraWebMComponents/dataDisplay/Typography';
import Button from '../../coraWebMComponents/inputs/Button';
import ArrowBack from '../../coraWebMComponents/dataDisplay/icons/ArrowBack';
import DialogIcon from '../../coraWebMComponents/feedBack/DialogIcon';
import EuroIcon from '../../coraWebMComponents/dataDisplay/icons/EuroIcon';
import CreditCard from '../../coraWebMComponents/dataDisplay/icons/CreditCardIcon';

import * as taxesActions from '../../actions/taxesActions';
import { nbWithSpaces } from './Taxes';

const styles = theme => ({
  ...getSharedStyles(theme),
});

class PropertyTaxesDetail extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      openAlertPayment: false,
    };
  }

  getType = (item) => {
    switch (item.TYP) {
      case "Pozemky":
        return "I. Daň z pozemkov";
      case "Stavby":
        return "II. Daň zo stavieb";
      case "Byty":
        return "III. Daň z bytov";
      default:
        return '';
    }
  };

  orderList = () => {
    let order = ["Pozemky", "Stavby", "Byty"];

    this.props.listPropertyTaxes.sort((a, b) => {
      return order.indexOf(a.TYP) - order.indexOf(b.TYP);
    });
  };

  handleOpenAlert = (name) => {
    this.setState({ [name]: true });
  };

  handleCloseAlert = (name) => {
    this.setState({ [name]: false });
  };
  render() {
    const { classes, listPropertyTaxes, platba, stav, lUhrPb } = this.props;
    const iconEuroSymbol = <EuroIcon />;
    const iconCreditCard = <CreditCard />;
    return (
      (<div className='container w-margin w-padding'>
        <div className={classNames(classes.dFlex, classes.justifyContentStart, classes.pt3, classes.pb3)}>
          <IconButton
            className={classNames(classes.navigationBackButton, classes.p0)}
            color="inherit"
            onClick={this.props.handleClose}
            toolTip="Späť"
            toolTipPlace="right"
            disableRipple
            disableHover
            size="large">
            <ArrowBack
              fontSize='default'
              variant='fab'
              style={{ fontSize: 30 }}
            />
          </IconButton>
          <Typography style={{ paddingTop: '5px', paddingLeft: '1em' }}><span className={classes.fontBold}>Daň z nehnuteľností</span><span className={classes.textItalic}>/detail</span></Typography>
        </div>
        {listPropertyTaxes.sort(this.orderList()).map(item =>
          <Paper elevation={2} key={item.TYP} className={classNames(classes.ziadostCard, classes.mb3)} >
            <div className={classes.cardContent}>
              <div className={classNames(classes.row, classes.alignItemsCenter, classes.justifyContentStart, classes.mb2)}>
                <Typography variant="subtitle2" style={{ fontWeight: 'bold' }}>{this.getType(item)}</Typography>
              </div>
              <div className={classNames(classes.row, classes.alignItemsCenter, classes.mb2)} >
                <Typography variant="body1" style={{ whiteSpace: 'pre-line' }}>{item.ZOZ_POPIS_OBJ.join("\n")}</Typography>
                <Typography variant="body2"></Typography>
              </div>
              <div className={classNames(classes.row, classes.alignItemsCenter)} >
                <Typography variant="body1" className={classes.fontBold}>Daň</Typography>
                <Typography variant="body2">{nbWithSpaces(item.SK_DAN)}{' '}{item.MENA === "EUR" ? "€" : ''}</Typography>
              </div>
            </div>
          </Paper>
        )}
        {stav !== 2 &&
          <div className={classes.row} >
            {(platba > 0 && lUhrPb === 0) &&
              <Button
                disabled={platba === 0 ? true : false}
                variant="contained"
                color="secondary"
                size="large"
                type="success"
                className={classNames(classes.w100, classes.mt2, classes.row, classes.justifyContentBetween)}
                onClick={() => this.handleOpenAlert('openAlertPayment')} >
                <span style={{ fontWeight: "normal" }}>Platba</span>
                {nbWithSpaces(platba.toFixed(2))} €
              </Button>
            }
          </div>
        }
        <DialogIcon
          open={this.state.openAlertPayment}
          onClose={() => this.handleCloseAlert('openAlertPayment')}
          title='Spôsob platby'
          actions={[
            {
              label: 'Platobná brána',
              onClick: () => this.props.handlePayment(this.props.VS, this.props.SSY, this.props.iKon, this.props.platba, 1),
              icon: iconCreditCard
            },
            {
              label: 'Platobné údaje',
              onClick: () => this.props.handlePayment(this.props.VS, this.props.SSY, this.props.iKon, this.props.platba, 2, this.props.cRozh),
              icon: iconEuroSymbol
            }
          ]}
        />
      </div>)
    );
  }
}

PropertyTaxesDetail.propTypes = {
  orderList: PropTypes.func,
};

const mapStateToProps = (state) => ({
  person: state.person.data,
  listPropertyTaxes: state.listPropertyTaxes.data,
});

const mapDispatchToProps = (dispatch) => ({
  getListPropertyTaxes: (IKon, iO) => dispatch(taxesActions.getListPropertyTaxes(IKon, iO)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(PropertyTaxesDetail));
