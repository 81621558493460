import { useEffect, useState, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { connect } from 'react-redux';
import classNames from 'classnames';

import { Box, Button } from '@mui/material';
// import Box from '@mui/material/Box';
import Stack from '@mui/material/Stack';
import useTheme from '@mui/system/useTheme';
import useMediaQuery from '@mui/material/useMediaQuery';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import RadioGroup from '@mui/material/RadioGroup';
import Radio from '@mui/material/Radio';
import withStyles from '@mui/styles/withStyles';

import * as requests from '../../coraWebMComponents/utils/requests';
import withRouter from '../../coraWebMComponents/utils/withRouter';
import getSharedStyles from '../../coraWebMComponents/sharedStyles';
import * as storage from '../../coraWebMComponents/utils/storage';
import CircularProgress from '../../coraWebMComponents/feedBack/progress/CircularProgress';
import ArrowBack from '../../coraWebMComponents/dataDisplay/icons/ArrowBack';
import Typography from '../../coraWebMComponents/dataDisplay/Typography';
import IconButton from '../../coraWebMComponents/inputs/IconButton';

import * as locatorActions from '../../actions/locatorActions';
import * as locatorUtils from '../../utils/locatorUtils';

import routes from '../../routes';

const styles = theme => ({
  ...getSharedStyles(theme),
  fld: {
    margin: '8px 0',
  },
  lbl: {
    fontSize: theme.typography.pxToRem(12),
    fontWeight: theme.typography.fontWeightLight,
  },
  val: {
    fontSize: theme.typography.pxToRem(13),
    fontWeight: theme.typography.fontWeightBold,
    minHeight: '1em',
    wordBreak: 'break-all'
  },
});

const WizardStateComponent = ({ classes, val }) => {
  if (![1, 2, 3, 4].includes(val)) {
    return null;
  }
  return (
    <FormControl id="fcOpg" className={classNames(classes.w100, classes.alignItemsLeft, classes.justifyContentCenter, classes.pt3)}>
      <RadioGroup
        aria-labelledby="demo-controlled-radio-buttons-group"
        name="opgWizardStep"
        value={`${val}`} //{`${val}`}
        row={true}
      >
        <FormControlLabel key="opt_1" value="1" control={<Radio />} label="Úvod" />
        <FormControlLabel key="opt_2" value="2" control={<Radio />} label="Formulár" />
        <FormControlLabel key="opt_3" value="3" control={<Radio />} label="Prílohy" />
        <FormControlLabel key="opt_4" value="4" control={<Radio />} label="Podpis" />
      </RadioGroup>
    </FormControl>);
}

const IntroStepComponent = ({ classes, waybill, onNextClick }) => {
  return (
    <>
      <div className={classNames(classes.alignItemsCenter, classes.fld)}>
        <Typography variant="body1" className={classNames(classes.row, classes.lbl)}>Názov</Typography>
        <Typography variant="body2" className={classNames(classes.row, classes.val)}>{waybill.zstSlzForm.FeForm.NFeForm}</Typography>
      </div>
      <div className={classNames(classes.alignItemsCenter, classes.fld)}>
        <Typography variant="body1" className={classNames(classes.row, classes.lbl)}>Popis</Typography>
        <Typography variant="body2" className={classNames(classes.row, classes.val)}>{waybill.zstSlzForm.FeForm.TTFeForm}</Typography>
      </div>
      <Box id="boxWrapper" sx={{ flexDirection: "collumn" }}>
        <Button
          onClick={onNextClick}
          variant="contained"
          color="primary"
          size="large"
          className={classNames(classes.btn)}
          toolTip=""
        >
          <Typography variant="body2">Vyplniť formulár</Typography>
        </Button>
      </Box>
    </>
  );
};

const FormStepComponent = ({ classes, waybill, onNextClick, onPreviousClick, formData }) => {

  const [xmlData, setXmlData] = useState(formData.XmlData);
  const [title, setTitle] = useState(null);
  const [origin, setOrigin] = useState(null);
  const [resultFromIFrame, setResultFromIFrame] = useState(null);

  const targetUrl = window.location.origin; //"http://localhost/Cora.CommIss.Support/HtmlPage2IFrame.html";
  // tento hook počúva udalosť, ktorá prišla z iframe
  useEffect(() => {
    const handler = (e) => {
      console.log(`Som v MainPage.addEventListener`);
      console.log('e:', e)
      // if (e.origin !== targetUrl) return;
      if (!e.data) return;
      if (typeof e.data !== 'object') return;
      const response = e.data;
      if (!e.data.fncName) return;
      const { fncName, result } = response;
      console.log(`[fncName="${fncName}"] MainPage.addEventListener`);
      if (typeof result !== 'object') return;
      console.log(`[result="${JSON.stringify(result)}"] MainPage.addEventListener`);

      let alertMessage = `Som v MainPage.addEventListener`;
      alertMessage += `\r\norigin: [${e.origin}]`;
      alertMessage += `\r\ne.data: [${JSON.stringify(response)}]`;
      alertMessage += `\r\nfncName: [${fncName}]`;
      alertMessage += `\r\result: [${JSON.stringify(result)}]`;
      alert(alertMessage);

      setTitle(`som v MainPage.addEventListener`);
      setOrigin(`origin: [${e.origin}]`);
      setResultFromIFrame(e.data)
      console.log(`${title}`);
      console.log(`origin="${origin}"`);
      console.log(`resultFromIFrame="${resultFromIFrame}"`);
    }

    window.addEventListener('message', handler)

    // Nezabudnúť odstránenie addEventListener
    return () => window.removeEventListener('message', handler)
  }, [])


  const callIFrame = (fncName) => {

    const request = { fncName, xmlData: null };
    if (fncName === "setFormData") {
      request.xmlData = xmlData;
    }
    const ifr = document.getElementById("ifr");
    ifr.contentWindow.postMessage(JSON.stringify(request), '*');
  };

  return (
    <>
      <iframe loading="lazy" width="924" height="500" id="ifr"
        // src={targetUrl}
        srcDoc={formData.HtmlData}
        title={waybill.zstSlzForm.FeForm.TTFeForm}
        frameborder="1" //sandbox="allow-scripts allow-modal"
      // allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
      // referrerpolicy="strict-origin-when-cross-origin"
      // allowfullscreen="true"
      />
      <Box id="boxWrapper" sx={{ flexDirection: "collumn" }}>
        <Button
          onClick={onPreviousClick}
          variant="contained"
          color="primary"
          size="large"
          className={classNames(classes.btn)}
          toolTip=""
        >
          <Typography variant="body2">Späť</Typography>
        </Button>
        <button onClick={() => callIFrame("setFormData")}>setFormData</button>
        <button onClick={() => callIFrame("getFormData")}>getFormData</button>
        <button onClick={() => callIFrame("validate")}>validate</button>
        <button onClick={() => callIFrame("clear")}>clear</button>
        {/* <Button
          onClick={rob}
          variant="contained"
          color="primary"
          size="large"
          type="success"
          className={classNames(classes.btn)}
          toolTip=""
        >
          <Typography variant="body2">Validácia</Typography>
        </Button> */}
        <Button
          onClick={onNextClick}
          variant="contained"
          color="primary"
          size="large"
          type="success"
          className={classNames(classes.btn)}
          toolTip=""
        >
          <Typography variant="body2">Pokračovať</Typography>
        </Button>
      </Box>
    </>
  );
};

const AttachmentStepComponent = ({ classes, waybill, onNextClick, onPreviousClick }) => {
  return (
    <>
      <Typography variant="body2">Prílohy</Typography>
      <Stack pt={2}>{waybill.zstSlzForm.FeForm.NFeForm}</Stack>
      <Box id="boxWrapper" sx={{ flexDirection: "collumn" }}>
        <Button
          onClick={onPreviousClick}
          variant="contained"
          color="primary"
          size="large"
          className={classNames(classes.btn)}
          toolTip=""
        >
          <Typography variant="body2">Späť</Typography>
        </Button>
        <Button
          onClick={onNextClick}
          variant="contained"
          color="primary"
          size="large"
          type="success"
          className={classNames(classes.btn)}
          toolTip=""
        >
          <Typography variant="body2">Pokračovať</Typography>
        </Button>
      </Box>
    </>
  );
};

const SigningStepComponent = ({ classes, waybill, onNextClick, onPreviousClick }) => {
  return (
    <>
      <Typography variant="body2">Prílohy</Typography>
      <Stack pt={2}>{waybill.zstSlzForm.FeForm.NFeForm}</Stack>
      <Box id="boxWrapper" sx={{ flexDirection: "collumn" }}>
        <Button
          onClick={onPreviousClick}
          variant="contained"
          color="primary"
          size="large"
          className={classNames(classes.btn)}
          toolTip=""
        >
          <Typography variant="body2">Späť</Typography>
        </Button>
        <Button
          onClick={onNextClick}
          variant="contained"
          color="primary"
          size="large"
          type="success"
          className={classNames(classes.btn)}
          toolTip=""
        >
          <Typography variant="body2">Odoslať</Typography>
        </Button>
      </Box>
    </>
  );
};

const ResultStepComponent = ({ classes, waybill, onNextClick }) => {
  return (
    <>
      <Stack pt={2}>Formulár bolo odoslaný do nášho registratúrneho systému</Stack>
      <Box id="boxWrapper" sx={{ flexDirection: "collumn" }}>
        <Button
          onClick={onNextClick}
          variant="contained"
          color="primary"
          size="large"
          className={classNames(classes.btn)}
          toolTip=""
        >
          <Typography variant="body2">Vyplniť formulár</Typography>
        </Button>
      </Box>
    </>
  );
};

const FFiWizardComponent = (props) => {

  const theme = useTheme();
  const breakpointMd = theme.breakpoints.up('md');
  const matchesThemeMD = useMediaQuery(breakpointMd);

  const [wizardStep, setWizardStep] = useState(1);

  const { location, classes } = props;
  const waybill = location.state;

  // inicializačné načítanie dát
  useEffect(() => {
    async function fetchData() {

      // zoznam vsetkych ZS a ich sluzieb
      if (!props.ffiWaybill.isLoaded) {
        await props.getFFiWaybill(waybill.zstSlzForm.FeForm.IFeForm);
      }
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  console.debug(`ffiWaybill.isLoaded=${props.ffiWaybill.isLoaded}`)
  if (props.ffiWaybill.isLoaded) {
    console.debug(`ffiWaybill is loaded`)
  } else {
    console.debug(`ffiWaybill is not loaded`)
  }

  return (
    <Box id="boxMain" backgroundColor={"#999"} className='container w-margin w-padding' style={{ maxWidth: matchesThemeMD ? "80%" : "90%" }}>
      {/* Wrapper  */}
      <Box id="boxWrapper" sx={{ flexDirection: "collumn" }}>
        {/* Navigation  */}
        <Stack id="stackNavig" backgroundColor={"#555"} sx={{ flexDirection: "collumn" }} justifyContent="flex-start" alignItems="flex-start" spacing={2} useFlexGap pt={0}>
          <Box id="boxWrapper" backgroundColor={"#FF5"} sx={{ flexDirection: "collumn" }}>
            <Button title={`Návrat na Výber služby`}>
              <Link to={routes.ZST} title={`Návrat na Výber služby`}>
                <Box className={classNames(classes.navigationButton, classes.navigationBackButton, classes.textCenter)}>
                  <ArrowBack />
                </Box>
              </Link>
            </Button>
          </Box>
        </Stack>
        <WizardStateComponent classes={classes} val={wizardStep} />
        {/* Title and search  */}
        <Stack id="stackTitles" pt={2}>
          {/* <Stack pt={2}>{waybill.zstSlzForm.Slz.ZstZs.NZstZs}</Stack> */}
          {/* <Stack pt={2}>{waybill.zstSlzForm.Slz.NSlz}</Stack> */}
          <Stack pt={2}>{waybill.zstSlzForm.FeForm.NFeForm}</Stack>
        </Stack>
        <Box
          backgroundColor={"#aaa"}
        // className='container w-margin w-padding'
        // style={"90%"}
        >
          {/* Navigation  */}
          <Stack
            sx={{ flexDirection: "collumn" }}
            justifyContent="flex-start"
            alignItems="flex-start" //na horny okraj
            spacing={2}
            useFlexGap
            pt={2}
          >
            <Box
            // backgroundColor={"#fbf"}
            >
              {(() => {
                switch (wizardStep) {
                  case 1:
                    return <IntroStepComponent classes={classes} waybill={waybill} onNextClick={() => setWizardStep(2)} />;
                  case 2:
                    return <FormStepComponent classes={classes} waybill={waybill} onNextClick={() => setWizardStep(3)} onPreviousClick={() => setWizardStep(1)} formData={props.ffiWaybill.data} />;
                  case 3:
                    return <AttachmentStepComponent classes={classes} waybill={waybill} onNextClick={() => setWizardStep(4)} onPreviousClick={() => setWizardStep(2)} />;
                  case 4:
                    return <SigningStepComponent classes={classes} waybill={waybill} onNextClick={() => setWizardStep(5)} onPreviousClick={() => setWizardStep(3)} />;
                  default:
                    return <ResultStepComponent classes={classes} waybill={waybill} onNextClick={() => props.navigate(`${routes.ZST}?resultCode=2`, { state: waybill })} />;
                }
              })()}
            </Box>
          </Stack>
        </Box>
      </Box>
    </Box >
  );
};

FFiWizardComponent.propTypes = {
};

const mapStateToProps = (state) => ({
  ffiWaybill: state.ffiWaybill,
});

const mapDispatchToProps = (dispatch) => ({
  getFFiWaybill: (iFeForm) => dispatch(locatorActions.getFFiWaybill(iFeForm)),
  dispatch
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(FFiWizardComponent)));
