import BarChartIcon from "@mui/icons-material/BarChart";
import PieChartIcon from "@mui/icons-material/PieChart";
import LineChartIcon from "@mui/icons-material/Timeline";

/**
 * Naformatuje cislo na dany pocet desatinnych miest. Ak to nie je cislo, nespravi nic.
 * e.g.: 12345,5 -> '12 345,50'
 *
 * @param {number} [number=0]
 * @param {*} [decimals=NUMBER_OF_DECIMALS]
 * @param {string} [localeCode="sk-SK"]
 * @return {*} 
 */
export const formatDecimals = (number = 0, decimals = 2, localeCode = "sk-SK") => {
  let toNumber = + number;
  return isNaN(toNumber) ? number : toNumber.toLocaleString(localeCode, { maximumFractionDigits: decimals, minimumFractionDigits: decimals });
};

/**
 * Zmaze v texte netlacitelne znaky
 *
 * @param {*} text
 * @return {*} 
 */
export const removeNonPrintableCharacters = (text) => {
  return text.replace(/[\p{C}]/gu, ''); // netlacitelne znakov pre vsetky jazyky v Unicode
  // return text.replace(/[^\x20-\x7E]/g, '');
  // return text.replace(/[\x00-\x1F\x7F-\x9F]/g, '');
};

/**
 * Skonvertuje true/false na textovy zmysel (defaut "Áno"/"Nie") 
 *
 * @param {*} text
 * @return {*} 
 */
export const boolToText = (value, options = { trueText: "Áno", falseText: "Nie" }) => {
  const { trueText, falseText } = options;
  return value ? trueText : falseText;
};

// vyparsuje z formatovacieho retazca kod a pocet desatin, napr: "c2" -> {code:"c", number:2}; "c" -> {code:"c", number:null}
export const parseCodeAndNumber = (format) => {
  // Definícia regulárneho výrazu pre hľadanie vzoru znak+cislo
  var regex = /([a-zA-Z]+)(\d*)/;

  var result = regex.exec(format);
  if (result !== null) {
    var number = result[2] !== "" ? parseInt(result[2]) : null;
    return { code: result[1], number: number };
  } else {
    return { code: null, number: null }; // Ak vzor nie je nájdený, vráti null
  }
};

/**
 * Vrati ikonu podla pozadovaneho typu
 *
 * @param {*} type
 * @return {*} 
 */
export const getChartIcon = (type, classes) => {
  switch (type) {
    case "bar":
      return <BarChartIcon className={classes} />;
    case "pie":
      return <PieChartIcon className={classes} />;
    case "line":
      return <LineChartIcon className={classes} />;
    default:
      return <BarChartIcon className={classes} />;
  }
};