import * as requests from '../coraWebMComponents/utils/requests';
import CoraError from '../coraWebMComponents/utils/coraError';

const buildGetListCountriesUrl = (filter) => {
  return `${window.config.url}/api/ciselniky/countries?filter=${filter}`;
};

export const getListCountries = async (filter) => {
  let buildedRequest = buildGetListCountriesUrl(filter);
  const response = await fetch(buildedRequest);

  if (response.status === 200) {
    return await response.json();
  }
  else if (response.status === 404) {
    return {
      Data: {
        TotalRecords: 0,
        Items: []
      }
    };
  }
  else {
    throw new CoraError(await response.json());
  }
};

const buildGetCountryUrl = (id) => {
  return `/api/ciselniky/countries/${id}`;
};

export const getCountry = async (id) => {
  let buildedRequest = buildGetCountryUrl(id);
  try {
    const response = await requests.get(buildedRequest);
    return response;
  }
  catch (error) {
    throw error;
  }
};

export const getPh = async (id) => {
  try {
    return await requests.get(`/api/ciselniky/ph/${id}`);
  }
  catch (error) {
    throw error;
  }
};

const buildGetDriveUrl = (id) => {
  return `/api/ciselniky/drive/${id}`;
};

export const getDrive = async (id) => {
  try {
    let buildedRequest = buildGetDriveUrl(id);
    return await requests.get(buildedRequest);
  }
  catch (error) {
    throw error;
  }
};

export const getZone = async (id) => {
  try {
    return await requests.get(`/api/ciselniky/zone/${id}`);
  }
  catch (error) {
    throw error;
  }
};

export const getListZone = async () => {
  try {
    return await requests.getList('/api/ciselniky/zone', { filters: [] }, [], { skip: 1, take: 1000 });
  }
  catch (error) {
    throw error;
  }
};

export const getListZoneParkShort = async (iDZone) => {
  try {
    const filter = iDZone ? { filters: [{ field: "I_ZONA", operator: "==", value: iDZone },] } : { filters: [] };
    return await requests.getList('/api/ciselniky/zoneParkShort', filter, [], { skip: 1, take: 1000 });
  }
  catch (error) {
    throw error;
  }
};

const buildGetListCardTypeUrl = (zony, pravOs, iKartaKat) => {
  return `/api/ciselniky/cardType?zony=${zony}&pravOs=${pravOs}&iKartaKat=${iKartaKat}`;
};

export const getListCardType = async (zony, pravOs, iKartaKat) => {
  try {
    let buildedRequest = buildGetListCardTypeUrl(zony, pravOs, iKartaKat);
    const response = await requests.get(buildedRequest);
    if (response.Data === null)
      return { Data: { Items: [], TotalRecords: 0 } };

    return response;
  }
  catch (error) {
    if (error.code === '404.000') {
      return {
        Data: {
          TotalRecords: 0,
          Items: []
        }
      };
    }
    else {
      throw error;
    }
  }
};

export const getCardType = async (id) => {
  try {

    return await requests.get(`/api/ciselniky/cardType/${id}`);
  }
  catch (error) {
    if (error.code === '404.000') {
      return {
        Data: {
          TotalRecords: 0,
          Items: []
        }
      };
    }
    else {
      throw error;
    }
  }
};

export const getListAddress = async (searchText) => {
  try {
    const filter = searchText ? { filters: [{ field: "ADRESA", operator: "contains", value: searchText }] } : { filters: [] };
    filter.filters.push({ field: "ZOZ_I_ZONA", operator: "isnotnull" })

    return await requests.get(`/api/ciselniky/adresy?filter=${JSON.stringify(filter)}`);

  }
  catch (error) {
    throw error;
  }
};

export const getListAddressByIAdrList = async (searchText, I_ADR) => {
  try {
    const filter = searchText ? { filters: [{ field: "ADRESA", operator: "contains", value: searchText }] } : { filters: [] };

    if (I_ADR !== null) {
      filter.filters.push({ field: "I_ADR", operator: "in", value: I_ADR });
    }

    return await requests.get(`/api/ciselniky/adresy?filter=${JSON.stringify(filter)}`);
  }
  catch (error) {
    throw error;
  }
};

export const getListAddressByZoneList = async (searchText, ZOZ_I_ZONA) => {
  try {
    const filter = searchText ? { filters: [{ field: "ADRESA", operator: "contains", value: searchText }] } : { filters: [] };

    return await requests.get(`/api/ciselniky/adresy?filter=${JSON.stringify(filter)}&zones=${ZOZ_I_ZONA}`);
  }
  catch (error) {
    throw error;
  }
};

export const getListAddressPerson = async (searchText, I_O, I_O_ADR_TYP) => {
  try {
    const filter = searchText ? { filters: [{ field: "ADRESA", operator: "contains", value: searchText }] } : { filters: [] };

    if (I_O_ADR_TYP !== null) {
      filter.filters.push({ field: "I_O_ADR_TYP", operator: "==", value: I_O_ADR_TYP });
    }

    return await requests.get(`/api/ciselniky/adresyOsoby/${I_O}?filter=${JSON.stringify(filter)}`);
  }
  catch (error) {
    throw error;
  }
};

const buildGetAdressUrl = (id) => {
  return `/api/ciselniky/adresa/${id}`;
};

export const getAdress = async (id) => {
  try {
    let buildedRequest = buildGetAdressUrl(id);
    return await requests.get(buildedRequest);
  }
  catch (error) {
    if (error.code === '404.000') {
      return {
        Data: {
          TotalRecords: 0,
          Items: []
        }
      };
    }
    else {
      throw error;
    }
  }
};

const buildGetCardStateUrl = (iO) => {
  return `/api/ciselniky/cardStateList/${iO}`;
};

export const getCardState = async (iO) => {
  try {
    let buildedRequest = buildGetCardStateUrl(iO);
    const response = await requests.get(buildedRequest);
    if (response.Data === null)
      return { Data: { Items: [], TotalRecords: 0 } };

    return response;
  }
  catch (error) {
    if (error.code === '404.000') {
      return {
        Data: {
          TotalRecords: 0,
          Items: []
        }
      };
    }
    else {
      throw error;
    }
  }
};

export const getZoneTime = async (datum, iZona, iParkDruh) => {
  try {

    const rok = datum.getFullYear();
    const mesiac = datum.getMonth() + 1;
    const den = datum.getDate();

    return await requests.get(`/api/ciselniky/zoneTime?rok=${rok}&mesiac=${mesiac}&den=${den}&iZona=${iZona}&iParkDruh=${iParkDruh}`);
  }
  catch (error) {
    if (error.code === '404.000') {
      return {
        Data: {
          TotalRecords: 0,
          Items: []
        }
      };
    }
    else {
      throw error;
    }
  }
};

export const getZoneTimeMaxDate = async (iParkDruh) => {
  try {
    return await requests.get(`/api/ciselniky/zoneTime/maxDate/${iParkDruh}`);
  }
  catch (error) {
    if (error.code === '404.000') {
      return {
        Data: {
          TotalRecords: 0,
          Items: []
        }
      };
    }
    else {
      throw error;
    }
  }
};

export const getZoneTimeList = async (iZona, iParkDruh) => {
  try {

    return await requests.get(`/api/ciselniky/zoneTime?iZona=${iZona}&iParkDruh=${iParkDruh}`);
  }
  catch (error) {
    if (error.code === '404.000') {
      return {
        Data: {
          TotalRecords: 0,
          Items: []
        }
      };
    }
    else {
      throw error;
    }
  }
};

const buildCardKatUrl = (id = '') => `/api/ciselniky/cardKat/${id}`;

export const getListCardKat = async () => {
  const url = buildCardKatUrl();
  try {
    return await requests.getList(url, { filters: [] }, [], { skip: 1, take: 1000 });
  }
  catch (error) {
    throw error;
  }
};

export const getCardKat = async (id) => {
  if (id === 0) {
    return null;
  }
  const url = buildCardKatUrl(id);
  try {
    return await requests.get(url);
  }
  catch (error) {
    throw error;
  }
};

export const getVersionInfo = async () => {
  try {
    return await requests.get(`/api/ciselniky/verInfo`);
  }
  catch (error) {
    throw error;
  }
};

export const changePulseTime = async (value) => {
  try {
    return await requests.put(`/api/ciselniky/changePulseTime/${value}`);
  }
  catch (error) {
    throw error;
  }
};

export const getPaymentSettings = async () => {
  try {
    const res = await requests.get(`/api/payment-gateway/paymentSettings`);
    return res;
  }
  catch (error) {

    console.debug(`ciselnikyUtils.getPaymentSettings - catch (error) [error="${JSON.stringify(error)}"]`);
    throw error;
  }
};
