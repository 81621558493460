import { React, useState } from 'react';
import classNames from 'classnames';
import { connect } from 'react-redux';

import withStyles from '@mui/styles/withStyles';

import Button from '../../coraWebMComponents/inputs/Button';
import { useDidMount } from '../../coraWebMComponents/utils/hooks';
import getSharedStyles from '../../coraWebMComponents/sharedStyles';

import * as accountUtils from '../../utils/accountUtils';
import * as accountActions from '../../actions/accountActions';

const styles = theme => ({
  ...getSharedStyles(theme),
});

const QAButton = (props) => {
  const [active, setActive] = useState(false);
  const [show, setShow] = useState(false);

  useDidMount(async () => {
    const quickAction = props.activeQuickActions.find(x => x.I_SPEED_AKC === props.iSpeedAkc);
    const enabled = await accountUtils.quickActionEnabled(props.iSpeedAkc);
    if (enabled.Data === true) {
      setShow(true);
    }
    if (quickAction) {
      setActive(true);
    }
  });

  const handleQuickAction = async () => {
    const res = active ? await accountUtils.quickActionDel(props.iSpeedAkc) : await accountUtils.quickActionAdd(props.iSpeedAkc);
    if (res.Code === "200.000") {
      setActive(!active);
    }
    await props.dispatch(accountActions.getListActiveQuickActions());
  };

  const { classes } = props;
  const backgroundColor = active ? '#ffc107' : '#ffffff';
  const imgSrc = active ? 'assets/icon-qa.svg' : 'assets/icon-qa-gray.svg';
  return (
    <>
      {show &&
        <Button
          onClick={handleQuickAction}
          variant="contained"
          style={{ backgroundColor: backgroundColor, width: '50px', ...props.style }}
          className={classNames(classes.ml2)}
        >
          <img src={imgSrc} style={{ height: '35px', width: '35px' }} alt='Rýchla akcia' className={classNames(classes.ml2, classes.mr2)}></img>
        </Button>
      }
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    activeQuickActions: state.activeQuickActions.data,
  };
};

export default connect(mapStateToProps)(withStyles(styles)(QAButton));