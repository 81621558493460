import PropTypes from 'prop-types';
import classNames from 'classnames';
import { connect } from 'react-redux';

import { Box, Grid, Stack } from '@mui/material';
import { styled } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import useTheme from '@mui/system/useTheme';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import Button from '@mui/material/Button';

import Paper from '../../coraWebMComponents/surfaces/Paper';
import IconButton from '../../coraWebMComponents/inputs/IconButton';
import Typography from '../../coraWebMComponents/dataDisplay/Typography';
import ArrowForward from '../../coraWebMComponents/dataDisplay/icons/ArrowForward';

import WarningNoData from "../feedBack/WarningNoData";

const Item = styled(Paper)(({ theme }) => ({
  backgroundColor: '#ffffff',
  ...theme.typography.body2,
  textAlign: 'left',
  height: "100%"
}));

const card = (classes, onClick, item, matchesThemeMD, itemCounter) => (
  <Card sx={{
    width: "100%", height: "100%",
    display: "flex",
    flexDirection: "column",
  }}
    variant="outlined" >
    <CardContent
      sx={{
        whiteSpace: "normal",
        flexGrow: 1,
        // minHeight: matchesThemeMD ? "6em" : "3em",
      }}>
      <Box sx={{
        height: "100%",
        // TODO: zapnut ak, chcem popisok na 1 riadok a orezany s '...' ak nevojde
        // whiteSpace: "nowrap",
        // overflow: "hidden",
        // textOverflow: "ellipsis"
      }}>
        <Typography title={item.NZstZs} variant="body1" gutterBottom>
          {item.NZstZs}
        </Typography>
        <Typography variant="caption" className={classNames(classes.textCaption)}>
          {/* kjhsadkj haskjh sakdhsakjd sakjdh sakhsadh askjdh sakdhsakjd sakjdh sakhsadh askjdhsa k */}
          {item.ZsTxt}
        </Typography>
      </Box>
    </CardContent>
    <CardActions
      sx={{ minHeight: matchesThemeMD ? "1em" : "0.8em" }}
    >
      <Stack
        sx={{ flexDirection: "row" }}
        width={"100%"}
        justifyContent="space-between"
        alignItems="center"
      >
        <IconButton
          onClick={() => onClick(item.IZstZs)}
          variant="contained"
          color="inherit"
          toolTip=""
          toolTipPlace="right"
          size="large">
          <ArrowForward className={classNames(classes.navigationForwardButton)} />
        </IconButton>
        <Box textAlign={"center"} className={classNames(classes.infoCounter)}>{itemCounter}</Box>
      </Stack>
    </CardActions>
  </Card >
);

/**
 *  Zoznam zivotnych situacii
 *
 * @param {*} props
 * @return {*} 
 */
const ZstZsList = (props) => {
  const { classes, flt, zstZsTree } = props;
  const theme = useTheme();
  const breakpointMd = theme.breakpoints.up('md');
  const matchesThemeMD = useMediaQuery(breakpointMd);
  const breakpointLG = theme.breakpoints.up('lg');
  const matchesThemeLG = useMediaQuery(breakpointLG);

  // props.listZstZs.data = []
  if (!props.listZstZs.isLoaded) {
    return null;
  }

  let zstZsOrdered = props.listZstZs.data;
  if (flt.searchText) {
    const uniqueIDs = props.listZstSlz.data.map(x => x.ZstZs.IZstZs).filter((value, index, array) => array.indexOf(value) === index);
    const zstZs = props.listZstZs.data.filter(x => uniqueIDs.includes(x.IZstZs));
    zstZsOrdered = zstZs.sort((a, b) => (Number(a.CZorad ?? 999999999) * 1000000000 + Number(a.IZstZs)) - (Number(b.CZorad ?? 999999999) * 1000000000 + Number(b.IZstZs)));
  }

  return (
    <>
      {
        //  No data
        zstZsOrdered.length < 1 &&
        <WarningNoData infoText="Žiadne životné situácie nie sú k dispozícii" />
      }
      <Grid container spacing={1} direction="row" justifyContent="left" alignItems="stretch">
        {
          zstZsOrdered.length > 0 && zstZsOrdered.map(item => {
            // {props.listZstZs.data.length > 0 && props.listZstZs.data.map(item => {
            // const switchedOn = props.selectedIDs.includes(item.IZstZs);
            // console.debug(`${item.IZstZs}. ${item.NZstZs} [switchedOn=${switchedOn}]`);
            let itemCounter;
            if (flt.searchText) {
              itemCounter = (props.listZstSlz?.data.filter(x => x.ZstZs.IZstZs === item.IZstZs))?.length;
            } else {
              itemCounter = (zstZsTree?.data.find(Treeitem => Treeitem.IZstZs === item.IZstZs))?.SlzCount;
            }

            return (
              <Grid item key={`zs_${item.IZstZs}`} xs={matchesThemeLG ? 3 : (matchesThemeMD ? 4 : 12)} >
                <Item
                  elevation={1}
                // className={classNames(classes.btnZstZs, (switchedOn ? classes.switchedOn : classes.switchedOff))}
                >
                  <Box sx={{ height: "100%" }} >
                    <Button
                      component={"Box"}
                      fullWidth
                      sx={{
                        p: 0, textTransform: 'none',
                        height: "100%",
                      }}
                      onClick={() => props.onClick(item.IZstZs)}
                    >
                      {
                        card(classes, props.onClick, item, matchesThemeMD, itemCounter)
                      }
                    </Button>
                  </Box>
                </Item>
              </Grid >
            );
          })}
      </Grid>
    </>
  );
};

ZstZsList.propTypes = {
  classes: PropTypes.object,
  listZstZs: PropTypes.object,
  listZstSlz: PropTypes.object,
  zstZsTree: PropTypes.object,
  selectedIDs: PropTypes.array,
  flt: PropTypes.object,
  onClick: PropTypes.func,
  matchesThemeMD: PropTypes.bool,
};

const mapStateToProps = (state) => ({
  zstZsTree: state.zstZsTree,
});

const mapDispatchToProps = () => ({
});

export default connect(mapStateToProps, mapDispatchToProps)(ZstZsList);
