import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";

import withStyles from '@mui/styles/withStyles';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import getSharedStyles from "../../coraWebMComponents/sharedStyles";
import { getLoadedText, getTotalText} from "../../utils/sharedUtils";

const styles = (theme) => ({
  ...getSharedStyles(theme),
  rciRoot: {
    width: "100%",
    flex: 1,
    alignItems: "center",
    alignSelf: "center",
  },
  rciTotal: {
    justifyContent: "flex-end",
    alignItems: "center",
    // fontFamily: theme.typography.fontFamily,
    // fontSize: theme.typography.fontSize,
  },
});

class RecordCountInfoComponent extends React.Component {
  render() {
    const { classes } = this.props;
    const { data, total } = this.props.frmData;
    const totalText = getTotalText(total);
    const loadedText = getLoadedText(data.length);

    // const loadingCount = this.props.loadingCount;
    return (
      <Box className={classNames(classes.rciRoot)}>
        <Box className={classNames(classes.rciTotal, classes.row)}>
          <Typography
            variant={"caption"}
            fontWeight="bold"
            title={`${data.length}\xA0${loadedText}`}
          >{total}</Typography>
          <Typography variant={"caption"}>{`\xA0${totalText}`}</Typography>
        </Box>
      </Box>
    );
  }
}

RecordCountInfoComponent.propTypes = {
  frmData: PropTypes.object.isRequired,
  loadingCount: PropTypes.bool,
  onClick: PropTypes.func,
};

export default withStyles(styles)(RecordCountInfoComponent);
