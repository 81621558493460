import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";

import OptionsButtonAdmin from "./OptionsButtonAdmin";
import AdminModeSpFrmTxt from "./AdminModeSpFrmTxt";
import AdminModeSpColumns from "./AdminModeSpColumns";

/**
 * Vygeneruje tlacidlo a k nemu menu s polozkamy pre editaciu v admin mode
 *
 * @class AdminModeOptionsComponent
 * @extends {React.Component}
 */
const AdminModeOptionsComponent = (props) => {
  const [frmType, setFrmType] = useState(null);

  const menuItems = [
    { id: "spFrmTxt", text: "Zmena textu nad formulárom" },
    { id: "spFrmCols", text: "Zmena položiek formulára" },
  ];

  /** Udalosť Otvorenie */
  const handleDialogOpen = (frmType) => {
    setFrmType(frmType);
  };

  const handleDialogClose = () => {
    setFrmType(null);
  };

  const { primaryField, isMobile, schema, adminMode, reloadSchema } = props;
  const befTxt = schema.Args.befTxt;
  const initSort = schema.initSort[0]; //akceptujeme len jeden sortovaci stlpec

  if (!isMobile && adminMode.isActive) {
    const dialogParams = {};
    const items = menuItems.map((x) => {
      dialogParams[x.id] = {
        id: x.id,
        isOpen: x.id === frmType,
        title: x.text,
      };
      return { itemText: x.text, onClick: () => handleDialogOpen(x.id) };
    });

    return (
      <>
        <OptionsButtonAdmin items={items} />

        {dialogParams["spFrmTxt"].isOpen && (
          <AdminModeSpFrmTxt
            params={dialogParams["spFrmTxt"]}
            onClose={handleDialogClose}
            befTxt={befTxt}
            reloadSchema={reloadSchema}
          />
        )}

        {dialogParams["spFrmCols"].isOpen && (
          <AdminModeSpColumns
            primaryField={primaryField}
            params={dialogParams["spFrmCols"]}
            onClose={handleDialogClose}
            reloadSchema={reloadSchema}
            initSort={initSort}
          />
        )}
      </>
    );
  } else {
    return "";
  }
};

AdminModeOptionsComponent.propTypes = {
  primaryField: PropTypes.string.isRequired,
  schema: PropTypes.object.isRequired,
  reloadSchema: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    adminMode: state.adminMode,
  };
};

export default connect(mapStateToProps)(AdminModeOptionsComponent);
