import { useState } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';

import DirectionsCarOutlined from '@mui/icons-material/DirectionsCarOutlined';
import withStyles from '@mui/styles/withStyles';
import CloseIcon from '@mui/icons-material/Close';
import { ButtonBase } from '@mui/material';

import Typography from '../../coraWebMComponents/dataDisplay/Typography';
import IconButton from '../../coraWebMComponents/inputs/IconButton';
import ButtonFab from '../../coraWebMComponents/inputs/ButtonFab';
import TextField from '../../coraWebMComponents/inputs/TextField';
import Dialog from '../../coraWebMComponents/feedBack/Dialog';
import AddIcon from '../../coraWebMComponents/dataDisplay/icons/AddIcon';
import getSharedStyles from '../../coraWebMComponents/sharedStyles';
import { withLocalizationConsumer } from '../../coraWebMComponents/localization/withLocalization';
import { isValidEcv } from '../../coraWebMComponents/utils/validator';

import * as userActions from '../../actions/userActions';
import * as parkingActions from '../../actions/parkingActions';
import * as parkingUtils from '../../utils/parkingUtils';


const styles = theme => ({
  ...getSharedStyles(theme),
  dialogContent: {
    marginBottom: '60px',
  },
  ECVDialog: {
    marginTop: '20px',
  },
  ECVDialogItem: {
    borderBottom: '1px solid #e1e1e1',
    wordBreak: 'break-all',
    padding: '10px 0px',
    width: '100%',
    paddingLeft: 20,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
});

const ECVDialog = (props) => {
  const [isECVDialogOpen, setIsECVDialogOpen] = useState(false);
  const [isAddECVDialogOpen, setIsAddECVDialogOpen] = useState(false);
  const [isDeleteECVDialogOpen, setIsDeleteECVDialogOpen] = useState(false);
  const [ECVToDelete, setECVToDelete] = useState(null);
  const [newCarECV, setNewCarECV] = useState('');
  const [newCarName, setNewCarName] = useState('');
  const [errorTextAddCarEcv, setErrorTextAddCarEcv] = useState('');
  const [errorTextAddCarName, setErrorTextAddCarName] = useState('');

  const { classes } = props;

  const selectECV = (item) => {
    setIsECVDialogOpen(false);
    props.handleChangeECV(item.ECV);
  };

  const handleOpenAddECVDialog = (newCarECV) => {
    setIsAddECVDialogOpen(true);
    setNewCarECV(newCarECV ?? '');
    setNewCarName('');
    setErrorTextAddCarEcv('');
    setErrorTextAddCarName('');
  };

  const handleCloseAddECVDialog = () => {
    setIsAddECVDialogOpen(false);
    setNewCarECV('');
    setNewCarName('');
    setErrorTextAddCarEcv('');
    setErrorTextAddCarName('');
  };

  const handleAddECV = async () => {
    let res = await parkingUtils.addECV(newCarECV, newCarName);
    switch (res.Data) {
      case 0:
        props.dispatch(userActions.showMsg("EČV úspešne zaradené do zoznamu vozidiel."));
        await props.dispatch(parkingActions.getECVList());
        break;
      case -998:
        props.dispatch(userActions.showErrorMsg("EČV už existuje v zozname."));
        break;
      case -999:
        props.dispatch(userActions.showErrorMsg("Požiadavku sa nepodarilo spracovať."));
        break;
      default:
        props.dispatch(userActions.showErrorMsg("Neočakávaná chyba."));
        break;
    }
    setIsAddECVDialogOpen(false);
    setNewCarECV('');
    setNewCarName('');
    setIsECVDialogOpen(false);
    props.handleChangeECV(newCarECV);
  };

  const handleChangeECV = (event, value) => {
    value = value.toUpperCase();
    value = value.trim();
    setNewCarECV(value);
    const errorTextAddCarEcv = value ? isValidEcv(value, props.language) : props.translate('ECV') + props.translate('isRequired');
    setErrorTextAddCarEcv(errorTextAddCarEcv);
  };

  const handleChangeName = (event, value) => {
    setNewCarName(value);
    const errorTextAddCarName = value.length > 200 ? "Maximálna dĺžka názvu vozidla je 200 znakov." : "";
    setErrorTextAddCarName(errorTextAddCarName);
  };

  const getAddECVDialogContent = () => {
    return (
      <>
        <TextField
          label={props.translate('ECV')}
          fullwidth={true}
          value={newCarECV}
          errorText={errorTextAddCarEcv}
          error={errorTextAddCarEcv ? true : false}
          onChange={handleChangeECV}
          name='newCarECV'
          variant="standard"
        />
        <TextField
          label="Názov"
          fullwidth={true}
          value={newCarName}
          errorText={errorTextAddCarName}
          error={errorTextAddCarName ? true : false}
          onChange={handleChangeName}
          name='newCarName'
          variant="standard"
        />
      </>
    );
  };

  const handleOpenDeleteECVDialog = (event, item) => {
    event.stopPropagation();
    setIsDeleteECVDialogOpen(true);
    setECVToDelete(item);
  };

  const handleCloseDeleteECVDialog = () => {
    setIsDeleteECVDialogOpen(false);
    setECVToDelete(null);
  };

  const handleDeleteECV = async () => {
    if (ECVToDelete && ECVToDelete.I_ECV_UZ) {
      await parkingUtils.deleteECV(ECVToDelete.I_ECV_UZ);
      await props.dispatch(parkingActions.getECVList());
      setIsDeleteECVDialogOpen(false);
      props.dispatch(userActions.showMsg("EČV úspešne vymazané zo zoznamu vozidiel"));
    }
  };

  const getDeleteECVDialogContent = () => {
    return (
      <>
        <Typography
          style={{
            textAlign: 'center',
            fontWeight: 'bold',
            marginBottom: 20
          }}
        >
          {ECVToDelete ? ECVToDelete.ECV : null}
        </Typography>
        <Typography
          style={{
            textAlign: 'center',
            fontStyle: 'italic',
            wordWrap: 'break-word',
          }}
          cursive={true}
        >
          {ECVToDelete ? ECVToDelete.N_VOZ : null}
        </Typography>
      </>
    );
  };

  const getECVDialogContent = () => {
    return (<>
      {
        props.ECVList && props.ECVList.length > 0 ?
          <div className={classNames(classes.ECVDialog)}
          >
            {props.ECVList.map((item) => {
              return (
                (<ButtonBase className={classNames(classes.ECVDialogItem)} key={item.I_ECV_UZ}
                  onClick={() => selectECV(item)}
                >
                  <div style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}>
                    <DirectionsCarOutlined />
                    <div style={{
                      marginLeft: 10,
                      display: 'flex',
                      flexDirection: 'column',
                    }}>
                      <Typography align="left" style={{ fontStyle: "italic" }}>
                        {item.N_VOZ}
                      </Typography>
                      <Typography align="left">
                        {item.ECV}
                      </Typography>
                    </div>
                  </div>
                  <IconButton
                    disableTooltip={true}
                    onClick={(event) => handleOpenDeleteECVDialog(event, item)}
                    size="large">
                    <CloseIcon fontSize="small" style={{ color: 'red' }} />
                  </IconButton>
                </ButtonBase>)
              );
            })}
          </div >
          :
          null
      }
      <ButtonFab
        disableTooltip={true}
        size="small"
        onClick={() => handleOpenAddECVDialog(null)}
        style={{
          backgroundColor: "#4caf50",
          color: "#ffffff",
          position: 'absolute',
          bottom: 20,
          right: 20,
        }}
      >
        <AddIcon />
      </ButtonFab>
    </>);
  };

  return (
    <>
      <Dialog
        open={isECVDialogOpen}
        onClose={() => setIsECVDialogOpen(false)}
        title="Moje vozidlá"
        titleClasses={{ root: classNames(classes.textCenter, classes.titlePadding) }}
        actions={[]}
        content={getECVDialogContent()}
        contentClasses={{ root: classes.dialogContent }}
        closeButton={<div style={{ textAlign: 'right' }}><IconButton disableTooltip={true} size='small' onClick={() => setIsECVDialogOpen(false)} ><CloseIcon /></IconButton></div>}
      />
      <Dialog
        open={isAddECVDialogOpen}
        onClose={handleCloseAddECVDialog}
        title="Nové vozidlo"
        titleClasses={{ root: classNames(classes.textCenter, classes.titlePadding) }}
        actions={[
          {
            label: "Zrušiť",
            color: "primary",
            onClick: () => handleCloseAddECVDialog(),
          },
          {
            label: "Uložiť",
            color: "secondary",
            disabled: errorTextAddCarEcv || errorTextAddCarName || newCarECV === '' || newCarECV?.length === '',
            onClick: handleAddECV,
          }
        ]}
        content={getAddECVDialogContent()}
      />
      <Dialog
        open={isDeleteECVDialogOpen}
        onClose={handleCloseDeleteECVDialog}
        title="Zmazať"
        titleClasses={{ root: classNames(classes.textCenter, classes.titlePadding) }}
        actions={[
          {
            label: "Zrušiť",
            color: "primary",
            onClick: handleCloseDeleteECVDialog,
          },
          {
            label: "Zmazať",
            color: "secondary",
            onClick: handleDeleteECV,
          }
        ]}
        content={getDeleteECVDialogContent()}
      />
      <ButtonFab
        style={{ backgroundColor: '#ffffff', flexShrink: 0 }}
        size="small"
        disabled={props.disabled}
        onClick={() => props.ECVList ? setIsECVDialogOpen(true) : handleOpenAddECVDialog(props.ECV)}>
        <DirectionsCarOutlined />
      </ButtonFab >
    </>
  );
};

const mapStateToProps = (state) => {
  return {
    ECVList: state.ECVList.data,
    ECVListTotal: state.ECVList.total,
  };
};

export default withLocalizationConsumer(connect(mapStateToProps)(withStyles(styles)(ECVDialog)));