import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import withStyles from '@mui/styles/withStyles';
import { Typography } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';

import getSharedStyles from '../../coraWebMComponents/sharedStyles';
import DialogHeader from '../../coraWebMComponents/surfaces/DialogHeader';

import { getDetailVisibleColumns, getDetailTitle, Transition, buildValue } from './support';
import { boolToText } from './helper';

const styles = theme => ({
  ...getSharedStyles(theme),
  root: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    margin: '0 16px',
    padding: theme.spacing(2),
  },
  fld: {
    margin: '8px 0',
  },
  lbl: {
    fontSize: theme.typography.pxToRem(13),
    fontWeight: theme.typography.fontWeightBold,
  },
  val: {
    fontSize: theme.typography.pxToRem(12),
    fontWeight: theme.typography.fontWeightLight,
    minHeight: '1em'
  },
  titleContainer: {
    justifyContent: "flex-start",
  },
  
});

const DetailDialogComponent = ({ classes, closeClick, detailProps }) => {
  const { isOpen, columns, rowData, rowTitle } = detailProps;
  const visibleColumns = getDetailVisibleColumns(columns);
  const itemTitle = getDetailTitle(rowTitle, rowData);

  const consolidateValue = (value) => {
    switch (typeof value) {
      case "string":
        return value.trim();
      case "number":
        return value;
      case "boolean":
        return boolToText(value);
      default:
        return value;
    }
  };

  return (
    (<Dialog
      open={isOpen}
      onClose={closeClick}
      scroll='paper'
      maxWidth='sm'
      aria-labelledby="scroll-dialog-title"
      fullWidth={true}
      TransitionComponent={Transition}
    >
      <DialogTitle id="scroll-dialog-title" className={classes.root}>
        <DialogHeader title={itemTitle} onClose={closeClick} variant={"H5"} classes={classes} />
      </DialogTitle>
      <DialogContent>
        {visibleColumns.map(col => {
          const value = buildValue(rowData, col);
          if (value === null || value === undefined) {
            return null;
          }
          return (
            <div key={`col_${col.field}`} className={classNames(classes.alignItemsCenter, classes.fld)}>
              <Typography variant="body2" className={classNames(classes.row, classes.lbl)}>{col.title}</Typography>
              <Typography variant="body1" className={classNames(classes.row, classes.val)}>{consolidateValue(value)}</Typography>
            </div>
          );
        })}
      </DialogContent>
    </Dialog>)
  );
};

DetailDialogComponent.propTypes = {
  closeClick: PropTypes.func.isRequired,
  detailProps: PropTypes.shape({
    isOpen: PropTypes.bool.isRequired,
    columns: PropTypes.array.isRequired,
    rowData: PropTypes.object,
    rowTitle: PropTypes.string,
  }),
};

export default withStyles(styles)(DetailDialogComponent);
