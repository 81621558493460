import React from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import classNames from "classnames";

import { alpha } from "@mui/material/styles";
import makeStyles from '@mui/styles/makeStyles';
import Stack from '@mui/material/Stack';

import getSharedStyles from "../../coraWebMComponents/sharedStyles";
import ArrowBack from "../../coraWebMComponents/dataDisplay/icons/ArrowBack";
import IconButton from "../../coraWebMComponents/inputs/IconButton";

import SearchFilter from "./SearchFilter";
import ComboFilter from "./ComboFilter";
import ComboFiltersButton from "./ComboFiltersButton";
import RecordCountInfo from "./RecordCountInfo";
import ExportBtn from "./ExportBtn";
import routes from "../../routes";
import AdminModeOptions from "./AdminModeOptions";
import ChartOptions from "./ChartOptions";

// Define styles using makeStyles
const useStyles = makeStyles((theme) => ({
  ...getSharedStyles(theme),
  fhRoot: {
    // position: "relative",
    // display: 'table',
    // margin: `${theme.spacing(4)}px 0 ${theme.spacing(2)}px`,
    // width: `fit-content`,
    // marginHorizontal: `${theme.spacing(2)}px`,
    backgroundColor: theme.palette.grey[theme.palette.mode === "dark" ? 800 : 200],
  },
  cfButton: {
    backgroundColor: alpha(theme.palette.common.black, 0.25),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.black, 0.15),
    },
    borderRadius: theme.shape.borderRadius,
  },
  searchTextField: {
    maxWidth: "24rem",
  },
}));

const FrmHeader = (props) => {
  const classes = useStyles(); // Use the useStyles hook to apply styles
  const { primaryField, schema, frmData, frmState, isMobile } = props;

  const customUI = schema.Args;
  const comboFilters = customUI.comboFilters.filter(
    (flt) => flt.dataItems && flt.dataItems.length > 0
  );
  const { cboFltValues } = frmState;

  return (
    <Stack justifyContent="space-between" alignItems={"flex-end"} width="100%" spacing={1} direction={'row'} p={1}
      style={{
        // backgroundColor: "#1DE9B6",
        boxShadow: "none",
        position: "sticky ",
        /* rovne vyske titulku v datamesta*/
        top: "65px",
        boxSizing: "border-box",
        zIndex: "2",
      }}
    >
      <Stack flexGrow="1" justifyContent="flex-start" spacing={2} direction={'row'}
        style={{
          // backgroundColor: "#80CBC4",
          boxSizing: "border-box"
        }}
      >

        <Link to={props.backRoute ? props.backRoute : routes.PZ} title={`Návrat späť`}>
          <IconButton
            className={classNames(classes.backButton, classes.navigationBackButton, classes.ml1, classes.mr6)}
            toolTip={"Návrat späť"}
            toolTipPlace="right"
            size="large">
            <ArrowBack
              variant="fab"
              style={{ fontSize: 30 }}
            />
          </IconButton>
        </Link>

        <Stack justifyContent={"center"} alignItems={"center"} className={[classes.searchTextField]} flexGrow={1}>
          <SearchFilter
            searchText={frmState.searchText}
            placeholderText={"Vyhľadajte text"}
            onClick={props.onSearchFilterClick}
          />
        </Stack>

        {!isMobile && (
          <Stack flexGrow="1" justifyContent="flex-start" spacing={2} direction={'row'}>
            {comboFilters && comboFilters.length > 0 && comboFilters.map((flt) => {
              const value = cboFltValues[flt.id];
              return (
                <ComboFilter
                  fltMeta={flt}
                  fltValue={value}
                  key={`cboFlt${flt.id}`}
                  onClick={() => props.onComboFilterClick(flt.id)}
                />
              );
            })}

            <ExportBtn
              onClick={props.onExportBtnClick}
              handleCsvExportBtnClick={props.handleCsvExportBtnClick}
              handleJsonExportBtnClick={props.handleJsonExportBtnClick}
              postExportData={props.postExportData}
            />

            <ChartOptions
              classes={classes}
              schema={schema}
              isMobile={isMobile}
              loadChartData={props.loadChartData}
            />

            <AdminModeOptions
              classes={classes}
              primaryField={primaryField}
              schema={schema}
              reloadSchema={props.reloadSchema}
              isMobile={isMobile}
            />
          </Stack>
        )}
      </Stack>

      <Stack flexGrow="0" justifyContent="flex-end" spacing={1} direction={'row'} >
        {!isMobile && (
          <RecordCountInfo
            frmData={frmData}
            loadingCount={true}
            onClick={() => props.onEnd(10)}
          />
        )}
        {isMobile && comboFilters && comboFilters.length > 0 && (
          <Stack direction={'column'} justifyContent="flex-end" spacing={2} alignItems={"flex-end"}>
            <RecordCountInfo
              frmData={frmData}
              loadingCount={true}
              onClick={() => props.onEnd(10)}
            />
            <Stack direction={'rows'} justifyContent="flex-end" spacing={1} alignItems={"flex-end"}>
              <ChartOptions
                classes={classes}
                schema={schema}
                isMobile={isMobile}
                loadChartData={props.loadChartData}
              />
              <ComboFiltersButton
                // className={classes.cfButton}
                comboFilters={comboFilters}
                cboFltValues={cboFltValues}
                onComboFilterClick={props.onComboFilterClick}
              />
            </Stack>
          </Stack>
        )}
      </Stack>
    </Stack>
  );
};

FrmHeader.propTypes = {
  primaryField: PropTypes.string.isRequired,
  schema: PropTypes.object.isRequired,
  frmData: PropTypes.object.isRequired,
  frmState: PropTypes.object.isRequired,
  onComboFilterClick: PropTypes.func.isRequired,
  onSearchFilterClick: PropTypes.func.isRequired,
  handleCsvExportBtnClick: PropTypes.func,
  handleJsonExportBtnClick: PropTypes.func,
  postExportData: PropTypes.func,
  isMobile: PropTypes.bool,
  backRoute: PropTypes.string,
  reloadSchema: PropTypes.func,
  loadChartData: PropTypes.func
};

export default FrmHeader;
