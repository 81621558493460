import PropTypes from 'prop-types';
import makeStyles from '@mui/styles/makeStyles';
import classNames from 'classnames';

import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Box from '@mui/material/Box';

import getSharedStyles from "../../coraWebMComponents/sharedStyles";
import DialogHeader from '../../coraWebMComponents/surfaces/DialogHeader';

import { Transition } from './support';
import ChartDetail from './ChartDetail.js';

const useStyles = makeStyles((theme) => ({
  ...getSharedStyles(theme),
  root: {
    margin: 0,
    boxSizing: "border-box",
  },
  dialogContent: {
    width: '100%',
    boxSizing: "border-box",
    paddingTop: "100px",
  },
  divider: {
    borderTop: `1px dotted ${theme.palette.primary.main}`,
  },
}));

const Chart = ({ _params, onClose, chartParams, data, isMobile }) => {
  const classes = useStyles();

  return (<>
    <Dialog
      className={classes.root}
      open={true}
      onClose={onClose}
      scroll='body'
      maxWidth={'md'}
      aria-labelledby="scroll-dialog-title"
      fullWidth={true}
      fullScreen
      TransitionComponent={Transition}
    >
      <DialogTitle id="scroll-dialog-title"
        className={classes.root}
      >
        <DialogHeader title={chartParams.nGraf} variant="h5" onClose={onClose} />
        <Box className={classes.divider}></Box>
      </DialogTitle>
      <DialogContent className={classNames(classes.dialogContent)}>
        <Box>
          {data.Items?.length > 0 && <ChartDetail data={data} chartParams={chartParams} isMobile={isMobile} />}
        </Box>
      </DialogContent>
    </Dialog>
  </>);
};

Chart.propTypes = {
  params: PropTypes.object.isRequired,
  chartParams: PropTypes.object.isRequired,
  data: PropTypes.object.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default Chart;