//@ts-check
/** @module src/utils/publicZone/parkingTicketUtils */

///////////////////////////////////////////////
/* Logika k parkovacím lístkom               */
///////////////////////////////////////////////

/**
 * Vráti aktuálnu minimálnu jednotku času pre daný deň (progresívne parkovanie)
 * @function getCMinJ
 * @param {Date} currentDay Deň parkovania
 * @param {number} currentMinutes Spoplatnené minúty parkovania
 * @param {ZoneTimeList} zoneTimeList Zoznam údajov o parkovacej zóne
 * @param {TicketTimeSums} ticketTimeSums Sumy časov lístkov po dňoch pre danú zónu a EČV
 * @returns {number} Aktuálna minimálna jednotka času v minútach
 */
export const getCMinJ = (currentDay, currentMinutes, zoneTimeList, ticketTimeSums) => {
  const zoneTime = zoneTimeList.find(x => dateToNumber(new Date(x.DATUM)) === dateToNumber(currentDay));
  if (!zoneTime) return 0;

  const ticketTimeSum = ticketTimeSums?.find(x => dateToNumber(new Date(x.DATUM)) === dateToNumber(currentDay));
  const ticketTimeSumMinutes = ticketTimeSum?.TICKET_TIME_SUM ?? 0;
  const minutesSum = ticketTimeSumMinutes + currentMinutes;
  let C_MIN_J_CURRENT = 0;

  if (zoneTime.C_MIN_J) {
    C_MIN_J_CURRENT = zoneTime.C_MIN_J;

    if (zoneTime.C_MIN_J2 && minutesSum >= zoneTime.C_MIN_J) {
      C_MIN_J_CURRENT = zoneTime.C_MIN_J2;
    }

    if (zoneTime.C_MIN_J2 && zoneTime.C_MIN_J3 && minutesSum >= zoneTime.C_MIN_J + zoneTime.C_MIN_J2) {
      C_MIN_J_CURRENT = zoneTime.C_MIN_J3;
    }
  }

  return C_MIN_J_CURRENT;
};

/**
 * Vráti cenu za parkovanie
 * @function getPrice
 * @param {number} time Čas parkovania v minútach
 * @param {ZoneTime} zoneTime Údaje o parkovacej zóne
 * @param {number} previousTime Už preparkovaný čas v minútach
 * @returns {number} Cena parkovania v eurách
 */
export const getPrice = (time, zoneTime, previousTime) => {
  let price = 0;

  let sk = 0;
  let sk2 = 0;
  let sk3 = 0;

  let c_min_j = 0;
  let c_min_j2 = 0;
  let c_min_j3 = 0;

  if (zoneTime) {
    c_min_j = zoneTime.C_MIN_J ? zoneTime.C_MIN_J : 0;
    c_min_j2 = zoneTime.C_MIN_J2 ? zoneTime.C_MIN_J2 : 0;
    c_min_j3 = zoneTime.C_MIN_J3 ? zoneTime.C_MIN_J3 : 0;

    sk = zoneTime.SK ? zoneTime.SK : 0;
    sk2 = zoneTime.D_SK2 ? zoneTime.D_SK2 : 0;
    sk3 = zoneTime.D_SK3 ? zoneTime.D_SK3 : 0;
  }

  if (time <= 0 || c_min_j === 0) {
    return 0;
  }

  if (c_min_j && !c_min_j2 && !c_min_j3) {
    price = Math.ceil(time / c_min_j) * sk;
  }

  if (c_min_j && c_min_j2 && !c_min_j3) {
    if (time > 0) {
      if (previousTime < c_min_j) {
        price += sk;
        time -= c_min_j;
        time = time > 0 ? time : 0;
      }

      const podiel = Math.ceil(time / c_min_j2);
      price += podiel * sk2;
    }
  }

  if (c_min_j && c_min_j2 && c_min_j3) {
    if (time > 0) {
      if (previousTime < c_min_j) {
        price += sk;
        time -= c_min_j;
        time = time > 0 ? time : 0;
      }

      if (time > 0) {
        if (previousTime < c_min_j + c_min_j2) {
          price += sk2;
          time -= c_min_j2;
          time = time > 0 ? time : 0;
        }

        const podiel = Math.ceil(time / c_min_j3);
        price += podiel * sk3;
      }
    }
  }
  price = Number(price.toFixed(2));

  return price;
};

/**
 * Vráti skontrolované minúty pre plánované parkovanie s presahom cez polnoc
 * @function getOverlapValidatedMinutes
 * @param {boolean} isToTime Či je to čas do
 * @param {number} minutes Minúty parkovania. Je to buď začiatok alebo koniec parkovania v minútach
 * @param {ZoneTime} zoneTime Údaje o parkovacej zóne
 * @param {TimeData} timeData Časové údaje pre plánované parkovanie
 */
export const getOverlapValidatedMinutes = (isToTime, minutes, zoneTime, timeData) => {
  const date = isToTime ? timeData.dateTo : timeData.dateFrom;
  let minutesFrom = isToTime ? timeData.minutesFrom : minutes;
  let minutesTo = isToTime ? minutes : timeData.minutesTo
  let dateTo = new Date(timeData.dateTo);
  const isPlanOverLapZone = zoneTime.D_OD === "00:00" && zoneTime.D_DO2 === "24:00"

  /* Keď meníme čas od, potrebujeme upraviť aj čas do, aby sme nedovolili parkovanie mimo časový interval */
  if (isPlanOverLapZone && !isToTime) {
    const zoneTimeMinutesTo = getMinutesFromTimeString(zoneTime.D_DO);
    const zoneTimeMinutesFrom = getMinutesFromTimeString(zoneTime.D_OD2);

    if (minutesFrom >= 0 && minutesFrom <= zoneTimeMinutesTo && (minutesTo > zoneTimeMinutesTo || dateDiffInDays(date, dateTo) > 0)) {
      minutesTo = zoneTimeMinutesTo

      dateTo = new Date(date);
    }

    if (minutesFrom >= zoneTimeMinutesFrom && minutesFrom <= 1440 && minutesTo > zoneTimeMinutesTo && dateDiffInDays(date, dateTo) > 0) {
      minutesTo = zoneTimeMinutesTo
    }
  }

  return { minutesFrom, minutesTo, dateTo };
}

/**
 * Vráti skontrolované minúty pre plánované parkovanie (kontroluje intervaly)
 * @function validateMinutesPlanIntervalBounds
 * @param {boolean} isToTime Či je to čas do
 * @param {boolean} isAdd Či sa pridávajú minúty
 * @param {number} minutes Minúty parkovania. Je to buď začiatok alebo koniec parkovania v minútach
 * @param {ZoneTimeList} zoneTimeList Zoznam údajov o parkovacej zóne
 * @param {TimeData} timeData Časové údaje pre plánované parkovanie
 * @returns {number} Skontrolované minúty
 */
export const validateMinutesPlanIntervalBounds = (
  isToTime,
  isAdd,
  minutes,
  zoneTimeList,
  timeData
) => {
  const day = isToTime ? timeData.dateTo : timeData.dateFrom;
  const currentZoneTime = zoneTimeList.find(item => dateToNumber(new Date(item.DATUM)) === dateToNumber(day));
  if (!currentZoneTime) return minutes;

  const isPlanOverLapZone = currentZoneTime.D_OD === "00:00" && currentZoneTime.D_DO2 === "24:00"
  const timeIntervals = getTimeIntervalsMinutes(currentZoneTime);
  const minutesTo = getMinutesFromTimeString(currentZoneTime.D_DO);
  const beginning = getMinutesFromTimeString(currentZoneTime.D_OD);
  let end = getMinutesFromTimeString(currentZoneTime.D_DO3 ?? currentZoneTime.D_DO2 ?? currentZoneTime.D_DO)

  /* Ak je to parkovanie s presahom cez polnoc, tak používateľa pustíme maximálne do konca prvého intervalu nasledujúceho dňa */
  if (isPlanOverLapZone && isToTime) {
    const isNextDay = dateDiffInDays(timeData.dateFrom, timeData.dateTo) === 1;

    if ((timeData.minutesFrom >= 0 && timeData.minutesFrom < minutesTo) || isNextDay) {
      end = minutesTo
    }
  }

  /* Ak čas nie je v niektorom z intervalov, tak ho posunieme buď na začiatok nasledujúceho alebo koniec predošlého
  intervalu, podľa toho, či sa čas pridáva alebo odoberá */
  if (!timeIntervals.some(interval => isMinutesWithinInterval(minutes, interval))) {
    if (isAdd) {
      for (let i = timeIntervals.length - 2; i >= 0; i--) {
        if (minutes >= timeIntervals[i].end) {
          const minutesOverflow = minutes - timeIntervals[i].end;
          minutes = timeIntervals[i + 1].start + minutesOverflow;
          break;
        }
      }
    } else {
      for (let i = 1; i < timeIntervals.length; i++) {
        if (minutes <= timeIntervals[i].start) {
          const minutesUnderflow = timeIntervals[i].start - minutes;
          minutes = timeIntervals[i - 1].end - minutesUnderflow;
          break;
        }
      }
    }
  }

  /* Skontrolujeme, či minúty presahujú cez hranice */
  if (isAdd) {
    if (minutes > end)
      minutes = end;
  } else {
    if (minutes < beginning) {
      minutes = beginning;
    }
    const nowMinutes = getNowZoneTimeMinutes(zoneTimeList);
    if (nowMinutes >= 0 && dateToNumber(new Date()) === dateToNumber(day) && nowMinutes > minutes) {
      minutes = nowMinutes > end ? end : nowMinutes;

      const currentInterval = timeIntervals.find(interval => interval.start <= minutes && minutes <= interval.end);
      if (!currentInterval) {
        let nextInterval = timeIntervals.find(interval => interval.start > minutes);

        if (nextInterval) {
          minutes = nextInterval.start;
        }
      }
    }
  }

  return minutes;
};

/**
 * Vráti skontrolované minúty pre plánované parkovanie (kontroluje maximálny počet hodín pre jeden lístok)
 * @function validateMinutesPlanMaxHoursOneTicket
 * @param {boolean} isToTime Či je to čas do
 * @param {boolean} isAdd Či sa pridávajú minúty
 * @param {number} minutes Minúty parkovania. Je to buď začiatok alebo koniec parkovania v minútach
 * @param {number} maxHoursOneTicket Maximálny počet hodín pre jeden lístok
 * @param {TimeData} timeData Časové údaje pre plánované parkovanie
 * @param {ZoneTimeList} zoneTimeList Zoznam údajov o parkovacej zóne
 * @param {Function} maxHoursOneTicketWarning Callback pre upozornenie na maximálny počet hodín pre jeden lístok
 * @param {TicketPriceSums} ticketPriceSums Sumy cien lístkov po dňoch pre danú zónu a EČV
 * @param {TicketTimeSums} ticketTimeSums Sumy časov lístkov po dňoch pre danú zónu a EČV
 * @returns {number} Skontrolované minúty
 */
export const validateMinutesPlanMaxHoursOneTicket = (
  isToTime,
  isAdd,
  minutes,
  maxHoursOneTicket,
  timeData,
  zoneTimeList,
  maxHoursOneTicketWarning,
  ticketPriceSums,
  ticketTimeSums
) => {
  const maxMinutesOneTicket = maxHoursOneTicket * 60

  const timeDataTemp = {
    minutesFrom: isToTime ? timeData.minutesFrom : minutes,
    minutesTo: isToTime ? minutes : timeData.minutesTo,
    dateFrom: timeData.dateFrom,
    dateTo: timeData.dateTo
  }

  const newPaidMinutesSum = getPlannedParkingData(timeDataTemp, zoneTimeList, ticketPriceSums, ticketTimeSums).totalMinutes

  if (maxMinutesOneTicket !== 0 && newPaidMinutesSum > maxMinutesOneTicket) {
    if (isToTime) {
      const minutesOverFlow = newPaidMinutesSum - maxMinutesOneTicket
      minutes = validateMinutesPlanIntervalBounds(isToTime, isAdd, minutes - minutesOverFlow, zoneTimeList, timeDataTemp);
    } else {
      const minutesOverFlow = newPaidMinutesSum - maxMinutesOneTicket
      minutes = validateMinutesPlanIntervalBounds(isToTime, isAdd, minutes + minutesOverFlow, zoneTimeList, timeDataTemp);
    }

    maxHoursOneTicketWarning();
  }

  return minutes;
}

/**
 * Vráti zoznam časových intervalov pre daný deň
 * @function getTimeIntervals
 * @param {ZoneTime} zoneTime Údaje o parkovacej zóne pre daný deň
 * @returns {TimeIntervals} Zoznam časových intervalov
 */
export const getTimeIntervals = (zoneTime) => {
  const baseDate = new Date(zoneTime.DATUM);

  const timeIntervals = []

  timeRanges.forEach(interval => {
    const start = mergeDateWithTimeString(baseDate, zoneTime[interval.start]);
    const end = mergeDateWithTimeString(baseDate, zoneTime[interval.end]);
    if (start && end) {
      timeIntervals.push({ start, end });
    }
  });

  return timeIntervals
}

/**
 * Vráti zoznam časových intervalov pre daný deň v minutách
 * @function getTimeIntervalsMinutes
 * @param {ZoneTime} zoneTime Údaje o parkovacej zóne pre daný deň
 * @returns {TimeIntervalsMinutes} Zoznam časových intervalov v minutách
 */
export const getTimeIntervalsMinutes = (zoneTime) => {
  const timeIntervals = getTimeIntervals(zoneTime);

  const timeIntervalsMinutes = timeIntervals.map(x => {
    return {
      start: getTotalMinutesOfDay(x.start),
      end: getTotalMinutesOfDay(x.end)
    }
  });

  return timeIntervalsMinutes;
}

/**
 * Vráti či sú minúty v intervale
 * @function isMinutesWithinInterval
 * @param {number} minutes Minúty
 * @param {TimeIntervalMinutes} interval Interval
 * @returns {boolean} Či sú minúty v intervale
 */
export const isMinutesWithinInterval = (minutes, interval) => interval.start <= minutes && minutes <= interval.end;

/**
 * Vráti najbližšie platné minúty pre parkovanie.
 * Ak je 00:25 a C_MIN_J je 15, tak vráti 30. Ak sa v danom dni neparkuje, tak vráti -1
 * @function getNowZoneTimeMinutes
 * @param {ZoneTimeList} zoneTimeList Zoznam údajov o parkovacej zóne
 * @returns {number} Minúty
 */
export const getNowZoneTimeMinutes = (zoneTimeList) => {
  const dateTimeNow = new Date();
  let dateTimeRounded = null;
  let minutes = -1;
  const zoneTime = zoneTimeList.find(item => dateToNumber(new Date(item.DATUM)) === dateToNumber(dateTimeNow));

  if (zoneTime !== null && zoneTime?.D_OD && zoneTime.D_DO) {
    dateTimeRounded = roundTime(dateTimeNow, zoneTime.C_MIN_J);
    minutes = getTotalMinutesOfDay(dateTimeRounded);
  }

  return minutes;
};

/**
 * Vráti limit s odrátanou už preparkovanou cenou pre daný deň
 * @function getLimitForDay
 * @param {Date} date Dátum parkovania
 * @param {TicketPriceSums} ticketPriceSums Sumy cien lístkov po dňoch pre danú zónu a EČV
 * @param {ZoneTimeList} zoneTimeList Zoznam údajov o parkovacej zóne
 * @returns {number | null} Limit s odrátanou cenou
 */
export const getLimitMinusSumForDay = (date, ticketPriceSums, zoneTimeList) => {
  let todayZoneTime = zoneTimeList.find(x => dateStringToNumber(x.DATUM) === dateToNumber(date));
  if (!todayZoneTime) return null

  if (todayZoneTime.L_SVIATOK_D7) {
    todayZoneTime = zoneTimeList.find(x => x.SKR_DEN === "NE");
    if (!todayZoneTime) return null
  }

  if (!todayZoneTime.LIMIT) return null;

  const ticketPriceSum = ticketPriceSums?.find(x => dateStringToNumber(x.DATUM) === dateStringToNumber(todayZoneTime.DATUM));
  const limitMinusSum = todayZoneTime.LIMIT - (ticketPriceSum?.TICKET_PRICE_SUM ?? 0);

  return limitMinusSum > 0 ? limitMinusSum : 0;
};

/**
 * Vráti limitom skontrolovanú cenu
 * @function getLimitValidatedPrice
 * @param {number} price Cena
 * @param {Date} date Dátum parkovania
 * @param {TicketPriceSums} ticketPriceSums Sumy cien lístkov po dňoch pre danú zónu a EČV
 * @param {ZoneTimeList} zoneTimeList Zoznam údajov o parkovacej zóne
 * @returns {number} Skontrolovaná cena
 */
export const getLimitValidatedPrice = (price, date, ticketPriceSums, zoneTimeList) => {
  const limitMinusSum = getLimitMinusSumForDay(date, ticketPriceSums, zoneTimeList);
  if (limitMinusSum !== null && price > limitMinusSum) {
    price = limitMinusSum;
  }

  return price;
};



/**
 * Vráti časovými intervalmi skontrolovaný čas
 * @function getIntervalValidatedTime
 * @param {ZoneTime} zoneTime Údaje o parkovacej zóne
 * @param {number} time Čas v minútach
 * @param {Date} timeFrom Čas od
 * @returns {Date | null} Skontrolovaný čas
 */
export const getIntervalValidatedTime = (zoneTime, time, timeFrom = new Date()) => {
  let timeTo = new Date(timeFrom)
  const timeMin = getTimeMin(zoneTime)

  if (timeTo < timeMin)
    timeTo = new Date(timeMin);

  const timeIntervals = getTimeIntervals(zoneTime)
  const currentIntervalIndex = getCurrentOrNextIntervalIndex(timeIntervals, timeTo)

  if (currentIntervalIndex === -1 && timeIntervals.length > 0) {
    return timeIntervals.at(-1)?.end ?? null
  }

  const intervalBounds = []
  timeIntervals.slice(currentIntervalIndex).forEach((x, i) => {
    const startMinutes = getTotalMinutesOfDay(x.start)
    const endMinutes = getTotalMinutesOfDay(x.end)

    const sum = endMinutes - startMinutes
    intervalBounds.push({ bound: sum, index: i })
  })

  let timePassed = getTotalMinutesOfDay(timeFrom) - getTotalMinutesOfDay(timeIntervals[currentIntervalIndex].start);
  if (timePassed <= 0 || dateToNumber(timeFrom) !== dateToNumber(timeIntervals[currentIntervalIndex].start)) timePassed = 0;

  let timeIntervalPassedSum = time + timePassed

  for (let i = 0; i < intervalBounds.length; i++) {
    if (timeIntervalPassedSum > intervalBounds[i].bound) {
      timeIntervalPassedSum -= intervalBounds[i].bound
    }
    else {
      timeTo = new Date(timeIntervals[currentIntervalIndex + intervalBounds[i].index].start)
      timeTo.setMinutes(timeTo.getMinutes() + timeIntervalPassedSum)
      break
    }
  }

  return timeTo;
}



/**
 * Vráti začiatok aktuálneho intervalu, alebo ak čas nie je v rámci intervalu tak začiatok nasledovného
 * @function getCurrentOrNextIntervalBeginning
 * @param {TimeIntervals} timeIntervals Zoznam časových intervalov
 * @param {Date} time Aktuálny čas
 * @returns {Date} Začiatok aktuálneho alebo nasledujúceho intervalu
 */
export const getCurrentOrNextIntervalBeginning = (timeIntervals, time) => {
  let interval = timeIntervals.find(x => x.start <= time && x.end >= time);

  if (!interval) {
    interval = timeIntervals.find(x => time < x.start);
  }

  return interval ? interval.start : timeIntervals.at(-1)?.start ?? getMidnight(time);
}

/**
 * Vráti index aktuálneho alebo nasledujúceho intervalu
 * @function getCurrentOrNextIntervalIndex
 * @param {TimeIntervals} timeIntervals Zoznam časových intervalov
 * @param {Date} time Aktuálny čas
 * @returns {number} Index aktuálneho alebo nasledujúceho intervalu
 */
export const getCurrentOrNextIntervalIndex = (timeIntervals, time) => {
  let index = timeIntervals.findIndex(x => x.start <= time && x.end >= time);

  if (index === -1) {
    index = timeIntervals.findIndex(x => time < x.start);
  }

  return index;
}



/**
 * Vráti aktuálne údaje o parkovacej zóne pre daný deň
 * @function getCurrentZoneTime
 * @param {ZoneTimeList} zoneTimeList Zoznam údajov o parkovacej zóne
 * @returns {ZoneTime | undefined} Údaje o parkovacej zóne pre daný deň
 */
export const getCurrentZoneTime = (zoneTimeList) => {
  let zoneTime = null
  let date = null

  for (let i = 0; i < zoneTimeList.length; i++) {
    const now = new Date()

    date = new Date(zoneTimeList[i].DATUM)
    zoneTime = zoneTimeList[i]

    const dDo = zoneTime.D_DO3 || zoneTime.D_DO2 || zoneTime.D_DO
    if (!dDo) continue

    const timeMax = new Date(date.getFullYear(), date.getMonth(), date.getDate(), getHours(dDo), getMinutes(dDo))

    if (now > timeMax) {
      continue
    }

    return zoneTime
  }
}

/**
 * Vráti počet spoplatnených minút do konca dňa
 * @function getPaidMinutesUntilEndOfDay
 * @param {ZoneTime} zoneTime Údaje o parkovacej zóne
 * @returns {number} Počet spoplatnených minút do konca dňa
 */
export const getPaidMinutesUntilEndOfDay = (zoneTime) => {
  let timeNow = new Date()
  const timeIntervals = getTimeIntervals(zoneTime)
  const timeMin = getTimeMin(zoneTime)

  if (timeNow < timeMin) {
    timeNow = timeMin;
  }

  let currentInterval = timeIntervals.findIndex(x => timeNow >= x.start && timeNow <= x.end)
  if (currentInterval === -1) {
    currentInterval = 0
  }

  const paidMinutesUntilEndOfDay = timeIntervals.slice(currentInterval).reduce((acc, x) => acc + (getTotalMinutesOfDay(x.end) - getTotalMinutesOfDay(x.start)), 0);
  const minutesPassed = getTotalMinutesOfDay(timeNow) - getTotalMinutesOfDay(timeIntervals[currentInterval].start);

  return paidMinutesUntilEndOfDay - minutesPassed;
}

/**
 * Vráti spodnú hranicu parkovania
 * @function getTimeMin
 * @param {ZoneTime} zoneTime Údaje o parkovacej zóne
 * @returns {Date} Spodná hranica parkovania
 */
export const getTimeMin = (zoneTime) => {
  const timeIntervals = getTimeIntervals(zoneTime)
  const timeMin = getCurrentOrNextIntervalBeginning(timeIntervals, new Date())

  return timeMin
}

/**
 * Vráti skontrolované minúty
 * @function getValidatedMinutes
 * @param {number} minutes Minúty
 * @param {Date} dateOfParking Dátum parkovania
 * @param {ZoneTimeList} zoneTimeList Zoznam údajov o parkovacej zóne
 * @param {TicketTimeSums} ticketTimeSums Sumy časov lístkov po dňoch pre danú zónu a EČV
 * @returns {number} skontrolovane minuty
 */
export const getValidatedMinutes = (minutes, dateOfParking, zoneTimeList, ticketTimeSums) => {
  const cMinJLowest = getCMinJ(dateOfParking, 0, zoneTimeList, ticketTimeSums);
  const zoneTime = getCurrentZoneTime(zoneTimeList);
  if (!zoneTime) return 0;

  const timeMin = getTimeMin(zoneTime)

  if (minutes < cMinJLowest) {
    minutes = cMinJLowest;
  }

  let timeNow = new Date();
  if (timeNow < timeMin)
    timeNow = new Date(timeMin);

  const toEndOfDay = getPaidMinutesUntilEndOfDay(zoneTime);

  if (minutes > toEndOfDay)
    minutes = toEndOfDay;

  return minutes;
};

/**
 * Vráti údaje pre parkovanie teraz
 * @function getNowParkingData
 * @param {number} totalMinutes Celkový počet minút
 * @param {ZoneTimeList} zoneTimeList Zoznam údajov o parkovacej zóne
 * @param {TicketTimeSums} ticketTimeSums Sumy časov lístkov po dňoch pre danú zónu a EČV
 * @param {TicketPriceSums} ticketPriceSums Sumy cien lístkov po dňoch pre danú zónu a EČV
 * @returns {NowParkingData | null} Vypočítané údaje pre parkovanie teraz
 */
export const getNowParkingData = (totalMinutes, zoneTimeList, ticketTimeSums, ticketPriceSums) => {
  const zoneTime = getCurrentZoneTime(zoneTimeList);
  if (!zoneTime) return null;

  const dateOfParking = new Date(zoneTime.DATUM);

  totalMinutes = getValidatedMinutes(totalMinutes, dateOfParking, zoneTimeList, ticketTimeSums);

  const hours = Math.trunc(totalMinutes / 60);
  const minutes = totalMinutes - (60 * hours);

  const previousTime = ticketTimeSums?.find(x => dateToNumber(new Date(x.DATUM)) === dateToNumber(dateOfParking))?.TICKET_TIME_SUM ?? 0;
  let price = getPrice(totalMinutes, zoneTime, previousTime);
  price = getLimitValidatedPrice(price, dateOfParking, ticketPriceSums, zoneTimeList);


  const timeTo = getIntervalValidatedTime(zoneTime, totalMinutes);

  const currentCMinJ = getCMinJ(dateOfParking, minutes, zoneTimeList, ticketTimeSums);

  return {
    hours,
    minutes,
    price,
    timeTo,
    currentCMinJ
  }
}

/**
 * Vráti údaje pre plánované parkovanie
 * @function getPlannedParkingData
 * @param {TimeData} timeData Časové údaje pre plánované parkovanie
 * @param {ZoneTimeList} zoneTimeList Zoznam údajov o parkovacej zóne
 * @param {TicketPriceSums} ticketPriceSums Sumy cien lístkov po dňoch pre danú zónu a EČV
 * @param {TicketTimeSums} ticketTimeSums Sumy časov lístkov po dňoch pre danú zónu a EČV
 * @returns {PlannedParkingData} Vypočítané údaje pre plánované parkovanie
  */
export const getPlannedParkingData = (
  timeData,
  zoneTimeList,
  ticketPriceSums,
  ticketTimeSums
) => {
  /** @type {PlannedParkingData} */
  const plannedParkingData = {
    dateFrom: timeData.dateFrom,
    dateTo: timeData.dateTo,
    totalMinutes: 0,
    totalPrice: 0.0,
    items: [],
    minutesFrom: timeData.minutesFrom,
    minutesTo: timeData.minutesTo
  };
  const fromDateNumber = dateToNumber(plannedParkingData.dateFrom);
  const toDateNumber = dateToNumber(plannedParkingData.dateTo);

  const filteredZoneTimeList = zoneTimeList.filter(item =>
    item.D_OD &&
    item.D_DO &&
    dateToNumber(new Date(item.DATUM)) > 0 &&
    dateToNumber(new Date(item.DATUM)) >= fromDateNumber &&
    dateToNumber(new Date(item.DATUM)) <= toDateNumber);

  if (timeData.minutesFrom >= 0 && timeData.minutesTo >= 0) {
    filteredZoneTimeList.forEach(zoneTime => {
      const D_OD = zoneTime.D_OD;
      const D_DO = zoneTime.D_DO3 ?? zoneTime.D_DO2 ?? zoneTime.D_DO;

      let zoneTimeMinutesFrom = getMinutesFromTimeString(D_OD);
      let zoneTimeMinutesTo = getMinutesFromTimeString(D_DO);

      if (dateToNumber(new Date(zoneTime.DATUM)) === fromDateNumber) {
        zoneTimeMinutesFrom = timeData.minutesFrom;
      }

      if (dateToNumber(new Date(zoneTime.DATUM)) === toDateNumber) {
        zoneTimeMinutesTo = timeData.minutesTo;
      }

      let resItem = {
        date: new Date(zoneTime.DATUM),
        dateNumber: dateToNumber(new Date(zoneTime.DATUM)),
        dOd: zoneTime.D_OD,
        dDo: zoneTime.D_DO3 ?? zoneTime.D_DO2 ?? zoneTime.D_DO,
        dSk: zoneTime.SK,
        cMinJ: zoneTime.C_MIN_J,
        dSk2: zoneTime.D_SK2,
        cMinJ2: zoneTime.C_MIN_J2,
        dSk3: zoneTime.D_SK3,
        cMinJ3: zoneTime.C_MIN_J3,
        minutesFrom: -1,
        minutesTo: -1,
        dayMinutes: 0,
        dayPrice: 0
      };

      if (zoneTime.D_OD && zoneTime.D_DO) {
        resItem.minutesFrom = zoneTimeMinutesFrom;
        resItem.minutesTo = zoneTimeMinutesTo;

        const timeIntervals = getTimeIntervalsMinutes(zoneTime);

        /* Zrátanie spoplatnených minút v rámci jedného dňa */
        resItem.dayMinutes = timeIntervals.reduce((acc, interval) => {
          if (resItem.minutesFrom < interval.start && resItem.minutesTo > interval.end) {
            return acc + (interval.end - interval.start);
          } else if (resItem.minutesFrom < interval.start && resItem.minutesTo >= interval.start && resItem.minutesTo <= interval.end) {
            return acc + (resItem.minutesTo - interval.start);
          } else if (resItem.minutesFrom >= interval.start && resItem.minutesFrom <= interval.end && resItem.minutesTo > interval.end) {
            return acc + (interval.end - resItem.minutesFrom);
          } else if (resItem.minutesFrom >= interval.start && resItem.minutesTo <= interval.end) {
            return acc + (resItem.minutesTo - resItem.minutesFrom);
          } else {
            return acc;
          }
        }, 0);

        if (resItem.dayMinutes > 0) {
          plannedParkingData.totalMinutes += resItem.dayMinutes;
          const previousTime = ticketTimeSums?.find(x => dateToNumber(new Date(x.DATUM)) === dateToNumber(resItem.date))?.TICKET_TIME_SUM ?? 0;
          let dayPrice = getPrice(resItem.dayMinutes, zoneTime, previousTime);
          const firstInterval = timeIntervals[0];
          const isPlanOverlapZone = zoneTime.D_OD === "00:00" && zoneTime.D_DO2 === "24:00"

          if (isPlanOverlapZone && resItem.minutesFrom >= firstInterval.start && resItem.minutesTo <= firstInterval.end) {
            /* Ak je parkovanie cez polnoc, tak kontrolujeme limit predošlého dňa (napr. 19:00 - 07:00 má len jeden limit) */
            const dayOfLimit = previousDay(resItem.date);
            const yesterdayPrice = plannedParkingData.items.find(x => x.dateNumber === dateToNumber(dayOfLimit))?.dayPrice ?? 0;
            const totalPrice = yesterdayPrice + dayPrice;
            const validatedTotalPrice = getLimitValidatedPrice(totalPrice, dayOfLimit, ticketPriceSums, zoneTimeList);

            dayPrice = validatedTotalPrice - yesterdayPrice;
          } else {
            dayPrice = getLimitValidatedPrice(dayPrice, resItem.date, ticketPriceSums, zoneTimeList);
          }

          resItem.dayPrice = dayPrice
          plannedParkingData.totalPrice += resItem.dayPrice;
        }
      }
      plannedParkingData.items.push(resItem);
    })
  }

  return plannedParkingData;
}

/**
 * Vráti čas predĺžený do konca parkovacej doby v prípade dosiahnutia limitu (parkovanie s presahom cez polnoc)
 * @function getExtendedEndTime
 * @param {TimeData} timeData Časové údaje pre plánované parkovanie
 * @param {ZoneTimeList} zoneTimeList Zoznam údajov o parkovacej zóne
 * @param {TicketPriceSums} ticketPriceSums Sumy cien lístkov po dňoch pre danú zónu a EČV
 * @param {TicketTimeSums} ticketTimeSums Sumy časov lístkov po dňoch pre danú zónu a EČV
 * @returns {TimeData | null} Čas predĺžený do konca parkovacej doby
 */
export const getExtendedEndTime = (timeData, zoneTimeList, ticketPriceSums, ticketTimeSums) => {
  const plannedParkingData = getPlannedParkingData(timeData, zoneTimeList, ticketPriceSums, ticketTimeSums)
  if (plannedParkingData.minutesTo < 0) return null

  let dayOfLimit = new Date(timeData.dateFrom)

  const zoneTime = zoneTimeList.find(item => dateStringToNumber(item.DATUM) === dateToNumber(dayOfLimit));

  if (!zoneTime) return null

  if (plannedParkingData.items.length === 1) {
    const item = plannedParkingData.items[0]
    const timeIntervals = getTimeIntervalsMinutes(zoneTime)
    const firstInterval = timeIntervals[0];

    if (item.minutesFrom >= firstInterval.start && item.minutesTo <= firstInterval.end) {
      dayOfLimit.setDate(dayOfLimit.getDate() - 1);
    }
  }

  const limitMinusSum = getLimitMinusSumForDay(dayOfLimit, ticketPriceSums, zoneTimeList)

  if (plannedParkingData.totalPrice === limitMinusSum) {
    const minutesTo = getMinutesFromTimeString(zoneTime.D_DO)
    const dateTo = new Date(plannedParkingData.dateTo)

    if (plannedParkingData.items.length === 1) {
      const plannedParkingItem = plannedParkingData.items[0]

      if (plannedParkingItem.minutesFrom >= getMinutesFromTimeString(zoneTime.D_OD2) && plannedParkingItem.minutesTo <= 1440) {
        dateTo.setDate(dateTo.getDate() + 1)
      }
    }

    if (minutesTo !== plannedParkingData.minutesTo) {
      return {
        minutesFrom: plannedParkingData.minutesFrom,
        minutesTo: minutesTo,
        dateFrom: plannedParkingData.dateFrom,
        dateTo: dateTo
      }
    }
  }

  return null
}

/**
 * Skontroluje, či po pridaní času nebude prekročený maximálny čas pre jeden lístok
 * @function isOkMaxTimeOneTicket
 * @param {boolean} isToTime Či je to čas do
 * @param {number} minutes Minúty
 * @param {TimeData} timeData Časové údaje pre plánované parkovanie
 * @param {number} maxHoursOneTicket Maximálny počet hodín pre jeden lístok
 * @param {ZoneTimeList} zoneTimeList Zoznam údajov o parkovacej zóne
 * @param {TicketPriceSums} ticketPriceSums Sumy cien lístkov po dňoch pre danú zónu a EČV
 * @param {TicketTimeSums} ticketTimeSums Sumy časov lístkov po dňoch pre danú zónu a EČV
 * @returns {boolean} True, ak nie je prekročený maximálny čas pre jeden lístok
 */
export const isOkMaxTimeOneTicket = (isToTime, minutes, timeData, maxHoursOneTicket, zoneTimeList, ticketPriceSums, ticketTimeSums) => {
  const maxMinutesOneTicket = maxHoursOneTicket * 60

  const timeDataTemp = {
    minutesFrom: isToTime ? timeData.minutesFrom : minutes,
    minutesTo: isToTime ? minutes : timeData.minutesTo,
    dateFrom: timeData.dateFrom,
    dateTo: timeData.dateTo
  }

  const newPaidMinutesSum = getPlannedParkingData(timeDataTemp, zoneTimeList, ticketPriceSums, ticketTimeSums).totalMinutes;

  if (maxMinutesOneTicket !== 0 && newPaidMinutesSum > maxMinutesOneTicket) {
    return false
  }

  return true
}

/**
 * Vráti upravený čas po stlačení tlačidiel pri plánovanom parkovaní
 * @function modifyPlannedTime
 * @param {boolean} isToTime Či je to čas do
 * @param {number} buttonId ID tlačidla
 * @param {TimeData} timeData Časové údaje pre plánované parkovanie
 * @param {ZoneTimeList} zoneTimeList Zoznam údajov o parkovacej zóne
 * @param {TicketPriceSums} ticketPriceSums Sumy cien lístkov po dňoch pre danú zónu a EČV
 * @param {TicketTimeSums} ticketTimeSums Sumy časov lístkov po dňoch pre danú zónu a EČV
 * @param {number} maxHoursOneTicket Maximálny počet hodín pre jeden lístok
 * @param {Function} maxHoursOneTicketWarning Varovanie pre prekročenie maximálneho počtu hodín pre jeden lístok
 * @returns {TimeData | null} Časové údaje pre plánované parkovanie
 */
export const modifyPlannedTime = (
  isToTime,
  buttonId,
  timeData,
  zoneTimeList,
  ticketPriceSums,
  ticketTimeSums,
  maxHoursOneTicket,
  maxHoursOneTicketWarning) => {
  let minutes = isToTime ? timeData.minutesTo : timeData.minutesFrom;
  if (minutes < 0) return null;

  const date = isToTime ? timeData.dateTo : timeData.dateFrom;
  const zoneTime = zoneTimeList.find(item => dateStringToNumber(item.DATUM) === dateToNumber(date));
  if (!zoneTime || !zoneTime.D_OD || !zoneTime.D_DO) return null

  const minutesOfDay = getPlannedParkingData(timeData, zoneTimeList, ticketPriceSums, ticketTimeSums)
    .items.find(x => x.dateNumber === dateToNumber(date))?.dayMinutes ?? 0;
  const cMinJCurrent = getCMinJ(date, minutesOfDay, zoneTimeList, ticketTimeSums)
  const isAdd = buttonId === 2 || buttonId === 4;

  const minutesDDo = getMinutesFromTimeString(zoneTime.D_DO)
  const minutesDOd2 = zoneTime.D_OD2 ? getMinutesFromTimeString(zoneTime.D_OD2) : -1
  const minutesDDo2 = zoneTime.D_DO2 ? getMinutesFromTimeString(zoneTime.D_DO2) : -1
  const minutesDOd3 = zoneTime.D_OD3 ? getMinutesFromTimeString(zoneTime.D_OD3) : -1

  if (minutes === minutesDDo && minutesDOd2 >= 0 && !isToTime && isAdd) {
    minutes = minutesDOd2
  } else if (minutesDOd2 >= 0 && minutesDOd3 >= 0 && minutes === minutesDDo2 && !isToTime && isAdd) {
    minutes = minutesDOd3
  } else {
    switch (buttonId) {
      case 1:
        minutes -= cMinJCurrent;
        break;
      case 2:
        minutes += cMinJCurrent;
        break;
      case 3:
        minutes -= 60;
        break;
      case 4:
        minutes += 60;
        break;
      default:
    }
  }
  minutes = validateMinutesPlanIntervalBounds(isToTime, isAdd, minutes, zoneTimeList, timeData)
  minutes = validateMinutesPlanMaxHoursOneTicket(
    isToTime,
    isAdd,
    minutes,
    maxHoursOneTicket,
    timeData,
    zoneTimeList,
    maxHoursOneTicketWarning,
    ticketPriceSums,
    ticketTimeSums)
  const { minutesFrom, minutesTo, dateTo } = getOverlapValidatedMinutes(isToTime, minutes, zoneTime, timeData)

  return {
    minutesFrom,
    minutesTo,
    dateFrom: timeData.dateFrom,
    dateTo
  }
}

///////////////////////////////////////////////
/* Funkcie na prácu s časom                  */
///////////////////////////////////////////////

/**
 * Vráti celkový počet minút z textovej reťazca vo formáte "HH:MM"
 * @function getMinutesFromTimeString
 * @param {string} text Textový reťazec vo formáte "HH:MM"
 * @returns {number} Celkový počet minút
 * @example getMinutesFromTimeString("01:30") => 90
 */
export const getMinutesFromTimeString = (text) => {
  const [hours, minutes] = text.split(':').map(Number);
  return hours * 60 + minutes;
}

/**
 * Vráti dátum s časom nastaveným na polnoc
 * @function getMidnight
 * @param {Date} date Dátum
 * @returns {Date} Dátum s časom nastaveným na polnoc
 */
export const getMidnight = (date) => {
  const midnight = new Date(date);
  midnight.setHours(0, 0, 0, 0);
  return midnight;
}

/**
 * Vráti včerajší dátum
 * @function previousDay
 * @param {Date} date Aktuálny dátum
 * @returns {Date} Včerajší dátum
 */
const previousDay = (date) => {
  const dtPrev = new Date(date);
  dtPrev.setDate(dtPrev.getDate() - 1);
  return dtPrev;
}

/**
 * Vráti Date s nastavenými hodinami a minútami z textového reťazca vo formáte "HH:MM"
 * @function mergeDateWithTimeString
 * @param {Date} baseDate Dátum
 * @param {string} timeString Textový reťazec s časom vo formáte "HH:MM"
 * @returns {Date | null} Dátum s nastavenými hodinami a minútami
 */
export const mergeDateWithTimeString = (baseDate, timeString) => {
  if (!timeString) return null;

  const date = new Date(baseDate);

  if (timeString === "24:00") {
    date.setHours(23, 59, 0, 0);
  } else {
    const [hours, minutes] = timeString.split(':').map(Number);
    date.setHours(hours, minutes, 0, 0);
  }

  return date;
}

/**
 * Vráti rozdiel medzi dvoma dátumami v dňoch (b - a)
 * @function dateDiffInDays
 * @param {Date} a Prvý dátum
 * @param {Date} b Druhý dátum
 * @returns {number} Rozdiel v dňoch - koľko dní treba od a, aby bolo b
 */
export const dateDiffInDays = (a, b) => {
  const MS_PER_DAY = 1000 * 60 * 60 * 24;

  const dt1 = Date.UTC(a.getFullYear(), a.getMonth(), a.getDate());
  const dt2 = Date.UTC(b.getFullYear(), b.getMonth(), b.getDate());

  return Math.floor((dt2 - dt1) / MS_PER_DAY);
}

/**
 * Vráti celkový počet minút uplynutých od polnoci
 * @function getTotalMinutesOfDay
 * @param {Date} dateTime Dátum
 * @returns {number} Počet minút od polnoci
 */
export const getTotalMinutesOfDay = (dateTime) => dateTime.getHours() * 60 + dateTime.getMinutes();

/**
 * Vráti dátum ako číslo vo formáte YYYYMMDD
 * @function dateToNumber
 * @param {Date} date Dátum
 * @returns {number} Dátum ako číslo
 */
export const dateToNumber = (date) => Number(`${date.getFullYear()}${('00' + (date.getMonth() + 1)).slice(-2)}${('00' + date.getDate()).slice(-2)}`);

/**
 * Vráti dátum ako číslo z textového reťazca vo formáte "YYYY-MM-DD"
 * @function dateStringToDate
 * @param {string} dateString textovy retazec s datumom
 * @returns {number} datum
 */
export const dateStringToNumber = (dateString) => dateToNumber(new Date(dateString));

/**
 * Vráti hodiny z reťazca vo formáte "HH:MM"
 * @function getHours
 * @param {string} time Čas vo formáte "HH:MM"
 * @returns {number} Hodiny
 */
export const getHours = (time) => {
  const pos = time.indexOf(":");
  return Number(time.substring(0, pos));
};

/**
 * Vráti minúty z reťazca vo formáte "HH:MM"
 * @function getMinutes
 * @param {string} time Čas vo formáte "HH:MM"
 * @returns {number} Minúty
 */
export const getMinutes = (time) => {
  const pos = time.indexOf(":");
  return Number(time.substring(pos + 1));
};

/**
 * Zaokrúhli čas na najbližšiu minimálnu jednotku času.
 * Napr. je 14:25 a cMinJ je 15, tak vráti 14:30
 * @function roundTime
 * @param {Date} dateTime Dátum
 * @param {number} cMinJ Minimálna jednotka času
 * @returns {Date} Zaokrúhlený dátum
 */
export const roundTime = (dateTime, cMinJ) => {
  dateTime.setSeconds(0, 0);
  let hours = dateTime.getHours();
  let minutes = hours * 60 + dateTime.getMinutes();
  let minutesRemainder = minutes % cMinJ;
  if (minutesRemainder > 0) {
    minutesRemainder = cMinJ - minutesRemainder;
    minutes += minutesRemainder;
    hours = Math.floor(minutes / 60);
    dateTime.setHours(hours);
    minutes -= hours * 60;
    dateTime.setMinutes(minutes);
  }
  return dateTime;
};

///////////////////////////////////////////////
/* Funkcie na získavanie textov k parkovaniu */
///////////////////////////////////////////////

/**
 * Vráti informačný text s časovými intervalmi parkovania, cenami a jednotkami času
 * @function getInfo1
 * @param {ZoneTime} zoneTime Údaje o parkovacej zóne
 * @param {Function} translate Preklad
 * @returns {string} Informačný text
 * @example "Spoplatnené od 08:00 do 18:00 hod.0,01 € za každých začatých 30 min."
 */
export const getInfo1 = (zoneTime, translate) => {
  if (!zoneTime) return '';

  let info = `${translate('isPaid')} `

  let texts = timeRanges.filter(x => zoneTime[x.start])
    .map(x => `${translate("from")} ${zoneTime[x.start]} ${translate('to')} ${zoneTime[x.end]}`)

  info += texts.join(', ')
  info += ` ${translate('hour')}.`

  const areEqual = zoneTime.C_MIN_J === zoneTime.C_MIN_J2 && zoneTime.SK === zoneTime.D_SK2;
  if (((zoneTime.C_MIN_J && !zoneTime.C_MIN_J2) || areEqual) && !zoneTime.C_MIN_J3) {
    info += `${zoneTime.SK.toString().replace('.', ',')}${translate('parkingShort.infoText2')}${zoneTime.C_MIN_J} min.`
  } else if (zoneTime.C_MIN_J2 && !zoneTime.C_MIN_J3) {
    info += `${zoneTime.SK.toString().replace('.', ',')}${translate('parkingShort.firstStarted')}${zoneTime.C_MIN_J} min., ${zoneTime.D_SK2.toString().replace('.', ',')}${translate('parkingShort.nextStarted')}${zoneTime.C_MIN_J2} min.`;
  } else if (zoneTime.C_MIN_J3) {
    info += `${zoneTime.SK.toString().replace('.', ',')}${translate('parkingShort.firstStarted')}${zoneTime.C_MIN_J} min., ${zoneTime.D_SK2.toString().replace('.', ',')}${translate('parkingShort.secondStarted')}${zoneTime.C_MIN_J2} min., ${zoneTime.D_SK3.toString().replace('.', ',')}${translate('parkingShort.nextStarted')}${zoneTime.C_MIN_J3} min.`;
  }

  return info;
}

/**
 * Vráti informačný text s časom dokedy sa parkuje, limitmi a zónami, v ktorých platí lístok kúpený v danej zóne
 * @function getInfo2
 * @param {ZoneTimeList} zoneTimeList Zoznam údajov o parkovacej zóne
 * @param {TicketPriceSums} ticketPriceSums Sumy cien lístkov po dňoch pre danú zónu a EČV
 * @param {Date} dateOfParking Dátum parkovania
 * @param {Date} timeTo Čas do
 * @param {Function} translate Preklad
 * @returns {string} Informačný text
 * @example "Parkovací lístok do 14. 8. 2024 13:00 hod. Vo vybranej zóne zaplatíte dnes maximálne 0.06€. Lístok v tejto zóne platí aj v zónach: Zóna B, Zóna C1, Zóna X"
 */
export const getInfo2 = (zoneTimeList, ticketPriceSums, dateOfParking, timeTo, translate) => {
  let info = translate('parkingShort.title') + " " + translate('to') + " " + dateOfParking.toLocaleDateString("sk-SK") + " " + timeTo.toLocaleTimeString("sk-SK", { hour: '2-digit', minute: '2-digit' }) + " " + translate('hour');
  let zoneTime = zoneTimeList.find(x => dateToNumber(new Date(x.DATUM)) === dateToNumber(dateOfParking));
  if (!zoneTime) return "";

  if (zoneTime.L_SVIATOK_D7) {
    zoneTime = zoneTimeList.find(x => x.SKR_DEN === "NE");
    if (!zoneTime) return "";
  }

  const currentLimitDate = new Date(zoneTime.DATUM);
  const ticketPriceSum = ticketPriceSums?.find(x => dateStringToNumber(x.DATUM) === dateToNumber(currentLimitDate));

  if (zoneTime.LIMIT) {
    if (ticketPriceSum) {
      const limitMinusSum = Number((zoneTime.LIMIT - ticketPriceSum.TICKET_PRICE_SUM).toFixed(2));
      if ((limitMinusSum) > 0) {
        info += ". " + translate('parkingShort.limitLeft') + " " + limitMinusSum + "€";
      } else {
        info += ". " + translate('parkingShort.noLimitLeft');
      }
    } else {
      info += ". " + translate('parkingShort.limitLeft') + " " + zoneTime.LIMIT + "€";
    }
  }

  if (zoneTime.N_ZONA_RPL) {
    info += ". " + translate('parkingShort.validityInZones') + " " + zoneTime.N_ZONA_RPL;
  }

  return info;
}

/**
 * Vráti textovú reprezentáciu minút vo formáte "HH:MM"
 * @function minutesToString
 * @param {number} value Minúty
 * @returns {string} Textová reprezentácia minút vo formáte "HH:MM"
 * @example minutesToString(90) => "01:30"
 */
export const minutesToString = (value) => {
  if (value != null && value >= 0) {
    const h = Math.floor(value / 60);
    const m = value - h * 60;

    return `${('00' + (h)).slice(-2)}:${('00' + m).slice(-2)}`;
  } else {
    return "-";
  }
};

/**
  * Vráti dátum ako textový reťazec vo formáte "YYYY-MM-DD"
  * @function dateToString
  * @param {Date} date Dátum
  * @returns {string} Textový reťazec vo formáte "YYYY-MM-DD"
 */
export const dateToString = (date) => `${date.getFullYear()}-${('00' + (date.getMonth() + 1)).slice(-2)}-${('00' + date.getDate()).slice(-2)}`;

/**
 * Vráti text pre parkovací lístok
 * @function generPayName
 * @param {number} ECV EČV
 * @param {Date} date Dátum
 * @param {number} minutesFrom Minúty od
 * @param {number} minutesTo Minúty do
 * @param {Function} translate Preklad
 * @returns {string} Text pre parkovací lístok
  */
export const generPayName = (ECV, date, minutesFrom, minutesTo, translate) => {
  let res = `${translate('parkingShort.title')}`;
  res += `, ${translate('ECV')}${ECV}`;
  res += `, ${translate('date')} ${dateToString(date)}`;
  res += ` ${translate('from')} ${minutesToString(minutesFrom)}`;
  res += ` ${translate('to')} ${minutesToString(minutesTo)}`;
  return res;
};

/**
 * Vráti textový reťazec s časom
 * @function getTimeString
 * @param {number} minutes Minúty
 * @returns {string} Textový reťazec s časom
 * @example getTimeString(90) => "1 hod. a 30 min."
 */
export const getTimeString = (minutes) => {
  const hours = Math.floor(minutes / 60);
  const minutesLeft = minutes % 60;
  let timeString;
  if (hours === 0) {
    timeString = `${minutesLeft} min.`;
  } else if (minutesLeft === 0) {
    timeString = `${hours} hod.`;
  } else {
    timeString = `${hours} hod.a ${minutesLeft} min.`;
  }
  return timeString;
};

///////////////////////////////////////////////
/* Definície typov                           */
///////////////////////////////////////////////

/**
 * @typedef {Object} ZoneTime
 * @property {string} DATUM - Dátum parkovania
 * @property {number} C_MIN_J - Prvá minimálna jednotka času v minútach
 * @property {number} C_MIN_J2 - Druhá minimálna jednotka času v minútach (progresívne parkovanie)
 * @property {number} C_MIN_J3 - Tretia minimálna jednotka času v minútach (progresívne parkovanie)
 * @property {string} D_OD - Začiatok prvého časového intervalu parkovania vo formáte "HH:MM"
 * @property {string} D_DO - Koniec prvého časového intervalu parkovania vo formáte "HH:MM"
 * @property {string} D_OD2 - Začiatok druhého časového intervalu parkovania vo formáte "HH:MM"
 * @property {string} D_DO2 - Koniec druhého časového intervalu parkovania vo formáte
 * @property {string} D_OD3 - Začiatok tretieho časového intervalu parkovania vo formáte "HH:MM"
 * @property {string} D_DO3 - Koniec tretieho časového intervalu parkovania vo formáte
 * @property {number} LIMIT - Maximalna cena ktoru zaplati pouzivatel za parkovanie na den
 * @property {number} SK - Cena za jednotku času
 * @property {number} D_SK2 - Cena za druhú jednotku času
 * @property {number} D_SK3 - Cena za tretiu jednotku času
 * @property {boolean} L_SVIATOK_D7 - Či je deň sviatok
 * @property {string} SKR_DEN - Skratka dňa
 * @property {string} N_ZONA_RPL - V týchto zónach platí lístok kúpený v aktuálnej zóne (tento ZoneTime)
 */

/**
 * @typedef {ZoneTime[]} ZoneTimeList
 */

/**
 * @typedef {Object} TicketTimeSum - Suma časov lístkov pre daný deň, zónu a EČV
 * @property {string} DATUM - Dátum sumy časov lístkov
 * @property {number} TICKET_TIME_SUM - Suma časov lístkov v minútach
 */

/**
 * @typedef {TicketTimeSum[]} TicketTimeSums - Sumy časov lístkov po dňoch pre danú zónu a EČV
 */

/**
 * @typedef {Object} TicketPriceSum - Suma cien lístkov pre daný deň, zónu a EČV
 * @property {string} DATUM - Dátum sumy cien lístkov
 * @property {number} TICKET_PRICE_SUM - Suma cien lístkov
*/

/**
 * @typedef {TicketPriceSum[]} TicketPriceSums - Sumy cien lístkov po dňoch pre danú zónu a EČV
*/

/**
 * @typedef {Object} TimeRange - Časový rozsah
 * @property {string} start - Začiatok časového rozsahu vo formáte "HH:MM"
 * @property {string} end - Koniec časového rozsahu vo formáte "HH:MM"
 */

/**
 * @typedef {TimeRange[]} TimeRanges - Zoznam intervalov časových rozsahov
*/

/**
 * Zoznam intervalov časových rozsahov
 * @constant timeRanges
 * @type {TimeRanges}
 */
export const timeRanges = [
  { start: 'D_OD', end: 'D_DO' },
  { start: 'D_OD2', end: 'D_DO2' },
  { start: 'D_OD3', end: 'D_DO3' }
];

/**
 * @typedef {Object} TimeInterval - Časový interval
 * @property {Date} start - Začiatok intervalu
 * @property {Date} end - Koniec intervalu
 */

/**
 * @typedef {TimeInterval[]} TimeIntervals - Zoznam časových intervalov
 */

/**
 * @typedef {Object} TimeIntervalMinutes - Časový interval v minútach
 * @property {number} start - Začiatok intervalu v minútach
 * @property {number} end - Koniec intervalu v minútach
 */

/**
 * @typedef {TimeIntervalMinutes[]} TimeIntervalsMinutes - Zoznam časových intervalov v minútach
 */

/**
 * @typedef {Object} TimeData - Časové údaje pre plánované parkovanie (nezačína v aktuálny čas)
 * @property {number} minutesFrom - Minúty od
 * @property {number} minutesTo - Minúty do
 * @property {Date} dateFrom - Dátum od
 * @property {Date} dateTo - Dátum do
  */

/**
 * @typedef {Object} PlannedParkingData - Vypočítané dáta pre plánované parkovanie
 * @property {Date} dateFrom - Dátum od
 * @property {Date} dateTo - Dátum do
 * @property {number} totalMinutes - Celkový počet minút spoplatneného parkovania
 * @property {number} totalPrice - Celkova cena parkovania
 * @property {PlannedParkingItem[]} items - Detailné informácie o jednotlivých dňoch
 * @property {number} minutesFrom - Minúty od
 * @property {number} minutesTo - Minúty do
 */

/**
 * @typedef {Object} PlannedParkingItem - Detailné informácie o jednotlivých dňoch
 * @property {Date} date - Dátum parkovania
 * @property {number} dateNumber - Dátum parkovania ako číslo
 * @property {string} dOd - Začiatok parkovania vo formáte "HH:MM"
 * @property {string} dDo - Koniec parkovania vo formáte "HH:MM"
 * @property {number} dSk - Cena za jednotku času
 * @property {number} cMinJ - Minimálna jednotka času
 * @property {number} dSk2 - Cena za druhú jednotku času
 * @property {number} cMinJ2 - Druhá minimálna jednotka času
 * @property {number} dSk3 - Cena za tretiu jednotku času
 * @property {number} cMinJ3 - Tretia minimálna jednotka času
 * @property {number} minutesFrom - Minúty od
 * @property {number} minutesTo - Minúty do
 * @property {number} dayMinutes - Počet minút spoplatneného parkovania v daný deň
 * @property {number} dayPrice - Cena parkovania v daný deň
 */

/**
 * @typedef {Object} NowParkingData - Vypočítané dáta pre parkovanie teraz
 * @property {number} hours - Spoplatnené hodiny parkovania
 * @property {number} minutes - Spoplatnené minúty parkovania
 * @property {number} price - Cena parkovania
 * @property {Date | null} timeTo - Čas do
 * @property {number} currentCMinJ - Nová minimálna jednotka času
*/