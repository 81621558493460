import clsx from 'clsx';
import { connect } from 'react-redux';

import Stack from '@mui/material/Stack';
import Divider from '@mui/material/Divider';
import useMediaQuery from '@mui/material/useMediaQuery';

import "./rowDetail.css";

import { formatDecimals, escapeRegExp, removeNonPrintableCharacters } from './helper';
import * as rozpocetActions from '../../actions/rozpocetActions';

const LOCALE_CODE = "sk-SK";
const REG_EXP_DELETE_TAG = /<\/?[^>]+(>|$)/g; // odtranenie vsetkych HTML tagov <...>
const REG_EXP_ASCENTS = /[\u0300-\u036f]/g; // odtranenie diakritiky
const REG_EXP_NBSP = /\u00A0/g; // odtranenie tvrdych medzier
const HIGHLIGHT_START_TAG = `<span class='row-detail-searched-text'>`; // startovaci tag pre zvyraznenie textu po fulltext search
const HIGHLIGHT_END_TAG = `</span>`; // ukoncovaci tag pre zvyraznenie textu po fulltext search

/**
 * Custom definicia detailu jedneho riadku pre TreeView
 *
 * @param {*} {
 *    initProgRozp - init data z reducera
 *    item - hodnoty polozky jedneho riadku
 *    count - pocet potomkov, ak ma riadok potomkov
 *    handleClick - handler kliku na ikonu '+/-'
 *    isTitle - priznak, ci riadok je titulok tabulky
 *    customClass - override class pre rormatovanie riadku
 *    themeCustom - referencia na custom temu, pre riadenie breakpoints
 *    fullText - hladany text po fulltext search
 * @return {*} 
 */
const RowDetail = ({ initProgRozp, item = {}, count = null, handleClick = () => { }, isTitle = false, customClass = null, themeCustom, fullText = '' }) => {
  const matchesThemeMD = useMediaQuery(themeCustom?.breakpoints?.down('xs'));
  const CFG_PG = initProgRozp;
  const NUMBER_OF_DECIMALS = CFG_PG.numberOfDecimals;

  // TODO: na zvazenie: menit rozlozenie prvkov podla pixelov alebo podla screen orientation?
  // const getOrientation = () =>
  // window.screen.orientation.type

  /**
     * Zvyrazni hladany text. Ak sa v retazci najde hladany text, doplni v mieste vyskytu startovaci a ukoncovaci tag, 
     *
     * @param {*} str - retazec v ktorom hladam
     * @param {*} regExp - reg.vyraz ktory hladam
     * @return {*} 
     */
  const highlightSearchString = (str, regExp) => {
    // zrus diakritiku, velkost pismen, a nezlomitelne medzery
    let strOptimized = str.normalize("NFD").replace(REG_EXP_ASCENTS, "").toLowerCase().replace(REG_EXP_NBSP, " ");
    // otoc poradie, ze zacinam doplat od konca, aby som neprisiel o pozicie kde doplnat
    const matches = [...strOptimized.matchAll(regExp)].reverse();

    for (const match of matches) {
      let start = match.index;
      let end = match.index + match[0].length;
      str = str.slice(0, end) + HIGHLIGHT_END_TAG + str.slice(end);
      str = str.slice(0, start) + HIGHLIGHT_START_TAG + str.slice(start);
    }

    return str;
  };

  // nastav '' ak je fullText nill alebo true
  fullText = fullText === true || !fullText ? '' : fullText;
  // osetri specialne znaky
  fullText = escapeRegExp(fullText);
  fullText = removeNonPrintableCharacters(fullText);
  // zrus diakritiku a velke pismena v hladanom texte
  const regExp = new RegExp(fullText?.normalize("NFD").replace(REG_EXP_ASCENTS, "").toLowerCase(), 'gi');

  // odstran HMTL tagy z textu a zvyrazni ak je fulltext
  let title = item.title && fullText ? highlightSearchString(item.title?.replace(REG_EXP_DELETE_TAG, ""), regExp) : item.title?.replace(REG_EXP_DELETE_TAG, "");
  let R_SCHVAL = isTitle ? item.R_SCHVAL : formatDecimals(item.R_SCHVAL, NUMBER_OF_DECIMALS, LOCALE_CODE) + ' €';
  let R_UPRAV = isTitle ? item.R_UPRAV : formatDecimals(item.R_UPRAV, NUMBER_OF_DECIMALS, LOCALE_CODE) + ' €';
  let R_SKUT = isTitle ? item.R_SKUT : formatDecimals(item.R_SKUT, NUMBER_OF_DECIMALS, LOCALE_CODE) + ' €';
  let percentoPlnenia = isTitle ? item.percentoPlnenia : formatDecimals(item.percentoPlnenia, NUMBER_OF_DECIMALS, LOCALE_CODE) + '%';

  // ak je aktivne vthladavanie (fullText ma hodnotu), tak dopln zvyraznovaci tag v mieste vyskytu hladaneho textu
  R_SCHVAL = R_SCHVAL && fullText ? highlightSearchString(R_SCHVAL, regExp) : R_SCHVAL;
  R_UPRAV = R_UPRAV && fullText ? highlightSearchString(R_UPRAV, regExp) : R_UPRAV;
  R_SKUT = R_SKUT && fullText ? highlightSearchString(R_SKUT, regExp) : R_SKUT;
  percentoPlnenia = percentoPlnenia && fullText ? highlightSearchString(percentoPlnenia, regExp) : percentoPlnenia;

  return (
    <Stack
      className={clsx(customClass, "row-detail-wrapper")}
      direction={matchesThemeMD ? 'column' : 'row'}
      divider={<Divider orientation="vertical" flexItem />}
    >
      {/* <div> su schvalne namiesto <Typography>, kvoli zvyseniu rychlosti vykreslovania */}
      <div
        onClick={handleClick}
        className={'row-detail-title-wrapper first-line-indent' + (isTitle ? '' : ' row-detail-title-pointer')}
        // style={{textAlign: matchesThemeMD ? 'right' : 'left'}} // zarovnanie titulu
        dangerouslySetInnerHTML={{
          __html: `${title}&nbsp;${count ? `<span onClick={handleClick} class='supplement-text'>[${count}x]</span>` : ''}`
        }}
      />

      <Stack className='row-detail-numbers-group'
        direction={matchesThemeMD ? 'column' : 'row'}
        spacing={matchesThemeMD ? 0 : 1} // pre riadkove robrazenie nedavaj medzeru
        divider={<Divider orientation="vertical" flexItem />}
      >
        <div className='row-detail-r-schval-wrapper row-detail' dangerouslySetInnerHTML={{ __html: `${R_SCHVAL}` }} />
        <div className='row-detail-r-uprav-wrapper row-detail' dangerouslySetInnerHTML={{ __html: `${R_UPRAV}` }} />
        <div className='row-detail-r-skut-wrapper row-detail' dangerouslySetInnerHTML={{ __html: `${R_SKUT}` }} />
        <div className='row-detail-percento-plnenia-wrapper'
          style={{ fontWeight: 'inherit', whiteSpace: matchesThemeMD ? 'no-wrap' : 'pre-line' }} // rozdel titulok na viac riadkov pri stlpcovom zbrazeni
          dangerouslySetInnerHTML={{ __html: `${percentoPlnenia}` }}
        />
      </Stack>
    </Stack >
  );
};

RowDetail.propTypes = {
};

const mapStateToProps = (state) => ({
  isInitLoading: state.initProgRozp.isLoading,
  initProgRozp: state.initProgRozp.data,
});

const mapDispatchToProps = (dispatch) => ({
  getInitProgRozp: () => dispatch(rozpocetActions.getInitProgRozp()),
});

// export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(RowDetail));
export default connect(mapStateToProps, mapDispatchToProps)(RowDetail);