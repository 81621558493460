import CircularProgress, { circularProgressClasses } from '@mui/material/CircularProgress';
import { Box } from '@mui/material';

function CircularProgressV2Component(props) {
  let { loading = true, top = "45%", left = "50%", customColorPrimary, size = 60 } = props

  if (loading)
    return (
      <Box sx={{
        position: 'absolute',
        top: top,
        left: left,
        transform: "translate(-45%, -45%)",
        zIndex: 1102
      }}>
        <CircularProgress
          variant="determinate"
          sx={{
            color: (theme) =>
              theme.palette.grey[theme.palette.mode === 'light' ? 300 : 800],
          }}
          size={size}
          thickness={4}
          value={100}
          {...props}
        />
        <CircularProgress
          variant="indeterminate"
          disableShrink
          sx={{
            color: (theme) => customColorPrimary ? customColorPrimary : theme.palette.error.light,
            animationDuration: '1000ms',
            position: 'absolute',
            left: 0,
            [`& .${circularProgressClasses.circle}`]: {
              strokeLinecap: 'round',
            },
          }}
          size={size}
          thickness={4}
          value={50}
          {...props}
        />
      </Box>
    );
  else
    return null;
}

export default CircularProgressV2Component